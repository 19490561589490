import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import { getLocationReferences } from '../../../api/viator';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';
import Loader from '../../Loader';
import ActivityItinerary from './ItineraryTypes/ActivityItinerary';
import SampleMenu from './ItineraryTypes/ActivityItinerary/SampleMenu';
import HopOnItinerary from './ItineraryTypes/HopOnItinerary';
import MultiDayItinerary from './ItineraryTypes/MultiDayItinerary';
import StandardItinerary from './ItineraryTypes/StandardItinerary';
import UnstructuredItinerary from './ItineraryTypes/UnstructuredItinerary';

const useStyles = makeStyles((theme) => ({
  itineraryContainer: {
    borderLeft: `1px dotted #d7d7d7`,
    paddingLeft: '25px',
    lineHeight: '24px',
  },
  itineraryItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',

    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  dayItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    '&:not(:first-child)': {
      marginTop: '20px',
    },
  },
  stopName: {
    marginBottom: '10px',
  },
  itineraryPoint: {
    marginLeft: '-29px',
    position: 'absolute',
    top: '8px',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  bodyText: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  unstructuredBodyText: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
    whiteSpace: 'pre-line',
  },
  title: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  routeTitle: {
    fontSize: '18px',
    marginBottom: '5px',
  },
  marginLeftTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    marginLeft: '10px',
  },
  thirdHeading: {
    fontWeight: '600',
    margin: '0',
    padding: '0 0 6px',
    fontSize: '18px',
  },
  bottomDetails: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: FontSizes.cta,
    color: '#63666a',
    marginBottom: '10px',
  },
  titleStop: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  admission: {
    marginLeft: '10px',
  },
  menuCourse: {
    fontWeight: '600',
  },
  menuText: {
    marginBottom: '5px',
  },
  pointsOfInterestContainer: {
    marginBottom: '10px',
  },
  pointOfInterest: {
    fontSize: FontSizes.cta,
    '&:not(:last-child)': {
      marginBottom: '5px',
    },
  },
}));

function formatDuration(durationInMinutes) {
  if (!isNaN(durationInMinutes)) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes % 60;
    return `${localization.tours.about.duration}: ${
      hours
        ? `${hours} ${
            hours > 1 ? localization.tours.duration.hours : localization.tours.duration.hour
          }`
        : ''
    } ${
      minutes
        ? `${minutes} ${
            minutes > 1 ? localization.tours.duration.minutes : localization.tours.duration.hour
          }`
        : ''
    }`;
  }
}

function formatAdmission(admission, classes) {
  if (admission !== 'NOT_APPLICABLE') {
    return (
      <span className={classes.admission}>
        {admission === 'YES'
          ? localization.itinerary.admissionIncluded
          : localization.itinerary.admissionNotIncluded}
      </span>
    );
  }
}

export default function TourItinerary({ itinerary }) {
  const [itineraryLocations, setLocations] = useState([]);
  const classes = useStyles();
  const itineraryTypes = {
    STANDARD: 'STANDARD',
    MULTI_DAY_TOUR: 'MULTI_DAY_TOUR',
    HOP_ON_HOP_OFF: 'HOP_ON_HOP_OFF',
    UNSTRUCTURED: 'UNSTRUCTURED',
    ACTIVITY: 'ACTIVITY',
  };

  useEffect(() => {
    getItineraryLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itinerary]);

  function collectLocationRefs() {
    switch (itinerary.itineraryType) {
      case itineraryTypes.STANDARD: {
        return itinerary.itineraryItems?.map((item) => item.pointOfInterestLocation.location.ref);
      }

      case itineraryTypes.MULTI_DAY_TOUR: {
        const items = itinerary.days.flatMap((day) => day.items);

        return items?.map((item) => item.pointOfInterestLocation.location.ref);
      }

      case itineraryTypes.HOP_ON_HOP_OFF: {
        const items = itinerary.routes.flatMap((route) =>
          route.pointsOfInterest.concat(route.stops)
        );

        return items?.map((item) => {
          if (item.location) {
            return item.location.ref;
          }
          return item.stopLocation.ref;
        });
      }

      case itineraryTypes.UNSTRUCTURED: {
        return itinerary.pointOfInterestLocations?.map((item) => item.location.ref);
      }

      case itineraryTypes.ACTIVITY: {
        return [itinerary.activityInfo.location.ref];
      }

      default:
        return [];
    }
  }

  async function getItineraryLocations() {
    const updatedLocations = await getLocationReferences(collectLocationRefs());
    setLocations(updatedLocations);
  }

  const itineraryDisplayComponent = {
    STANDARD: (
      <StandardItinerary
        itinerary={itinerary}
        itineraryLocations={itineraryLocations}
        classes={classes}
        formatAdmission={formatAdmission}
        formatDuration={formatDuration}
      />
    ),
    MULTI_DAY_TOUR: (
      <MultiDayItinerary
        itinerary={itinerary}
        itineraryLocations={itineraryLocations}
        classes={classes}
        formatAdmission={formatAdmission}
        formatDuration={formatDuration}
      />
    ),
    ACTIVITY: (
      <ActivityItinerary
        itinerary={itinerary}
        itineraryLocations={itineraryLocations}
        classes={classes}
        formatDuration={formatDuration}
      />
    ),
    UNSTRUCTURED: (
      <UnstructuredItinerary
        itinerary={itinerary}
        itineraryLocations={itineraryLocations}
        classes={classes}
        formatDuration={formatDuration}
      />
    ),
    HOP_ON_HOP_OFF: (
      <HopOnItinerary
        itinerary={itinerary}
        itineraryLocations={itineraryLocations}
        classes={classes}
        formatDuration={formatDuration}
      />
    ),
  };

  if (!itinerary || !itineraryLocations?.length) return <Loader />;

  return (
    <>
      <Row>
        <Col md="2">
          <div className={classes.thirdHeading}>{localization.itinerary.itinerary}</div>
        </Col>
        <Col md="10">
          <div className={classes.itineraryContainer}>
            {itineraryDisplayComponent[itinerary.itineraryType] || null}
          </div>
        </Col>
      </Row>
      {itinerary.foodMenus && <SampleMenu itinerary={itinerary} />}
    </>
  );
}
