import {
    SET_FILTER_DATE, 
    SET_FILTER_PAGE, 
    SET_FILTERS,
    FETCH_FILTER_OPTIONS_SUCCESS,
    FETCH_FILTER_OPTIONS_INIT,
  
} from './actionTypes';
import defaultState from '../defaultState';

export default function filterReducer(state = defaultState.filter, {type,payload}){
    switch(type){
        case SET_FILTER_PAGE:
            return {
                ...state,
                filters:{
                    ...state.filters,
                    page: payload
                }
            };
        case SET_FILTERS:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    ...payload
                }
            };
        case SET_FILTER_DATE:
            return {
                ...state,
                filters:{
                    ...state.filters,
                    fromDate: payload.from,
                    toDate: payload.to,
                }
            }
        case FETCH_FILTER_OPTIONS_SUCCESS:
            return {
                ...state,
                filterOptions: payload,
                isLoaded: true,
                view: payload
            }
        case FETCH_FILTER_OPTIONS_INIT:
            return {
                ...state,
                filterOptions:[],
                isLoaded: false,
                view: payload
            }
       
      
        default:
            return state;
    }
}