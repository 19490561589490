import { FETCH_TOUR_INIT, FETCH_TOUR_SUCCESS, FETCH_TOUR, FETCH_TOUR_ERROR } from './actionTypes';
import {HIDE_FALLBACK_MODAL, FALLBACK_FETCH} from '../fallbackTours/types';
import { FETCH_TOUR_OPTIONS_INIT, FETCH_TOUR_OPTIONS_SUCCESS, FETCH_TOUR_OPTIONS_ERROR } from '../tourOptions/actionTypes'
import { fetchTour as fetchTourData, fetchPricings, fetchAvailabilities } from '../../api/tour';
import { call, put, all, takeLatest, fork } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';

function* fetchTour({ payload: { tourId, language, locationId } }) {
    try {

        yield put({ type: FETCH_TOUR_INIT });
        yield put({ type: FETCH_TOUR_OPTIONS_INIT });
        yield put({ type: HIDE_FALLBACK_MODAL});

        const tour = yield call(fetchTourData, { tourId, language });
        yield put({ type: FETCH_TOUR_SUCCESS, payload: tour });
    } catch (error) {
        console.error(error);
        yield put({type: FETCH_TOUR_ERROR, payload: {error: error.message} })
        yield put({ type: FALLBACK_FETCH, payload: {failedTourId: tourId, language, locationIds: [locationId]}});
    }
}

export function* watchTour() {
    yield takeLatest(FETCH_TOUR, fetchTour);
}