import { StyleBreakpoints } from '@constants';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import paymentPoweredByStripe from '../../../assets/img/poweredByStripe/PaymentButton_TrustSignals.svg';
import localization from '../../../localization';
import { formatIfInr, formatPrice, getPriceWithCurrencySymbol } from '../../../utils/helpers';
import { ButtonLargeWithHover } from '../../Common';
import { LoaderVerySmall } from '../../Loader';

const useStyles = makeStyles((theme) => ({
  purchaseButton: {
    height: '44px',
    width: 'auto',
    minWidth: '240px',
    maxWidth: 'unset',
    margin: '18px 0 20px',
    fontSize: '18px',
    boxShadow: '0 4px 7px 0 rgba(0, 0, 0, 0.5)',
    borderRadius: '6.7px',

    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      width: '100%',
    },
  },
  paymentTerms: {
    marginBottom: '12px',
  },
  poweredByImg: {
    display: 'block',
    width: '240px',
    marginBottom: '30px',

    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      width: '100%',
      height: '40px',
    },
  },
}));

const PurchaseTotal = ({ total, isCheckoutInProgress, bookerCurrency = null }) => {
  const classes = useStyles();

  return (
    <div>
      <div>
        <ButtonLargeWithHover
          className={classes.purchaseButton}
          type="submit"
          disabled={isCheckoutInProgress}
        >
          {isCheckoutInProgress ? (
            <LoaderVerySmall />
          ) : (
            <>
              <span>{localization.payment.completePurchase} </span>
              {getPriceWithCurrencySymbol(formatIfInr(formatPrice(total)), bookerCurrency)}
            </>
          )}
        </ButtonLargeWithHover>
        <img
          src={paymentPoweredByStripe}
          className={classes.poweredByImg}
          alt="Payment powered by Stripe"
        />
        <div className={classes.paymentTerms}>{localization.payment.terms}</div>
      </div>
    </div>
  );
};

export default PurchaseTotal;
