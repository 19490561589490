import { StyleBreakpoints } from '@constants';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import localization from '../../localization';
import { Analytics, getBookedRecentlyNumber } from '../../utils/analytics';
import { FontSizes } from '../../utils/constants';
import { isLongTourCard } from '../../utils/experimentConstants';
import { Bold as BoldText } from '../Common';
import Loader from '../Loader';
import TourCard from '../TourCard';
import LongTourCard from '../TourCard/LongCard';

const textStyle = `font-size: 34px;
margin-top: 42px; 
color: black;
text-align: left`;

const Text = styled.div(textStyle);

const Bold = styled(BoldText)(textStyle);

const Wrapper = styled(Row)``;

const LongCard = styled(Col).attrs({ sm: 12, md: 12, lg: 12, xl: 12 })`
  display: flex;
`;

const Card = styled(Col).attrs({ sm: 12, md: 6, lg: 4, xl: 4 })`
  display: flex;
  margin-bottom: 20px;
`;

const Heading = styled.div`
  display: flex;
`;

const Link = styled(NavLink)`
  flex: 1 0 20%;
  display: block;
  text-align: right;
  font-size: ${FontSizes.h2};
  color: ${(props) => props.theme.colors.primary};
  display: none;
  @media (min-width: ${StyleBreakpoints.sm}px) {
    display: block;
  }
`;

const Title = styled.div`
  flex: 1 0 100%;
  @media (min-width: ${StyleBreakpoints.sm}px) {
    flex: 1 0 80%;
  }
`;

const useStyles = makeStyles({
  scrollableTarget: {
    height: 'auto',
    marginTop: '10px',
    '&::-webkit-scrollbar': {
      width: '10px',
      paddingRight: '6px',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#d5d1d1',
      width: '10px',
      borderRadius: '6px',
    },
  },
});

export const ToursTitle = ({ location }) => {
  switch (location.type) {
    case 'CITY':
    case 'AREA':
      return (
        <Heading>
          <Title>
            <Text>
              {localization.location.nearBy} <Bold>{localization.location.attractions}</Bold>
            </Text>
          </Title>
          <Link to={`/search/${location.english_name}`}>
            {localization.location.viewAllActivities}
          </Link>
        </Heading>
      );
    default:
      return (
        <Text>
          Tours visiting<Bold>this attraction</Bold>
        </Text>
      );
  }
};

function alignToursAmount(t) {
  if (t.length >= 9) {
    if (window.innerWidth >= StyleBreakpoints.lg) {
      do {
        t.pop();
      } while (t.length % 4 !== 0);
    } else if (window.innerWidth < StyleBreakpoints.md) {
      if (t.length % 2 !== 0) {
        t.pop();
      }
    }
    return t;
  } else {
    return t;
  }
}

const LOADER_HEIGHT = 180;
const MARGIN_TO_START_LOADING = 200;

const Tours = ({
  staticNoScroll,
  tours = [],
  isLoaded,
  canLoadMore,
  onLoadMore,
  extraTrackingProperties,
}) => {
  const [toursToRender, setToursToRender] = useState([]);
  const classes = useStyles();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWidth = () => setScreenWidth(window.innerWidth);
    window.addEventListener('resize', updateWidth);
    return () => window.removeEventListener('resize', updateWidth);
  }, [screenWidth]);

  useEffect(() => {
    const alignedTours = canLoadMore ? alignToursAmount(tours) : tours;
    setToursToRender(alignedTours);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tours]);

  useEffect(() => {
    const toursContainer = document.getElementById('tours-scroll-container');
    const hasNoScroll = toursContainer.scrollHeight === toursContainer.offsetHeight;

    function checkLoadMoreRelevance() {
      if (isBottomReached(toursContainer) && hasNoScroll && isLoaded && canLoadMore) {
        onLoadMore();
      }
    }

    if (hasNoScroll) {
      document.addEventListener('scroll', checkLoadMoreRelevance);
    }

    return () => {
      document.removeEventListener('scroll', checkLoadMoreRelevance);
    };
  }, [canLoadMore, isLoaded, onLoadMore, tours]);

  function isBottomReached(el) {
    return (
      el.getBoundingClientRect().bottom - LOADER_HEIGHT - MARGIN_TO_START_LOADING <=
      window.innerHeight
    );
  }

  const CardWrapper = isLongTourCard() && screenWidth > StyleBreakpoints.sm ? LongCard : Card;
  const CardComponent =
    isLongTourCard() && screenWidth > StyleBreakpoints.sm ? LongTourCard : TourCard;

  return (
    <div id="tours-scroll-container" className={staticNoScroll ? null : classes.scrollableTarget}>
      <InfiniteScroll
        dataLength={toursToRender.length}
        next={() => {
          if (isLoaded) {
            onLoadMore();
          }
        }}
        hasMore={canLoadMore}
        scrollThreshold="80%"
        style={{ overflow: 'none' }}
        scrollableTarget="tours-scroll-container"
      >
        <Wrapper>
          {toursToRender.map((tour, i) => (
            <CardWrapper
              key={`location-${i - 1}`}
              onClick={() => {
                Analytics.track('tour selected', {
                  ...extraTrackingProperties,
                  'tour name': tour.Title,
                  'tour id': tour.TourID,
                  'tour index': i,
                  'likely to sell out': tour.Flags.likelyToSellOut,
                  'booked recently displayed': Boolean(getBookedRecentlyNumber(tour.TourID)),
                  'booked recently number': getBookedRecentlyNumber(tour.TourID),
                });
              }}
            >
              <CardComponent item={tour} inList />
            </CardWrapper>
          ))}
        </Wrapper>
      </InfiniteScroll>
      {canLoadMore && (
        <div style={{ height: `${LOADER_HEIGHT}px`, overflow: 'hidden' }}>
          {!isLoaded && <Loader />}
        </div>
      )}
    </div>
  );
};

export default Tours;
