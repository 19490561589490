export const generatePaxMix = participants => {
  return Object.keys(participants).map(type => ({
    ageBand: type,
    numberOfTravelers: participants[type]
  }));
};

export const generateBreakdown = lineItems => {
  return lineItems.map(lineItem => ({
    type: 'participant',
    priceType: 'pax',
    title: lineItem.ageBand,
    count: lineItem.numberOfTravelers,
    elementPrice: '',
    elementPriceWithoutDiscount: '',
    textRepresentation: `${lineItem.ageBand} x ${lineItem.numberOfTravelers} - ${lineItem.subtotalPrice.price.recommendedRetailPrice}`
  }));
};

export const getEmptyMock = () => [];

export const getTimeslotsTime = startTime => ({
  hours: startTime?.split(':')[0] || '',
  minutes: startTime?.split(':')[1] || ''
});

export const formatTimeSlotsPrice = price => {
  return Number.isInteger(price) ? price : price.toFixed(2);
};

export const getTimeslotsPrice = (lineItems, totalPrice, currency) => ({
  breakdown: generateBreakdown(lineItems),
  total: formatTimeSlotsPrice(totalPrice.price.recommendedRetailPrice),
  totalWithoutDiscount: formatTimeSlotsPrice(totalPrice.price.recommendedRetailPrice),
  currency: currency.toUpperCase()
});

export const getTimeSlot = ({ selectedTimeslot }) => {
  if (!selectedTimeslot) return null;

  return selectedTimeslot.hours + ':' + selectedTimeslot.minutes;
};

export const getLanguageGuides = ({ languageGuides, selectedLanguage }) => {
  if (!selectedLanguage) return null;

  return languageGuides.filter(guide => guide.language === selectedLanguage)[0];
};

export const getLanguageGuidesForProductOption = (tour, productOptionId) => {
  const option = tour.productOptions?.find(e => e.productOptionCode === productOptionId);
  if (option?.languageGuides?.length) {
    return option.languageGuides.map(lg => lg.language);
  }
  return [];
};
