import React, { Component } from 'react';
import Truncate from 'react-truncate';
import { Col, Row } from 'reactstrap';
import localization from '../../../localization';
import { stripeTags } from '../../../utils/TextUtils';
import { TextPrimaryNormal } from '../../Common';

export default class Text extends Component {
  constructor(props) {
    super(props);
    const { title, text } = props;
    this.state = {
      title,
      text: stripeTags(text),
      expanded: false
    };

    this.scrollRef = React.createRef();
  }

  scrollToSelf() {
    setTimeout(() => {
      const offset = this.scrollRef.current.getBoundingClientRect().top;
      if (offset >= 0) return;
      window.scrollTo({
        top: window.scrollY + offset,
        behavior: 'smooth'
      });
    }, 0);
  }

  render() {
    const { expanded } = this.state;
    return (
      <div ref={this.scrollRef}>
        <Row style={{ textAlign: 'left', lineHeight: '24px' }}>
          <Col md="2">
            <div className="third-heading">{this.state.title}</div>
          </Col>

          <Col md="10">
            <Truncate
              lines={!expanded && 3}
              ellipsis={
                <TextPrimaryNormal onClick={() => this.setState({ expanded: true })}>
                  {localization.common.showMore}
                </TextPrimaryNormal>
              }
            >
              {this.state.text.split(/\n/g).map(p => (
                <div>
                  {p[0] === '\u2022' ? `${p[0]} ${p.substring(1)}` : p}
                  <br />
                </div>
              ))}
            </Truncate>
            {expanded && (
              <TextPrimaryNormal
                onClick={() => {
                  this.setState({ expanded: false });
                  this.scrollToSelf();
                }}
              >
                {localization.common.showLess}
              </TextPrimaryNormal>
            )}
          </Col>
        </Row>
      </div>
    );
  }
}
