import moment from "moment";
import { toPairs } from "lodash/fp";

export const languages = tour => tour.CondLanguages
export const GYG_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss'
export const extractDateFromISO = isoDateString => isoDateString.substr(0, 10);
export const availabilitiesForDay = day => availabilities =>
    availabilities.filter(x => moment(x.start_time, GYG_DATE_FORMAT).isSame(day, 'day'));

const getCategory = (pricing, name) => {
    const category = pricing.categories.find(x => x.name === name);
    if (category === undefined) throw new Error(`This option doesn't have ${name} option available`);
    return category;
}
const getScalePax = (category, count) => {
    const scale = category.scale.find(x => x.min_participants <= count && x.max_participants >= count);
    if (scale === undefined) throw new Error(`No sutable scale found for category ${category.count}`);
    return scale;
}
const flatPrice = (category, count) => {
    const scale = category.scale.sort((a, b) => a.max_participants - b.max_participants);
    const biggestScale = scale[scale.length - 1];
    const biggestScaleCount = Number.parseInt(count / biggestScale.max_participants);
    const restCount = count % biggestScale.max_participants;
    const restPrice = restCount > 0 ? scale.find(x => x.max_participants >= restCount).retail_price: 0;
    return biggestScaleCount * biggestScale.retail_price + restPrice;
}

const isFlatPricing = pricing => pricing.categories.length > 0 && pricing.categories[0].scale.some(scale => scale.type === 'flat');

const priceDetails = (priceMultiplier, pricing) => selectedOptions => selectedOptions.reduce(
    (priceDetails, [optionName, count]) => {
        const category = getCategory(pricing, optionName);
        const scale = getScalePax(category, count);
        const perItem = scale.retail_price * priceMultiplier;
        const price = perItem * count;
        priceDetails.breakdown.push({
            category,
            price,
            perItem,
            count,
            name: optionName
        })
        priceDetails.total += price;
        priceDetails.totalWithoutDiscount += price / priceMultiplier;
        return priceDetails;
    },
    {
        breakdown: [],
        total: 0,
        totalWithoutDiscount: 0,
        isPax: true
    }
);
export const optionLanguages = option => Object.values(option.cond_language).flat();
export const optionHasLanguage = language => option => language === null || optionLanguages(option).some(x => x === language);

export const setPricingManifest = pricings => manifest => option => {
    try {
        const { selectedAvailability } = option;
        if (!selectedAvailability) {
            return { ...option, priceDetails: null, priceError: new Error('Not available') };
        }
        const pricing = pricings[selectedAvailability.pricing_id];
        const priceMultiplier = 1 - (selectedAvailability.discount || 0) / 100.0;
        const selectedOptions = toPairs(manifest).filter(
            ([_, count]) => count > 0
        );
        if (isFlatPricing(pricing)) {
            const totalPersonCount = selectedOptions.reduce(
                (acc, [_,count]) => acc + count,
                0
            );
            const price = flatPrice(pricing.categories[0], totalPersonCount);
            return {
                ...option,
                priceDetails:{
                    isPax: false,
                    total: price * priceMultiplier,
                    totalWithoutDiscount: price,
                    count: totalPersonCount,
                    category: pricing.categories[0]
                },
                priceError: null
            }
        }
        const optionPricing = priceDetails(priceMultiplier, pricing)(selectedOptions);
        return {
            ...option,
            priceDetails: optionPricing,
            priceError: null
        }
    } catch (e) {
        return { ...option, priceDetails: null, priceError: e }
    }
}

export const setSelectedLanguage = language => option => ({
    ...option,
    hasSelectedLanguage: optionHasLanguage(language)(option)
});
export const setSelectedDay = availabilities => date => option => {
    if (date === null) {
        return {
            ...option,
            timeslots: [],
            selectedAvailability: null
        }
    }
    const timeslots = availabilitiesForDay(date)(availabilities[option.option_id]);
    const selectedAvailability = timeslots[0] || null;
    return {
        ...option,
        timeslots,
        selectedAvailability
    }
}

export const pricingCategories = priceDetails => {
    if(priceDetails.isPax){
        return  priceDetails.breakdown.map(x => ({
            number_of_participants: x.count,
            category_id: x.category.id
        }))
    }

    return [
        {
            number_of_participants: priceDetails.count,
            category_id: priceDetails.category.id
        }
    ]
}
