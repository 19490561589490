import { InputAdornment, MenuItem, Select, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { Field } from 'formik';
import React, { useState } from 'react';
import styled from 'styled-components';
import { FontSizes } from '../../../utils/constants';
import { formatDateToDMY } from '../../../utils/helpers';
import { CalendarIcon } from '../../TourPage/TourOptions/BookingWidget/Datepicker';

const useStyles = makeStyles({
  inputRoot: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: '#767676',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
      paddingLeft: '14px',
    },
  },
  textField: {
    width: '114.28%', // 16 / 14 * 100, https://thingsthemselves.com/no-input-zoom-in-safari-on-iphone-the-pixel-perfect-way/
    fontSize: FontSizes.p,
    transform: 'scale(0.875)', // 16 / 14
    transformOrigin: 'left center',
  },
  unitSelect: {
    backgroundColor: '#d3d3d359',

    '& .MuiOutlinedInput-notchedOutline': {
      border: 0,
    },
  },
});

const FormInput = styled.div`
  flex-basis: 100%;
  display: block;
  width: 100%;
  margin: 10px 0px;
`;

const FieldType = {
  STRING: 'string',
  NUMBER: 'number',
  DATE: 'date',
};

const CustomizedTextField = ({ field, form, placeholder, ...props }) => {
  const classes = useStyles();
  const [adornmentValue, setAdornmentValue] = useState(props.units?.[0] || null);
  const [categoryOfField, name] = field.name.split('.');
  const [date, setDate] = useState((field.value && new Date(field.value)) || null);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  if (props.type === 'DATE') {
    return (
      <>
        <DesktopDatePicker
          {...props}
          {...field}
          open={isDatePickerOpen}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          value={date}
          color="primary"
          onChange={(value) => {
            setDate(value);
            if (value instanceof Date && !isNaN(value)) {
              props.setFieldValue(field.name, formatDateToDMY(new Date(value)));
            }
          }}
          className={classes.textField}
          classes={{
            root: classes.inputRoot,
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              classes={{
                root: classes.inputRoot,
              }}
              onClick={() => setIsDatePickerOpen(true)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </>
    );
  }

  return (
    <>
      <TextField
        {...props}
        {...field}
        onBlur={(e) => {
          form.handleBlur(e);
          if (props.onBlur) {
            props.onBlur(e);
          }
        }}
        type={FieldType[props.type] || 'string'}
        classes={{ root: classes.inputRoot }}
        className={classes.textField}
        variant="outlined"
        color="primary"
        error={Boolean(form.errors[categoryOfField]?.[name]) || props.error}
        helperText={form.errors[categoryOfField]?.[name] || props.helperText}
        label={placeholder}
        InputProps={{
          startAdornment:
            props.units && props.type !== 'LOCATION_REF_OR_FREE_TEXT' ? (
              <>
                <Select
                  className={classes.unitSelect}
                  value={adornmentValue}
                  onChange={(e) => {
                    setAdornmentValue(e.target.value);
                  }}
                >
                  {props.units.map((unit, i) => {
                    return (
                      <MenuItem key={i} value={unit}>
                        {unit}
                      </MenuItem>
                    );
                  })}
                </Select>
              </>
            ) : null,
        }}
        onChange={(e) => {
          const emailFields = [
            'cardholder.email',
            'cardholder.emailConfirmation',
            'traveler.email',
            'traveler.emailConfirmation',
          ];
          if (emailFields.includes(e.target.name)) {
            e.target.value = e.target.value.trim();
          }
          form.handleChange(e);

          if (adornmentValue) {
            props.setFieldValue(`${field.name}-unit`, adornmentValue);
          }
        }}
      />
    </>
  );
};

export const StyledField = ({ children, name, ...props }) => (
  <FormInput>
    <Field name={name} {...props} component={CustomizedTextField}>
      {children}
    </Field>
  </FormInput>
);
