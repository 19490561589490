import { toastr } from 'react-redux-toastr';
import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { getAttractionsInDestination } from '../../api/attractions';
import {
  fetchGlobalAttractions,
  fetchGlobalCities,
  fetchLocation,
  fetchLocations,
  fetchNearbyLocations,
  searchLocations
} from '../../api/location';
import { fetchToursByLocation } from '../../api/tours';
import { SortedLocations } from '../../lib/location';
import {
  FETCH_ATTRACTIONS_BY_DESTINATION,
  FETCH_ATTRACTIONS_BY_DESTINATION_INIT,
  FETCH_ATTRACTIONS_BY_DESTINATION_SUCCESS,
  FETCH_LOCATION,
  FETCH_LOCATIONS,
  FETCH_LOCATION_INIT,
  FETCH_LOCATION_LIST,
  FETCH_LOCATION_LIST_INIT,
  FETCH_LOCATION_LIST_SUCCESS,
  FETCH_LOCATION_SUCCESS,
  FETCH_NEARBY_LOCATIONS,
  FETCH_NEARBY_LOCATIONS_INIT,
  FETCH_NEARBY_LOCATIONS_SUCCESS,
  FETCH_TOURS_BY_LOCATION,
  FETCH_TOURS_BY_LOCATION_INIT,
  FETCH_TOURS_BY_LOCATION_SUCCESS
} from './actionTypes';

function* fetchLocationListSaga({ payload: { query, langauge } }) {
  try {
    yield put({
      type: FETCH_LOCATION_LIST_INIT,
      payload: {
        type: 'ALL',
        resetList: true
      }
    });
    const locations = yield call(searchLocations, { query, langauge });
    yield put({
      type: FETCH_LOCATION_LIST_SUCCESS,
      payload: {
        data: SortedLocations(locations),
        type: 'ALL'
      }
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FETCH_LOCATION_LIST_SUCCESS,
      payload: {
        data: [],
        type: 'ALL'
      }
    });
  }
}

function fetchGlobal(global, data) {
  switch (global) {
    case 'cities':
      return fetchGlobalCities(data);
    case 'attractions':
      return fetchGlobalAttractions(data);
    default:
      return fetchLocations(data);
  }
}

function* fetchToursByLocationSaga({ payload: { locationId, page, perPage, language, filters } }) {
  try {
    yield put({
      type: FETCH_TOURS_BY_LOCATION_INIT,
      payload: {
        resetList: page === 0
      }
    });
    const { data, metadata } = yield call(fetchToursByLocation, {
      locationId,
      page,
      perPage,
      language,
      filters
    });
    yield put({
      type: FETCH_TOURS_BY_LOCATION_SUCCESS,
      payload: {
        canLoadMore: metadata.total - (page + 1) * perPage > 0,
        page,
        data,
        total: metadata.total
      }
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FETCH_TOURS_BY_LOCATION_SUCCESS,
      payload: {
        canLoadMore: false,
        page: 0,
        data: [],
        total: 0
      }
    });
  }
}

function* fetchLocationsSaga({ payload: { type, page, perPage = 12, global, language } }) {
  try {
    yield put({
      type: FETCH_LOCATION_LIST_INIT,
      payload: {
        type,
        resetList: page === 0
      }
    });

    const { data, metadata } = yield call(fetchGlobal, global, { type, page, perPage, language });
    yield put({
      type: FETCH_LOCATION_LIST_SUCCESS,
      payload: {
        type,
        page,
        canLoadMore: metadata.total - (page + 1) * perPage > 0,
        data,
        global
      }
    });
  } catch (e) {
    console.warn(e);
  }
}

function* fetchNearbyLocationsSaga({ payload: { locationId, page, perPage = 8, language } }) {
  try {
    yield put({
      type: FETCH_NEARBY_LOCATIONS_INIT,
      payload: {
        resetList: page === 0
      }
    });
    const res = yield call(fetchNearbyLocations, { locationId, page, perPage, language });
    const { data, metadata } = res;
    yield put({
      type: FETCH_NEARBY_LOCATIONS_SUCCESS,
      payload: {
        page,
        data,
        canLoadMore: metadata.total - (page + 1) * perPage > 0
      }
    });
  } catch (e) {
    yield put({
      type: FETCH_NEARBY_LOCATIONS_SUCCESS,
      payload: {
        page: 0,
        data: [],
        canLoadMore: false
      }
    });
  }
}

function* fetchLocationSaga({ payload: { locationId, language, filters } }) {
  try {
    yield put({ type: FETCH_LOCATION_INIT });

    const [location] = yield all([
      call(fetchLocation, { locationId, language }),
      fork(fetchAttractionsSaga, {
        payload: {
          locationId
        }
      }),
      fork(fetchNearbyLocationsSaga, {
        payload: {
          language,
          locationId: locationId,
          page: 0,
          perPage: 9
        }
      }),
      put({
        type: FETCH_TOURS_BY_LOCATION,
        payload: {
          language,
          page: 0,
          perPage: 16,
          locationId: locationId,
          filters
        }
      })
    ]);

    yield put({ type: FETCH_LOCATION_SUCCESS, payload: location });
  } catch (e) {
    console.warn(e);
    toastr.error(
      'Something went wrong',
      'Application encountered major error and stoped working. Please, reload the page',
      {
        timeOut: 4800
      }
    );
  }
}

function* fetchAttractionsSaga({ payload: { locationId } }) {
  try {
    yield put({
      type: FETCH_ATTRACTIONS_BY_DESTINATION_INIT
    });

    const { data } = yield call(getAttractionsInDestination, locationId);
    yield put({
      type: FETCH_ATTRACTIONS_BY_DESTINATION_SUCCESS,
      payload: {
        data: data
      }
    });
  } catch (e) {
    console.warn(e);
  }
}

export function* watchLocation() {
  yield takeLatest(FETCH_LOCATION_LIST, fetchLocationListSaga);
  yield takeEvery(FETCH_LOCATIONS, fetchLocationsSaga);
  yield takeLatest(FETCH_NEARBY_LOCATIONS, fetchNearbyLocationsSaga);
  yield takeEvery(FETCH_LOCATION, fetchLocationSaga);
  yield takeLatest(FETCH_TOURS_BY_LOCATION, fetchToursByLocationSaga);
  yield takeLatest(FETCH_ATTRACTIONS_BY_DESTINATION, fetchAttractionsSaga);
}
