import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import {Modal, ModalBody} from 'reactstrap';
import localization from '../../localization';
import TourCard from '../TourCard';
import styled from 'styled-components';
import {connect} from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StyleBreakpoints } from '@constants';

const ModalHeaderBold = styled.span`
    font-family: ${props => props.theme.fonts.bold};
    font-weight: bold;
    font-size: 24px;
`;

const ModalHeaderNormal = styled.span`
    font-family: ${props => props.theme.fonts.primary};
    font-weight: normal;
    font-size: 18px;
`;

const StyledModal = styled(Modal)`
    max-width: 500px;
    @media(min-width: ${StyleBreakpoints.md}px){
        max-width: 950px;
    }
`

const ModalHeaderWrapper = styled.div`
    text-align: center;
`;

const TourCardWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    padding: 0 10px;
    @media(min-width: ${StyleBreakpoints.md}px){
        padding: 0;
        flex-direction: row;
        align-items: stretch;
    }
`;

const CardWrapper = styled.div`
    width: 100%;
    margin-bottom: 20px;
    max-width: 300px;

    @media(min-width: ${StyleBreakpoints.md}px){
        margin: 0 15px;
        display: flex;
        flex: 1;
    }
`;

const CloseButton = styled.div`
    position: absolute;
    right: 0;
    top: 0;
    font-size: 32px;
    padding: 0px 16px;
    color: #767777;

    :hover {
        cursor: pointer;
        color: black;
    }
`;

export function FallbackTourModal({isHidden, isLoaded, tours, closeModal}){
    const body = isLoaded ?  <ModalTours tours={tours}/> : <Loader />;
    const history = useHistory();
    
    const closeWrongTourModal = () => history.push(`/location/${tours[0].Locations[0].location_id}`);
    
    return (
    <StyledModal isOpen={!isHidden} toggle={closeModal || closeWrongTourModal}>
        <ModalBody style={{position: 'relative'}}>
        <CloseButton onClick={closeModal || closeWrongTourModal}>&times;</CloseButton>
            <ModalHeaderWrapper>
                <ModalHeaderBold>{localization.fallbackModal.bold}</ModalHeaderBold>
                <br />
                <ModalHeaderNormal>{localization.fallbackModal.alternative}</ModalHeaderNormal>
            </ModalHeaderWrapper>
            {body}
        </ModalBody>
    </StyledModal>
    );
}

function ModalTours({tours}){
    return (
    <TourCardWrapper>
        {tours.map((tour, i) => 
            <CardWrapper key={i}>
                <TourCard item={tour} />
            </CardWrapper>)}
    </TourCardWrapper>
    );
}

export default connect(
    state => state.fallback
)(FallbackTourModal)
