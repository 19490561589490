import React, { useState, useEffect, useLayoutEffect } from 'react';
import StaticBannerImageCard from '../StaticBannerImageCard';
import theme from 'theme';
import defaultStyle from '../../themes/default.js';

function RollingBanner(props) {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(screen.width);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWindowWidth]);

  const defaultImages = defaultStyle.bannerImages.images;

  const images = theme.bannerImages.images.length ? theme.bannerImages.images : defaultImages;

  return (
    <StaticBannerImageCard windowWidth={windowWidth} item={images[props.i ? props.i : 0]}>
      {props.children}
    </StaticBannerImageCard>
  );
}

export default RollingBanner;
