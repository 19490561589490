import { flow, isEmpty, join, map, pickBy, toPairs } from 'lodash/fp';
import localization from '../../../../../localization';

export const getAgeRepresentation = (minAge, maxAge) => {
  switch(true) {
    case minAge && maxAge: return `(${localization.common.age} ${minAge}-${maxAge})`;
    case minAge: return `(${localization.common.age}; older than ${minAge})`;
    case maxAge: return `(${localization.common.age}; younger than ${maxAge})`;
    default: return ''
  }
};

export const formatOptions = participants => {
  return participants
    .filter(p => p.maxTravelersPerBooking)
    .map(participant => {
      const { displayName } = participant;

      return {
        ...participant,
        displayName: displayName.charAt(0).toUpperCase() + displayName.slice(1)
      };
    });
};

export const formatSelectedOptions = participants => {
  const options = Object.entries(participants);
  const mappedOptions = options.map(option => {
    option[0] = option[0][0].toUpperCase() + option[0].substring(1).toLowerCase();
    return option;
  });
  return Object.fromEntries(mappedOptions);
};

export const selectedOptionsTextRepresentation = selectedOptions => {
  const formattedOptions = formatSelectedOptions(selectedOptions);
  const result = flow(
    pickBy(x => x > 0),
    toPairs,
    map(([name, v]) => `${name} x ${v}`),
    join(', ')
  )(formattedOptions);

  return isEmpty(result) ? localization.tours.availabilitySearch.chooseParticipants : result;
};
