import { Button } from '@mui/material';
import React from 'react';
import theme from 'theme';

function CommonButton({
  style = {},
  className = '',
  background = true,
  onClick,
  disabled = false,
  children,
}) {
  return (
    <Button
      className={className}
      variant={background ? 'contained' : 'outlined'}
      color="primary"
      style={{
        width: '100%',
        ...style,
        ...theme.styles.airserbiaButton,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
}

export default CommonButton;
