import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import localization from '../../../../../localization';
import { FontSizes } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  itineraryItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',

    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  dayItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    '&:not(:first-child)': {
      marginTop: '20px',
    },
  },
  itineraryPoint: {
    marginLeft: '-29px',
    position: 'absolute',
    top: '8px',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  bodyText: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  routeTitle: {
    fontSize: '18px',
    marginBottom: '5px',
  },
  marginLeftTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    marginLeft: '10px',
  },
  bottomDetails: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: FontSizes.cta,
    color: '#63666a',
    marginBottom: '10px',
  },
  titleStop: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  admission: {
    marginLeft: '10px',
  },
  pointsOfInterestContainer: {
    marginBottom: '10px',
  },
  pointOfInterest: {
    fontSize: FontSizes.cta,
    '&:not(:last-child)': {
      marginBottom: '5px',
    },
  },
}));

export default function HopOnItinerary({ itinerary, itineraryLocations, formatDuration }) {
  const classes = useStyles();

  return itinerary.routes.map((route, i) => {
    return (
      <div key={`${i}-${route.name}`} className={classes.dayItem}>
        <div className={classes.routeTitle}>{route.name}</div>
        <div className={classes.titleStop}>{localization.itinerary.pointsOfInterest}:</div>
        <div className={classes.pointsOfInterestContainer}>
          {route.pointsOfInterest?.map((point, i) => {
            return (
              <div key={i} className={classes.pointOfInterest}>
                {
                  itineraryLocations.find((location) => location.reference === point.location.ref)
                    ?.name
                }
              </div>
            );
          })}
        </div>
        <div className={classes.bottomDetails}>
          {localization.itinerary.stops}: {route.stops.length}{' '}
          <span className={classes.admission}>
            {route.duration.fixedDurationInMinutes &&
              formatDuration(route.duration.fixedDurationInMinutes)}
          </span>
        </div>
        {route.stops?.map((stop, i) => {
          return (
            <div key={i} className={classes.itineraryItem}>
              <div className={classes.itineraryPoint} />
              <div className={classes.titleStop}>
                {localization.itinerary.stop}:
                <span className={classes.marginLeftTitle}>
                  {
                    itineraryLocations.find(
                      (location) => location.reference === stop.stopLocation.ref
                    )?.name
                  }
                </span>
              </div>
            </div>
          );
        })}
      </div>
    );
  });
}
