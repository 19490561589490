import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import localization from '../../localization';

const useStyles = makeStyles({
  resultAmount: {
    display: 'block',
    height: '25px',
    textAlign: 'left',
    fontWeight: '700',
    margin: '-10px 0 0px',
  },
});

export default function ResultsCount({ isLoaded, total, clearFilters, haveActiveFilters }) {
  const classes = useStyles();

  return (
    <div className={classes.resultAmount}>
      {isLoaded ? (
        <>
          <span>
            {total} {localization.location.filter.results}
          </span>
          {haveActiveFilters && (
            <>
              {' | '}
              <span
                style={{ cursor: 'pointer', fontFamily: 'MontserratBold' }}
                onClick={clearFilters}
              >
                {localization.location.filter.backToAllResults}
              </span>
            </>
          )}
        </>
      ) : null}
    </div>
  );
}
