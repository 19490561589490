import React from 'react';
import { NavLink } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import styled from 'styled-components';
import { FontSizes } from '../../utils/constants';
import { Bold, Text as TextBase } from '../Common';
import localization from '../../localization';
import { StyleBreakpoints } from '@constants';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  @media (max-width: ${StyleBreakpoints.xs}px) {
    margin-bottom: 10px;
  }
`;
const Text = styled(TextBase)`
  line-height: 36px;
  font-size: 34px;
  color: black;
  text-align: left;
`;

const TextS = styled(TextBase)`
  line-height: 28px;
  font-size: 24px;
  color: black;
  text-align: left;
`;

const Link = styled(NavLink)`
  width: 100%;
  text-align: right;
  font-size: ${FontSizes.h2};
  color: ${props => props.theme.colors.primary};
`;

class ViewAllActivities extends React.Component {
  constructor(props) {
    super(props);
    const withBoldText = props.withBoldText === undefined ? true : props.withBoldText;
    this.state = {
      term: props.term || '',
      withBoldText,
      children: props.children
    };
  }

  componentDidMount() {}
  componentWillMount() {
    this.setState({ width: window.innerWidth });
  }

  render() {
    return (
      <Wrapper>
        <Row style={{ width: '100%' }}>
          <Col md="6" sm="5" xs="5">
            <TextS>
              <Bold> {localization.home.attractionMessage}</Bold>
            </TextS>
          </Col>
          <Col md="6" sm="7" xs="7" style={{ paddingRight: 0 }}>
            <div style={{ marginLeft: 'auto', marginRight: 0, width: 'fit-content' }}>
              {this.state.children}
            </div>
          </Col>
        </Row>
      </Wrapper>
    );
  }
}

export default ViewAllActivities;
