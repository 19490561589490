import axios from 'axios';
import { apiRoot } from 'env-config';

export const getTopAttractions = () =>
    axios
      .get(`${apiRoot}/api/v1/tour/viator/topattractions`)
      .then(res => res.data.data);

export const getAttractionsInDestination = (
    location_id
  ) =>
    axios
      .get(`${apiRoot}/api/v1/tour/viator/attractions`, {
        params: {
            location_id
        }
      })
      .then(res => res.data);