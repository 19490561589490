import { StyleBreakpoints } from '@constants';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import theme from 'theme';
import { ReactComponent as DisabledSvg } from '../../../assets/img/disabled.svg';
import { ReactComponent as DurationSvg } from '../../../assets/img/Duration.svg';
import { ReactComponent as MobileVoucherSvg } from '../../../assets/img/Eticket.svg';
import { ReactComponent as PrivateTourSvg } from '../../../assets/img/icons/PrivateTour.svg';
import { ReactComponent as InstantSvg } from '../../../assets/img/Instant.svg';
import { ReactComponent as PickupSvg } from '../../../assets/img/PickUp.svg';
import { ReactComponent as QueueSvg } from '../../../assets/img/Queue.svg';
import localization from '../../../localization';
import { getTourDuration } from '../../../utils/helpers';
import { SvgColorHelper } from '../../Common';
import CancellationFeature from './CancellationFeature';
import Guide from './Guide';
import { mapTourGuides, shuffleFeatures } from './helper';

const DurationIcon = SvgColorHelper('AeTourSelected_MobileV2', DurationSvg, theme.colors.primary);
const QueueIcon = SvgColorHelper('AeTourSelected_MobileV2', QueueSvg, theme.colors.primary);
const InstantIcon = SvgColorHelper('AeTourSelected_MobileV2', InstantSvg, theme.colors.primary);
const PickupIcon = SvgColorHelper('AeTourSelected_MobileV2', PickupSvg, theme.colors.primary);
const MobileIcon = SvgColorHelper(
  'AeTourSelected_MobileV2',
  MobileVoucherSvg,
  theme.colors.primary
);
const DisabledIcon = SvgColorHelper('AeTourSelected_MobileV2', DisabledSvg, theme.colors.primary);
const PrivateTourIcon = SvgColorHelper(
  'AeTourSelected_MobileV2',
  PrivateTourSvg,
  theme.colors.primary
);

const useStyles = makeStyles((theme) => ({
  languageWrapper: {
    display: 'flex',
  },
  languageSub: {
    margin: 0,
  },
  image: {
    width: '28px',
    height: '28px',
    marginRight: '14px',
    color: 'black',
  },
  imageSmall: {
    width: '14px',
    height: '14px',
    marginRight: '14px',
  },
  featured: {
    marginTop: '14px',
    marginBottom: '14px',
    color: 'black',
    alignItems: 'center',
  },
  cancellationText: {
    color: '#84BD00',
  },
  subtext: {
    color: '#63666A',
    marginLeft: '42px',
    fontWeight: 300,
    fontSize: '13px',
  },
  policyText: {
    marginTop: '5px',
  },
  aboutExperience: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    gap: '60px',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      gridTemplateColumns: '1fr',
      gap: 0,
    },
  },
  aboutExperienceLeft: {
    gridColumn: '1/1',
  },
  aboutExperienceRight: {
    gridColumn: '2/2',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      gridColumn: '1',
      justifyContent: 'flex-start',
    },
  },
}));

function TourFeatures({ tour, limit }) {
  const [cardFeatures, setFeatures] = useState([]);
  const classes = useStyles();

  function renderDuration() {
    if (tour.duration) {
      return (
        <div className={classes.featured}>
          <DurationIcon className={classes.image} />
          {localization.tours.about.duration} {getTourDuration(tour.duration)}
        </div>
      );
    }
    return null;
  }

  function renderIcon(SvgIcon) {
    return <SvgIcon className={limit ? classes.imageSmall : classes.image} />;
  }

  const durations = renderDuration();
  const cancellationText = (
    <CancellationFeature
      CancelationPolicySupplierText={tour.CancelationPolicySupplierText}
      classes={classes}
      limit={limit}
    />
  );
  const flaggedProps = [
    {
      flagName: 'mobile_voucher',
      textDisplay: localization.tours.about.voucher,
      icon: renderIcon(MobileIcon),
    },
    {
      flagName: 'instant_confirmation',
      textDisplay: localization.tours.about.instantConfirm,
      icon: renderIcon(InstantIcon),
    },
    {
      flagName: 'skip_line',
      textDisplay: localization.tours.about.skipTheLine,
      icon: renderIcon(QueueIcon),
    },
    {
      flagName: 'pick_up',
      textDisplay: localization.tours.about.hotelPickup,
      icon: renderIcon(PickupIcon),
    },
    !tour.itinerary?.privateTour
      ? null
      : {
          flagName: 'private',
          textDisplay: localization.tours.about.private,
          icon: renderIcon(PrivateTourIcon),
        },
    {
      flagName: 'wheelchair',
      textDisplay: localization.tours.about.wheelchair,
      icon: renderIcon(DisabledIcon),
    },
  ].reverse();

  const getFlaggedProp = (fpObj, key) => {
    if (!fpObj) {
      return null;
    }
    const { flagName, textDisplay, icon } = fpObj;

    if (!tour.Flags || !tour.Flags[flagName]) {
      return null;
    }

    if (tour.Flags[flagName]) {
      return (
        <div className={classes.featured} key={key}>
          {icon}
          {textDisplay}
        </div>
      );
    }
  };

  const renderedFlaggedProps = flaggedProps.map(getFlaggedProp) || [];

  useEffect(() => {
    if (limit) {
      setFeatures(
        shuffleFeatures(renderedFlaggedProps.filter((element) => element !== undefined)).slice(
          0,
          limit
        )
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (limit) {
    return (
      <div style={{ textAlign: 'left', fontSize: 12 }}>
        {cardFeatures}
        {cancellationText}
      </div>
    );
  }

  return (
    <div className={classes.aboutExperience}>
      <div className={classes.aboutExperienceLeft}>
        {durations}
        {renderedFlaggedProps}
        {cancellationText}
      </div>
      <div className={classes.aboutExperienceRight}>
        <Guide guides={mapTourGuides(tour.languageGuides)} />
      </div>
    </div>
  );
}

export default TourFeatures;
