import {FETCH_FILTER_OPTIONS, FETCH_FILTER_OPTIONS_INIT, FETCH_FILTER_OPTIONS_SUCCESS} from './actionTypes';
import {fetchAvailableFilters} from '../../api/tours';
import {put, call, takeLatest} from 'redux-saga/effects';

function* fetchFilters({payload: primaryQuery}){

    yield put({type: FETCH_FILTER_OPTIONS_INIT});
    const filterOptions = yield call(fetchAvailableFilters, primaryQuery);
    yield put({type: FETCH_FILTER_OPTIONS_SUCCESS, payload: filterOptions});

}

export function* watchFilter(){
    yield takeLatest(FETCH_FILTER_OPTIONS, fetchFilters);
}