import React from 'react';
import { checkoutCart } from '../../../api/checkout';
import CreditCardInput from '../../CreditCardForm';
import { useHistory } from 'react-router-dom';
import { Analytics } from '../../../utils/analytics';

var liveAdyen =
  '10001|8903C4F04E66D9932D76C8172DB5246477529B7187F53DB9B5BB0857818AD771A66EDD185BEEF32E76077DAAC927DBFF972F6D1EF063CE93A3BE052BB55B28CEB6C7575C2D040611AF011510E6A4CF7DB92CB48DBF9E0E05DD9530A4AD39ACCC82EB8AFC91393AB492F9D6282B23BB5C367557CEE13483232DD451EC07C673DE350FA57B727E0D915EF2FDB37BFAFC6A41367584C18D3A291D70FEC15AC2DA2A8E06C72047C3D10C8FE621122E3A69D2B323273236D3B7931019A1AC1356D47D620D84EBCA6614841E1E835966A42E3D260CB033884E133AB6D3F86EF574DD7C59A7EE6F28FEA291560C7D9DD9C799D4358BCEDFD3F4D3D7CFCBDB2984FD90F5';
var testAdyen =
  '10001|8E8295DFEDF274D5848344480AF50F85577BEABD4D56A175BCE07CEF9CF21BE8C6104023ABC5C83F49DF8D65619298B4666B8425A0FCFA549F15D8F23ED8D07E32E4C5BA298A384C13C0C9F4A25F10AE77BEC2E38A1DBD7856D7DB4818B5D832F5E201E0A5CCE871A510E1EC1A85B6B15F28B2A727898100D2247A62D815B4970D7D8A8474A07496477B9524CD5DC8ACB2763E37DC21B332231980FB05EA990583674616D326BF62E89F44A15EF316E5A28A4903BBF9153F3DCB16E66464716CFD03D6178417F0D512FF7F6F5404D151B162BD6EC3FB82A05EA340BB2D1624BAD50719213226743E0E25D5187DBE49C09568BF5EDC2B53147C68FC7A0C22D5A7';
const loadAdyen = () => {
  if (!window.adyen) {
    //require ayden client side encription library
    const script = document.createElement('script');
    script.src = 'js/adyen.encrypt.min.19.js';
    script.async = false;
    document.body.appendChild(script);
  }

  if (!window.adyen) {
    return null;
  }
  const key = liveAdyen;
  return window.adyen.encrypt.createEncryption(key, {});
};
loadAdyen();

function encryptCardData(cvc, number, expiry, name, cse) {
  const [expirationMonth, expirationYear] = expiry.replace(/ /g, '').split(/\//);
  const sanitizedNumber = number.replace(/[^\d]/g, '');
  const generationtime = new Date().toISOString();
  const cardData = {
    number: sanitizedNumber,
    cvc: cvc,
    holderName: name,
    expiryMonth: expirationMonth,
    //will break in 80 years
    expiryYear: `20${expirationYear}`,
    generationtime
  };

  return cse.encrypt(cardData);
}

export function PaymentModule({ FormContainer, bookingId, showModal }) {
  const history = useHistory();
  const [checkoutInProgress, setCheckourInProgress] = React.useState(false);
  const [paymentFailed, setPaymentFailed] = React.useState(false);
  const [ccState, setCCState] = React.useState({
    nameOnCard: '',
    ccv: '',
    cardNumber: '',
    expirationDate: ''
  });

  const onSubmit = async paymentDetails => {
    if (
      !/\d{4} \d{4} \d{4} \d{4}/.test(ccState.cardNumber) ||
      !/\d{3}/.test(ccState.ccv) ||
      !/(0\d|1[1,2]) \/ \d{2}/.test(ccState.expirationDate) ||
      !ccState.nameOnCard
    ) {
      showModal('Please, fill in payment details');
      return;
    }
    Analytics.track('GYG payment start');
    setCheckourInProgress(true);
    try {
      const encryptedCardData = encryptCardData(
        ccState.ccv,
        ccState.cardNumber,
        ccState.expirationDate,
        ccState.nameOnCard,
        loadAdyen()
      );
      paymentDetails.payment = {
        encrypted_credit_card: {
          format: 'adyen',
          data: encryptedCardData
        }
      };
      const result = await checkoutCart({ bookingId, ...paymentDetails });
      Analytics.track('GYG payment complete');
      history.push(`/order-confirmation/${bookingId}`);
    } catch (err) {
      console.log('got error')
      setPaymentFailed(true)
      console.error(err);
    } finally {
      setCheckourInProgress(false);
    }
  };

  return (
    <FormContainer
      isCheckoutInProgress={checkoutInProgress}
      paymentFailed={paymentFailed}
      paymentForm={<CreditCardInput onChange={setCCState} formData={ccState} classes={{}} />}
      onSubmit={onSubmit}
    />
  );
}
