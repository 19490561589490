import 'react-dates/initialize';
import GygWidget from './GygBookingWidget';
import './style.scss';
import TsWidget from './TsBookingWidget';

export const GygBookingWidget = GygWidget;
export const TsBookingWidget = TsWidget;

/*const FormDivider = styled.hr`
    border: solid 1px ${props => props.theme.colors.secondary};
    margin-bottom: 35px;
`;

const NotAvailable = styled.div`
font-family: ${props => props.theme.fonts.bold};
font-size: 24px;
`
class TourOptions extends Component {
    constructor(props) {
        super(props);
        const {
            pricingManifest,
            languages,
            isLoaded,
            availabilities,
            selectedDate,
            selectedManifest,
            selectedLanguage,
            error
        } = props;
        this.state = {
            pricingManifest,
            languages,
            isLoaded,
            availabilities,
            selectedDate,
            selectedManifest,
            selectedLanguage,
            error,
            dateFocused: false,
            isAvailabilitiesSearched: false,
        };
    }

    static getDerivedStateFromProps({
        isLoaded,
        isAvailabilitiesLoaded,
        languages,
        availabilities,
        tour,
        pricingManifest,
        selectedDate,
        selectedManifest,
        selectedLanguage,
        error
    }) {
        return {
            isLoaded,
            isAvailabilitiesLoaded,
            tour,
            languages,
            availabilities,
            pricingManifest,
            selectedDate,
            selectedManifest,
            selectedLanguage,
            error
        }
    }

    render() {
        const {
            pricingManifest,
            availabilities,
            selectedManifest,
            selectedDate,
            isLoaded,
            isAvailabilitiesLoaded,
            tour,
            error
        } = this.state;
        if (!isLoaded) return <Loader />
        if (error || tour.tour_options.length === 0) {
            return (
                <Fragment>
                    <NotAvailable>
                        {localization.tours.notAvailable}
                    </NotAvailable>
                    <FormDivider />
                </Fragment>
            );
        }
        return (
            <div className="option-select">
            </div>
        );
    }
    maybeRenderOptions() {
        const { isAvailabilitiesSearched, isLoaded } = this.state;
        if (!isAvailabilitiesSearched) {
            return null;
        }
        if (!isLoaded) {
            return <Loader />
        }
        return <TourOptionList />;
    }
}

export default connect(
    state => ({
        isLoaded: state.tourOptions.isLoaded,
        isAvailabilitiesLoaded: state.tourOptions.isAvailabilitiesLoaded,
        tour: state.tour.item,
        languages: languages(state.tour.item),
        availabilities: state.tourOptions.availabilities,
        pricingManifest: getOr({}, 'pricings.manifest', state.tourOptions),
        selectedManifest: state.tourOptions.manifest,
        selectedDate: state.tourOptions.date,
        selectedLanguage: state.tourOptions.language,
        error: state.tourOptions.error,
    }),
    dispatch => ({
        updateManifest: payload => dispatch({ type: UPDATE_MANIFEST, payload }),
        updateDate: payload => dispatch({ type: UPDATE_DATE, payload }),
        updateLanguage: payload => dispatch({ type: UPDATE_LANGUAGE, payload }),
        loadAvailabilities: (tourId, optionIds) => startDate => dispatch({ type: FETCH_AVAILABILITIES, payload: { tourId, optionIds, startDate } })
    })
)(TourOptions)*/
