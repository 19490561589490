import { FormControl, Select } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import localization from '../../localization';
import { FontSizes } from '../../utils/constants';
import { capitalize, getLangName } from '../../utils/helpers';
import { StyledField } from './Input';

const Title = styled.div`
  font-family: ${(props) => props.theme.fonts.primary};
  font-size: ${FontSizes.p};
  margin-bottom: 5px;
`;
const CustomSelect = ({ name, title, languages, values, setFieldValue }) => (
  <>
    <Title>{localization.tours.availabilitySearch[title] || capitalize(title)}</Title>
    <FormControl style={{ width: '100%', marginBottom: '14px' }} variant="outlined">
      {/* <InputLabel htmlFor="outlined-age-native-simple">{localization.tours.availabilitySearch.languageSelect}</InputLabel> */}
      <Select
        native
        value={values.name}
        onChange={(e) => setFieldValue(name, e.target.value)}
        // label={localization.tours.availabilitySearch.languageSelect}
        inputProps={{
          name: name,
          id: 'outlined-age-native-simple',
        }}
      >
        <option value={''}>{localization.tours.availabilitySearch.languageSelect}</option>
        {languages.map((x) => (
          <option value={x} key={`language-${x}`}>
            {localization.language[x] || getLangName(x)}
          </option>
        ))}
      </Select>
    </FormControl>
  </>
);

const Default = ({ name, title }) => (
  <>
    <Title>{capitalize(title)}</Title>
    <StyledField type="text" name={name} />
  </>
);

export const BookingParameters = ({ bookingParameters, languages, values, setFieldValue }) => {
  return (
    <>
      {bookingParameters.map((param, i) => {
        const { name, description } = param;
        switch (name) {
          case 'language':
            return (
              <CustomSelect
                languages={languages}
                title={description || name}
                name={`bookingParameters.${i}.value_2`}
                key={i}
                values={values}
                setFieldValue={setFieldValue}
              />
            );
          default:
            return (
              <Default
                title={description || name}
                name={`bookingParameters.${i}.value_1`}
                key={i}
              />
            );
        }
      })}
    </>
  );
};

export default BookingParameters;
