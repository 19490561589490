import React, { Component } from 'react';
import { FaCircle } from 'react-icons/fa';
import { Col, Row } from 'reactstrap';
import { splitForBullets, stripeTags } from '../../../utils/TextUtils';

export default class BulletedText extends Component {
  constructor(props) {
    super(props);
    const { title, text } = props;
    this.state = {
      title,
      text
    };
  }

  renderBullet = content => (
    <div style={{ margin: '6px 0 16px', display: 'flex', alignItems: 'flex-start' }}>
      <FaCircle style={{ fontSize: '7px', marginRight: '14px', marginTop: '5px', flex: '0 0 10px' }} /> {content}
    </div>
  );

  //TODO: Maybe replace with a component with slots
  render() {
    if(!this.state.text){
      return ''
    }
    const text = splitForBullets(this.state.text);
    if (text.length < 1) {
      return null;
    }
    return (
      <Row style={{ textAlign: 'left' }}>
        <Col md="2">
          <div className="third-heading">
            {this.state.title}
          </div>
        </Col>

        <Col md="10">
          <div style={{ listStyleType: 'none' }}>{text.map(t =>{
            if(t.length <=1 ){
              return ''
            }else if(t.substring(0,3) == 'Day'){
              return (<div style={{marginTop : '24px'}}><h4>{t}</h4></div>)
            }else{
              return (
                <div style={{ margin: '6px 0 16px', display: 'flex', alignItems: 'flex-start' }}>
      <FaCircle style={{ fontSize: '7px', marginRight: '14px', marginTop: '5px', flex: '0 0 10px' }} /> {t}
    </div>
              )
            }
          })}</div>
        </Col>
      </Row>
    );
  }
}
