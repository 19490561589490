import ReactLoader from "react-loader-spinner";
import React from "react";
import styled from 'styled-components';
import theme from 'theme';

const Wrapper = styled.div`
display: flex;
justify-content:center;
align-items: center;
width: 100%;
min-height:200px;
`;

export const Loader = () => (
    <Wrapper>
        <ReactLoader
            color={theme.colors.primary}
            type="Oval"
        />
    </Wrapper>
);

export const LoaderSmall = ({height = 40}) => (
    <ReactLoader
        color={'#fff'}
        type="Grid"
        height={height}
    />
)

export const LoaderVerySmall = ({height = 20}) => (
    <ReactLoader
        color={'#fff'}
        type="Grid"
        height={height}
    />
)
export default Loader;