import { call, put, takeLatest } from 'redux-saga/effects';
import { getSearchPrompts } from '../../api/search';
import { SortedLocations } from '../../lib/location';
import { mapToursToPrompts } from '../../utils/Tour';
import {
  FETCH_SEARCH_PROMPTS,
  FETCH_SEARCH_PROMPTS_INIT,
  FETCH_SEARCH_PROMPTS_SUCCESS
} from './actionTypes';

function mapSearchPrompts(prompts) {
  return [...SortedLocations(prompts.locations)];
}

function* fetchSearchPromptsSaga({ payload: { query, language } }) {
  try {
    yield put({
      type: FETCH_SEARCH_PROMPTS_INIT,
      payload: {
        resetList: true
      }
    });
    const searchPrompts = yield call(getSearchPrompts, { query, language });
    yield put({
      type: FETCH_SEARCH_PROMPTS_SUCCESS,
      payload: {
        data: mapSearchPrompts(searchPrompts)
      }
    });
  } catch (e) {
    console.warn(e);
    yield put({
      type: FETCH_SEARCH_PROMPTS_SUCCESS,
      payload: {
        data: []
      }
    });
  }
}

export function* watchSearch() {
  yield takeLatest(FETCH_SEARCH_PROMPTS, fetchSearchPromptsSaga);
}
