import { encryptCardData } from "./cardEncryption";

const required = value => (value.value_1 != null && value.value_1 !== '') ? null : 'This field is required';
const requiredLanguage = value => (value.value_2 != null && value.value_2 !== '') ? null : 'This field is required';
const extractLanguagesAndGYGName = languages => {
    for (const key in languages) {
        if (languages[key].length !== 0) {
            return [key, languages[key]];
        }
    }
}
export const BookingParamsForm = (booking) => booking.bookingParameters.filter(x => x.mandatory).reduce(
        (acc, param) => {
            const bookingParam = booking.bookable.booking_parameters.find(x => x.name === param.name);
            //handle get your guide language options differently
            if (param.name === 'language') {
                const [name, languages] = extractLanguagesAndGYGName(booking.languages);
                acc.values[param.name] = {
                    ...bookingParam,
                    type: 'language',
                    name: param.name,
                    values: languages,
                    value_1: name,
                    title: param.description || param.name
                }
                acc.validators[param.name] = requiredLanguage;
                return acc;
            }
            acc.values[param.name] = {
                ...bookingParam,
                type: 'other',
                name: param.name,
                title: param.description || param.name
            }
            acc.validators[param.name] = required;
            return acc;

        },
        {
            values:{},
            validators:{},
            errors: {},
            bookingHash: booking.bookingHash,
            title:`${booking.tourTitle} - ${booking.title}` 
        }
    );
export const encryptBilling = ({ ccv, cardholder_name, expiration_date, number }) =>  ({
    format: 'adyen',
    data: encryptCardData(
        ccv,
        number,
        expiration_date,
        cardholder_name
    )
});
export const bookingFormName = booking => `booking-params-${booking.bookingHash}`;
export const bookingData = bookingForm => ({
    booking_hash: bookingForm.bookingHash,
    booking_parameters: Object.values(bookingForm.values).map(x => ({
        name: x.name,
        value_1: x.value_1,
        value_2: x.value_2
    }))
})