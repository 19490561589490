import { Checkbox, FormControlLabel } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import theme from 'theme';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';
import { Bold } from '../../Common';
import { StyledField } from './Input';

const useStyles = makeStyles({
  section: {
    marginBottom: '30px',
  },
  title: {
    fontSize: FontSizes.h2,
    margin: '20px 0',
    display: 'block',
  },
  billingCheckbox: {
    margin: '10px 0',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  checkBox: {
    transform: 'scale(2)',
    margin: '10px',
    fontSize: FontSizes.cta,
    height: '30px',
  },
  checkboxRoot: {
    width: '30px',
  },
  formBody: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  intlTelInput: {
    margin: '10px 0px',
    width: '100px',
    display: 'block',
    flexBasis: '100%',
  },
});

const priorityPrefixCountries = theme.config.priorityPrefixCountries || ['us', 'gb'];

const FieldNames = {
  FIRST_NAME: 'traveler.firstName',
  LAST_NAME: 'traveler.lastName',
  EMAIL: 'traveler.email',
  EMAIL_CONFIRMATION: 'traveler.emailConfirmation',
  PHONE_NUMBER: 'traveler.phoneNumber',
};

const TravelerForm = ({ travelerDetailsSame, setTravelerDetailsSame }) => {
  const classes = useStyles();

  const toggleTravelerDetailsSame = () => {
    setTravelerDetailsSame(!travelerDetailsSame);
  };

  return (
    <div className={classes.section}>
      <Bold className={classes.title}>{localization.payment.form.travelerDetails}</Bold>
      <div className={classes.billingCheckbox}>
        <FormControlLabel
          control={
            <Checkbox
              checked={travelerDetailsSame}
              onChange={toggleTravelerDetailsSame}
              size="medium"
              color="primary"
              classes={{ root: classes.checkboxRoot }}
              className={classes.checkBox}
            />
          }
          label={localization.payment.form.sameAsBilling}
        />
      </div>
      {!travelerDetailsSame && (
        <div className={classes.formBody}>
          <StyledField
            name={FieldNames.FIRST_NAME}
            placeholder={localization.payment.form.firstName}
          />
          <StyledField
            name={FieldNames.LAST_NAME}
            placeholder={localization.payment.form.lastName}
          />
          <StyledField name={FieldNames.EMAIL} placeholder={localization.payment.form.email} />
          <StyledField
            name={FieldNames.EMAIL_CONFIRMATION}
            placeholder={localization.payment.form.confirmEmail}
          />
          <Field
            name={FieldNames.PHONE_NUMBER}
            required
            render={({ field, form }) => {
              return (
                <IntlTelInput
                  preferredCountries={priorityPrefixCountries}
                  fieldId={FieldNames.PHONE_NUMBER}
                  defaultValue={field.value}
                  fieldName={FieldNames.PHONE_NUMBER}
                  containerClassName={`${classes.intlTelInput} intl-tel-input`}
                  inputClassName="form-control"
                  placeholder={localization.payment.form.mobile}
                  onPhoneNumberChange={(_, value) => {
                    form.setFieldValue(FieldNames.PHONE_NUMBER, value);
                  }}
                />
              );
            }}
          />
        </div>
      )}
    </div>
  );
};

export default TravelerForm;
