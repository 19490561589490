import {
  answers,
  arrivalQuestions,
  conditionalQuestionRules,
  departureQuestions,
  filterableFields,
  pickupType
} from './constants';

export const shouldRenderQuestion = (
  questionId,
  transferArrivalMode,
  transferDepartureMode,
  hasArrivalModeQuestion,
  hasDepartureModeQuestion
) => {
  if (questionId === 'PICKUP_POINT' && !hasArrivalModeQuestion && !hasDepartureModeQuestion) {
    return true;
  }

  if (filterableFields.includes(questionId)) {
    if (transferArrivalMode && arrivalQuestions.includes(questionId)) {
      return conditionalQuestionRules.TRANSFER_ARRIVAL_MODE[transferArrivalMode].includes(
        questionId
      );
    } else if (transferDepartureMode && departureQuestions.includes(questionId)) {
      return conditionalQuestionRules.TRANSFER_DEPARTURE_MODE[transferDepartureMode].includes(
        questionId
      );
    }
  } else {
    return !hasArrivalModeQuestion || !hasDepartureModeQuestion;
  }

  return false;
}

export const getPassengerCount = paxMix => {
  return paxMix.reduce((prev, passengerEntry) => {
    return prev + passengerEntry.numberOfTravelers
  }, 0)
}

export const paxMixAsArrayOfNames = paxMix => {
  const paxMixAsArray = [];
  for (let pType = 0; pType < paxMix.length; pType++) {
    for (let pCount = paxMix[pType].numberOfTravelers; pCount > 0; pCount--) {
      paxMixAsArray.push(paxMix[pType].ageBand);
    }
  }
  return paxMixAsArray;
};

export const getPassengers = (filledQuestions, paxMix) => {
  const perTravelerQuestions = filledQuestions.filter(e => e.group === 'PER_TRAVELER');
  const passengerCount = getPassengerCount(paxMix);
  const eachPax = paxMixAsArrayOfNames(paxMix);
  const passengers = [];

  if (perTravelerQuestions?.length) {
    let eachPaxCounter = 0
    for (let i = passengerCount; i >= 1; i--) {
      passengers.push({
        questions: perTravelerQuestions,
        ageBand : eachPax[eachPaxCounter]
      });
      eachPaxCounter++;
    }
  }

  return passengers;
}

export const getPickupLocation = travelerPickupLocations => pickupType => {
  return travelerPickupLocations.find(loc => loc.pickupType === pickupType);
};

export const shouldRenderSelectOption = (
  ans,
  travelerPickupLocations,
  allowCustomTravelerPickup
) => {
  if (!travelerPickupLocations) return false;

  const getPickupLocationByType = getPickupLocation(travelerPickupLocations);

  switch (ans) {
    case answers.AIR: {
      const airportPickupLocation = getPickupLocationByType(pickupType.AIRPORT);

      return airportPickupLocation || allowCustomTravelerPickup;
    }
    case answers.SEA: {
      const portPickupLocation = getPickupLocationByType(pickupType.PORT);

      return Boolean(portPickupLocation);
    }
    case answers.OTHER: {
      const hotelPickupLocation = getPickupLocationByType(pickupType.HOTEL);
      const locationPickupLocation = getPickupLocationByType(pickupType.LOCATION);

      return hotelPickupLocation || locationPickupLocation || allowCustomTravelerPickup;
    }
    default:
      return false;
  }
};
