import axios from 'axios';
import {apiRoot} from 'env-config';
import {getCurrency} from '../utils/helpers'

export default{
  getTourByTourId(tourId, next) {
    axios.get(apiRoot + '/api/v1/tour/' + tourId)
      .then(function (resp) {
        return next(null, resp.data)
      })
  },
  getLocationChildren(location_id, next) {
    axios.get(`${apiRoot}/api/v1/location/${location_id}/children`)
      .then(function (response) {
        return next(null, response.data)
      }).catch(function (error) {
        console.log('Caught error in getLocationChildren', error)
        return next(error)
      });
  },
  getLocation(location_id, next) {
    axios.get(`${apiRoot}/api/v1/location/${location_id}`)
      .then(function (response) {
        return next(null, response.data)
      }).catch(function (error) {
        console.log('Caught error in getLocationChildren', error)
        return next(error)
      });
  },
  getLocationCities(next) {
    axios.get(`${apiRoot}/api/v1/location/cities`)
      .then(function (response) {
        return next(null, response.data)
      }).catch(function (error) {
        console.log('Caught error in getLocationChildren', error)
        return next(error)
      });
  },
  getLocationAttractions(next) {
    axios.get(`${apiRoot}/api/v1/location/attractions`)
      .then(function (response) {
        return next(null, response.data)
      }).catch(function (error) {
        console.log('Caught error in getLocationChildren', error)
        return next(error)
      });
  },
  getLocationAttractionsIn(attractionsIn, next) {
    axios.get(`${apiRoot}/api/v1/location/siblings/${attractionsIn}`)
      .then(function (response) {
        return next(null, response.data)
      }).catch(function (error) {
        console.log('Caught error in getLocationChildren', error)
        return next(error)
      });
  },
  getFeaturedTours(next) {
    axios.get(`${apiRoot}/api/v1/tour/featured?currency=${getCurrency()}`)
      .then(function (response) {
        return next(null, response.data)
      }).catch(function (error) {
        console.log('Caught error in getFeaturedTours', error)
        return next(error)
      });
  },
  getLocationTours(location_id, page, next) {
    axios.get(`${apiRoot}/api/v1/tour/location/${location_id}/${page}`)
      .then(function (response) {
        return next(null, response.data)
      }).catch(function (error) {
        console.log('Caught error in getLocationTours', error)
        return next(error)
      });
  }
}