import {
   FETCH_TOUR_SUCCESS,
   FETCH_TOUR_INIT,
   FETCH_TOUR_ERROR
} from './actionTypes';
import defaultState from '../defaultState';

function tourReducer(state = defaultState.tour, { type, payload }) {
    switch (type) {
        case FETCH_TOUR_SUCCESS:
            return {
                ...state,
                item: payload,
                isLoaded: true
            }
        case FETCH_TOUR_INIT:
            return {
                ...state,
                tour: null,
                error: null,
                isLoaded: false
            }
        case FETCH_TOUR_ERROR:{
            return {
                ...state, 
                isLoaded: true,
                error: payload.error
            }
        }
        default:
            return state;
    }
}

export default tourReducer;