import React from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { Title, Paragraph, Block, List, Link } from '../../../components/Common/Policies';
import localization from '../../../localization';

class CookiePolicy extends React.Component {

    render() {
        return (
            <div className="CookiePolicy" style={{ fontFamily: 'DiodrumRegular' }}>
                <Header />
                <div style={{ textAlign: 'left', width: '80vw', margin: 'auto', marginTop: '5vh' }}>
                    <Title>{localization.policies.coockies.title}</Title>
                    <Paragraph>{localization.policies.coockies.intro}</Paragraph>
                    <Block content={localization.policies.coockies.whatAreCoockies} />
                    <Title>{localization.policies.coockies.whatAreCoociesUsedFor.title}</Title>
                    <Paragraph>{localization.policies.coockies.whatAreCoociesUsedFor.intro}</Paragraph>
                    <List content={localization.policies.coockies.whatAreCoociesUsedFor.list} />
                    <Block content={localization.policies.coockies.essentialCoockies} />
                    <Block content={localization.policies.coockies.analyticsCoockies} />
                    <Block content={localization.policies.coockies.functionalityCoockies} />
                    <Block content={localization.policies.coockies.advertisingAndMarketing} />
                    <Block content={localization.policies.coockies.howToManage} />
                    <Title>{localization.policies.coockies.changingSettings.title}</Title>
                    <Paragraph>{localization.policies.coockies.changingSettings.intro}</Paragraph>
                    <Link href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies">
                        {localization.policies.coockies.changingSettings.ie}
                    </Link>
                    <Link href="https://support.google.com/chrome/answer/95647?hl=en">
                        {localization.policies.coockies.changingSettings.chrome}
                    </Link>
                    <Link href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies">
                        {localization.policies.coockies.changingSettings.firefox}
                    </Link>
                    <Link href="https://support.apple.com/kb/PH21411?viewlocale=en_US&locale=en_US">
                        {localization.policies.coockies.changingSettings.safari}
                    </Link>
                    <Block content={localization.policies.coockies.updates} />
                </div>
                <Footer />
            </div >

        );
    }
}

export default CookiePolicy;
