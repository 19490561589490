import defaultState from '../defaultState';
import {
  CANCEL_BOOKING_INIT,
  CANCEL_BOOKING_SUCCESS,
  FETCH_CANCEL_QUOTE_SUCCESS,
  FETCH_CANCEL_REASONS_ERROR,
  FETCH_CANCEL_REASONS_SUCCESS
} from './actionTypes';

const cancelBookingReducer = (state = defaultState.cancelBooking, { type, payload }) => {
  switch (type) {
    case FETCH_CANCEL_REASONS_SUCCESS:
      return {
        ...state,
        cancelReasons: payload,
        cancelReasonsError: null
      };
    case FETCH_CANCEL_REASONS_ERROR:
      return {
        ...state,
        cancelReasonsError: payload.error
      };
    case FETCH_CANCEL_QUOTE_SUCCESS:
      return {
        ...state,
        cancelQuote: payload
      };
    case CANCEL_BOOKING_SUCCESS:
      return {
        ...state,
        cancelResponse: payload,
        isCancelLoading: false
      }
    case CANCEL_BOOKING_INIT:
      return {
        ...state,
        isCancelLoading: true
      }
    default:
      return state;
  }
};

export default cancelBookingReducer;
