export const FETCH_CART = 'FETCH_CART';
export const FETCH_CART_SUCCESS = 'FETCH_CART_SUCCESS';
export const FETCH_CART_INIT = 'FETCH_CART_INIT';
export const CHECKOUT_CART = 'CHECKOUT_CART';
export const CHECKOUT_CART_INIT = 'CHECKOUT_CART_INIT';
export const CHECKOUT_CART_ERROR = 'CHECKOUT_CART_ERROR';
export const CHECKOUT_CART_SUCCESS = 'CHECKOUT_CART_SUCCESS';
export const SET_CART_HASH = 'SET_BOOKING_HASH';
export const SET_CART_DATA = 'SET_CART_DATA';
export const SET_BOOKING_PARAMS_DATA = 'SET_BOOKING_PARAMS_DATA';
export const VALIDATE_FORMS = 'VALIDATE_FORMS';