import React from 'react';

const img = require('../../assets/img/PoweredByLogo.svg');

const PoweredBy = () => {
  const isPaymentScreen = window.location.pathname.split('/')[1] === 'checkout';

  const poweredBy = (
    <img
      src={img}
      style={{ width: '210px', margin: 'auto', marginBottom: '24px', marginTop: '24px' }}
      alt="Powered by TripAdmit"
    />
  );

  const poweredByWithLink = (
    <a
      alt="Powered by TripAdmit"
      rel="noopener noreferrer"
      target="_blank"
      href="https://tripadmit.com/"
    >
      <img
        src={img}
        style={{ width: '210px', margin: 'auto', marginBottom: '24px', marginTop: '24px' }}
        alt="Powered by TripAdmit"
      />
    </a>
  );
  return isPaymentScreen ? poweredBy : poweredByWithLink;
};

export default PoweredBy;
