import { flow, map, filter, toLower, join, upperFirst, identity, toPairs, fromPairs, pickBy } from 'lodash/fp'
const validator = predicate => message => (value, values) => predicate(value, values) ? null : message
export const composeValidators = (...validators) => (value, values) =>
    flow(
        map(v => v(value, values)),
        filter(identity),
        join(', '),
        toLower,
        upperFirst
    )(validators)
export const validationWrapper = (error, noError, validator) => (value, values) => {
    const res = validator(value, values);
    if (res) return error(res);
    return noError();
}
export const required = validator(x => !!x);
export const regex = (regex, message) => validator(val => regex.test(val))(message);
export const noValidator = () => null;
export const validateForm = ({ values, validators, ...rest }) => flow(
    toPairs,
    map(
        ([name, validator]) => [name, validator(values[name], values)]
    ),
    fromPairs,
    pickBy(x => x),
    errors => ({...rest, values, validators, errors })
)(validators);