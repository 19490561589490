import React, { Fragment } from 'react';
import styled from 'styled-components';
import { TickedCheckboxIcon } from './index'

const Tick = styled(TickedCheckboxIcon)`
margin-right: 5px;
min-width: 30px;
`
export const Link = styled.a`
    display:block;
    margin-bottom: 5px;
`
export const Title = styled.h2``;
export const Paragraph = styled.p``;
export const Row = styled.div`
    display: flex;
    margin-bottom: 10px;
`;

export const Paragraphs = ({ content }) => (
    <Fragment>
        {
            content.map((text, i) =>
                <Paragraph key={i}> {text} </Paragraph>
            )
        }
    </Fragment>
)

export const Block = ({ content: { title, content } }) => (
    <Fragment>
        <Title>{title}</Title>
        <Paragraphs content={content} />
    </Fragment>
)

export const List = ({ content }) =>
    (
        <Fragment>
            {
                content.map((text, i) =>
                    (
                        <Row key={i}>
                            <Tick />
                            {text}
                        </Row>
                    )
                )
            }
        </Fragment>
    )