import { Card, CardContent } from '@mui/material';
import React from 'react';
import localization from '../../localization';

const TourDetails = ({ classes, name, lang, date, time, participants, amount }) => {
  return (
    <>
      <div className={classes.importantDetailsBlock}>
        <h2 className={classes.secondHeading}>{localization.orderConfirmation.tourDetails}</h2>
        <Card elevation={2}>
          <CardContent>
            <div className={classes.tourDetailsInfo}>
              <div>
                {`${localization.orderConfirmation.tourName}: `} <b>{name}</b>
              </div>
              <div>
                {`${localization.orderConfirmation.language}: `} <b>{lang}</b>
              </div>
              <div>
                {`${localization.orderConfirmation.date}: `} <b>{date}</b>
              </div>
              <div>
                {`${localization.orderConfirmation.time}: `} <b>{time}</b>
              </div>
              <div>
                {`${localization.orderConfirmation.participants}: `} <b>{participants}</b>
              </div>
              <div>
                {`${localization.orderConfirmation.amountPaid}: `} <b>{amount}</b>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default TourDetails;
