import {put, call, takeLatest} from 'redux-saga/effects'
import { INIT_FALLBACK_FETCH, SUCCESS_FALLBACK_FETCH, ERROR_FALLBACK_FETCH, FALLBACK_FETCH } from './types'
import { fetchFallbackTours } from '../../api/tours';

export function* fetchFallbackSaga({payload}){
    try{
        yield put({type: INIT_FALLBACK_FETCH});
        const tours = yield call(fetchFallbackTours, payload);
        yield put({type: SUCCESS_FALLBACK_FETCH, payload: {tours}});
    } catch (error){
        yield put({type: ERROR_FALLBACK_FETCH, payload: {error}});
    }
}

export function* watchFallback(){
    yield takeLatest(FALLBACK_FETCH, fetchFallbackSaga);
}