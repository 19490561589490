import React from 'react';
import styled from 'styled-components';
import theme from 'theme';
import { Icon, Input, Select } from '../../PeoplePicker/styles';

const CounterBlock = styled.div`
  height: 30px;
  width: auto;
`;

const SelectBlock = styled(Select)`
  min-width: 125px;
`;

function Counter({ id, count, isMandatory, participantsAmount, handleExtraCountChange }) {
  const handleIncrement = () => {
    handleExtraCountChange(id, +count + 1);
  };

  const handleDecrement = () => {
    if (isMandatory) {
      if (count > participantsAmount) handleExtraCountChange(id, +count - 1);
    } else if (count > 0) {
      handleExtraCountChange(id, +count - 1);
    }
  };

  return (
    <CounterBlock>
      <SelectBlock>
        <Icon
          style={{
            ...theme.styles.pickerControlButton,
            opacity: count === 0 ? 0.2 : 1
          }}
          onClick={handleDecrement}
        >
          -
        </Icon>
        <Input
          style={{ ...theme.styles.pickerCounter }}
          type="tel"
          pattern="[0-9]*"
          tabIndex="-1"
          value={count}
        />
        <Icon style={{ ...theme.styles.pickerControlButton }} onClick={handleIncrement}>
          +
        </Icon>
      </SelectBlock>
    </CounterBlock>
  );
}

export default Counter;
