import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import localization from '../../localization';
import { Analytics } from '../../utils/analytics';
import { changeFormatToSmallRect } from '../../utils/helpers';
import { StyleBreakpoints } from '@constants';

const Card = styled(Col).attrs({ sm: 12, md: 6, lg: 4 })`
  font-size: 24px;
  text-shadow: -1px 1px 10px rgba(0, 0, 0, 0.85);
  position: relative;
  margin: 10px auto;
  @media (min-width: ${StyleBreakpoints.sm}px) {
    margin: 10px 0;
  }
`;
const CardContent = styled.div`
  position: relative;
`;

const CardContentNoImage = styled.div`
  position: relative;
  height: 140px;
  background-color: #1a73e8;
  border-radius: 4px;
`;

const CardImage = styled.img`
  width: 100%;
  position: relative;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
`;
const CardText = styled.div`
  color: white;
  position: absolute;
  margin: auto;
  width: 100%;
  line-height: 34px;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  font-weight: bold;
  justify-content: space-around;
  align-items: center;
  font-family: 'MontserratBold';
  transition: background 0.5s;

  :hover {
    @media (min-width: ${StyleBreakpoints.sm}px) {
      font-size: 20px;
    }
    border-radius: 5px;
    background: rgba(0, 0, 0, 0.2);
  }
`;
const ToursCount = styled.div`
  font-size: 64px;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const LocationMessage = styled.div`
  font-family: 'MontserratRegular';
  font-weight: 400;
`;

const LocationCard = ({ windowWidth, location, index, extraTrackingProperties }) => {
  const [hover, setHover] = useState(false);

  const analyticsDestinationSelected = () => {
    Analytics.track('destination selected', {
      ...extraTrackingProperties,
      'destination name': location.name,
      'destination id': location.location_id,
      'destination index': index
    });
  }

  if (!location.Image) {
    return (
      <Card
        key={location.location_id}
        onClick={analyticsDestinationSelected}
      >
        <NavLink to={{ pathname: `/location/${location['english_name'].split(' ').join('-')}` }}>
          <CardContentNoImage>
            <CardText onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
              {hover || windowWidth <= StyleBreakpoints.sm ? (
                <div>
                  <div>{location.name}</div>
                  <ToursCount>{location.toursCount}</ToursCount>
                  <LocationMessage>{localization.home.attractionMessage}</LocationMessage>
                </div>
              ) : (
                location.name
              )}
            </CardText>
          </CardContentNoImage>
        </NavLink>
      </Card>
    );
  }

  return (
    <Card
      key={location.location_id}
      onClick={analyticsDestinationSelected}
    >
      <NavLink to={{ pathname: `/location/${location['english_name'].split(' ').join('-')}` }}>
        <CardContent>
          <CardImage src={changeFormatToSmallRect(location.Image)} />
          <CardText onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            {hover || windowWidth <= StyleBreakpoints.sm ? (
              <div>
                <div>{location.name}</div>
                <ToursCount>{location.toursCount}</ToursCount>
                <LocationMessage>{localization.home.attractionMessage}</LocationMessage>
              </div>
            ) : (
              location.name
            )}
          </CardText>
        </CardContent>
      </NavLink>
    </Card>
  );
};

export default LocationCard;
