import React from 'react';
import styled from 'styled-components';
import { ReactComponent as English } from '../../assets/img/country/english-icon.svg';
import { ReactComponent as French } from '../../assets/img/country/french-icon.svg';
import { ReactComponent as Serbian } from '../../assets/img/country/serbia-icon.svg';
import { ReactComponent as Russian } from '../../assets/img/country/russian-icon.svg';
import { ReactComponent as German } from '../../assets/img/country/german-icon.svg';
import { ReactComponent as Dutch } from '../../assets/img/country/dutch-icon.svg';
import { ReactComponent as Italian } from '../../assets/img/country/italian-icon.svg';
import { ReactComponent as CanFrench } from '../../assets/img/country/fr-ca.svg';

const SizedSvg = styled.span`
  svg {
    width: ${(props) => props.size}px;
  }
`;

function LanguageIcon({ language }) {
  switch (language.toLowerCase()) {
    case 'en':
      return <English />;
    case 'fr':
      return <French />;
    case 'ru':
      return <Russian />;
    case 'nl':
      return <Dutch />;
    case 'de':
      return <German />;
    case 'it':
      return <Italian />;
    case 'sr':
      return <Serbian />;
    case 'fr-ca':
      return <CanFrench />;
    default:
      return language;
  }
}

function WrapedLanguageIcon({ size, ...props }) {
  return (
    <SizedSvg size={size}>
      <LanguageIcon {...props} />
    </SizedSvg>
  );
}

export default WrapedLanguageIcon;
