import axios from 'axios';
import { apiRoot } from 'env-config';
import React from 'react';
import { Card, CardBody, Collapse } from 'reactstrap';
import localization from '../../localization';
import './Footer.scss';

import { GoChevronDown, GoChevronUp } from 'react-icons/go';
import theme from 'theme';
import paymentTrustSignals from '../../assets/img/poweredByStripe/Footer_PaymentTrustSignals.svg';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggle1 = this.toggle1.bind(this);
    this.toggle2 = this.toggle2.bind(this);
    this.toggle3 = this.toggle3.bind(this);
    this.toggle4 = this.toggle4.bind(this);
    this.state = {
      attractions: [],
      cities: [],
      collapse1: false,
      collapse2: false,
      collapse3: false,
      collapse4: false,
    };
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    axios
      .get(`${apiRoot}/api/v1/location/global/attractions`, {
        params: { page: 0, perPage: 5, language: localization.getLanguage() },
      })
      .then((res) => res.data.data.data)
      .then((attr) => this.setState({ attractions: attr }));
    axios
      .get(`${apiRoot}/api/v1/location/global/cities`, {
        params: { page: 0, perPage: 5, language: localization.getLanguage() },
      })
      .then((res) => res.data.data.data)
      .then((c) => this.setState({ cities: c }));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
  toggle1() {
    this.setState((state) => ({ collapse1: !state.collapse1 }));
  }
  toggle2() {
    this.setState((state) => ({ collapse2: !state.collapse2 }));
  }
  toggle3() {
    this.setState((state) => ({ collapse3: !state.collapse3 }));
  }
  toggle4() {
    this.setState((state) => ({ collapse4: !state.collapse4 }));
  }
  render() {
    const contactUsLink = theme.config.contactUsLink || 'https://tripadmit.com/enquire';
    const supportLink = theme.config.supportLink || 'https://tripadmit.com/enquire';
    const aboutUsLink = theme.config.aboutUsLink || 'https://tripadmit.com/about';
    const cookiePolicyLink = theme.config.cookiePolicyLink || '/policy/cookiepolicy';
    const privacyPolicyLink = theme.config.privacyPolicyLink || '/policy/privacypolicy';
    const tosLink = theme.config.tosLink || '/policy/termsandconditions';

    var width = this.state.width;
    return (
      <div>
        {width < 800 ? (
          <div>
            <div
              style={{
                cursor: 'pointer',
                marginBottom: '1rem',
                display: 'flex',
                paddingLeft: '5vw',
                paddingRight: '5vw',
              }}
              onClick={this.toggle1}
              className="footer__column-title"
            >
              <div style={{ textAlign: 'left', flex: 1 }}>
                {localization.footer.helpAndSupport.title}
              </div>
              <div style={{ textAlign: 'right', flex: 1 }}>
                {!this.state.collapse1 ? <GoChevronDown /> : <GoChevronUp />}
              </div>
            </div>
            <Collapse isOpen={this.state.collapse1}>
              <Card>
                <CardBody>
                  <div style={{ marginTop: '16px' }} className="footer__column-link">
                    <a href={contactUsLink} target="_blank" rel="noreferrer">
                      {localization.footer.helpAndSupport.contactUs}
                    </a>
                  </div>
                  <div style={{ marginTop: '16px' }} className="footer__column-link">
                    <a href={supportLink} target="_blank" rel="noreferrer">
                      {localization.footer.helpAndSupport.support}
                    </a>
                  </div>
                  <div style={{ marginTop: '16px' }} className="footer__column-link">
                    <a href={aboutUsLink} target="_blank" rel="noreferrer">
                      {localization.footer.policies.about}
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Collapse>
            <div
              style={{
                cursor: 'pointer',
                marginBottom: '1rem',
                display: 'flex',
                paddingLeft: '5vw',
                paddingRight: '5vw',
              }}
              onClick={this.toggle2}
              className="footer__column-title"
            >
              <div style={{ textAlign: 'left', flex: 1 }}>{localization.footer.policies.title}</div>
              <div style={{ textAlign: 'right', flex: 1 }}>
                {!this.state.collapse2 ? <GoChevronDown /> : <GoChevronUp />}
              </div>
            </div>
            <Collapse isOpen={this.state.collapse2}>
              <Card>
                <CardBody>
                  <div style={{ marginTop: '16px' }} className="footer__column-link">
                    <a href={cookiePolicyLink} target="_blank" rel="noreferrer">
                      {localization.footer.policies.coockie}
                    </a>
                  </div>
                  <div style={{ marginTop: '16px' }} className="footer__column-link">
                    <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                      {localization.footer.policies.privacy}
                    </a>
                  </div>
                  <div style={{ marginTop: '16px' }} className="footer__column-link">
                    <a href={tosLink} target="_blank" rel="noreferrer">
                      {localization.footer.policies.terms}
                    </a>
                  </div>
                </CardBody>
              </Card>
            </Collapse>
            <div
              style={{
                cursor: 'pointer',
                marginBottom: '1rem',
                display: 'flex',
                paddingLeft: '5vw',
                paddingRight: '5vw',
              }}
              onClick={this.toggle3}
              className="footer__column-title"
            >
              <div style={{ textAlign: 'left', flex: 1 }}>{localization.footer.topAttractions}</div>
              <div style={{ textAlign: 'right', flex: 1 }}>
                {!this.state.collapse3 ? <GoChevronDown /> : <GoChevronUp />}
              </div>
            </div>
            <Collapse isOpen={this.state.collapse3}>
              <Card>
                <CardBody>
                  {this.state.attractions.slice(0, 5).map((m, i) => {
                    var href = `/location/${m.location_id}`;
                    var name = m.name;
                    return (
                      <div key={i} style={{ marginTop: '16px' }} className="footer__column-link">
                        <a href={href}>{name}</a>
                      </div>
                    );
                  })}
                </CardBody>
              </Card>
            </Collapse>
            <div
              style={{
                cursor: 'pointer',
                marginBottom: '1rem',
                display: 'flex',
                paddingLeft: '5vw',
                paddingRight: '5vw',
              }}
              onClick={this.toggle4}
              className="footer__column-title"
            >
              <div style={{ textAlign: 'left', flex: 1 }}>
                {localization.footer.topDestinations}
              </div>
              <div style={{ textAlign: 'right', flex: 1 }}>
                {!this.state.collapse4 ? <GoChevronDown /> : <GoChevronUp />}
              </div>
            </div>
            <Collapse isOpen={this.state.collapse4}>
              <Card>
                <CardBody>
                  {this.state.cities.slice(0, 5).map((m, i) => {
                    var href = `/location/${m.location_id}`;
                    var name = m.name;
                    return (
                      <div key={i} style={{ marginTop: '16px' }} className="footer__column-link">
                        <a href={href}>{name}</a>
                      </div>
                    );
                  })}
                </CardBody>
              </Card>
            </Collapse>
          </div>
        ) : (
          <div className="footer">
            <div className="footer__links">
              <div className="footer__column">
                <div className="footer__column-title">
                  {localization.footer.helpAndSupport.title}
                </div>

                <div className="footer__column-link">
                  <a href={contactUsLink} target="_blank" rel="noreferrer">
                    {localization.footer.helpAndSupport.contactUs}
                  </a>
                </div>
                <div className="footer__column-link">
                  <a href={supportLink} target="_blank" rel="noreferrer">
                    {localization.footer.helpAndSupport.support}
                  </a>
                </div>
              </div>

              <div className="footer__column">
                <div className="footer__column-title">{localization.footer.policies.title}</div>

                <div className="footer__column-link">
                  <a target="blank" href={aboutUsLink} target="_blank" rel="noreferrer">
                    {localization.footer.policies.about}
                  </a>
                </div>
                <div className="footer__column-link">
                  <a href={cookiePolicyLink} target="_blank" rel="noreferrer">
                    {localization.footer.policies.coockie}
                  </a>
                </div>
                <div className="footer__column-link">
                  <a href={privacyPolicyLink} target="_blank" rel="noreferrer">
                    {localization.footer.policies.privacy}
                  </a>
                </div>
                <div className="footer__column-link">
                  <a href={tosLink} target="_blank" rel="noreferrer">
                    {localization.footer.policies.terms}
                  </a>
                </div>
              </div>

              <div className="footer__column">
                <div className="footer__column-title">{localization.footer.topDestinations}</div>

                {this.state.cities.slice(0, 5).map((m, i) => {
                  var href = `/location/${m.location_id}`;
                  var name = m.name;
                  return (
                    <div key={i} className="footer__column-link">
                      <a href={href}>{name}</a>
                    </div>
                  );
                })}
              </div>

              <div className="footer__column footer__column-payment">
                <div className="footer__column-title">{localization.footer.paymentChannels}</div>
                <img src={paymentTrustSignals} alt="Payment Trust Signals" />
              </div>
            </div>

            <div className="footer__copyright">
              <span className="gray-3 m equal-height-element v-middle w-50">
                <span i18n="common.text.copyright;">{localization.footer.copyRight}</span> <br />
                <span i18n="" data-test-id="test_footer_fare_notice"></span>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default Footer;
