import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import { FontSizes } from '../../../../../../utils/constants';
import { StyledField } from '../../../Input';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSizes.p,
    marginBottom: '5px',
    whiteSpace: 'pre-wrap',
  },
}));

const Question = ({ question, fieldName, shouldRender = true, setFieldValue }) => {
  const classes = useStyles();
  const [customError, setCustomError] = useState();
  const isRequiredField = question.required !== 'OPTIONAL';

  const handleOnBlur = (e) => {
    const value = e.target.value;

    if (value.length > question?.maxLength) {
      setCustomError(`${question.label} may not be longer than ${question.maxLength} characters.`);
      return;
    }

    if (customError) setCustomError(null);
  };

  if (!shouldRender) return null;

  return (
    <div key={question.id}>
      <div className={classes.title}>{`${question.label} ${isRequiredField ? '*' : ''}`} </div>
      <StyledField
        name={fieldName}
        autoComplete="new-password"
        required={isRequiredField}
        onBlur={handleOnBlur}
        units={question?.units}
        setFieldValue={setFieldValue}
        type={question.type}
        placeholder={question.hint || ''}
        error={customError}
        helperText={customError}
      />
    </div>
  );
};

export default Question;
