import {
    FETCH_CART_INIT,
    FETCH_CART_SUCCESS,
    SET_CART_HASH,
    SET_CART_DATA,
    SET_BOOKING_PARAMS_DATA,
    CHECKOUT_CART_ERROR,
    CHECKOUT_CART_INIT,
    CHECKOUT_CART_SUCCESS
} from './actionTypes';
import defaultState from '../defaultState';

export default function (state = defaultState.cart, { type, payload }) {
    switch (type) {
        case FETCH_CART_INIT:
            return { ...defaultState.cart, isLoaded: false }
        case FETCH_CART_SUCCESS:
            return {
                ...state,
                cart: payload,
                shoppingCartHash: payload.shopping_cart_hash,
                isLoaded: true
            }
        case CHECKOUT_CART_INIT:
            return {
                ...state,
                cartCheckoutInProgress: true
            }
        case CHECKOUT_CART_ERROR:
        case CHECKOUT_CART_SUCCESS:
            return {
                ...state,
                cartCheckoutInProgress: false
            }
        case SET_CART_HASH:
            return {
                ...state,
                shoppingCartHash: payload
            }
        case SET_CART_DATA:
            return {
                ...state,
                cartData: {
                    ...state.cartData,
                    [payload.form]: payload.data
                }
            }
        case SET_BOOKING_PARAMS_DATA:
            return {
                ...state,
                cartData: {
                    ...state.cartData,
                    ...payload
                }
            }
        default:
            return state;
    }
}
