import {
    BOOK_OPTION_INIT,
    BOOK_OPTION_SUCCESS,
    BOOK_OPTION_ERROR,
    FETCH_TOUR_OPTIONS_INIT,
    FETCH_TOUR_OPTIONS_SUCCESS,
    FETCH_TOUR_OPTIONS_ERROR,
    FETCH_AVAILABILITIES_INIT,
    FETCH_AVAILABILITIES_SUCCESS,
    UPDATE_DATE,
    UPDATE_LANGUAGE,
    UPDATE_MANIFEST,
    UPDATE_SELECTED_TIMESLOT
} from './actionTypes';
import { flow, mapValues, keyBy, toPairs, fromPairs, map } from 'lodash/fp';
import { setPricingManifest, setSelectedDay, setSelectedLanguage } from '../../lib/tour';
import defaultState from '../defaultState';

const setTourOptions = ({ options, availabilities, pricings }, date, language) => ({
    availabilities,
    pricings,
    options: mapValues(
        flow(
            setSelectedDay(availabilities.options)(date),
            setPricingManifest(pricings.pricings)({}),
            setSelectedLanguage(language)
        ),
        keyBy(x => x.option_id)(options)
    ),
    manifest: {}
});

const setDate = (options, date, availabilities, pricings, manifest) => ({
    options: mapValues(
        flow(
            setSelectedDay(availabilities.options)(date),
            setPricingManifest(pricings.pricings)(manifest)
        ),
        options
    ),
    date: date,
});

const setPriceManifest = (options, pricings, manifest) => ({
    manifest,
    options: mapValues(setPricingManifest(pricings.pricings)(manifest))(options)
});

const setLanguage = (options, language) => ({
    language,
    options: mapValues(setSelectedLanguage(language))(options)
});

const updateSeletedAvailability = (timeslot, pricings, manifest, option) => setPricingManifest(pricings.pricings)
    (manifest)
    ({ ...option, selectedAvailability: timeslot });

export default function (state = defaultState.tourOptions, { type, payload }) {
    switch (type) {
        case FETCH_TOUR_OPTIONS_SUCCESS: {
            return {
                ...state,
                ...setTourOptions(payload, null, null),
                isLoaded: true,
                isAvailabilitiesLoaded: true,
                date: null,
                language: null,
                error: null
            }
        }
        case FETCH_TOUR_OPTIONS_ERROR: {
            return {
                ...state,
                isLoaded: true,
                date: null,
                language: null,
                error: payload
            }
        }
        case FETCH_TOUR_OPTIONS_INIT:
            return {
                ...state,
                isLoaded: false,
                error: null
            }
        case FETCH_AVAILABILITIES_INIT:
            return {
                ...state,
                isAvailabilitiesLoaded: false
            }
        case FETCH_AVAILABILITIES_SUCCESS:
            return {
                ...state,
                isAvailabilitiesLoaded: true,
                availabilities: {
                    all: state.availabilities.all.concat(payload.all),
                    options: flow(
                        toPairs,
                        map(
                            ([id, availabilities]) => [id, availabilities.concat(payload.options[id])]
                        ),
                        fromPairs
                    )(state.availabilities.options)
                }
            }

        case UPDATE_DATE:
            return {
                ...state,
                ...setDate(
                    state.options,
                    payload,
                    state.availabilities,
                    state.pricings,
                    state.manifest
                )
            }
        case UPDATE_MANIFEST:
            return {
                ...state,
                ...setPriceManifest(state.options, state.pricings, payload)
            }
        case UPDATE_LANGUAGE:
            return {
                ...state,
                ...setLanguage(state.options, payload)
            }
        case UPDATE_SELECTED_TIMESLOT:
            return {
                ...state,
                options: {
                    ...state.options,
                    [payload.optionId]: updateSeletedAvailability(
                        payload.timeslot,
                        state.pricings,
                        state.manifest,
                        state.options[payload.optionId]
                    )
                }
            }
        case BOOK_OPTION_INIT:
            return {
                ...state,
                isBookingInProgress: true,
                options: {
                    ...state.options,
                    [payload.optionId]: { ...state.options[payload.optionId], bookingError: null }
                }
            }
        case BOOK_OPTION_ERROR:
            return {
                ...state,
                isBookingInProgress: false,
                options: {
                    ...state.options,
                    [payload.optionId]: { ...state.options[payload.optionId], bookingError: payload.error }
                }
            }
        case BOOK_OPTION_SUCCESS:
            return {
                ...state,
                isBookingInProgress: false,
                options: {
                    ...state.options,
                    [payload.optionId]: { ...state.options[payload.optionId], bookingError: null }
                }
            }
        default:
            return state;
    }
}