import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { BrowserView } from 'react-device-detect';
import theme from 'theme';
import SwiperCore, { FreeMode, Keyboard, Navigation, Scrollbar } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { ReactComponent as LeftArrow } from '../../assets/img/icons/LeftIcon.svg';
import { ReactComponent as RightArrow } from '../../assets/img/icons/RightIcon.svg';
import TourCard from '../TourCard';
import { SvgColorHelper } from '../Common';

const useStyles = makeStyles({
  swiperContainer: {
    paddingBottom: '15px',
    '& .swiper-pagination-bullet': {
      background: 'blue',
    },
    '& .swiper-button-next:after': {
      fontSize: '2rem !important',
      content: 'none',
    },
    '& .swiper-button-prev:after': {
      fontSize: '2rem !important',
      content: 'none',
    },
    '& .swiper-slide': {
      width: 'fit-content',
      height: 'auto',
    },
  },
  rightArrow: {
    right: '0px',
    width: '40px',
    height: '40px',
    top: '42%',

    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
  leftArrow: {
    left: '0px',
    width: '40px',
    height: '40px',
    top: '42%',

    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
});

SwiperCore.use([Keyboard, Scrollbar, Navigation, FreeMode]);

const PostCard = (props) => {
  const classes = useStyles();
  const LeftArrowIconColor = SvgColorHelper('LeftIcon #Oval', LeftArrow, theme.colors.primary);
  const RightArrowIconColor = SvgColorHelper('RightIcon #Oval', RightArrow, theme.colors.primary);

  return (
    <Swiper
      grabCursor
      freeMode={{
        enabled: true,
        momentumBounce: false,
      }}
      keyboard={{ enabled: true }}
      centerInsufficientSlides
      loop
      preventClicks
      className={classes.swiperContainer}
      slidesPerView={'auto'}
      loopedSlides={100}
      spaceBetween={22}
      breakpoints={{
        1280: {
          spaceBetween: 26,
        },
      }}
      navigation={{
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      }}
    >
      {props.tours.map((tour, index) => (
        <SwiperSlide key={index}>
          <TourCard draggable item={tour} isClickable={true} inCarousel />
        </SwiperSlide>
      ))}
      <BrowserView>
        <div className={`${classes.rightArrow} swiper-button-next`}>
          <RightArrowIconColor />
        </div>
        <div className={`${classes.leftArrow} swiper-button-prev`}>
          <LeftArrowIconColor />
        </div>
      </BrowserView>
    </Swiper>
  );
};
export default PostCard;
