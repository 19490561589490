import moment from 'moment';
import React, { useState } from 'react';
import { availabilityCheck, bookingHold, getSchedule } from '../../../api/viator';
import { updateCountdownData } from '../../../utils/helpers';
import {
  generatePaxMix,
  getEmptyMock,
  getLanguageGuides,
  getLanguageGuidesForProductOption,
  getTimeSlot,
  getTimeslotsPrice,
  getTimeslotsTime,
} from './common/viatorWidgetHelper';
import EffectfulBookingWidget from './EffectfulBookingWidget';

function ViatorBookingWidget({ tourId, language, tour }) {
  const [bookingReference, setBookingReference] = useState(false);

  const handleFetchAvailabilities = async ({ tourId }) => {
    //https://docs.viator.com/partner-api/technical/#operation/availabilitySchedules
    let schedule = await getSchedule(tourId);
    schedule.sort(function (a, b) {
      return a < b ? -1 : a > b ? 1 : 0;
    });

    return schedule.map((dateString) => new Date(dateString));
  };

  const handleFetchParticipants = () => {
    return tour.pricingInfo.ageBands
      .map((p) => ({
        id: p.ageBand,
        minAge: p.startAge,
        maxAge: p.endAge,
        displayName: p.ageBand,
        minTravelersPerBooking: p.minTravelersPerBooking,
        maxTravelersPerBooking: p.maxTravelersPerBooking,
      }))
      .sort((a, b) => (a.endAge > b.endAge ? 1 : -1));
  };

  const handleFetchCalculatedPricing = async ({ participants, currency, date }) => {
    const paxMix = generatePaxMix(participants);

    setBookingReference({
      tourId: tour.TourID,
      date: moment(date).format('YYYY-MM-DD'),
      currency: currency.toUpperCase(),
      paxMix,
    });

    const availabilities = await availabilityCheck(
      tour.TourID,
      moment(date).format('YYYY-MM-DD'),
      currency.toUpperCase(),
      paxMix
    );

    if (!availabilities.data) {
      return [];
    }

    return availabilities.data.bookableItems
      .filter((item) => item.available)
      .map(({ startTime, productOptionCode, lineItems, totalPrice }) => {
        const timeslotsTime = getTimeslotsTime(startTime);
        const timeslotsPrice = getTimeslotsPrice(lineItems, totalPrice, currency);

        return {
          slotId: productOptionCode,
          title: tour.Title,
          productOptionDescription: tour.productOptions?.find(
            (e) => e.productOptionCode === productOptionCode
          )?.title,
          description: tour.productOptions?.find((e) => e.productOptionCode === productOptionCode)
            ?.description,
          languages: getLanguageGuidesForProductOption(tour, productOptionCode),
          timeslots: [
            {
              time: timeslotsTime,
              price: timeslotsPrice,
            },
          ],
          tag: startTime ? 'interval' : 'freesale',
          minimumParticipants: 1,
          maximumParticipants: null,
          ...(startTime ? { selectedTimeslot: timeslotsTime } : { price: timeslotsPrice }),
        };
      });
  };

  const handleBookOption = async ({ countdownValue, ...bookingTemp }) => {
    const heldBooking = await bookingHold(
      tour.TourID,
      bookingTemp.option.slotId,
      bookingReference.date,
      bookingReference.currency,
      getTimeSlot(bookingTemp),
      bookingReference.paxMix,
      getLanguageGuides({
        languageGuides: tour.languageGuides,
        selectedLanguage: bookingTemp.selectedLanguage,
      })
    );

    if (heldBooking.data) {
      updateCountdownData(heldBooking.data.taCartId, countdownValue);
      window.location.href = `/vcheckout/${heldBooking.data.taCartId}`;
    }
  };

  return (
    <EffectfulBookingWidget
      tourId={tourId}
      bookingRequirements={tour.bookingRequirements}
      languages={tour.languageGuides || []}
      language={language}
      fetchAvailabilities={handleFetchAvailabilities}
      fetchParticipants={handleFetchParticipants}
      fetchSeason={getEmptyMock}
      fetchCalculatedPricings={handleFetchCalculatedPricing}
      extraTrackingProperties={getEmptyMock}
      bookOption={handleBookOption}
      fetchFallback={getEmptyMock}
    />
  );
}

export default ViatorBookingWidget;
