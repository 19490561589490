import Checkbox from '@mui/material/Checkbox';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import makeStyles from '@mui/styles/makeStyles';
import { isArray } from 'lodash';
import * as React from 'react';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  checkBox: {
    marginRight: '5px',
  },
  label: {
    fontSize: FontSizes.cta,
    fontWeight: '500',
    textTransform: 'capitalize',
    lineHeight: '15px',
    cursor: 'pointer',
    padding: 'unset',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: '-9px',
  },
});

export default function DurationFilter({ handleChange, selectedItems }) {
  const classes = useStyles();
  function checkIfDurationIsChecked(durationObj) {
    if (isArray(selectedItems) && durationObj?.duration) {
      return selectedItems.indexOf(durationObj.duration) !== -1;
    } else {
      return false;
    }
  }

  let durations = [
    {
      displayText: localization.location.filter.durationOptions.toOneHour,
      duration: 60,
    },
    {
      displayText: localization.location.filter.durationOptions.toFourHour,
      duration: 240,
    },
    {
      displayText: localization.location.filter.durationOptions.toOneDay,
      duration: 1440,
    },
    {
      displayText: localization.location.filter.durationOptions.toThreeDays,
      duration: 4320,
    },
    {
      displayText: localization.location.filter.durationOptions.moreThenThreeDays,
      duration: 999999,
    },
  ];

  return (
    <List className={classes.root}>
      {durations.map((duration) => {
        const durationText = duration.displayText;
        const durationId = duration.duration;
        const labelId = `checkbox-list-label-${durationText}`;

        return (
          <ListItem
            className={classes.label}
            key={durationId}
            role={undefined}
            dense
            onClick={() => handleChange(duration)}
          >
            <Checkbox
              className={classes.checkBox}
              checked={checkIfDurationIsChecked(duration)}
              tabIndex={-1}
              disableRipple={true}
              inputProps={{ 'aria-labelledby': labelId }}
            />

            <div className={classes.text} id={labelId}>
              {durationText}
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}
