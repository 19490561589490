import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import localization from '../../../../../localization';
import { FontSizes } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  itineraryItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',

    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  dayItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    '&:not(:first-child)': {
      marginTop: '20px',
    },
  },
  itineraryPoint: {
    marginLeft: '-29px',
    position: 'absolute',
    top: '8px',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  bodyText: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  title: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  marginLeftTitle: {
    fontSize: '18px',
    marginBottom: '10px',
    marginLeft: '10px',
  },
  bottomDetails: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: FontSizes.cta,
    color: '#63666a',
    marginBottom: '10px',
  },
  titleStop: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  admission: {
    marginLeft: '10px',
  },
}));

export default function MultiDayItinerary({
  itinerary,
  itineraryLocations,
  formatDuration,
  formatAdmission,
}) {
  const classes = useStyles();

  return itinerary.days?.map((day, i) => {
    return (
      <div key={`${day.dayNumber}-${i}`} className={classes.dayItem}>
        <div className={classes.itineraryPoint} />
        <div className={classes.title}>
          {localization.itinerary.day} {day.dayNumber}
        </div>
        {day.items?.map((item, i) => {
          if (!item.duration) {
            item.duration = {};
          }
          return (
            <div key={i} className={classes.itineraryItem}>
              <div className={classes.titleStop}>
                {!item.passByWithoutStopping
                  ? `${localization.itinerary.stopAt}:`
                  : `${localization.itinerary.see}:`}
                <span className={classes.marginLeftTitle}>
                  {
                    itineraryLocations.find(
                      (location) => location.reference === item.pointOfInterestLocation.location.ref
                    )?.name
                  }
                </span>
              </div>
              <div className={classes.bodyText}>{item.description}</div>
              <div className={classes.bottomDetails}>
                {item.duration.fixedDurationInMinutes &&
                  formatDuration(item.duration.fixedDurationInMinutes)}{' '}
                {formatAdmission(item.admissionIncluded, classes)}
              </div>
            </div>
          );
        })}
      </div>
    );
  });
}
