import { Checkbox, List, ListItem } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { isArray } from 'lodash';
import * as React from 'react';
import { FontSizes } from '../../../utils/constants';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  checkBox: {
    marginRight: '5px',
  },
  label: {
    fontSize: FontSizes.cta,
    fontWeight: '500',
    textTransform: 'capitalize',
    lineHeight: '15px',
    cursor: 'pointer',
    padding: 'unset',
    paddingLeft: 0,
    paddingRight: 0,
    marginLeft: '-9px',
  },
});

export default function AttractionsFilter({ attractions, handleChange, selectedItems }) {
  const classes = useStyles();

  function checkIfAttractionIsChecked(attraction) {
    if (isArray(selectedItems) && attraction?.seoId) {
      return selectedItems.indexOf(attraction.seoId) !== -1;
    } else {
      return false;
    }
  }
  return (
    <List className={classes.root}>
      {attractions.slice(0, 8).map((attraction) => {
        const attractionText = attraction.title;
        const attractionId = attraction.seoId;
        const labelId = `checkbox-list-label-${attractionText}`;

        return (
          <ListItem
            className={classes.label}
            key={attractionId}
            role={undefined}
            dense
            onClick={() => handleChange(attraction)}
          >
            <Checkbox
              className={classes.checkBox}
              checked={checkIfAttractionIsChecked(attraction)}
              tabIndex={-1}
              disableRipple={true}
              inputProps={{ 'aria-labelledby': labelId }}
            />

            <div className={classes.text} id={labelId}>
              {attractionText}
            </div>
          </ListItem>
        );
      })}
    </List>
  );
}
