import React from 'react';
import ReactMapGl, { Marker } from 'react-map-gl';
import { mapboxToken } from "env-config";
import { Pin } from './Pin'

export default class MapContainer extends React.Component {
  constructor(props) {
    super(props);
    const { long, lat, width = '100%', height = '400px', description = 'Meeting Point' } = props
    this.state = {
      long,
      lat,
      description,
      viewport: {
        width,
        height,
        latitude: lat,
        longitude: long,
        zoom: 16
      }
    }
  }

  render() {
    const { viewport, long, lat, description } = this.state;
    if(!lat || !long){
      return null
    }
    return (
      <ReactMapGl
        {...viewport}
        onViewportChange={viewport => this.setState({ viewport })}
        mapboxApiAccessToken={mapboxToken}
        mapStyle="mapbox://styles/mapbox/streets-v9"
      >
        <Marker
          latitude={lat}
          longitude={long}
          offsetLeft={0}
          offsetTop={0}
        >
          <Pin title={description} />
        </Marker>
      </ReactMapGl>
    );
  }
}