import defaultState from '../defaultState';
import {
  FETCH_ATTRACTIONS_BY_DESTINATION_INIT,
  FETCH_ATTRACTIONS_BY_DESTINATION_SUCCESS,
  FETCH_LOCATION_INIT,
  FETCH_LOCATION_LIST_INIT,
  FETCH_LOCATION_LIST_SUCCESS,
  FETCH_LOCATION_SUCCESS,
  FETCH_NEARBY_LOCATIONS_INIT,
  FETCH_NEARBY_LOCATIONS_SUCCESS,
  FETCH_TAGS_BY_DESTINATION_INIT,
  FETCH_TAGS_BY_DESTINATION_SUCCESS,
  FETCH_TOURS_BY_LOCATION_INIT,
  FETCH_TOURS_BY_LOCATION_SUCCESS,
  SELECT_LOCATION
} from './actionTypes';

const Key = type => {
  switch (type.toUpperCase()) {
    case 'CITY':
      return 'cities';
    case 'POI':
      return 'attractions';
    default:
      return 'locations';
  }
};

export default function locationsReducer(state = defaultState.location, { type, payload }) {
  switch (type) {
    case FETCH_LOCATION_INIT:
      return {
        ...state,
        location: null,
        isLoaded: false
      };
    case FETCH_LOCATION_SUCCESS:
      return {
        ...state,
        location: payload,
        isLoaded: true
      };
    case FETCH_LOCATION_LIST_INIT:
      return {
        ...state,
        [Key(payload.type)]: {
          ...state[Key(payload.type)],
          list: payload.resetList ? [] : state[Key(payload.type)].list,
          isLoaded: false
        }
      };
    case FETCH_LOCATION_LIST_SUCCESS:
      return {
        ...state,
        [Key(payload.type)]: {
          page: payload.page,
          list: state[Key(payload.type)].list.concat(payload.data),
          isLoaded: true,
          canLoadMore: payload.canLoadMore
        }
      };
    case FETCH_NEARBY_LOCATIONS_INIT:
      return {
        ...state,
        nearbyLocations: {
          ...state.nearbyLocations,
          list: payload.resetList ? [] : state.nearbyLocations.list,
          isLoaded: false
        }
      };
    case FETCH_NEARBY_LOCATIONS_SUCCESS:
      return {
        ...state,
        nearbyLocations: {
          page: payload.page,
          list: state.nearbyLocations.list.concat(payload.data),
          isLoaded: true,
          canLoadMore: payload.canLoadMore
        }
      };
    case SELECT_LOCATION:
      return {
        ...state,
        selectedLocation: payload
      };
    case FETCH_TOURS_BY_LOCATION_INIT:
      return {
        ...state,
        tours: {
          ...state.tours,
          list: payload.resetList ? [] : state.tours.list,
          isLoaded: false
        }
      };
    case FETCH_TOURS_BY_LOCATION_SUCCESS:
      return {
        ...state,
        tours: {
          list: state.tours.list.concat(payload.data),
          canLoadMore: payload.canLoadMore,
          page: payload.page,
          isLoaded: true,
          total: payload.total
        }
      };
    case FETCH_ATTRACTIONS_BY_DESTINATION_INIT:
      return {
        ...state,
        viatorAttractions: {
          ...state.viatorAttractions,
          list: [],
          isLoaded: false
        }
      };
    case FETCH_ATTRACTIONS_BY_DESTINATION_SUCCESS:
      return {
        ...state,
        viatorAttractions: {
          list: state.viatorAttractions.list.concat(payload.data),
          isLoaded: true
        }
      };
    default:
      return state;
  }
}
