export const FETCH_TOURS = 'FETCH_TOURS';
export const FETCH_TOURS_RESTORED = 'FETCH_TOURS_RESTORED';
export const SEARCH_BY_QUERY = 'SEARCH_BY_QUERY';
export const FETCH_TOURS_INIT = 'FETCH_TOURS_INIT';
export const FETCH_TOURS_SUCCESS = 'FETCH_TOURS_SUCCESS';
export const SET_SCROLL = 'SET_SCROLL';

export const FETCH_FEATURED_TOURS = 'FETCH_FEATURED_TOURS';
export const FETCH_FEATURED_TOURS_INIT = 'FETCH_FEATURED_TOURS_INIT';
export const FETCH_FEATURED_TOURS_SUCCESS = 'FETCH_FEATURED_TOURS_SUCCESS'; 


export const FETCH_HIGHLIGHTED_TOURS = 'FETCH_HIGHLIGHTED_TOURS_TOURS';
export const FETCH_HIGHLIGHTED_TOURS_INIT = 'FETCH_HIGHLIGHTED_TOURS_TOURS_INIT';
export const FETCH_HIGHLIGHTED_TOURS_SUCCESS = 'FETCH_HIGHLIGHTED_TOURS_TOURS_SUCCESS'; 