import React, { useState } from 'react';
import PlaceCardBase from './PlaceCardBase';

const AttractionCard = ({ attraction, mobile, isClickable }) => {
  return (
    <PlaceCardBase
      mobile={mobile}
      title={attraction.title}
      isClickable={isClickable}
      imageUrl={attraction.thumbnailHiResURL}
      link={`/location/${attraction['primaryDestinationId']}?attractionId=${attraction.seoId}`}
    />
  );
};

export default AttractionCard;
