import { Box, Rating } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useRef, useState } from 'react';
import { fetchReviews } from '../../../api/reviews';
import EmptyStar from '../../../assets/img/assets/EmptyStar.svg';
import Star from '../../../assets/img/assets/Star.svg';
import localization from '../../../localization/index';
import { FontSizes } from '../../../utils/constants';
import { capitalize } from '../../../utils/helpers';
import { fixDecimals } from '../../ActivityRating';
import { ButtonMedium, Divider } from '../../Common';
import Loader from '../../Loader';

function mapResponseToReviews(res) {
  return {
    outline:
      res?.totalReviewsSummary?.reviewCountTotals?.map((r) => ({
        number_of_reviews: r.count,
        rating: r.rating,
      })) ?? [],
    review_items:
      res?.reviews?.map((r) => ({
        review_rating: r.rating,
        reviewer_name: r.userName,
        comment: r.text,
        title: r.title,
      })) ?? [],
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    '& > span': {
      paddingRight: '7px',
    },
  },
  icon: {
    width: '18px',
  },
  coloredInput: {
    color: theme.palette.primary.main,
  },
  reviewContainer: {
    textAlign: 'left',
  },
  heading: {
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: '24px',
    fontWeight: 'bold',
    margin: '15px 0',
  },
  commentInfoBlock: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
  },
  comment: {
    lineHeight: '1.38',
    letterSpacing: '-0.03px',
  },
  title: {
    lineHeight: '1.38',
    letterSpacing: '-0.03px',
    fontWeight: '600',
    marginBottom: '6px',
  },
  reviewerIcon: {
    height: '35px',
    width: '35px',
    backgroundColor: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamilyBold,
    marginRight: '10px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#fff',
    fontSize: FontSizes.cta,
  },
  reviewerBlock: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    lineHeight: '1.38',
    letterSpacing: '-0.03px',
    fontSize: '0.95em',
  },
  reviewedBy: {
    flex: '0 1 100%',
    color: '#777474',
  },
  reviewerName: {
    flex: '0 1 100%',
  },
}));

const NUMBER_OF_REVIEWS_CHUNK = 4;

function TourReviews({ tour_id }) {
  const classes = useStyles();
  const overallRatingRef = useRef();

  const [loading, setLoading] = useState(true);
  const [numDisplay, setNumDisplay] = useState(NUMBER_OF_REVIEWS_CHUNK);
  const [reviews, setReviews] = useState([]);

  const [overallRating, setOverallRating] = useState(0);
  const [totalVotes, setTotalVotes] = useState(0);
  const [remainder, setRemainder] = useState(0);

  useEffect(() => {
    async function fetchClientsReviews() {
      const response = await fetchReviews({
        tourId: tour_id,
        language: localization.getLanguage(),
      });

      const reviews = mapResponseToReviews(response);

      const outlines = reviews.outline;

      let sumOfRTimesC = 0;
      let totalVotes = 0;

      outlines.forEach((outline) => {
        sumOfRTimesC += outline.rating * outline.number_of_reviews;
        totalVotes += outline.number_of_reviews;
      });

      const overallRating = fixDecimals(sumOfRTimesC / totalVotes);
      const remainder = (overallRating - Math.trunc(overallRating)) * 100;

      setReviews(reviews);
      setLoading(false);
      setOverallRating(overallRating);
      setTotalVotes(totalVotes);
      setRemainder(remainder || 100);
    }
    fetchClientsReviews();
  }, [tour_id]);

  useEffect(() => {
    const filledStars = overallRatingRef.current?.querySelectorAll('.MuiRating-iconFilled');
    if (filledStars?.length) {
      const lastFilledStar = filledStars[filledStars.length - 1];
      lastFilledStar.style.clipPath = `polygon(0 1%, ${remainder}% 0, ${remainder}% 100%, 0% 100%)`;
    }
  }, [remainder, overallRating]);

  const loadMore = () => setNumDisplay(numDisplay + NUMBER_OF_REVIEWS_CHUNK);
  const getCustomRateIcon = (icon) => <img style={{ width: '100%' }} src={icon} alt="Rating" />;

  if (loading) {
    return <Loader />;
  }

  if (reviews.review_items.length <= 0 || !reviews) {
    return null;
  }

  const review_items = reviews.review_items.slice(0).sort(function (a, b) {
    return a.review_rating < b.review_rating ? 1 : -1;
  });

  return (
    <div className={classes.reviewContainer}>
      <div className="second-heading">{localization.tours.reviews.title}</div>
      <Box
        ref={overallRatingRef}
        component="fieldset"
        style={{ margin: '-10px 0 16px' }}
        borderColor="transparent"
      >
        <Rating
          readOnly
          value={overallRating}
          getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
          precision={0.1}
          icon={getCustomRateIcon(Star)}
          emptyIcon={getCustomRateIcon(EmptyStar)}
          classes={{
            root: classes.root,
            icon: classes.icon,
          }}
        />
      </Box>
      <div>
        {capitalize(localization.formatString(localization.tours.reviews.basedOn, totalVotes))}
      </div>
      <Divider />
      {review_items.slice(0, numDisplay).map((m) => (
        <>
          <Box component="fieldset" mb={1.9} borderColor="transparent">
            <Rating
              readOnly
              value={m.review_rating}
              getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
              precision={1}
              icon={getCustomRateIcon(Star)}
              emptyIcon={getCustomRateIcon(EmptyStar)}
              classes={{
                root: classes.root,
                icon: classes.icon,
              }}
            />
          </Box>
          <div className={classes.title}>{m.title}</div>
          <div className={classes.comment}>{m.comment}</div>
          <div className={classes.commentInfoBlock}>
            <div className={classes.reviewerIcon}>{m.reviewer_name.slice(0, 1).toUpperCase()}</div>
            <div className={classes.reviewerBlock}>
              <span className={classes.reviewedBy}>{localization.tours.reviews.reviewedBy}</span>
              <span className={classes.reviewerName}>{capitalize(m.reviewer_name)}</span>
            </div>
          </div>
          <Divider />
        </>
      ))}
      <div style={{ width: '100%', textAlign: 'center' }}>
        {numDisplay < review_items.length ? (
          <ButtonMedium onClick={loadMore}>{localization.common.seeMore}</ButtonMedium>
        ) : null}
      </div>
    </div>
  );
}

export default TourReviews;
