import { FETCH_CART, FETCH_CART_SUCCESS, FETCH_CART_INIT, SET_BOOKING_PARAMS_DATA, SET_CART_DATA, CHECKOUT_CART, CHECKOUT_CART_SUCCESS, CHECKOUT_CART_ERROR, CHECKOUT_CART_INIT } from './actionTypes';
import { BookingParamsForm, bookingFormName, bookingData, encryptBilling } from '../../lib/payment';
import { validateForm } from '../../components/Form/validation';
import { call, put, takeLatest, takeLeading, all } from 'redux-saga/effects';
import { fetchCart, checkoutCart } from '../../api/checkout';
import { fromPairs } from 'lodash/fp';
import { isObjectEmptyNullOrUndefined } from '../../utils/helpers';
import { toastr } from 'react-redux-toastr';

function* fetchCartSaga({ payload: cartHash }) {
    try {
        yield put({ type: FETCH_CART_INIT });
        const cart = yield call(fetchCart, cartHash);
        const bookingForms = fromPairs(
            cart.bookings.map(x => [bookingFormName(x), BookingParamsForm(x)])
        );
        yield put({ type: SET_BOOKING_PARAMS_DATA, payload: bookingForms });
        yield put({ type: FETCH_CART_SUCCESS, payload: cart });
    } catch (e) {
        console.warn(e);
        toastr.error(
            'Something went wrong',
            'Application encountered major error and stoped working. Please, reload the page',
            {
                timeOut: 4800
            }
        );
    }
}

function* validateFormsSaga({ payload: forms }) {
    let valid = true;
    for (const key in forms) {
        const form = forms[key];
        const validatedForm = validateForm(form);
        yield put({ type: SET_CART_DATA, payload: { form: key, data: validatedForm } });
        valid &= isObjectEmptyNullOrUndefined(validatedForm.errors);
    }
    return valid;
}

function* checkoutCartSaga({ payload: { forms, isTravelerDetailsSame, shopping_cart_id, history } }) {
    try {
        yield put({ type: CHECKOUT_CART_INIT });
        const { billing, traveler, payment, ...bookingParams } = forms;
        const formData = {
            billing,
            traveler: isTravelerDetailsSame ? billing : traveler,
            payment,
            ...bookingParams
        };
        const isValid = yield call(validateFormsSaga, { payload: formData });
        if (!isValid) {
            return toastr.error('Please, check your form for errors', '');
        }
        const shoppingCart = {
            shopping_cart_id,
            billing: billing.values,
            traveler: (isTravelerDetailsSame ? billing : traveler).values,
            payment: {
                encrypted_credit_card: encryptBilling(payment.values)
            },
            booking_data: Object.values(bookingParams).map(bookingData).filter(x => x.booking_parameters > 0)
        }

        const cartData = {
            base_data: {
                cnt_language: 'en',
                currency: 'eur'
            },
            data: {
                shopping_cart: shoppingCart
            }
        }
        const shoppingCartHash = yield call(checkoutCart, cartData);
        yield put({ type: CHECKOUT_CART_SUCCESS });
        return history.push(`/order-confirmation?shopping_cart_hash=${shoppingCartHash}`)
    } catch (e) {
        console.log('Got error E', e)
        yield put({ type: CHECKOUT_CART_ERROR })
        return toastr.error('Whoops, something went wrong...', e.response.data.error[0].errorMessage);
    }
}

export function* watchCart() {
    yield all([
        takeLatest(FETCH_CART, fetchCartSaga),
        takeLeading(CHECKOUT_CART, checkoutCartSaga)
    ])
}