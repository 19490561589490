export const filterableFields = [
  'TRANSFER_AIR_ARRIVAL_AIRLINE',
  'TRANSFER_AIR_ARRIVAL_FLIGHT_NO',
  'TRANSFER_ARRIVAL_TIME',
  'TRANSFER_ARRIVAL_DROP_OFF',
  'TRANSFER_RAIL_ARRIVAL_LINE',
  'TRANSFER_RAIL_ARRIVAL_STATION',
  'TRANSFER_ARRIVAL_TIME',
  'TRANSFER_ARRIVAL_DROP_OFF',
  'TRANSFER_PORT_ARRIVAL_TIME',
  'TRANSFER_PORT_CRUISE_SHIP',
  'TRANSFER_ARRIVAL_DROP_OFF',
  'PICKUP_POINT',
  'TRANSFER_AIR_DEPARTURE_AIRLINE',
  'TRANSFER_AIR_DEPARTURE_FLIGHT_NO',
  'TRANSFER_DEPARTURE_DATE',
  'TRANSFER_DEPARTURE_TIME',
  'TRANSFER_DEPARTURE_PICKUP',
  'TRANSFER_RAIL_DEPARTURE_LINE',
  'TRANSFER_RAIL_DEPARTURE_STATION',
  'TRANSFER_DEPARTURE_DATE',
  'TRANSFER_DEPARTURE_TIME',
  'TRANSFER_DEPARTURE_PICKUP',
  'TRANSFER_PORT_CRUISE_SHIP',
  'TRANSFER_DEPARTURE_DATE',
  'TRANSFER_PORT_DEPARTURE_TIME',
  'TRANSFER_DEPARTURE_PICKUP'
];

export const arrivalQuestions = [
  'TRANSFER_AIR_ARRIVAL_AIRLINE',
  'TRANSFER_AIR_ARRIVAL_FLIGHT_NO',
  'TRANSFER_ARRIVAL_TIME',
  'TRANSFER_ARRIVAL_DROP_OFF',
  'PICKUP_POINT',
  'TRANSFER_RAIL_ARRIVAL_LINE',
  'TRANSFER_RAIL_ARRIVAL_STATION',
  'TRANSFER_ARRIVAL_TIME',
  'TRANSFER_ARRIVAL_DROP_OFF',
  'TRANSFER_PORT_ARRIVAL_TIME',
  'TRANSFER_PORT_CRUISE_SHIP',
  'TRANSFER_ARRIVAL_DROP_OFF',
  'PICKUP_POINT'
];
export const departureQuestions = [
  'TRANSFER_AIR_DEPARTURE_AIRLINE',
  'TRANSFER_AIR_DEPARTURE_FLIGHT_NO',
  'TRANSFER_DEPARTURE_DATE',
  'TRANSFER_DEPARTURE_TIME',
  'TRANSFER_DEPARTURE_PICKUP',
  'TRANSFER_RAIL_DEPARTURE_LINE',
  'TRANSFER_RAIL_DEPARTURE_STATION',
  'TRANSFER_DEPARTURE_DATE',
  'TRANSFER_DEPARTURE_TIME',
  'TRANSFER_DEPARTURE_PICKUP',
  'TRANSFER_PORT_CRUISE_SHIP',
  'TRANSFER_DEPARTURE_DATE',
  'TRANSFER_PORT_DEPARTURE_TIME',
  'TRANSFER_DEPARTURE_PICKUP'
];

export const conditionalQuestionRules = {
  TRANSFER_ARRIVAL_MODE: {
    AIR: [
      'TRANSFER_AIR_ARRIVAL_AIRLINE',
      'TRANSFER_AIR_ARRIVAL_FLIGHT_NO',
      'TRANSFER_ARRIVAL_TIME',
      'TRANSFER_ARRIVAL_DROP_OFF',
      'PICKUP_POINT'
    ],
    RAIL: [
      'TRANSFER_RAIL_ARRIVAL_LINE',
      'TRANSFER_RAIL_ARRIVAL_STATION',
      'TRANSFER_ARRIVAL_TIME',
      'TRANSFER_ARRIVAL_DROP_OFF'
    ],
    SEA: [
      'TRANSFER_PORT_ARRIVAL_TIME',
      'TRANSFER_PORT_CRUISE_SHIP',
      'TRANSFER_ARRIVAL_DROP_OFF',
      'PICKUP_POINT'
    ],
    OTHER: ['PICKUP_POINT']
  },
  TRANSFER_DEPARTURE_MODE: {
    AIR: [
      'TRANSFER_AIR_DEPARTURE_AIRLINE',
      'TRANSFER_AIR_DEPARTURE_FLIGHT_NO',
      'TRANSFER_DEPARTURE_DATE',
      'TRANSFER_DEPARTURE_TIME',
      'TRANSFER_DEPARTURE_PICKUP'
    ],
    RAIL: [
      'TRANSFER_RAIL_DEPARTURE_LINE',
      'TRANSFER_RAIL_DEPARTURE_STATION',
      'TRANSFER_DEPARTURE_DATE',
      'TRANSFER_DEPARTURE_TIME',
      'TRANSFER_DEPARTURE_PICKUP'
    ],
    SEA: [
      'TRANSFER_PORT_CRUISE_SHIP',
      'TRANSFER_DEPARTURE_DATE',
      'TRANSFER_PORT_DEPARTURE_TIME',
      'TRANSFER_DEPARTURE_PICKUP'
    ],
    OTHER: []
  }
};

export const pickupType = {
  AIRPORT: 'AIRPORT',
  PORT: 'PORT',
  HOTEL: 'HOTEL',
  LOCATION: 'LOCATION'
};

export const answers = {
  AIR: 'AIR',
  RAIL: 'RAIL',
  SEA: 'SEA',
  OTHER: 'OTHER'
};

export const optionValues = {
  [answers.OTHER]: 'Hotel',
  [answers.AIR]: 'Airport',
  [answers.RAIL]: 'Rail',
  [answers.SEA]: 'Ship / Cruise Ship'
};

export const questionsAllowedAnswers = {
  TRANSFER_ARRIVAL_MODE: 'TRANSFER_ARRIVAL_MODE',
  TRANSFER_DEPARTURE_MODE: 'TRANSFER_DEPARTURE_MODE'
};
