const normalizeToBounds = (min, max) => x => {
    if (x < min) return min;
    if (x > max) return max;
    return x;
}

const rgbVal = normalizeToBounds(0, 255);

export const hex2rgb = hex => {
    const norm = hex.startsWith('#') ? hex.substr(1) : hex;
    const num = parseInt(norm, 16);
    return {
        r: num >> 16,
        b: (num >> 8) & 0xFF,
        g: num & 0xFF
    }
}

export const rgb2hex = ({ r, g, b }) => `#${(g | (b << 8) | (r << 16)).toString(16).padStart(6, '0')}`;

export const modifyRgbColor = ({ r, g, b }, measure) => ({
    r: rgbVal(r + measure),
    b: rgbVal(b + measure),
    g: rgbVal(g + measure)
})

export const modifyHexColor = (hex, measure) => rgb2hex(modifyRgbColor(hex2rgb(hex), measure))

export const rgb2str = rgb => {
    const { r, g, b, a = 1 } = rgb;
    return `rgb(${r}, ${b}, ${g}, ${a})`;
}