import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { PER_PAGE } from '../../api/tours';
import { ReactComponent as BackIcon } from '../../assets/img/assets/Chevron.svg';
import { ButtonMedium } from '../../components/Common';
import defaultState from '../../store/defaultState';
import { SET_FILTERS } from '../../store/filters/actionTypes';
import { FontSizes } from '../../utils/constants';
import MultiSelectFilter from '../FilterSelects/MultiSelectFilter';
import './Filters.scss';
import localization from '../../localization';

var filterOpen = require('../../assets/img/FIltersIcon.svg');

var refreshIcon = require('../../assets/img/refresh_icon.svg');

const Button = styled(ButtonMedium)`
  border-radius: 3px;
`;

const ApplyButton = styled.div`
   {
    cursor: pointer;
    color: #ffffff;
    background-color: ${props => props.theme.colors.primary};
    border-radius: 35px;
    height: 48px;
    font-family: MontserratRegular;
    font-size: ${FontSizes.cta};
    padding-right: 10px;
    padding-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const ResetButton = styled.button`
  background-color: white;
  border: none;
  color: ${props => props.theme.colors.primary};
  font-size: ${FontSizes.cta};
  fontweight: 12px;
`;

class Filters extends React.Component {
  constructor(props) {
    super(props);
    const {
      onApply,
      filters,
      page,
      perPage,
      filterOptions,
      onFilterChange,
      onReset,
      toggle,
      func
    } = props;
    this.state = {
      filters,
      page,
      perPage,
      filterOptions,
      isModalOpen: true,
      screenWidth: window.innerWidth,
      toggle: toggle,
      func: func
    };
    this.onFilterChange = onFilterChange;
    this.onReset = onReset;
    this.onApply = onApply;
    this.resizeHandler = this.resize.bind(this);
    this.toggle.bind(this);
  }

  closeModal() {
    this.setState({ isModalOpen: false });
  }

  openModal() {
    this.setState({ isModalOpen: true });
  }

  static getDerivedStateFromProps({ filters, page, perPage, filterOptions }) {
    return {
      filters,
      page,
      perPage,
      filterOptions
    };
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeHandler);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeHandler);
    this.setState({ screenWidth: window.innerWidth });
  }

  isDesktop() {
    return this.state.screenWidth > 800;
  }

  resize() {
    this.setState({ screenWidth: window.innerWidth });

    if (this.state.screenWidth > 800 && this.props.isModalOpen) {
      this.closeModal();
    }
  }

  onFiltersChanged(type) {
    return value => {
      const filters = {
        ...this.state.filters,
        page: 0,
        [type]: value
      };
      this.onFilterChange(filters);

      if (this.isDesktop()) {
        this.onApply(filters);
      }
    };
  }

  onFiltersReset(type) {
    return value => {
      let filters = {
        ...this.state.filters,
        services: [],
        categories: []
      };

      this.onFilterChange(filters);

      this.onReset();
      this.onApply(defaultState.filter.filters);
    };
  }

  toggle() {
    this.setState({ toggle: false });
  }

  render() {
    const { isModalOpen } = this.state;
    if (this.isDesktop()) {
      return this.renderFilters();
    }

    return (
      <div className="full-screen-modal">
        <div className="modal__header">
          <div
            className={`modal__header-back ${isModalOpen ? 'opened' : 'closed'}`}
            onClick={() => {
              this.closeModal();
              this.onApply(this.state.filters);
              this.state.func();
            }}
          >
            <BackIcon />
          </div>

          <div className="modal__header-text">{localization.filters.backToResults}</div>
          <ApplyButton
            onClick={() => {
              this.closeModal();
              this.onApply(this.state.filters);
              this.state.func();
            }}
          >
            {localization.filters.applyFilters}
          </ApplyButton>
        </div>
        {this.state.toggle && this.state.screenWidth <= '800' && (
          <div class={{ display: 'table' }}>
            <p className="filter_activities_text">{localization.filters.filterActivities} </p>
            <img
              style={{
                float: 'right',
                marginTop: '40px',
                marginRight: '30px',
                marginLeft: 'auto',
                width: '50px'
              }}
              src={filterOpen}
            ></img>

          </div>
        )}
        <div className="modal__content">{this.state.isModalOpen && this.renderFilters()}</div>
      </div>
    );
  }

  renderFilters() {
    const {
      filterOptions: { categories, services },
      filters: { categories: categoriesSelected, services: servicesSelected }
    } = this.state;

    return (
      <div className="filters">
        {/* <DateRangeFilter
          isFullModal={this.props.isModalOpen}
          fromDateChange={this.onFiltersChanged('fromDate')}
          toDateChange={this.onFiltersChanged('toDate')}
          fromDate={this.state.filters.fromDate}
          toDate={this.state.filters.toDate}
        /> */}

        <MultiSelectFilter
          title="Categories"
          options={categories}
          selectedOptions={categoriesSelected}
          screenWidth={this.state.screenWidth}
          onChange={this.onFiltersChanged('categories')}
        />

        <MultiSelectFilter
          title="Services"
          options={services}
          selectedOptions={servicesSelected}
          screenWidth={this.state.screenWidth}
          onChange={this.onFiltersChanged('services')}
        />

        {this.state.toggle && this.state.screenWidth < 800 && (
          <ApplyButton
            style={{ width: '40%', marginLeft: 'auto', marginRight: 'auto', marginBottom: '10px' }}
            onClick={() => {
              this.closeModal();
              this.onApply(this.state.filters);
              this.state.func();
            }}
          >
            {localization.filters.applyFilters}
          </ApplyButton>
        )}
        <ResetButton onClick={this.onFiltersReset('services')}>{localization.filters.resetFilters}</ResetButton>
        <img src={refreshIcon}></img>
      </div>
    );
  }
}
export default connect(
  state => {
    const {
      filter: { filters, page, perPage = PER_PAGE, filterOptions }
    } = state;
    return {
      filters,
      page,
      perPage,
      filterOptions
    };
  },
  dispatch => ({
    onFilterChange: filters => dispatch({ type: SET_FILTERS, payload: filters }),
    onReset: () => dispatch({ type: SET_FILTERS, payload: defaultState.filter.filters })
  })
)(Filters);
