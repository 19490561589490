import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import localization from '../../localization';
import { FontSizes } from '../../utils/constants';
import { formatPrice, getPriceWithCurrencySymbol } from '../../utils/helpers';

const useStyles = makeStyles({
  boldNumber: {
    fontWeight: 'bold',
  },
  infoText: {
    marginTop: '24px',
    padding: '4px',

    '& p': {
      fontSize: FontSizes.cta,
    },
  },
});

const RefundDetails = ({ refundDetails, bookingDetails }) => {
  const classes = useStyles();

  if (!refundDetails) return null;

  const totalWithoutDiscount =
    bookingDetails.priceDetails.totalWithoutDiscountInUserCurrency ||
    bookingDetails.priceDetails.totalWithoutDiscount;

  let refundAmount = (totalWithoutDiscount / 100) * refundDetails.refundPercentage;

  const refundAmountWithCurrency = getPriceWithCurrencySymbol(
    formatPrice(refundAmount),
    bookingDetails.bookerCurrency
  );

  const [newRefundAmount, newRefundPercentage] = [
    refundAmount >= 1 ? refundAmountWithCurrency : 0,
    refundAmount >= 1 ? refundDetails.refundPercentage : 0,
  ];

  return (
    <div className={classes.infoText}>
      <p>
        {`${localization.cancelBooking.cancelInfoText} `}
        <span className={classes.boldNumber}>{newRefundAmount}</span>
        {` ${localization.cancelBooking.cancelInfoText1}`}
        <span className={classes.boldNumber}>{newRefundPercentage}%</span>
        {` ${localization.cancelBooking.cancelInfoText2}`}
      </p>
      <p>{localization.cancelBooking.cancelInfoNote}</p>
    </div>
  );
};

export default RefundDetails;
