import React from 'react';
import { ChevronIcon, EmptyCheckboxIcon, TickedCheckboxIcon } from '../Common'
import { values } from 'lodash/fp';

import './MultiSelectFilter.scss';
import styled from 'styled-components';
const StyledTitle = styled.div`{

    color: ${props => (props.theme.colors.primary)};
    
    fontSize: 50px;
    display: block;
    margin-top: auto;
  }`

class MultiSelectFilter extends React.Component {
    constructor(props) {
        super(props);
        const { options, selectedOptions, title, onChange, screenWidth } = props;
        this.state = {
            options,
            selectedOptions: selectedOptions || [],
            filterTitle: title,
            screenWidth: screenWidth,
            isOpen: screenWidth < 800 ? true : false,
        }
        this.onChange = onChange
        this.toggleOpen = this.toggleOpen.bind(this);
        this.resizeHandler = this.resize.bind(this);
    }

    static getDerivedStateFromProps({ options, selectedOptions }) {
        return { options, selectedOptions: selectedOptions || [] };
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.resizeHandler);
    }

    componentDidMount() {
        window.addEventListener("resize", this.resizeHandler);
        this.setState({ screenWidth: window.innerWidth });
    }

    resize() {
        this.setState({ screenWidth: window.innerWidth });

    }

    toggleOpen() {

        if (this.state.screenWidth > 800) {
            this.setState({
                isOpen: !this.state.isOpen,
            });

        }


    }

    render() {

        const { filterTitle, options, selectedOptions } = this.state;

        return (
            <div style={{ backgroundColor: 'white' }}>

                <div
                    className={"filter-options " + (this.state.isOpen ? "open" : '')}>
                    <div
                        className="filter-options__title"
                        onClick={this.toggleOpen}>
                        <div className="filter-options__title-text" >
                            {this.state.screenWidth > 800 &&
                                <div className="title">
                                    {filterTitle}
                                </div>
                            }
                            {this.state.screenWidth < 800 &&
                                <StyledTitle>
                                    {filterTitle}
                                </StyledTitle>
                            }
                        </div>
                        {this.state.screenWidth > 800 &&
                            <ChevronIcon
                                className="filter-options__title-icon filter-options__icon" />
                        }
                    </div>
                    {this.state.screenWidth > 800 &&
                        <div
                            className="filter-options__options">
                            {this.state.isOpen &&
                                values(options).map(this.renderFilterOption(selectedOptions))
                            }
                        </div>
                    }
                    {this.state.screenWidth < 800 &&
                        <div style={{ backgroundColor: 'white' }}>
                            <div className="filter-options__options">
                                {this.state.isOpen &&
                                    values(options).map(this.renderFilterOption(selectedOptions))
                                }
                            </div>
                        </div>
                    }
                </div>
              
            </div>
        );
    }

    renderFilterOption(selectedOptions) {
  
        const checked = ({ id }) => selectedOptions.includes(id);
        const unselect = ({ id }) => this.onChange(selectedOptions.filter(x => x !== id));
        const select = ({ id }) => this.onChange([...selectedOptions, id]);
        return option => {
            const isChecked = checked(option);
            return (
                <div>
                    {this.state.screenWidth > 800 &&
                        <div className="filter-options__option" key={option.id}
                            onClick={() => isChecked ? unselect(option) : select(option)}>
                            <label className="filter-options__option-label">
                                <div className="filter-options__option-label-text">{option.value}</div>
                                {isChecked ?
                                    (<TickedCheckboxIcon />) :
                                    (<EmptyCheckboxIcon />)}
                            </label>
                        </div>
                    }
                    {
                        this.state.screenWidth < 800 &&

                        <div className="filter-options-mob_option" key={option.id}
                            onClick={() => isChecked ? unselect(option) : select(option)}>
                            <label className="filter-options__option-label">
                                <div className="filter-options-mob_text">{option.value}</div>
                                {isChecked ?
                                    (<TickedCheckboxIcon />) :
                                    (<EmptyCheckboxIcon />)}
                            </label>
                            <hr style={{color: 'grey'}}></hr>
                        </div>
                    }
                </div>
            );
        };
    }
}

export default MultiSelectFilter