import { SEND_ENQUIRY } from "./actionTypes";

export const sendEnquiry = (history, { firstName, lastName, email, website, query, client }) => ({
    history,
    type: SEND_ENQUIRY,
    payload: {
        firstName,
        lastName,
        email,
        website,
        query, 
        client
    }
})