import { Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { fetchGygBooking, fetchGygBookingDetails, fetchTsBookingDetails } from '../../api/booking';
import checkMarkGreenIcon from '../../assets/img/checkMarkGreen.svg';
import CloseIcon from '../../assets/img/close.svg';
import Sidebar from '../../components/Common/BookingSummarySidebar/Sidebar';
import { CircularAnimation } from '../../components/Common/CircularAnimation';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import MapContainer from '../../components/Map';
import localization from '../../localization';
import { FontSizes } from '../../utils/constants';
import { splitForBullets, stripeTags } from '../../utils/TextUtils';
import './SimulatedOrderConfirmation.scss';
const emailCss = require('./emailCss.js');

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '1220px',
    width: '100%',
    margin: '50px auto 0',
    textAlign: 'left',
    [theme.breakpoints.down('960')]: {
      margin: '10px auto 0',
    },
  },
  container: {
    padding: '24px',
    display: 'grid',
    columnGap: '4.5%',
    rowGap: '0',
    gridTemplateColumns: '56.33% 39.17%',
    [theme.breakpoints.down('960')]: {
      gridTemplateColumns: '100%',
      columnGap: '0',
      rowGap: '0',
    },
  },
  rightBlock: {
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
    [theme.breakpoints.down('960')]: {
      gridColumn: '1 / 2',
      gridRow: '2 / 3',
    },
  },
  introBlock: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
    [theme.breakpoints.down('960')]: {
      gridRow: '1 / 2',
      marginBottom: '30px',
    },
  },
  mainInfoBlock: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    [theme.breakpoints.down('960')]: {
      gridRow: '3 / 4',
    },
  },
  circularAnimation: {
    [theme.breakpoints.down('960')]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  firstHeading: {
    fontSize: FontSizes.h1,
    color: '#424242',
    fontFamily: theme.typography.fontFamilyBold,
    margin: '20px 0 30px',
    [theme.breakpoints.down('960')]: {
      fontSize: '28px',
    },
  },
  firstHeadingPart: {
    marginBottom: '-2px',
    display: 'block',
  },
  secondHeading: {
    fontSize: FontSizes.h2,
    fontFamily: theme.typography.fontFamilyBold,
    color: '#333333',
    [theme.breakpoints.down('960')]: {
      fontSize: '18px',
    },
  },
  detailsHeading: {
    fontSize: '15px',
    fontFamily: theme.typography.fontFamilyBold,
    color: '#282828',
  },
  bookRef: {
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    overflowWrap: 'break-word',
    display: 'inline-block',
    marginTop: '5px',
    width: 'fit-content',
    maxWidth: '100%',
  },
  importantText: {
    fontSize: '18px',
    color: '#333333',
    [theme.breakpoints.down('960')]: {
      fontSize: '15px',
    },
  },
  inclusionsList: {
    listStyle: 'none',
    padding: '0px',
    '& li': {
      lineHeight: '1.65',
    },
  },
  exclusionsList: {
    listStyle: 'none',
    padding: '0px',
    '& li': {
      lineHeight: '1.65',
    },
  },
  listIcon: {
    width: '20px',
    height: '20px',
    margin: '0 16px',
  },
  listItem: {
    listStyle: 'none',
    margin: '10px 0',
    fontSize: FontSizes.cta,
    color: '#282828',
    display: 'flex',
    alignItems: 'center',
  },
  importantDetailsBlock: {
    margin: '40px 0 30px',
  },
  closeButton: {
    width: '50px',
    height: '50px',
    color: '#aeaeae',
    fontSize: '25px',
    position: 'absolute',
    top: '11px',
    right: '11px',
  },
  viewTicketsText: {
    color: '#ffffff',
    textTransform: 'capitalize',
  },
  mapBlock: {
    width: '100%',
  },
}));

function OrderConfirmation({ match }) {
  const classes = useStyles();
  const fetchBookingDetails =
    match.params.cartId?.length === 32 ? fetchGygBookingDetails : fetchTsBookingDetails;

  const [bookingDetails, setBookingDetails] = React.useState(null);
  const [booking, setBooking] = React.useState(null);

  React.useEffect(() => {
    fetchBookingDetails({ id: match.params.cartId, language: localization.getLanguage() }).then(
      (bookingDetails) => {
        setBookingDetails(bookingDetails);
        if (match.params.cartId?.length !== 32) setBooking(bookingDetails);
      }
    );

    if (match.params.cartId?.length === 32) {
      fetchGygBooking({
        id: match.params.cartId,
        language: localization.getLanguage(),
      }).then((booking) => {
        setBooking(booking);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.cartId]);

  if (!bookingDetails || !booking) {
    return null;
  }

  return (
    <div>
      <Header />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.introBlock}>
            <div className={classes.circularAnimation}>
              <CircularAnimation />
            </div>
            <h1 className={classes.firstHeading}>
              <span className={classes.firstHeadingPart}>
                {' '}
                {localization.orderConfirmation.orderConfirmed}
              </span>
              <span className={classes.firstHeadingPart}>
                {' '}
                {localization.orderConfirmation.thankYou}!
              </span>
            </h1>
            <h2 className={classes.secondHeading}>
              {localization.orderConfirmation.bookingRef}:{' '}
              <span className={classes.bookRef}>{match.params.cartId}</span>
            </h2>
            <span className={classes.importantText}>
              {localization.orderConfirmation.detailsToEmail}: <br />
              {bookingDetails.traveler.email}
            </span>{' '}
          </div>

          <div className={classes.mainInfoBlock}>
            {(booking.inclusions?.length || booking.exclusions?.length) && (
              <div className={classes.importantDetailsBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.importantDetails}
                </h2>

                <Card elevation={2}>
                  <CardContent>
                    {booking.inclusions?.length && (
                      <>
                        <span className={classes.detailsHeading}>
                          {localization.orderConfirmation.included}:
                        </span>
                        <ul className={classes.inclusionsList}>
                          {splitForBullets(booking.inclusions)
                            .map(stripeTags)
                            .map((inclusion) => (
                              <li key={inclusion} className={classes.listItem}>
                                <img className={classes.listIcon} alt="" src={checkMarkGreenIcon} />
                                {inclusion}
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                    {booking.exclusions?.length && (
                      <>
                        <span className={classes.detailsHeading}>
                          {localization.orderConfirmation.notIncluded}:
                        </span>
                        <ul className={classes.exclusionsList}>
                          {splitForBullets(booking.exclusions)
                            .map(stripeTags)
                            .map((exclusion) => (
                              <li key={exclusion} className={classes.listItem}>
                                <img className={classes.listIcon} alt="" src={CloseIcon} />
                                {exclusion}
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </CardContent>
                </Card>
              </div>
            )}

            {booking.cancellationPolicyText && (
              <div className={classes.importantDetailsBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.cancellationPolicy}
                </h2>
                <Card elevation={2}>
                  <CardContent>
                    <span className={classes.listItem}>{booking.cancellationPolicyText}</span>
                  </CardContent>
                </Card>
              </div>
            )}

            {booking.importantInformation && (
              <div className={classes.importantDetailsBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.importantInformation}
                </h2>
                <Card elevation={2}>
                  <CardContent>
                    <span className={classes.listItem}>{booking.importantInformation}</span>
                  </CardContent>
                </Card>
              </div>
            )}

            {Boolean(booking?.meetingPoint?.lat && booking?.meetingPoint?.long) && (
              <div className={classes.mapBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.meetingPoint}
                </h2>
                <Card elevation={2}>
                  <MapContainer
                    description={booking.meetingPoint.description}
                    lat={booking.meetingPoint.lat}
                    long={booking.meetingPoint.long}
                    width="100%"
                  />
                </Card>
              </div>
            )}
          </div>
          <div className={classes.rightBlock}>
            <Sidebar
              bookings={[booking]}
              totalPrice={bookingDetails?.price?.total || bookingDetails?.priceDetails?.total}
            />
          </div>
        </div>
        <div style={{ marginTop: '50px' }}></div>
      </div>
      <Footer />
    </div>
  );
}

export default OrderConfirmation;
