import { textLocalization } from '@jjog1/ta-wl-localization';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';
import 'moment/locale/ru';
import LocalizedStrings from 'react-localization';

const {
  en: WLen,
  fr: WLfr,
  sr: WLsr,
  nl: WLnl,
  it: WLit,
  de: WLde,
  frCA: WLfrCA,
} = textLocalization;

const localization = new LocalizedStrings({
  en: WLen,
  fr: WLfr,
  'fr-CA': WLfrCA,
  sr: WLsr,
  nl: WLnl,
  de: WLde,
  it: WLit,
});

const init = () => {
  const urlLocale = new URLSearchParams(window.location.search).get('language');
  if (urlLocale) {
    localization.setLanguage(urlLocale);
    moment.locale(urlLocale);
    return;
  }
  const savedLocale = localStorage.getItem('locale');
  if (savedLocale) {
    localization.setLanguage(savedLocale);
    moment.locale(savedLocale);
    return;
  }
  moment.locale(localization.getLanguage());
};

export const setLocalization = (locale) => {
  localStorage.setItem('locale', locale);
  //Note this fixes an issue where a user had a specified a query param lang then interacts with the lang control
  window.history.pushState({}, document.title, window.location.pathname);
  window.location.reload();
};

init();
export default localization;
