import React from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { Block, Title, Link, List, Paragraph, Paragraphs } from '../../../components/Common/Policies';

import localization from '../../../localization'

class PrivacyPolicy extends React.Component {

	render() {
		return (
			<div className="PrivacyPolicy" style={{ fontFamily: 'DiodrumRegular' }}>
				<Header />
				<div style={{ textAlign: 'left', width: '80vw', margin: 'auto', marginTop: '5vh' }}>
					<Title>{localization.policies.privacy.title}</Title>
					<Block content={localization.policies.privacy.commitment} />
					<Title>{localization.policies.privacy.whatInformation.title}</Title>
					<List content={localization.policies.privacy.whatInformation.content} />
					<Title>{localization.policies.privacy.howAndWhy.title}</Title>
					<Title>{localization.policies.privacy.transaction.title}</Title>
					<Paragraphs content={localization.policies.privacy.transaction.content} />
					<Paragraph>{localization.policies.privacy.transaction.paymentPartnersTitle}</Paragraph>
					<Link href="https://stripe.com/ie/privacy">Stripe</Link>
					<Link href="https://www.adyen.com/policies-and-disclaimer/privacy-policy">Adyen</Link>
					<Link href="https://www.getyourguide.com/privacy_policy">GetYourGuide</Link>
					<Paragraph>{localization.policies.privacy.transaction.paymentPartnersOutro}</Paragraph>
					<Title>{localization.policies.privacy.businessInterests.title}</Title>
					<List content={localization.policies.privacy.businessInterests.content} />
					<Title>{localization.policies.privacy.consent.title}</Title>
					<List content={localization.policies.privacy.consent.content} />
					<hr />
					<Title>{localization.policies.privacy.dataShare.title}</Title>
					<List content={localization.policies.privacy.dataShare.content} />
					<Block content={localization.policies.privacy.dataTransfer} />
					<Title>{localization.policies.privacy.choisesAndRights.title}</Title>
					<Paragraph>{localization.policies.privacy.choisesAndRights.choises.title}</Paragraph>
					<Paragraph>{localization.policies.privacy.choisesAndRights.choises.intro}</Paragraph>
					<List content={localization.policies.privacy.choisesAndRights.choises.content} />
					<Paragraph>{localization.policies.privacy.choisesAndRights.rights.title}</Paragraph>
					<Paragraph>{localization.policies.privacy.choisesAndRights.rights.intro}</Paragraph>
					<List content={localization.policies.privacy.choisesAndRights.rights.content} />
					<Paragraph>{localization.policies.privacy.choisesAndRights.rights.outro}</Paragraph>
					<Block content={localization.policies.privacy.lookingAfterInformation} />
					<Block content={localization.policies.privacy.linksToOtherSites} />
					<Block content={localization.policies.privacy.contactUs} />
					<Block content={localization.policies.privacy.updates} />
				</div>
				<Footer />
			</div>

		);
	}
}

export default PrivacyPolicy;
