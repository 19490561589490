import React from 'react';
import styled from 'styled-components';
import localization from '../../../../../localization';
import { FontSizes } from '../../../../../utils/constants';
import CommonButton from '../../../../Common/Button';
import ExtraItem from './ExtraItem';

const ExtrasWrapper = styled.div`
  margin-bottom: 20px;
`;

const SectionHeading = styled.h2`
  font-size: ${FontSizes.h2};
  display: inline-block;
  color: #202020;
  font-weight: 700;
  margin: 4px 0;
`;

const ExtrasControlsBlock = styled.div`
  display: flex;
  flex-flow: column nowrap;
  // padding-left: 18px;
`;

const ExtrasList = styled.div`
  margin: 50px 0 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

//TODO (initially this comment from AdditionalExtrasPurchase component): Split into independent component during booking widget logic refactoring

function AdditionalExtrasPicker({
  participantsAmount,
  currency,
  extras,
  handleCompletePurchase,
  handleExtraCountChange
}) {
  const isDisabled = extras.some(e => e.isMandatory);
  const canBePurchased = extras.every(e =>
    e.isMandatory === true ? e.count >= participantsAmount : true
  );

  const handleSkip = () => {
    handleCompletePurchase({ isExtrasSkipped: true });
  };

  const skipButton = (
    <CommonButton
      disabled={isDisabled}
      background={false}
      style={{
        fontSize: '18px',
        borderRadius: '10px',
        marginBottom: '2px',
        height: '50px',
        width: '100%'
      }}
      onClick={handleSkip}
    >
      {localization.extras.skip}
    </CommonButton>
  );

  return (
    <ExtrasWrapper>
      <ExtrasControlsBlock>
        <Header>
          <SectionHeading>{localization.extras.additionalExtras}</SectionHeading>
          <div className="top-skip-button">{skipButton}</div>
        </Header>

        <ExtrasList>
          {extras.map(extra => (
            <ExtraItem
              key={extra._id}
              id={extra._id}
              participantsAmount={participantsAmount}
              currency={currency}
              title={extra.title}
              description={extra.description}
              image={extra.image}
              price={extra.price}
              count={extra.count}
              isMandatory={extra.isMandatory}
              handleExtraCountChange={handleExtraCountChange}
            />
          ))}
        </ExtrasList>
        <CommonButton
          className="compete-purchase-button"
          style={{
            fontSize: '18px',
            borderRadius: '10px',
            marginBottom: '2px',
            height: '50px',
            width: '100%',
            alignSelf: 'flex-end',
            minWidth: '200px'
          }}
          onClick={() => handleCompletePurchase({})}
          disabled={!canBePurchased}
        >
          {localization.payment.complete}
        </CommonButton>
        <div className="bottom-skip-button">{skipButton}</div>
      </ExtrasControlsBlock>
    </ExtrasWrapper>
  );
}

export default AdditionalExtrasPicker;
