import React, { Component } from 'react';
import { getBookingQuestions } from '../../../../api/viator';
import { optionValues } from './constants';
import PickupPoint from './CustomQuestionFields/PickupPoint/index.js';
import Question from './CustomQuestionFields/Question';
import QuestionWithAnswers from './CustomQuestionFields/QuestionWithAnswers';
import {
  getPassengers,
  shouldRenderQuestion,
  shouldRenderSelectOption
} from './customQuestionsHelper';

class CustomQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: props.questions,
      booking: props.booking,
      transferArrivalMode: props.transferArrivalMode,
      transferDepartureMode: props.transferDepartureMode,
      setArrivalMode: props.setArrivalMode,
      setDepartureMode: props.setDepartureMode,
      setFieldValue: props.setFieldValue,
      tour: props.tour
    };
  }

  async componentDidMount() {
    const bookingQuestions = await getBookingQuestions(this.state.questions);
    this.setState({
      filledQuestions: bookingQuestions
    });;
  }
  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  changeArrivalMode(e) {
    this.setState({ TRANSFER_ARRIVAL_MODE: e.target.value });
  }

  changeDepartureMode(e) {
    this.setState({ TRANSFER_DEPARTURE_MODE: e.target.value });
  }

  render() {
    const changeArrivalMode = this.state.setArrivalMode;
    const changeDepartureMode = this.state.setDepartureMode;
    const setFieldValue = this.state.setFieldValue;
    const transferArrivalMode = this.state.transferArrivalMode;
    const transferDepartureMode = this.state.transferDepartureMode;
    const filledQuestions = this.state.filledQuestions;
    const tour = this.state.tour;

    if (filledQuestions) {
      const perBookingQuestions = filledQuestions
        .filter(e => e.group === 'PER_BOOKING')
        .sort(e => (e.id === 'TRANSFER_ARRIVAL_MODE' ? -1 : 1));
      const hasArrivalModeQuestion = Boolean(
        filledQuestions.find(e => e.id === 'TRANSFER_ARRIVAL_MODE')
      );
      const hasDepartureModeQuestion = Boolean(
        filledQuestions.find(e => e.id === 'TRANSFER_DEPARTURE_MODE')
      );
      const passengers = getPassengers(filledQuestions, this.state.booking.bookingHold.paxMix);

      function renderSelectOptionForMode(ans) {
        const shouldRender = shouldRenderSelectOption(
          ans,
          tour.logistics.travelerPickup?.locations,
          tour.logistics.travelerPickup?.allowCustomTravelerPickup
        );

        if (!shouldRender) return null;

        return <option value={ans}>{optionValues[ans]}</option>;
      }

      function renderQuestion(question, passengerNum, i) {
        const fieldName = `customQuestionsAnswers.${passengerNum}.${question.id}-${i}`;
        const shouldRender = shouldRenderQuestion(
          question.id,
          transferArrivalMode,
          transferDepartureMode,
          hasArrivalModeQuestion,
          hasDepartureModeQuestion
        );

        if (question.allowedAnswers) {
          if (question.id === 'AGEBAND') return null;

          return (
            <QuestionWithAnswers
              question={question}
              fieldName={fieldName}
              shouldRender={shouldRender}
              renderSelectOptionForMode={ans => <option value={ans}>{ans}</option>}
            />
          );
        }

        if (['FULL_NAMES_FIRST', 'FULL_NAMES_LAST'].includes(question.id) && !passengerNum) {
          return null;
        }

        return <Question question={question} fieldName={fieldName} setFieldValue={setFieldValue} />;
      }

      let elementCount = -1;

      function renderPerBookingQuestion(question, passengerNum, elementCount) {
        const fieldName = `customQuestionsAnswers.${elementCount}.${question.id}-${passengerNum}-pb`;
        const shouldRender = shouldRenderQuestion(
          question.id,
          transferArrivalMode,
          transferDepartureMode,
          hasArrivalModeQuestion,
          hasDepartureModeQuestion
        );

        if (question.allowedAnswers) {
          if (question.id === 'TRANSFER_ARRIVAL_MODE') {
            return (
              <QuestionWithAnswers
                question={question}
                label={'Pickup Details'}
                fieldName={fieldName}
                onChange={changeArrivalMode(fieldName)}
                renderSelectOptionForMode={renderSelectOptionForMode}
              />
            );
          }
          if (question.id === 'TRANSFER_DEPARTURE_MODE') {
            return (
              <QuestionWithAnswers
                question={question}
                fieldName={fieldName}
                onChange={changeDepartureMode(fieldName)}
                renderSelectOptionForMode={renderSelectOptionForMode}
              />
            );
          }
          return (
            <QuestionWithAnswers
              question={question}
              fieldName={fieldName}
              shouldRender={shouldRender}
              renderSelectOptionForMode={ans => <option value={ans}>{ans}</option>}
            />
          );
        }

        if (question.id === 'PICKUP_POINT' && shouldRender) {
          return (
            <div key={question.id}>
              <PickupPoint
                name={fieldName}
                question={question}
                TRANSFER_ARRIVAL_MODE={transferArrivalMode}
                tour={tour}
                setFieldValue={setFieldValue}
              />
            </div>
          );
        }
        return <Question question={question} fieldName={fieldName} shouldRender={shouldRender} setFieldValue={setFieldValue}  />;
      }
      return (
        <div>
          {perBookingQuestions.map((question, passengerNum) => {
            elementCount++;
            return renderPerBookingQuestion(question, passengerNum, elementCount);
          })}
          {passengers.map((p, passengerNum) => {
            const questionsOtherThanName = p.questions.filter(
              e => !['FULL_NAMES_FIRST', 'FULL_NAMES_LAST', 'AGEBAND'].includes(e.id)
            );

            if (passengerNum || questionsOtherThanName.length) {
              return (
                <div>
                  <br />
                  <br />
                  <div>
                    <b>
                      Passenger {passengerNum + 1} :{' '}
                      {p.ageBand[0] + p.ageBand.substring(1).toLowerCase()}
                    </b>
                  </div>
                  <br />
                  {p.questions.map((question, i) => {
                    elementCount++;
                    return renderQuestion(question, passengerNum, i);
                  })}
                </div>
              );
            } else {
              return null;
            }
          })}
        </div>
      );
    } else {
      return null;
    }
  }
}
export default CustomQuestions;
