import { TextField } from '@mui/material';
import Input from '@mui/material/Input';
import makeStyles from '@mui/styles/makeStyles';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { FormGroup } from 'reactstrap';
import localization from '../../localization';
import { getCreditCardType, wrapedSynthetichandler } from '../../utils/helpers';
import './style.scss';

import cvcLogo from '../../assets/img/payment/CVC.svg';
import discoverLogo from '../../assets/img/payment/discover.svg';
import jcbLogo from '../../assets/img/payment/jcb.svg';
import maestroLogo from '../../assets/img/payment/maestro.svg';
import mastercardLogo from '../../assets/img/payment/mastercard.svg';
import visaLogo from '../../assets/img/payment/visa.svg';
import { FontSizes } from '../../utils/constants';

const logo = {
  mastercard: mastercardLogo,
  visa: visaLogo,
  maestro: maestroLogo,
  jcb: jcbLogo,
  discover: discoverLogo,
};

const useStyles = makeStyles({
  cardsInputRoot: {
    height: '20px',
    width: '100%',
  },
  inputRoot: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: '#767676',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
  },
  input: {
    '&::placeholder': {
      color: '#5a5a5a',
    },
  },
  textField: {
    width: '100%',
    fontSize: FontSizes.cta,
  },
});

const MaskedInput = ({ onChange, className, type = 'text', ...props }) => {
  const classes = useStyles();

  return (
    <div className={className}>
      <InputMask
        {...props}
        className={`form-control`}
        maskChar=" "
        type={type}
        onChange={wrapedSynthetichandler(onChange)}
      >
        {() => (
          <Input
            classes={{
              root: classes.cardsInputRoot,
            }}
            inputProps={{
              classes: { input: classes.input },
            }}
            disableUnderline={true}
            placeholder={props.placeholder}
            name={props.name}
            type="text"
          />
        )}
      </InputMask>
    </div>
  );
};

const autofillYearFormat = /^\n?\d{2}[ ]?(\\|\/)[ ]?(\d{2}|\d{4})$/;
function trimYearForAutofill(val) {
  // eslint-disable-next-line no-unused-vars
  const [mm, _separator, yy] = val.split(/(\\|\/)/).map((str) => str.trim());

  return `${String(mm).padStart(2, '0')} / ${String(yy % 100).padStart(2, '0')}`;
}

const CreditCardForm = ({
  classes: {
    wrapperClass = 'cc',
    cardholderClass = 'cc__input-full',
    numberClass = 'cc__input-full',
    dateClass = 'cc__input-half',
    cvcClass = 'cc__input-half',
  },
  onChange,
  formData,
}) => {
  const classes = useStyles();
  const changeHandler = (name) => (val) => onChange({ ...formData, [name]: val });
  const [brandLogo, setBrandLogo] = useState('');

  const handleCardNumberChange = (value) => {
    changeHandler('cardNumber')(value);
    const brand = getCreditCardType(value);
    setBrandLogo(logo[brand] || '');
  };

  return (
    <FormGroup className={wrapperClass}>
      <div style={{ margin: '10px 0 22px' }} className={cardholderClass}>
        <TextField
          value={formData.nameOnCard}
          onChange={wrapedSynthetichandler(changeHandler('nameOnCard'))}
          classes={{ root: classes.inputRoot }}
          className={classes.textField}
          variant="outlined"
          color="primary"
          label={localization.payment.form.nameOnCard}
          // InputLabelProps={{
          //   shrink: true
          // }}
        />
      </div>
      <div className="credit-card">
        <div className="credit-card-number">
          <span className="credit-card-number-brands">
            {brandLogo.length ? (
              <img className="brand-logo" src={brandLogo} alt="BrandLogo" />
            ) : null}
          </span>
          <MaskedInput
            value={formData.cardNumber}
            onChange={handleCardNumberChange}
            name="number"
            className={numberClass}
            placeholder={localization.payment.form.cardNumber}
            mask="9999 9999 9999 9999"
          />
        </div>
        <div className="credit-card-date">
          <MaskedInput
            name="expiration_date"
            className={dateClass}
            placeholder={localization.payment.form.expiryMMYY}
            mask="99 \/ 99"
            beforeMaskedValueChange={(newState, oldState, rawVal) => {
              return {
                value: autofillYearFormat.test(rawVal)
                  ? trimYearForAutofill(rawVal)
                  : newState.value,
                selection: newState.selection,
              };
            }}
            value={formData.expirationDate}
            onChange={changeHandler('expirationDate')}
          />
        </div>
        <div className="credit-card-cvc">
          <img className="cvc-icon" src={cvcLogo} alt="Cvc icon" />
          <MaskedInput
            name="ccv"
            className={cvcClass}
            placeholder="CCV"
            mask="999"
            value={formData.ccv}
            onChange={changeHandler('ccv')}
          />
        </div>
      </div>
    </FormGroup>
  );
};

export default CreditCardForm;
