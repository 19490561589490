import logo from '../assets/img/logos/aircairologo.svg';
import logoWhite from '../assets/img/logos/aircairologo.svg';
import rollingBanner1Tab from '../assets/img/banners/aircairo/v1/iPad_WL_Home.jpg';
import rollingBanner1Mob from '../assets/img/banners/aircairo/v1/Phone_WL_Home.jpg';
import rollingBanner1Web from '../assets/img/banners/aircairo/v1/HDDesktop_WL_Home [1920x400].jpg';

import rollingBanner2Tab from '../assets/img/banners/aircairo/v2/iPad_WL_Home.jpg';
import rollingBanner2Mob from '../assets/img/banners/aircairo/v2/Phone_WL_Home.jpg';
import rollingBanner2Web from '../assets/img/banners/aircairo/v2/HDDesktop_WL_Home [1920x400].jpg';
import localization from '../localization';
var banner = require('../assets/img/banners/airserbia_banner.jpg');
var favicon = require('../assets/img/favicon.ico');

export default {
  name: 'Air Cairo',
  clientName: 'Air Cairo',
  config: {
    showMapsByDefault: false,
    googleAnalyticsKey: 'GTM-KVM723L',
    clientGAKey: 'GTM-KVM723L',
    GTMID: 'GTM-KVM723L',
    googleAnalyticsKey: 'GTM-KVM723L',
    errorReportingUrl: '',
    usePrimaryColoredBanner: false,
    dontUseSliderForLocations: false,
    alwaysUseBookNowButton: true,
    showPriceOverImage: true,
    useSecondaryColorForUIComponents: true,
    useRollingBanner: false,
    useWideTourLocation: true,
  },
  meta: {
    title: 'TripAdmit - Tours and Activities',
    description: 'Connecting Experiences',
    home: '/home',
  },
  colors: {
    primary: '#451d66',
    secondary: '#f48526',
    inactive: '#BCBCBC',
  },
  searchBanner: '#451d66',
  fonts: {
    colors: {
      primary: '#004A97',
      secondary: '#f48526',
      inactive: '#BCBCBC',
    },
    primary: 'GeoramaRegular',
    regular: 'GeoramaRegular',
    light: 'GeoramaRegular',
    bold: 'GeoramaBold',
  },
  styles: {
    pickerControlButton: {
      background: '#451d66',
      borderRadius: '4px',
      width: '32px',
      height: '32px',
      padding: '0',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: '#fff',
    },
    pickerCounter: {
      background: 'none',
      border: '0',
      width: '36px',
      height: '32px',
      padding: '0',
      textAlign: 'center',
      margin: '0 2px',
    },
  },
  bannerImages: {
    images: [
      {
        web: rollingBanner1Web,
        mob: rollingBanner1Mob,
        tab: rollingBanner1Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
      {
        web: rollingBanner2Web,
        mob: rollingBanner2Mob,
        tab: rollingBanner2Tab,
        text: localization.rollingBanner.static,
        link: '',
      },
    ],
  },
  assets: {
    logo,
    logoWhite,
    favicon,
    banner,
  },
  currency: {
    default: 'EGP',
    defaultWithSymbol: 'EGP £',
    available: {
      EGP: 'EGP £',
      EUR: 'EUR €',
      USD: 'USD $',
      KWD: 'KWD د.ك',
      GEL: 'GEL ლ',
      QAR: 'QAR',
      JOD: 'JOD د.ا',
      RSD: 'RSD',
      SAR: 'SAR ر.س',
    },
  },
  language: {
    default: 'en',
    available: ['en'],
  },
};
