import React from 'react';
import styled from 'styled-components';
import magnifyingGlassIcon from '../../assets/img/assets/Zoom_WL.svg';
import localization from '../../localization';
import { FontSizes } from '../../utils/constants';

const NoResultsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 80px auto 0 auto;
`;

const Heading = styled.h3`
  margin-bottom: 10px;
  color: #aeaeae;
  font-weight: bold;
  font-size: 23px;
`;

const Icon = styled.img`
  margin: 0 auto 12px auto;
  width: 200px;
  height: 200px;
`;

const Text = styled.p`
  color: #aeaeae;
  font-weight: bold;
  font-size: ${FontSizes.p};
  max-width: 410px;
  margin: 0 auto;
`;

function NoResultsFound() {
  return (
    <NoResultsContainer>
      <Icon alt="No results" src={magnifyingGlassIcon} />
      <Heading>{localization.location.noResults}</Heading>
      <Text>{localization.location.alternateFilters}</Text>
    </NoResultsContainer>
  );
}
export default NoResultsFound;
