import { merge } from 'lodash/fp';
import mockData from './mockData.json';
const defaultCardData = {};
export default {
  location: {
    selectedLocation: null,
    locations: {
      list: [],
      isLoaded: false,
      page: 0
    },
    cities: {
      list: [],
      isLoaded: false,
      page: 0
    },
    attractions: {
      list: [],
      isLoaded: false,
      page: 0
    },
    viatorAttractions: {
      list: [],
      isLoaded: false
    },
    tours: {
      list: [],
      isLoaded: false,
      page: 0,
      canLoadMore: false
    },
    isLoaded: false
  },
  filter: {
    filters: {
      page: 0,
      primaryQuery: ''
    },
    filterOptions: {
      services: [],
      categories: []
    },
    isLoaded: false
  },
  tours: {
    featured: {
      list: [],
      isLoaded: false,
      page: 0
    },
    all: [],
    scroll: null,
    isLoaded: false
  },
  tourOptions: {
    error: null,
    manifest: {},
    date: null,
    language: null,
    availabilities: {},
    options: {},
    pricings: null,
    isLoaded: null,
    isAvailabilitiesLoaded: false,
    isBookingInProgress: false
  },
  tour: {
    item: null,
    isLoaded: false
  },
  cart: {
    shoppingCartHash: null,
    cart: null,
    cartData:
      process.env.NODE_ENV === 'production'
        ? defaultCardData
        : merge(defaultCardData, mockData.cartData),
    bookingParameters: {},
    isLoaded: false,
    cartCheckoutInProgress: false
  },
  fallback: {
    isHidden: true,
    isLoaded: true,
    tours: []
  },
  search: {
    promptsList: [],
    isLoaded: false
  },
  cancelBooking: {
    cancelReasons: [],
    cancelReasonsError: null,
    cancelQuote: null,
    isCancelLoading: false
  }
};
