export const mapTourGuides = languageGuides => {
  if (!languageGuides?.length) return [];

  const uniqueGuides = [
    ...new Map(
      languageGuides.map(guide => [guide['type'], { type: guide.type, languages: [] }])
    ).values()
  ];

  languageGuides.forEach(languageGuide => {
    const specificGuide = uniqueGuides.find(guide => guide.type === languageGuide.type);
    const isGuideHasLanguage = specificGuide.languages.includes(languageGuide.language);

    if (!isGuideHasLanguage) {
      specificGuide.languages.push(languageGuide.language);
    }
  });

  return uniqueGuides;
};

export const shuffleFeatures = features => {
  for (let i = features.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [features[i], features[j]] = [features[j], features[i]];
  }

  return features;
};
