import { StyleBreakpoints } from '@constants';
import { Button, Popover, TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { pickBy } from 'lodash/fp';
import React, { useState } from 'react';
import localization from '../../../../../localization';
import { FontSizes } from '../../../../../utils/constants';
import { isiOS } from '../../../../../utils/helpers';
import CommonButton from '../../../../Common/Button';
import { formatOptions, selectedOptionsTextRepresentation } from './helper';
import ParticipantOption from './ParticipantOption';

const useStyles = makeStyles((theme) => ({
  inputField: {
    fontSize: isiOS() ? '16px' : '14px',
    width: '100%',
  },
  paper: {
    borderRadius: '7px',
    marginTop: '8px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      borderRadius: '0px',
      backgroundColor: theme.palette.background.dark,
      position: 'absolute',
      left: '0!important',
      top: 'auto!important',
      right: 0,
      bottom: 0,
      height: '400px',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: '0',
      maxWidth: '100vw',
      color: '#fff',
    },
  },
  selectParticipantsBlock: {
    width: '360px',
    minHeight: '150px',
    maxHeight: '400px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    color: theme.palette.text.primary,
    '& ::-webkit-scrollbar': {
      width: '4px',
      paddingRight: '2px',
    },
    '& ::-webkit-scrollbar-thumb': {
      backgroundColor: theme.palette.primary.main,
      width: '4px',
      borderRadius: '2px',
    },
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      width: '100%',
      color: '#fff',
    },
  },
  optionsBlock: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      marginTop: '64px',
    },
  },
  selectParticipantsBlockTitle: {
    fontSize: '15px',
    fontWeight: '700',
    textAlign: 'center',
    margin: '20px',
  },
  inputCount: {
    paddingTop: '3px',
    fontSize: '15px',
    fontWeight: '600',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      color: 'grey',
      backgroundColor: theme.palette.background.dark,
    },
  },
  cancelBtn: {
    color: theme.palette.text.secondary,
    display: 'none',
    position: 'absolute',
    fontSize: '12px',
    top: '16px',
    left: '7px',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      display: 'block',
    },
  },
  selectBtnBlock: {
    marginTop: 'auto',
    padding: '0 20px',
    textAlign: 'center',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      width: '256px',
      margin: 'auto auto 0',
    },
  },
  inputRoot: {
    '& label.Mui-focused': {
      color: theme.palette.text.grey,
    },
    '& label': {
      color: theme.palette.text.grey,
    },
  },
}));

export function PeoplePicker({
  availableOptions,
  selectedOptions,
  updateSelectedOptions,
  peopleRef,
  anchorEl,
  nextAnchor,
  setAnchorEl,
  isMobileView,
  onClose,
  bookingRequirements,
}) {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const open = Boolean(anchorEl === peopleRef.current);
  const id = open ? 'people-popover' : undefined;

  const handleClick = () => {
    setAnchorEl(peopleRef.current);
  };

  const handleClose = () => {
    if (onClose) onClose();

    setAnchorEl(null);
  };

  const handleCancel = () => {
    updateSelectedOptions({});
    handleClose();
  };

  const handleSelect = () => {
    if (isMobileView) {
      setAnchorEl(nextAnchor);
    } else {
      handleClose();
    }
  };

  const incrementTotal = (i) => {
    if (i) {
      setTotal(total + i);
    } else {
      setTotal(total + 1);
    }
  };

  const decrementTotal = () => {
    if (total > 0) {
      setTotal(total - 1);
    }
  };

  const canIncrementTotal = () => {
    return total < bookingRequirements.maxTravelersPerBooking;
  };

  const updateOption = (id, newValue) =>
    updateSelectedOptions(pickBy((x) => x > 0, { ...selectedOptions, [id]: newValue }));

  return (
    <>
      <TextField
        className={classes.inputField}
        label={localization.tours.availabilitySearch.participants}
        value={selectedOptionsTextRepresentation(selectedOptions)}
        InputProps={{
          readOnly: true,
        }}
        defaultValue="Hello World"
        variant="outlined"
        color="secondary"
        aria-describedby={id}
        onClick={handleClick}
        ref={peopleRef}
        classes={{
          root: classes.inputRoot,
        }}
      />
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paper,
        }}
      >
        <div className={classes.selectParticipantsBlock}>
          <Button className={classes.cancelBtn} onClick={handleCancel}>
            {localization.tours.availabilitySearch.cancel}
          </Button>
          <div className={classes.optionsBlock}>
            {formatOptions(availableOptions).map((option) => (
              <ParticipantOption
                key={option.id}
                option={option}
                optionCount={selectedOptions[option.id] ?? 0}
                updateOption={updateOption}
                incrementTotal={incrementTotal}
                decrementTotal={decrementTotal}
                canIncrementTotal={canIncrementTotal}
              />
            ))}
          </div>
          <div className={classes.selectBtnBlock}>
            <CommonButton
              style={{
                width: '100%',
                margin: '25px 0',
                fontSize: FontSizes.cta,
                borderRadius: '8.5px',
                height: '37px',
              }}
              onClick={handleSelect}
            >
              {localization.tours.availabilitySearch.select}
            </CommonButton>{' '}
          </div>
        </div>
      </Popover>
    </>
  );
}

export default PeoplePicker;
