import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { StyleBreakpoints } from '../../utils/constants';
import { optimizeTourImages } from '../../utils/helpers';
import Button from '../Common/Button';

const useStyles = makeStyles((theme) => ({
  bannerImageCard: {
    position: 'relative',
    width: '100%',
  },
  bannerText: {
    textAlign: 'left',
    fontFamily: 'MontserratBold',
    maxHeight: '340px',
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    fontWeight: 'bold',
    color: 'white',
    letterSpacing: '-0.12px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    [theme.breakpoints.down('750')]: {
      fontSize: '24px',
      textShadow: '0 2px 4px rgba(0, 0, 0, 0.3)',
    },
    [theme.breakpoints.down('926')]: {
      top: '7.5rem',
      width: '90vw',
    },

    width: '60vw',
    margin: '60px auto 0 auto',
  },
  textStyleWeb: {
    fontSize: '35px',
    lineHeight: '1.14',
    marginBottom: '18px',
    fontWeight: 'bold',
    textShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
    maxWidth: '65vw',
    [theme.breakpoints.down('1200')]: {
      fontSize: '35px',
      maxWidth: '90vw',
    },
    [theme.breakpoints.down('926')]: {
      fontSize: '30px',
      lineHeight: '1.09',
      maxWidth: '90vw',
    },
    [theme.breakpoints.down('650')]: {
      fontSize: '22px',
      marginBottom: '10px',
      lineHeight: 1.46,
    },
    [theme.breakpoints.down('415')]: {
      fontSize: '20px',
      marginBottom: '10px',
      lineHeight: 1.46,
    },
    [theme.breakpoints.down('376')]: {
      fontSize: '18px',
      marginBottom: '10px',
      lineHeight: 1.46,
    },
    [theme.breakpoints.down('338')]: {
      fontSize: '16px',
      marginBottom: '10px',
      lineHeight: 1.46,
    },
  },
  buttonContainer: {
    maxWidth: '160px',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      maxWidth: '100%',
    },
  },
  subTextStyle: {
    fontSize: '18px',
  },
  imageStyle: {
    minHeight: '400px',
    maxHeight: '400px',
    width: '100%',
    objectFit: 'cover',
    objectPosition: '50% 0%',
    [theme.breakpoints.down('650')]: {
      minHeight: '315px',
      maxHeight: '315px',
    },
  },
  linkStyle: { display: 'block' },
}));
function BannerImageCard({ windowWidth, item, link, children }) {
  const classes = useStyles();
  item.src = item.web;
  if (windowWidth < parseInt(StyleBreakpoints.md)) {
    item.src = item.tab;
  }
  if (windowWidth <= parseInt(StyleBreakpoints.xs)) {
    item.src = item.mob;
  }
  return (
    <div className={classes.bannerImageCard}>
      <div className={classes.bannerText}>
        <div className={classes.textStyleWeb}>
          <span style={{ display: 'inline-block', marginRight: '10px' }}>{item.text.mainText}</span>
          <span style={{ display: 'inline-block' }}>{item.text.subText}</span>
        </div>
        <div className={classes.buttonContainer}>{link ? <Button>Book Now</Button> : null}</div>
        {children}
      </div>
      <img className={classes.imageStyle} src={optimizeTourImages(item.src)} alt="Background"></img>
    </div>
  );
}

export default BannerImageCard;
