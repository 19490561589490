import React from 'react';
import localization from '../../../localization';
import { SvgColorHelper } from '../../Common';
import theme from 'theme';
import { ReactComponent as CancelationSvg } from '../../../assets/img/FreeCancellation.svg';

const CancelationIcon = SvgColorHelper(
  'AeTourSelected_MobileV2',
  CancelationSvg,
  theme.colors.primary
);

const CancellationFeature = ({ CancelationPolicySupplierText, classes, limit }) => {
  const sanitizedCancellationPolicy = CancelationPolicySupplierText.split('<br>');
  const isSerbianWithoutTranslation =
    localization.getLanguage() === 'sr' &&
    CancelationPolicySupplierText === 'Cancel up to 24 hours in advance to receive a full refund';

  if (isSerbianWithoutTranslation) {
    return (
      <div className={classes.cancellationText}>
        <div>{'Otkažite do 24 sata unapred da biste dobili puni povraćaj novca'}</div>
      </div>
    );
  }

  if (limit) {
    return (
      <div className={classes.cancellationText}>
        <div>{sanitizedCancellationPolicy[0]}</div>
        {sanitizedCancellationPolicy[1] && (
          <div className={classes.policyText}>{sanitizedCancellationPolicy[1]}</div>
        )}
      </div>
    );
  }

  return (
    <div className={classes.featured}>
      <CancelationIcon className={classes.image} />

      <span>{localization.tours.about.cancelation}</span>
      <div className={classes.subtext}>{sanitizedCancellationPolicy[0]}</div>
      {sanitizedCancellationPolicy[1] && (
        <div className={classes.subtext}>{sanitizedCancellationPolicy[1]}</div>
      )}
    </div>
  );
};

export default CancellationFeature;
