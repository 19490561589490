import { all } from 'redux-saga/effects';
import { watchCart } from './checkout/saga';
import { watchContactSaga } from './contact/saga';
import { watchFallback } from './fallbackTours/sagas';
import { watchFilter } from './filters/saga';
import { watchLocation } from './locations/saga';
import { watchSearch } from './search/saga';
import { watchTour } from './tour/saga';
import { watchTourOptions } from './tourOptions/saga';
import { watchTours } from './tours/saga';
import { watchCancelBooking } from './cancel_booking/saga';
import { watchStripeActions } from './stripe/saga';

export default function* () {
  yield all([
    watchTours(),
    watchTour(),
    watchTourOptions(),
    watchCart(),
    watchFilter(),
    watchLocation(),
    watchContactSaga(),
    watchFallback(),
    watchSearch(),
    watchCancelBooking(),
    watchStripeActions(),
  ]);
}
