import axios from 'axios';
import { apiRoot } from 'env-config';

export const bookOption = booking => axios.post(`${apiRoot}/api/v1/booking/`, booking).then(res => res.data.data);

export const bookTsTour = bookingData => axios.post(`${apiRoot}/api/v1/tour/ts/book`, bookingData).then(res => res.data.data);

export const fetchTsBookingDetails = ({id, language = 'en'}) => axios.get(`${apiRoot}/api/v1/tour/ts/booking/${id}`, {
    params: {language}
}).then(res => res.data.data);

export const fetchGygBooking = ({id, language = 'en', currency}) => axios.get(`${apiRoot}/api/v1/cart/${id}`, {
    params: {language, currency}
}).then(res => res.data.data);

export const fetchGygBookingDetails = ({id, language = 'en'}) => axios.get(`${apiRoot}/api/v1/booking/${id}`, {
    params: {language}
}).then(res => res.data.data);

export const fetchViatorBookingDetails = ({bookingRef, partnerBookingRef, language = 'en'}) => axios.get(`${apiRoot}/api/v1/tour/viator/confirmedbooking/${bookingRef}`, {
    params: {language, partnerBookingRef}
}).then(res => res.data.data);