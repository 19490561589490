import axios from 'axios';
import { apiRoot } from 'env-config';
import { getCurrency } from '../utils/helpers';
export const PER_PAGE = 21;
export const PRIMARY_QUERY = 'Dublin';
let currency = localStorage.getItem('curr');


export const fetchToursWithFilters = ({
  categories,
  services,
  fromDate,
  toDate,
  page,
  language,
  currency = getCurrency(),
  primaryQuery = PRIMARY_QUERY,
  perPage = PER_PAGE
}) => {
  let url = window.location.pathname.split('/');

  if (primaryQuery.length == 0) {
    let url = window.location.pathname.split('/');
    if (url.length == 3) {
      primaryQuery = url[2];
    }
  }

  return axios
    .get(`${apiRoot}/api/v1/tour/search`, {
      params: {
        categories,
        services,
        language,
        fromDate: fromDate && fromDate.toISOString(),
        toDate: toDate && toDate.toISOString(),
        page,
        perPage,
        currency,
        q: primaryQuery
      }
    })
    .then(res => {
      if (res.data.data.count[0]) {
        localStorage.setItem('count', res.data.data.count[0].my_count);
      }

      return res.data.data.data;
    });
};

    
export const fetchSearchTours = ({
  categories,
  services,
  fromDate,
  toDate,
  page,
  language,
  primaryQuery = PRIMARY_QUERY,
  perPage = PER_PAGE
}) =>
  axios
    .get(`${apiRoot}/api/v1/tour/gyg`, {
      params: {
        categories,
        services,
        language,
        fromDate: fromDate && fromDate.toISOString(),
        toDate: toDate && toDate.toISOString(),
        page,
        perPage,
        currency,
        q: primaryQuery
      }
    })
    .then(res => res.data.data);

export const fetchAvailableFilters = primaryQuery =>
  axios
    .get(`${apiRoot}/api/v1/tour/filters`, {
      params: {
        q: primaryQuery
      }
    })
    .then(res => res.data.data);

export const fetchFeaturedTours = ({ page, perPage, language, currency = getCurrency() }) =>
  axios
    .get(`${apiRoot}/api/v1/tour/featured`, {
      params: {
        page,
        perPage,
        language,
        currency
      }
    })
    .then(res => res.data.data);

export const fetchHighlightedTours = ({ page, perPage, language, currency = getCurrency() }) => axios.get(`${apiRoot}/api/v1/tour/highlighted`, {
  params: {
      page,
      perPage,
      language,
      currency,
      name : 'test'
  }
}).then(res => res.data.data);
  
export const fetchToursByLocation = ({
  page,
  perPage,
  locationId,
  language,
  currency = getCurrency(),
  filters = null
}) =>
  axios
    .post(`${apiRoot}/api/v1/tour/location/${locationId}`, {
      page,
      perPage,
      language,
      currency: currency.substring(0, 3),
      filters
    })
    .then(res => res.data.data);

export const fetchFallbackTours = ({
  locationIds,
  language,
  currency = getCurrency(),
  failedTourId
}) =>
  axios
    .get(`${apiRoot}/api/v1/tour/fallback`, {
      params: {
        locationIds,
        language,
        currency,
        failedTourId
      }
    })
    .then(res => res.data.data);
