import { call, put, takeLatest } from 'redux-saga/effects';
import { sendCancellationEmail } from '../../api/emails';
import { getCancelQuote, getCancelReasons, cancelBooking } from '../../api/viator';
import {
  CANCEL_BOOKING,
  CANCEL_BOOKING_INIT,
  CANCEL_BOOKING_SUCCESS,
  FETCH_CANCEL_QUOTE,
  FETCH_CANCEL_QUOTE_SUCCESS,
  FETCH_CANCEL_REASONS,
  FETCH_CANCEL_REASONS_ERROR,
  FETCH_CANCEL_REASONS_SUCCESS
} from './actionTypes';

function* fetchCancelReasons() {
  try {
    const cancelReasons = yield call(getCancelReasons);

    yield put({ type: FETCH_CANCEL_REASONS_SUCCESS, payload: cancelReasons.reasons });
  } catch (err) {
    console.error(err);
    yield put({ type: FETCH_CANCEL_REASONS_ERROR, payload: { error: err.message } });
  }
}

function* fetchCancelQuote({ payload: { bookingReference } }) {
  try {
    const cancelQuote = yield call(getCancelQuote, bookingReference);

    yield put({ type: FETCH_CANCEL_QUOTE_SUCCESS, payload: cancelQuote });
  } catch (err) {
    console.error(err);
  }
}

function* cancelBookingWorker({
  payload: {
    bookingReference,
    reasonCode,
    to,
    cancellationDetails,
    cancellationTranslations = undefined
  }
}) {
  try {
    yield put({ type: CANCEL_BOOKING_INIT, payload: { isCancelLoading: true } });
    const cancelResponse = yield call(cancelBooking, bookingReference, reasonCode);

    yield call(sendCancellationEmail, {
      to,
      cancellationDetails,
      cancellationTranslations
    });
    yield put({ type: CANCEL_BOOKING_SUCCESS, payload: cancelResponse });
    yield put({ type: FETCH_CANCEL_QUOTE, payload: { bookingReference } });
  } catch (err) {
    console.error(err);
  }
}

export function* watchCancelBooking() {
  yield takeLatest(FETCH_CANCEL_REASONS, fetchCancelReasons);
  yield takeLatest(FETCH_CANCEL_QUOTE, fetchCancelQuote);
  yield takeLatest(CANCEL_BOOKING, cancelBookingWorker);
}
