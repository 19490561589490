import defaultState from '../defaultState';
import { FETCH_SEARCH_PROMPTS_INIT, FETCH_SEARCH_PROMPTS_SUCCESS } from './actionTypes';

export default function searchReducer(state = defaultState.search, { type, payload }) {
  switch (type) {
    case FETCH_SEARCH_PROMPTS_INIT:
      return {
        ...state,
        promptsList: payload.resetList ? [] : state.promptsList,
        isLoaded: false
      };
    case FETCH_SEARCH_PROMPTS_SUCCESS:
      return {
        ...state,
        promptsList: state.promptsList.concat(payload.data),
        isLoaded: true
      };
    default:
      return state;
  }
}
