import { StyleBreakpoints } from '@constants';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import theme from 'theme';
import { ReactComponent as AudioGuideIcon } from '../../../assets/img/icons/AudioGuide.svg';
import { ReactComponent as HumanGuideIcon } from '../../../assets/img/icons/Language.svg';
import { ReactComponent as PrintedGuideIcon } from '../../../assets/img/icons/PrintedGuide.svg';
import localization from '../../../localization';
import { capitalize } from '../../../utils/helpers';
import { SvgColorHelper } from '../../Common';

const GuideIcon = {
  AUDIO: SvgColorHelper('AeTourSelected_MobileV2', AudioGuideIcon, theme.colors.primary),
  WRITTEN: SvgColorHelper('AeTourSelected_MobileV2', PrintedGuideIcon, theme.colors.primary),
  GUIDE: SvgColorHelper('AeTourSelected_MobileV2', HumanGuideIcon, theme.colors.primary),
};

export function normalizeLangCode(code) {
  const languageNameService = new Intl.DisplayNames([localization.getLanguage() || 'en'], {
    type: 'language',
  });

  const twoLettersCode = code.slice(0, 2);
  try {
    const langName = languageNameService.of(code);
    return langName;
  } catch (e) {
    try {
      const langName = languageNameService.of(twoLettersCode);
      return langName;
    } catch (e) {
      return code;
    }
  }
}

const useStyles = makeStyles((theme) => ({
  image: {
    width: '28px',
    height: '28px',
    marginRight: '14px',
    color: 'black',
  },
  guideWrapper: {
    display: 'flex',
    gridTemplateColumns: '42px auto',
    marginTop: 14,
    marginBottom: 14,

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      marginTop: 0,
    },
  },
  subText: {
    color: '#63666A',
    fontWeight: 300,
    fontSize: '13px',
  },
}));

const Guide = ({ guides }) => {
  const classes = useStyles();

  const getAllGuideLanguages = (languages) => {
    return languages.reduce((prev, curr, index, arr) => {
      const maybeComma = index === arr.length - 1 ? '' : ',';

      return `${prev} ${capitalize(normalizeLangCode(curr))}${maybeComma}`;
    }, '');
  };

  function renderIcon(SvgIcon) {
    return <SvgIcon className={classes.image} alt="Guide Icon" />;
  }

  if (!guides?.length) return null;

  return guides.map((guide, i) => {
    return (
      <div key={i} className={classes.guideWrapper}>
        {renderIcon(GuideIcon[guide.type])}
        <div>
          <div>{localization.tours.about[guide.type.toLowerCase()]}</div>
          <div className={classes.subText}>{getAllGuideLanguages(guide.languages)}</div>
        </div>
      </div>
    );
  });
};

export default Guide;
