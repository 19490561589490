import { flatten, flow, map, uniq } from 'lodash/fp';

const extractLanguageFromOption = type => option => option.cond_language[type];

const liveLanguage = extractLanguageFromOption('language_live');
const audioLanguage = extractLanguageFromOption('language_audio');
const bookletLanguage = extractLanguageFromOption('language_booklet');

const extractLanguagesFromOptions = extractor =>
  flow(
    map(extractor),
    flatten,
    uniq
  );

const extractLanguagesFromTour = extractor => tour =>
  extractLanguagesFromOptions(extractor)(tour.tour_options);
export const tourLiveLanguages = extractLanguagesFromTour(liveLanguage);
export const tourAudioLanguages = extractLanguagesFromTour(audioLanguage);
export const tourBookletLanguages = extractLanguagesFromTour(bookletLanguage);

export const mapToursToPrompts = tours =>
  tours.map(tour => ({ name: tour.Title, tourId: tour.TourID }));
