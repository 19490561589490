import styled from 'styled-components';
import { ButtonMedium } from '../../../../Common';

export const Age = styled.div`
  font-size: 13px;
  color: #ababab;
  margin-top: 10px;
  margin-bottom: 10px;
`;
export const Data = styled.div`
  font-size: 15px;
  line-height: 1;
  font-weight: 700;
  margin-top: 14px;
`;
export const Done = styled(ButtonMedium)`
  min-width: 30%;
  margin: 10px auto;
`;
export const Icon = styled.span`
  font-size: 1.3rem;
  display: inline-block;
  padding: 0 16px;
  cursor: pointer;
  color: ${props => (props.disabled ? '#d3d3d4;' : props.theme.colors.secondary)};
`;
export const Input = styled.input`
  text-align: center;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  width: 40px;
`;
export const InputPrimary = styled.input`
  display: block;
  width: 100%;
  height: 56px;
  padding: 0.375rem 0.75rem;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  ${props =>
    props.active &&
    'border-bottom: 0px;' + 'border-bottom-left-radius: 0px;' + 'border-bottom-right-radius: 0px;'}
`;
export const Option = styled.div`
  margin: 0 12px 20px 20px;
  list-style: none;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
`;

export const Options = styled.div`
  margin-right: 10px;
  width: 360px;
  z-index: 999;
  background: #fff;
  display: flex;
  flex-direction: column;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
`;
export const MobileOptions = styled.div`
  z-index: 999;
  display: flex;
  flex-direction: column;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);

  background-color: #010e1d;
  position: fixed;
  left: 0 !important;
  top: calc(100vh - (200px + ${props => props.count * 50}px));
  right: 0;
  bottom: 0;
  height: calc(200px + ${props => props.count * 50}px);
  max-height: 500px;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  color: #fff;
`;
export const Select = styled.div`
  user-select: none;
  font-size: 0.9rem;
  min-width: 124px;
  margin: 20px 0 0 0;
  max-height: 30px;
  display: flex;
  justify-content: flex-end;
`;
export const Separator = styled.div`
  display: block;
  height: 2px;
  background: ${props => props.theme.colors.secondary};
  flex-basis: 100%;
`;
export const Wrapper = styled.div`
position: relative;
margin-right:15px;
display: flex;
width: 100%;
flex: 1 0 20%;
padding: 10px 0;
> div{
    width: 100%;
}
// ${props => props.active && 'min-width:50%'}
`;
