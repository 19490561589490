import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { FontSizes } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  itineraryItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',

    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  itineraryPoint: {
    marginLeft: '-29px',
    position: 'absolute',
    top: '8px',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  bodyText: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  title: {
    fontSize: '18px',
    marginBottom: '10px',
  },
  bottomDetails: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: FontSizes.cta,
    color: '#63666a',
    marginBottom: '10px',
  },
  titleStop: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
}));

export default function ActivityItinerary({ itinerary, itineraryLocations, formatDuration }) {
  const classes = useStyles();

  return (
    <>
      <div className={classes.itineraryItem}>
        <div className={classes.itineraryPoint} />
        <div className={classes.titleStop}>
          <span className={classes.title}>
            {
              itineraryLocations.find(
                (location) => location.reference === itinerary.activityInfo.location.ref
              )?.name
            }
          </span>
        </div>
        <div className={classes.bodyText}>{itinerary.activityInfo.description}</div>

        <div className={classes.bottomDetails}>
          {itinerary.duration.fixedDurationInMinutes &&
            formatDuration(itinerary.duration.fixedDurationInMinutes)}
        </div>
      </div>
    </>
  );
}
