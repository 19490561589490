import { createTheme } from '@mui/material';
import theme from 'theme';

const materialTheme = createTheme({
  palette: {
    text: {
      primary: '#202020',
      secondary: theme.fonts.colors.secondary,
      grey: '#767676',
    },
    primary: {
      main: theme.colors.primary,
      dark: theme.colors.primary,
      contrastText: '#fff',
    },
    searchBanner: theme.searchBanner,
    secondary: {
      main: theme.colors.secondary,
    },
    warning: {
      main: '#BCBCBC',
    },

    background: {
      default: '#fff',
      dark: '#010e1d',
    },
  },
  status: {
    danger: 'orange',
  },
  typography: {
    fontFamily: theme.fonts.primary,
    fontFamilyBold: theme.fonts.bold,
    fontSize: '16px',
    button: {
      textTransform: 'capitalize',
    },
  },
  overrides: {
    'MuiContainer-root': {
      margin: 'unset',
      width: 'unset',
    },
  },
  transitions: {
    easing: {
      easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    },
  },
});

export default materialTheme;
