import {
  CardCvcElement, CardExpiryElement, CardNumberElement, Elements, useElements, useStripe
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useLayoutEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { checkoutTsBookingInit } from '../../../api/checkout';
import cvcLogo from '../../../assets/img/payment/CVC.svg';
import discoverLogo from '../../../assets/img/payment/discover.svg';
import jcbLogo from '../../../assets/img/payment/jcb.svg';
import maestroLogo from '../../../assets/img/payment/maestro.svg';
import mastercardLogo from '../../../assets/img/payment/mastercard.svg';
import visaLogo from '../../../assets/img/payment/visa.svg';
import '../customCardStyles.css';
import { Analytics } from '../../../utils/analytics';


const logo = {
  mastercard: mastercardLogo,
  visa: visaLogo,
  maestro: maestroLogo,
  jcb: jcbLogo,
  discover: discoverLogo
};

const stripe = loadStripe(process.env.REACT_APP_STRIPE_KEY);

export function PaymentModule(args) {
  return (
    <Elements stripe={stripe}>
      <PaymentModuleInner {...args} />
    </Elements>
  );
}

function CustomCardElement(onChange, options, brandLogo) {
  return (
    <div className="credit-card">
      <div className="credit-card-number">
        <span className="credit-card-number-brands">
          {brandLogo.length ? <img className="brand-logo" src={brandLogo} alt="BrandLogo" /> : null}
        </span>
        <CardNumberElement options={options} onChange={onChange} />
      </div>
      <div className="credit-card-date">
        <CardExpiryElement options={options} onChange={onChange} />
      </div>
      <div className="credit-card-cvc">
        <img className="cvc-icon" src={cvcLogo} alt="Cvc icon" />
        <CardCvcElement options={options} onChange={onChange} />
      </div>
    </div>
  );
}

function PaymentModuleInner({ FormContainer, bookingId, showModal }) {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();

  const [brandLogo, setBrandLogo] = useState('');

  const [windowWidth, setWindowWidth] = useState(0);
  const isMobileView = windowWidth < 450;

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWindowWidth]);

  const options = {
    style: {
      base: {
        color: '#5a5a5a',
        fontWeight: 500,
        border: 'none',
        fontSize: isMobileView ? '14px' : '16px'
      }
    }
  };

  const handleChange = event => {
    setIsPaymentDetailsFilled(event.complete);

    if (event.brand) {
      setBrandLogo(logo[event.brand] || '');
    }
  };

  const [isPaymentDetailsFilled, setIsPaymentDetailsFilled] = React.useState(false);

  const [checkoutInProgress, setCheckourInProgress] = React.useState(false);

  const onSubmit = async paymentDetails => {
    try {
      if (!isPaymentDetailsFilled) {
        showModal('Please, fill in payment details');
        return;
      }

      if (!stripe || !elements) {
        return;
      }

      setCheckourInProgress(true);

      Analytics.tracking('TS payment start');

      const { clientSecret } = await checkoutTsBookingInit({ ...paymentDetails, bookingId });
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: `${paymentDetails.firstName} ${paymentDetails.lastName}`,
            email: paymentDetails.email.replace(/\s/g, '')
          }
        }
      });
      if (result.error) {
        showModal(
          `We encountered an error processing payment, please try again. Reason: ${result.error.code}, ${result.error.message}`
        );
      } else {
        Analytics.tracking('TS payment complete');
        history.push(`/order-confirmation/${bookingId}`);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setCheckourInProgress(false);
    }
  };

  return (
    <FormContainer
      isCheckoutInProgress={checkoutInProgress}
      paymentForm={CustomCardElement(handleChange, options, brandLogo)}
      onSubmit={onSubmit}
    />
  );
}
