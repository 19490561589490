export const StyleBreakpoints = {
  xs: '480',
  sm: '768',
  md: '992',
  lg: '1200'
};

export const FontSizes = {
  h1: '40px',
  h2: '22px',
  h3: '16px',
  p: '16px',
  cta: '14px'
};
