
import React from 'react';
import { connect } from 'react-redux';
import { sendEnquiry } from '../../store/contact/actions';
import { Button, Form, FormGroup, Label, Input } from 'reactstrap';
import localization from "../../localization";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import theme from 'theme';
import { withRouter } from 'react-router-dom';
var ta = {
  "fontFamily": "Montserrat",
  "fontSize": "43.2px",
  "fontWeight": "900",
  "fontStyle": "normal",
  "fontStretch": "normal",
  "lineHeight": "normal",
  "letterSpacing": "0.82px",
  "color": "#e4e4e4",
  textAlign: 'left',
  paddingLeft: '45px',
  paddingBottom: '27px',
  width: '100%'
}
class ContactForm extends React.Component {

  constructor(props) {
    super(props);
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.state = {
      email: '',
      firstName: '',
      lastName: '',
      website: '',
      yourQuery: '',
      client: ''
    };

  }


  onFormSubmit(e) {
    e.preventDefault();
    this.props.sendEnquiryNotification(
      this.props.history,
      {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        website: this.state.website,
        query: this.state.yourQuery,
        client: theme.clientName
      }
    );
  }

  render() {
    return (
      <div style={{
        "borderRadius": "9.6px",
        "boxShadow": "0 1px 2px 0 rgba(0, 0, 0, 0.5)",
        "backgroundColor": "#ffffff",
        padding: '20px',
        maxWidth: '400px',
        margin: 'auto'
      }}>
        <div style={heading}>
          {localization.contactForm.title}
        </div>

        <div style={subHeading}>
          {localization.contactForm.intro}
        </div>


        <AvForm onValidSubmit={this.onFormSubmit}>
          <FormGroup style={{ marginBottom: 'unset' }}>
            <Label for="firstName" style={labelStyle}>{localization.contactForm.firstName}</Label>
            <AvField onChange={e => this.setState({ firstName: e.target.value })} type="firstName" name="firstName" id="firstName" placeholder="Jane" style={inputStyle}/>

          </FormGroup>
          <FormGroup style={{ marginBottom: 'unset' }}>
            <Label for="lastName" style={labelStyle}>{localization.contactForm.lastName}</Label>
            <AvField onChange={e => this.setState({ lastName: e.target.value })} type="lastName" name="lastName" id="lastName" placeholder="Smith" style={inputStyle} />
          
          </FormGroup>
          <FormGroup style={{ marginBottom: 'unset' }}>
            <Label for="exampleEmail" style={labelStyle}>{localization.contactForm.email}</Label>
            <AvField onChange={e => this.setState({ email: e.target.value })} type="email" name="email" id="exampleEmail" placeholder="jane.smith@example.com" style={inputStyle} required/>

          </FormGroup>
          <FormGroup style={{ marginBottom: 'unset' }}>
            <Label for="yourQuery" style={labelStyle}>{localization.contactForm.query}</Label>
            <Input onChange={e => this.setState({ yourQuery: e.target.value })} type="textarea" name="yourQuery" id="yourQuery" style={inputStyle} />
          </FormGroup>
          <div style={{ textAlign: 'center' }}>
            <Button rounded size="lg" style={{
              "borderRadius": "32.4px",
              "boxShadow": "0 2px 3px 0 rgba(0, 0, 0, 0.23)",
              "backgroundColor": "#004e5e",
              "fontSize": "16px",
              "letterSpacing": "0.28px",
              fontWeight: '500',
              padding: '10px',
              width: '200px',
              margin: '40px'
            }}>{localization.contactForm.enquire}</Button>
          </div>
        </AvForm>
      </div>

    )
  }
}

export default withRouter(
  connect(
    () => ({}),
    dispatch => ({
      sendEnquiryNotification: (history, props) => dispatch(sendEnquiry(history, props))
    })
  )(ContactForm)
);


var labelStyle = {
  border: 'unset',
  fontSize: '10.4px',
  fontWeight: 'bold'
}

var inputStyle = {
  "borderRadius": "3px",
  "backgroundColor": "#e1e1e1",
  border: 'unset',
  fontSize: '10.4px'
}

var heading = {
  "fontSize": "2em",
  "fontWeight": "bold",
  "fontStyle": "normal",
  "fontStretch": "normal",
  "lineHeight": "normal",
  "letterSpacing": "0.52px",
  "color": "#000000",
  textAlign: 'center',
  marginTop: '40px',
  marginBottom: '20px'
}

var subHeading = {
  "fontSize": "1em",
  "fontWeight": "normal",
  "fontStyle": "normal",
  "fontStretch": "normal",
  "lineHeight": "normal",
  "letterSpacing": "0.29px",
  "textAlign": "center",
  "color": "#434343",
  marginBottom: '40px'
}