export const UPDATE_MANIFEST = 'UPDATE_MANIFEST';
export const UPDATE_DATE = 'UPDATE_DATE';
export const UPDATE_LANGUAGE = 'UPDATE_LANGUAGE';

export const FETCH_TOUR_OPTIONS_INIT = 'FETCH_TOUR_OPTIONS_INIT';
export const FETCH_TOUR_OPTIONS_SUCCESS = 'FETCH_TOUR_OPTIONS_SUCCESS';
export const FETCH_TOUR_OPTIONS_ERROR = 'FETCH_TOUR_OPTIONS_ERROR';

export const FETCH_AVAILABILITIES = 'FETCH_AVAILABILITIES';
export const FETCH_AVAILABILITIES_INIT = 'FETCH_AVAILABILITIES_INIT';
export const FETCH_AVAILABILITIES_SUCCESS = 'FETCH_AVAILABILITIES_SUCCESS';

export const UPDATE_SELECTED_TIMESLOT = 'UPDATE_SELECTED_TIMESLOT';

export const BOOK_OPTION = 'BOOK_OPTION';
export const BOOK_OPTION_INIT = 'BOOK_OPTION_INIT';
export const BOOK_OPTION_SUCCESS = 'BOOK_OPTION_SUCCESS';
export const BOOK_OPTION_ERROR = 'BOOK_OPTION_ERROR';