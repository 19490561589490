import { StyleBreakpoints } from '@constants';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form, Formik } from 'formik';
import { pick } from 'lodash/fp';
import React, { useState } from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import PoweredBy from '../../components/PoweredBy';
import localization from '../../localization';
import { FontSizes } from '../../utils/constants';
import { getPriceWithCurrencySymbol } from '../../utils/helpers';
import { Bold, ButtonLargeWithHover } from '../Common';
import Sidebar from '../Common/BookingSummarySidebar/Sidebar';
import { LoaderVerySmall } from '../Loader';
import BookingParameters from './BookingParameters';
import { CountryNames } from './Countries';
import CountrySelect from './CountrySelect';
import CustomQuestions from './CustomQuestions';
import { StyledField } from './Input';
import './style.scss';

const useStyles = makeStyles({
  inputRoot: {
    '& .MuiFormLabel-root': {
      color: '#767676',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
  },
  textField: {
    fontSize: FontSizes.cta,
  },
  selectLabel: {
    color: '#767676!important',
    fontSize: FontSizes.cta,
  },
  checkboxRoot: {
    width: '30px',
  },
});

const PurchaseButton = styled(ButtonLargeWithHover)`
  min-width: 240px;
  max-width: unset;
  width: auto;
  height: 56px;
  margin: 18px 0 30px;
  font-size: 18px;
  border-radius: 6.7px;
  box-shadow: 0 4px 7px 0 rgba(0, 0, 0, 0.5);
  background-color: #0f1928;
  height: 44px;
  @media screen and (max-width: ${StyleBreakpoints.md}) {
    width: 100%;
  }
`;
const Section = styled.div`
  margin-bottom: 30px;
`;
const Title = styled(Bold)`
  font-size: ${FontSizes.h2};
  margin: 20px 0;
  display: block;
`;
const FormBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const BillingCheckbox = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
`;

const initialState = {
  cardholder: {
    firstName: '',
    lastName: '',
    email: '',
    emailConfirmation: '',
    phoneNumber: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
  },
  traveler: {
    firstName: '',
    lastName: '',
    email: '',
    emailConfirmation: '',
    phoneNumber: '',
  },
  promo: '',
};

function initialFormState(booking) {
  return {
    ...initialState,
    bookingParameters: booking.bookingParameters?.map((x) => ({ ...x, value_1: '' })),
    customQuestionsAnswers:
      booking.customQuestions?.map((q) => ({ questionId: q.id, answer: '' })) ?? [],
  };
}

function validationSchema(travelerDetailsSame) {
  const cardholder = yup.object({
    firstName: yup.string().required().min(3).max(40).label(localization.payment.form.firstName),
    lastName: yup.string().required().min(3).max(60).label(localization.payment.form.lastName),
    email: yup.string().email().required().label(localization.payment.form.email),
    emailConfirmation: yup
      .string()
      .email()
      .required()
      .test('email-match', 'Emails must match', function (value) {
        return this.parent.email === value;
      })
      .label(localization.payment.form.confirmEmail),
    phoneNumber: yup.string().required().min(5).label(localization.payment.form.mobile),
    address1: yup.string().required().label(localization.payment.form.address1),
    city: yup.string().required().min(2).label(localization.payment.form.city),
    country: yup
      .string()
      .required()
      .oneOf(CountryNames.map((x) => x.code))
      .label(localization.payment.form.country),
  });

  const traveler = yup.object({
    firstName: yup.string().required().min(3).max(40).label(localization.payment.form.firstName),
    lastName: yup.string().required().min(3).max(60).label(localization.payment.form.lastName),
    email: yup.string().email().required().label(localization.payment.form.email),
    emailConfirmation: yup
      .string()
      .email()
      .required()
      .test('email-match', 'Emails must match', function (value) {
        return this.parent.email === value;
      })
      .label(localization.payment.form.confirmEmail),
    phoneNumber: yup.string().min(5).label(localization.payment.form.mobile),
  });

  return yup.object(travelerDetailsSame ? { cardholder } : { cardholder, traveler });
}

function PurchaseTotal({ total, isCheckoutInProgress }) {
  return (
    <div className="total-details">
      <div>
        <PurchaseButton type="submit" disabled={isCheckoutInProgress}>
          {isCheckoutInProgress ? (
            <LoaderVerySmall />
          ) : (
            <>
              <span>{localization.payment.completePurchase} </span>
              {getPriceWithCurrencySymbol(total)}
            </>
          )}
        </PurchaseButton>
        <div style={{ marginBottom: '12px' }}>{localization.payment.terms}</div>
      </div>
    </div>
  );
}

function CardholderForm({ displayPromo, setDisplayPromo, values, errors, setFieldValue }) {
  const hasCountrySelectError = Boolean(errors.cardholder?.country);
  return (
    <Section>
      <h2 className="payment-form-title-small">{localization.payment.form.cardholder}</h2>
      <FormBody>
        <StyledField
          name="cardholder.firstName"
          placeholder={localization.payment.form.firstName}
        />
        <StyledField name="cardholder.lastName" placeholder={localization.payment.form.lastName} />
        <StyledField name="cardholder.email" placeholder={localization.payment.form.email} />
        <StyledField
          name="cardholder.emailConfirmation"
          placeholder={localization.payment.form.confirmEmail}
        />
        <StyledField placeholder={localization.payment.form.mobile} name="cardholder.phoneNumber" />
        <StyledField placeholder={localization.payment.form.address1} name="cardholder.address1" />
        <StyledField placeholder={localization.payment.form.address2} name="cardholder.address2" />
        <StyledField placeholder={localization.payment.form.city} name="cardholder.city" />

        <CountrySelect
          name="cardholder.country"
          value={values.cardholder.country}
          hasError={hasCountrySelectError}
          errorMessage={errors.cardholder?.country}
          setFieldValue={setFieldValue}
        />
      </FormBody>
      <div style={{ display: 'none' }} className="promo">
        <span
          onClick={() => {
            setDisplayPromo(!displayPromo);
          }}
        >
          {localization.payment.form.enterPromo}
        </span>
      </div>
      {displayPromo && <StyledField placeholder={localization.payment.form.promo} name="promo" />}
    </Section>
  );
}

function TravelerForm({ travelerDetailsSame, setTravelerDetailsSame }) {
  const classes = useStyles();
  return (
    <Section>
      <Title>{localization.payment.form.travelerDetails}</Title>
      <BillingCheckbox>
        <FormControlLabel
          control={
            <Checkbox
              checked={travelerDetailsSame}
              onChange={() => setTravelerDetailsSame(!travelerDetailsSame)}
              size="medium"
              color="primary"
              style={{
                transform: 'scale(2)',
                margin: '10px',
                fontSize: FontSizes.cta,
              }}
              classes={{
                root: classes.checkboxRoot,
              }}
              className="payment-form-checkbox"
            />
          }
          label={localization.payment.form.sameAsBilling}
        />
      </BillingCheckbox>
      {!travelerDetailsSame && (
        <FormBody>
          <StyledField
            name="traveler.firstName"
            placeholder={localization.payment.form.firstName}
          />
          <StyledField name="traveler.lastName" placeholder={localization.payment.form.lastName} />
          <StyledField name="traveler.email" placeholder={localization.payment.form.email} />
          <StyledField
            name="traveler.emailConfirmation"
            placeholder={localization.payment.form.confirmEmail}
          />
          <StyledField placeholder={localization.payment.form.mobile} name="traveler.phoneNumber" />
        </FormBody>
      )}
    </Section>
  );
}

export default function PaymentForm({
  booking,
  paymentForm,
  onSubmit,
  isCheckoutInProgress,
  paymentFailed,
}) {
  const [isTravelerDetailsSame, setTravelerDetailsSame] = useState(true);
  if (!booking.bookingParameters) {
    booking.bookingParameters = [];
  }
  const [displayPromo, setDisplayPromo] = useState(false);
  return (
    <Formik
      initialValues={initialFormState(booking)}
      validateOnChange={false}
      validateOnBlur={false}
      onSubmit={(values) => {
        const { traveler, cardholder, bookingParameters, customQuestionsAnswers, promo } = values;
        onSubmit({
          cardholder,
          promo,
          bookingParameters,
          customQuestionsAnswers,
          language: localization.getLanguage(),
          traveler: isTravelerDetailsSame
            ? pick(
                ['firstName', 'lastName', 'email', 'emailConfirmation', 'phoneNumber'],
                cardholder
              )
            : traveler,
        });
      }}
      validationSchema={validationSchema(isTravelerDetailsSame)}
    >
      {({ values, errors, setFieldValue }) => (
        <Form>
          <Grid container spacing={6} style={{ width: '100%', margin: '0' }}>
            <Grid item xs={12} md={7} className={'left-block'}>
              <h1 className="main-heading">{localization.payment.form.billingDetails}</h1>
              <CardholderForm
                displayPromo={displayPromo}
                setDisplayPromo={setDisplayPromo}
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
              />
              <TravelerForm
                travelerDetailsSame={isTravelerDetailsSame}
                setTravelerDetailsSame={setTravelerDetailsSame}
              />

              {booking.bookingParameters.length > 0 && (
                <Section>
                  <Title>{localization.payment.form.infoRequestedByLocalPartners}</Title>
                  <BookingParameters
                    bookingParameters={booking.bookingParameters}
                    languages={booking.languages}
                    values={values}
                    setFieldValue={setFieldValue}
                  />
                </Section>
              )}
              {booking.customQuestions?.length > 0 && (
                <Section>
                  <Title>{localization.payment.form.infoRequested}</Title>
                  <CustomQuestions questions={booking.customQuestions} />
                </Section>
              )}
              <Section>
                <Title>{localization.payment.form.creditCardDetails}</Title>
                {paymentForm}
              </Section>
              {paymentFailed ? (
                <div style={{ color: 'red' }}>
                  Processing your payment failed, please check your card details and try again
                </div>
              ) : null}
              <PurchaseTotal
                total={booking.price.totalInUserCurrency}
                isCheckoutInProgress={isCheckoutInProgress}
              />
            </Grid>

            <Grid item xs={12} md={5} className={'right-block'}>
              <Sidebar bookings={[booking]} totalPrice={booking.price.total} />

              <PurchaseButton
                className="second-submit-button"
                type="submit"
                disabled={isCheckoutInProgress}
              >
                {isCheckoutInProgress ? (
                  <LoaderVerySmall />
                ) : (
                  <>
                    <span>{localization.payment.completePurchase} </span>
                    {getPriceWithCurrencySymbol(booking.price.total)}
                  </>
                )}
              </PurchaseButton>
            </Grid>
          </Grid>

          <div style={{ textAlign: 'center' }}>
            <PoweredBy style={{ width: '48px', margin: 'auto' }} />
          </div>
        </Form>
      )}
    </Formik>
  );
}
