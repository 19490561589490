import React from 'react';

var img = require('../../assets/img/NewsLetter.svg')

class NewsLetter extends React.Component {
    componentDidMount(){

    }

    render(){
        return (
            <img src={img} style={{width: '100%', display : 'flex', marginBottom : '120px'}}/>
        )
    }
}

export default NewsLetter