import defaultState from '../defaultState';
import { SET_SCROLL, FETCH_TOURS_SUCCESS, FETCH_TOURS_INIT, FETCH_FEATURED_TOURS_INIT, FETCH_FEATURED_TOURS_SUCCESS, FETCH_HIGHLIGHTED_TOURS_INIT, FETCH_HIGHLIGHTED_TOURS_SUCCESS } from './actionTypes'
export default function (state = defaultState.tours, { type, payload }) {
    switch (type) {
        case FETCH_TOURS_INIT:
            return {
                ...state,
                all: payload.resetTours ? [] : state.all,
                isLoaded: false
            }
        case FETCH_TOURS_SUCCESS:
            return {
                ...state,
                canLoadMore: payload.tours.length === payload.perPage,
                all: state.all.concat(payload.tours),
                isLoaded: true
            }
        case SET_SCROLL:
            return {
                ...state,
                scroll: payload
            };
        case FETCH_FEATURED_TOURS_INIT:
            return {
                ...state,
                featured: {
                    ...state.featured,
                    list: payload.shouldResetList ? [] : state.featured.list,
                    isLoaded: false
                }
            };
        case FETCH_FEATURED_TOURS_SUCCESS:
            return {
                ...state,
                featured: {
                    ...state.featured,
                    isLoaded: true,
                    canLoadMore: payload.canLoadMore,
                    list: state.featured.list.concat(payload.data),
                    page: payload.page
                }
            };
        case FETCH_HIGHLIGHTED_TOURS_INIT:
            return {
                ...state,
                featured: {
                    ...state.featured,
                    list: payload.shouldResetList ? [] : state.featured.list,
                    isLoaded: false
                }
            };
        case FETCH_HIGHLIGHTED_TOURS_SUCCESS:
            return {
                ...state,
                featured: {
                    ...state.featured,
                    isLoaded: true,
                    canLoadMore: payload.canLoadMore,
                    list: state.featured.list.concat(payload.data),
                    page: payload.page
                }
            };
        default:
            return state;
    }
}