import React, { useState } from 'react';
import PlaceCardBase from './PlaceCardBase';

const DestinationCard = ({ destination, mobile, isClickable }) => {
  return (
    <PlaceCardBase
      mobile={mobile}
      title={destination.name}
      imageUrl={destination.Image}
      link={`/location/${destination['location_id']}`}
      isClickable={isClickable}
    />
  );
};

export default DestinationCard;
