import mixpanel from 'mixpanel-browser';
import ExperimentConstants from './experimentConstants';
import isbot from 'isbot';

mixpanel.init('aa7ed6cb5ac932f8e73b0688b3d4f1f0');

// Uncomment to have MixPanel track events logged to the console
// mixpanel.set_config({ debug: true });

const env_check = process.env.NODE_ENV === 'production';
const EXPERIMENT_KEY = 'experiments';
const isBot = isbot(window.navigator.userAgent);
export const URGENCY_MESSAGES_DATA_KEY = 'urgency_messages';

const actions = {
  register: (properties) => {
    if (env_check && !isBot) mixpanel.register(properties);
  },
  track: (name, props) => {
    if (env_check && !isBot) mixpanel.track(name, props);
  },
  // addExperiemnts allows for registering new experiments to MP and LS
  addExperiments: (experimentName, option) => {
    if (!isBot) {
      mixpanel.register({ [experimentName]: option });
      setExperimentOption(experimentName, option);
    }
  },
};

export const Analytics = actions;

export function setExperimentOption(experimentName, experimentOption) {
  const existingExperiments = {
    ...JSON.parse(localStorage.getItem(EXPERIMENT_KEY)),
    [experimentName]: experimentOption,
  };
  localStorage.setItem(EXPERIMENT_KEY, JSON.stringify(existingExperiments));
  return experimentOption;
}

export function findExperiment(experimentName) {
  return ExperimentConstants.find((experiment) => experiment.name === experimentName);
}

export function generateAndSaveExperiment(experimentName) {
  const experiment = findExperiment(experimentName);
  if (!experiment) return null;

  const experimentSetting = experiment.generateExperimentSetting();
  actions.addExperiments(experimentName, experimentSetting);
  return experimentSetting;
}

export function getExperimentOption(experimentName) {
  const existingExperiments = JSON.parse(localStorage.getItem(EXPERIMENT_KEY));
  return existingExperiments && existingExperiments[experimentName]
    ? existingExperiments[experimentName]
    : generateAndSaveExperiment(experimentName);
}

export function loadAllExperimentsFromCache() {
  const cachedExperiments = JSON.parse(localStorage.getItem(EXPERIMENT_KEY));

  ExperimentConstants.forEach((experiment) => {
    actions.addExperiments(
      experiment.name,
      cachedExperiments && cachedExperiments[experiment.name]
        ? cachedExperiments[experiment.name]
        : experiment.generateExperimentSetting()
    );
  });

  checkUrgencyMessagesData();
}

function checkUrgencyMessagesData() {
  const urgencyMessagesData = JSON.parse(localStorage.getItem(URGENCY_MESSAGES_DATA_KEY));

  const cacheData = new Date(urgencyMessagesData?.cache?.date || 0);
  const twoDaysOldDate = new Date();
  twoDaysOldDate.setDate(twoDaysOldDate.getDate() - 2);

  if (cacheData < twoDaysOldDate || !urgencyMessagesData?.cache?.tours) {
    const newUrgencyMessagesData = {
      cache: {
        date: new Date(),
        tours: {},
      },
    };
    localStorage.setItem(URGENCY_MESSAGES_DATA_KEY, JSON.stringify(newUrgencyMessagesData));
  }
}

export function getBookedRecentlyNumber(tourId) {
  const urgencyMessagesData = JSON.parse(localStorage.getItem(URGENCY_MESSAGES_DATA_KEY));
  return urgencyMessagesData?.cache?.tours?.[tourId];
}
