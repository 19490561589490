import React, { Fragment, useEffect, useState } from 'react';
import { Row } from 'reactstrap';
import styled from 'styled-components';
import localization from '../../localization';
import { ButtonMedium } from '../Common';
import Loader from '../Loader';
import LocationCard from './LocationCard';

const Wrapper = Row;
const BtnWrapper = styled.div`
  flex: 1 0 100%;
`;

const Attractions = ({ attractions, isLoaded, canLoadMore, onLoadMore, extraTrackingProperties }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
  })

  const handleWindowResize = () => setWindowWidth(window.innerWidth);

  attractions.forEach(location => {
    //TODO: Random hotfix for campsited. Remove.
    //const image = screenWidth <= 800 ? location.Image.replace('-93.jpg', '-156.jpg') : location.Image;
    if(location.Image){
      if (location.Image.indexOf('86279') > -1) {
        location.Image = location.Image.replace('86279', '155');
      }
      if (location.Image.indexOf('135537') > -1) {
        location.Image = location.Image.replace('135537', '155');
      }
      if (location.Image.indexOf('122420') > -1) {
        location.Image = location.Image.replace('122420', '292');
      }
    }

  });

  return (
    <Fragment>
      <Wrapper>
        {attractions.map((location, index) => (
          <LocationCard
            key={index}
            windowWidth={windowWidth}
            location={location}
            index={index}
            extraTrackingProperties={extraTrackingProperties}
          />
        ))}
        {!isLoaded && <Loader />}
        {isLoaded && canLoadMore && (
          <BtnWrapper>
            <ButtonMedium onClick={onLoadMore}>{localization.common.seeMore}</ButtonMedium>
          </BtnWrapper>
        )}
      </Wrapper>
    </Fragment>
  );
};

export default Attractions;
