import React from 'react';
import { Helmet } from 'react-helmet';
import theme from 'theme';
import localization from '../../localization';

const getHelmetInfo = (page, item, location) => {
  let title = theme.meta.title;
  let description = theme.meta.description;
  let keywords = theme.meta.keywords;
  let image = theme.assets.banner;

  const twitterSite = theme.twitter ? theme.twitter.site : '';
  const twitterCreator = theme.twitter ? theme.twitter.creator : '';

  if (page === 'location' && theme.location) {
    title = theme.location.title.replace(/{{CityName}}/g, location.name);
    description = theme.location.description
      .replace(/{{CityName}}/g, location.name)
      .replaceAll(/{{CountryName}}/g, location.countryName);
    keywords = theme.location.keywords.replace(/{{CityName}}/g, location.name);
    image = location.Image;
  }

  if (page === 'tour' && theme.activity) {
    const language = localization.getLanguage();
    title = theme.activity.title.replace(/{{ActivityTitle}}/g, item.Title);
    const name = item.primaryLocation?.translations
      ? item.primaryLocation?.translations[language]?.name
      : item.primaryLocation?.english_name;

    description = theme.activity.description
      .replace(/{{ActivityTitle}}/g, item.Title)
      .replace(/{{CityName}}/g, name);
    keywords = theme.activity.keywords.replace(/{{ActivityTitle}}/g, item.Title);
    image = item.DisplayImageRemote.CarouselOne;
  }

  return { title, description, keywords, image, twitterSite, twitterCreator };
}

const CustomHelmet = ({ page, item, url }) => {
  const { title, description, keywords, image, twitterSite, twitterCreator } = getHelmetInfo(page, item);

  return (
    <Helmet>
    <title>{title}</title>
    <meta name="description" content={description} />
    <meta name="keywords" content={keywords} />
    <meta name="TripAdmit" />
    <link rel="icon" type="image/png" href={theme.assets.favicon} />
    <meta name="og:title" content={title} />
    <meta name="twitter:card" content={twitterSite} />
    <meta name="twitter:site" content={twitterSite} />
    <meta name="twitter:creator" content={twitterCreator} />
    <meta name="og:image" content={image} />
    <meta name="og:url" content={url} />
    <meta name="og:description" content={description} />
  </Helmet>
  )
}

export default CustomHelmet;
