import { Card, CardActionArea, CardContent, CardMedia, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import styled from 'styled-components';
import localization from '../../localization';
import { FontSizes, StyleBreakpoints } from '../../utils/constants';
import { isLongTourCard } from '../../utils/experimentConstants';
import { formatPrice, getPriceWithCurrencySymbol, getTourDuration } from '../../utils/helpers';
import ActivityRating from '../ActivityRating';
import { BoldFont } from '../TourPage/TourDetailsBase';
import './CardBase.scss';
import { isBookNowButton } from '../../utils/experimentConstants';
import CommonButton from '../Common/Button';
import { Analytics } from '@analytics';

const TourTitle = styled.span`
  font-size: ${FontSizes.p};
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-family: ${(props) => props.theme.fonts.bold};
  letter-spacing: -0.03px;
  text-align: justify;
  color: #000;
`;

const FlagTag = styled.div`
  background-color: #04112d;
  font-family: MontserratBold;
  display: inline-block;
  font-weight: bold;
  font-size: 10px;
  border-radius: 4.4px;
  padding: 0 0.5rem;
  color: #fff;
  margin-bottom: 0.5rem;
  margin-right: 4px;
  height: 2em;
  line-height: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
  @media (max-width: 768px) {
    font-size: 8px;
    border-radius: 4.4px;
    padding: 2px 0.5rem;
    height: 2em;
    line-height: 12px;
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: '270px',
    width: '100%',
    marginBottom: '25px',
    borderRadius: '7px',
    textAlign: 'left',
  },
  cardActionArea: {
    padding: '11px',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  rootMobile: {
    marginBottom: '14px',
    borderRadius: '7px',
    textAlign: 'left',
  },
  media: {
    height: '170px',
    width: '200px',
    borderRadius: '5px',
    flex: '0 0 200px',
    backgroundSize: 'cover',
  },
  mediaMobile: {
    height: 80,
    backgroundSize: 'cover',
  },
  mediaMobileList: {
    height: 180,
    borderRadius: '5px',
    flex: '0 0 200px',
    backgroundSize: 'cover',
  },
  title: {
    textAlign: 'left',
    lineHeight: '13px',
    fontSize: FontSizes.h3,
    fontWeight: 'bold',
  },
  titleMobile: {
    textAlign: 'left',
    lineHeight: '9px',
    fontSize: '10px',
    fontWeight: 'bold',
  },
  desc: {
    fontSize: '13px',
    margin: '5px 0',
  },
  tag: {},
  cardContent: {
    display: 'flex',
    flex: '1',
    flexDirection: 'column',
    padding: '2px 10% 16px 16px',
  },
  cardContentMobile: {
    display: 'flex',
    flexDirection: 'column',
    height: 146,
    padding: '4px 16px 16px 16px',
  },
  cardBody: {
    flex: '1 0 auto',
    alignItems: 'flex-end',
    justifyContent: 'center',
  },
  cardActions: {
    padding: '12px 12px 0 0',
    justifyContent: 'space-between',
    alignSelf: 'flex-start',
    textAlign: 'right',
  },
  priceDesc: {
    fontSize: '12px',
  },
  price: {
    fontSize: FontSizes.h2,
    margin: '-6px 0 -8px',
  },
  cardActionsFlags: {
    display: 'flex',
  },
  locationTitle: {
    padding: '8px 0 4px 0',
    fontSize: '12px',
    color: '#63666a',
    fontFamily: theme.typography.fontFamily,
  },
}));

export default function LongTourCard({
  item,
  appendHeadlessQueryParam,
  isClickable,
  inCarousel,
  inList,
  isLocationPage = false,
}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [tourLocation, setTourLocation] = useState();
  const classes = useStyles({
    isLongTourCard: isLongTourCard && screenWidth > StyleBreakpoints.sm,
  });

  useEffect(() => {
    item.tourLocation && setTourLocation(item.tourLocation.english_name);
  }, [item]);
  useEffect(
    function initTourDetailsBase() {
      const updateWidth = () => setScreenWidth(window.innerWidth);
      window.addEventListener('resize', updateWidth);
      return () => window.removeEventListener('resize', updateWidth);
    },
    [screenWidth]
  );
  if (!item) {
    return null;
  }

  const { Title, WasPrice, PublishedPrice, PriceDescription } = item;

  function renderFlag(flagText, redVariant) {
    let style = {};
    if (redVariant) {
      style = {
        backgroundColor: '#fe424d',
      };
    }
    if (isMobile && inCarousel) {
      style.height = '1.5em';
    }
    return (
      <FlagTag className={classes.tag} style={style}>
        {flagText}
      </FlagTag>
    );
  }

  const flagOrder = [
    { key: 'uniqueExperience', text: localization.tours.flags.uniqueExperience },
    { key: 'topRated', text: localization.tours.flags.topRated },
    { key: 'instant_confirmation', text: localization.tours.flags.instantConfirmation },
    { key: 'mobile_voucher', text: localization.tours.flags.mobileVoucher },
    { key: 'private', text: localization.tours.flags.private },
    { key: 'freeCancellation', text: localization.tours.flags.freeCancellation },
    { key: 'wheelchair', text: localization.tours.flags.wheelchair },
  ];

  function renderFlags(flags) {
    if (!flags) {
      return null;
    }

    let renderableFlags = [];

    for (var f = 0; f < flagOrder.length; f++) {
      let flagContent = flagOrder[f];
      if (flags[flagContent.key] && renderableFlags.length < 2) {
        renderableFlags.push(flagContent);
      }
    }
    return <>{renderableFlags.map((f) => renderFlag(f.text))}</>;
  }

  function renderTopFlags(flags) {
    //Render 1
    if (flags.likelyToSellOut) {
      return renderFlag(localization.tours.flags.likelyToSellOut, true);
    }
    return null;
  }

  function clipDesc(desc) {
    if (desc.length < 200) {
      return desc;
    }
    return desc.slice(0, 200) + '...';
  }

  var url = '/tour/' + item.TourID + '/' + item.Title.replace(/%|\//gi, '').split(' ').join('-');
  if (appendHeadlessQueryParam) {
    //url = "/headlesstour/" + item._id
  }
  let imageUrl = item.DisplayImageRemote.CarouselOne;
  const openInNewTab = (url) => {
    window.open(url, '_blank');
  };

  let nonCarouselWidth = '100%';
  let carouselWidth = isMobile ? '220px' : '320px';
  let cardStyles = { width: inCarousel ? carouselWidth : nonCarouselWidth };
  let mediaStyles = isMobile ? classes.mediaMobile : classes.media;
  if (inList) {
    mediaStyles = isMobile ? classes.mediaMobileList : classes.media;
  }

  if (!item.PriceDescription) {
    item.PriceDescription = 'per person';
  }

  return (
    <Card className={isMobile ? classes.rootMobile : classes.root} style={cardStyles}>
      <CardActionArea
        className={classes.cardActionArea}
        onClick={() => {
          openInNewTab(url);
        }}
      >
        <CardMedia classes={{ root: mediaStyles }} image={imageUrl}>
          <div
            style={{
              padding: '10px',
            }}
          >
            {renderTopFlags(item.Flags)}
          </div>
        </CardMedia>
        <CardContent className={isMobile ? classes.cardContentMobile : classes.cardContent}>
          <div className={classes.cardBody}>
            <div className={classes.locationTitle}>
              {tourLocation}
              {tourLocation && item.duration && ' | '}
              {item.duration && `${localization.tours.about.duration}: `}
              {getTourDuration(item.duration)}
            </div>
            <Typography
              className={isMobile ? classes.titleMobile : classes.title}
              gutterBottom
              variant="h5"
              component="h2"
            >
              <TourTitle>{item.Title}</TourTitle>
            </Typography>
            <ActivityRating tour={item} />
            <p className={classes.desc}>{clipDesc(item.Description.summary)}</p>
          </div>
          <div className={classes.cardActionsFlags}>{renderFlags(item.Flags)}</div>
        </CardContent>
        <div className={classes.cardActions}>
          <span className={classes.priceDesc}>{localization.tours.from}</span>
          <BoldFont className={classes.price}>
            {getPriceWithCurrencySymbol(formatPrice(item.PublishedPrice))}
          </BoldFont>
          <span className={classes.priceDesc}>
            {localization.common[item.PriceDescription] || item.PriceDescription || 'per person'}
          </span>
        </div>

        <div>
          {isBookNowButton() && (
            <CommonButton
              onClick={() => Analytics.track('tour card book now button clicked')}
              style={{
                borderRadius: '7px',
                minHight: '32px',
                minWidth: '140px',
                fontWeight: 'bold',
                lineHeight: '1.3',
                position: 'absolute',
                bottom: '15px',
                right: '16px',
                width: '10%',
              }}
            >
              {localization.common.bookNow}
            </CommonButton>
          )}
        </div>
      </CardActionArea>
    </Card>
  );
}
