import { Paper } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { FontSizes } from '../../../../../../utils/constants';
import { getCurrencySymbol } from '../../../../../../utils/helpers';
import Counter from '../Counter';

const ItemWrapper = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const ExtrasImageBlock = styled.div`
  flex: 0 0 110px;
  align-self: flex-start;
`;

const ExtrasImage = styled.img`
  width: 100%;
`;

const ExtrasInfoBlock = styled.div`
  margin: 0 20px;
  flex: 1 1 auto;
`;

const ExtrasTitle = styled.h3`
  font-size: ${FontSizes.p};
  font-weight: 700;
  color: #131313;
  margin: 5px 0;
`;

const ExtrasDesc = styled.p`
  font-size: 13px;
  font-weight: 700;
  color: #979797;
  letter-spacing: -0.03px;
  margin: 0;
`;

const ExtrasPriceBlock = styled.div`
  margin-left: auto;
  flex: 0 1 auto;
`;

const ExtrasPrice = styled.span`
  display: block;
  font-size: 27px;
  font-weight: bold;
  color: #000;
  margin: 8px 0 8px auto;
  text-align: right;
`;

const CounterBlock = styled.div`
  text-align: right;
`;

const SubText = styled.span`
  font-size: 10px;
`;

function ExtraItem({
  participantsAmount,
  isMandatory,
  currency,
  title,
  description,
  image,
  price,
  id,
  count,
  handleExtraCountChange,
}) {
  return (
    <ItemWrapper className="extra-wrapper">
      <ExtrasImageBlock>
        <Paper>
          <ExtrasImage src={image} alt={title} title={title} />
        </Paper>
      </ExtrasImageBlock>

      <ExtrasInfoBlock className="extras-info-block">
        <ExtrasTitle>{title}</ExtrasTitle>
        <ExtrasDesc>{description}</ExtrasDesc>
      </ExtrasInfoBlock>

      <ExtrasPriceBlock>
        <ExtrasPrice>
          {getCurrencySymbol() === 'AED' && (
            <span>
              {price}
              <SubText>{getCurrencySymbol()}</SubText>
            </span>
          )}
          {getCurrencySymbol() !== 'AED' && (
            <span>
              {getCurrencySymbol()}
              {price}
            </span>
          )}
        </ExtrasPrice>
        <CounterBlock>
          <Counter
            styles={{
              float: 'right',
            }}
            id={id}
            count={count}
            isMandatory={isMandatory}
            participantsAmount={participantsAmount}
            handleExtraCountChange={handleExtraCountChange}
          />
        </CounterBlock>
      </ExtrasPriceBlock>
    </ItemWrapper>
  );
}

export default ExtraItem;
