import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { getExperimentOption } from '../../../utils/analytics';
import { TOUR_COUNTDOWN_V1 } from '../../../utils/experimentConstants';

const useStyles = makeStyles(() => ({
  securedFor: {
    fontSize: '13px',
    color: '#000',
    fontWeight: '700',
    textTransform: 'uppercase',
    marginRight: '11px',
  },
  timer: {
    color: '#fff',
    padding: '5px 12px 5px 12px',
    borderRadius: '5.5px',
    backgroundColor: '#636261',
    minWidth: '75px',
    display: 'inline-block',
    textAlign: 'center',
  },
}));

export default function UrgencyCountdown({ timer, setTimer, bookingHash = null }) {
  const classes = useStyles();

  const [countdown, setCountdown] = useState();
  const [duration, setDuration] = useState();

  useEffect(() => {
    const experimentValue = getExperimentOption(TOUR_COUNTDOWN_V1.name);
    const durationByValue = bookingHash
      ? getDurationForBooking()
      : Number(experimentValue.replace(/[^0-9]/g, '')) * 60 || null;
    setDuration(durationByValue);
    setTimer(durationByValue);

    let minutes, seconds;
    minutes = parseInt(durationByValue / 60, 10);
    seconds = parseInt(durationByValue % 60, 10);
    minutes = minutes < 10 ? '0' + minutes : minutes;
    seconds = seconds < 10 ? '0' + seconds : seconds;
    setCountdown(minutes + ':' + seconds);
  }, []);

  useEffect(() => {
    let minutes, seconds;

    function startCountdown() {
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);

      minutes = minutes < 10 ? '0' + minutes : minutes;
      seconds = seconds < 10 ? '0' + seconds : seconds;

      setCountdown(minutes + ':' + seconds);

      setTimer(timer - 1);

      if (timer - 1 < 0) {
        setTimer(0);
      }
    }

    const id = setInterval(startCountdown, 1000);

    return () => clearInterval(id);
  }, [duration, timer]);

  function getDurationForBooking() {
    const data = JSON.parse(localStorage.getItem('countdown'));
    const expireAtDate = data?.[bookingHash]?.countdownExpireAt;
    if (expireAtDate && new Date() < new Date(expireAtDate)) {
      return (new Date(expireAtDate).getTime() - new Date().getTime()) / 1000;
    }
    return null;
  }

  return (
    <>
      {duration && (
        <div>
          <span className={classes.securedFor}>PRICE SECURED FOR</span>
          <span className={classes.timer}>{countdown}</span>
        </div>
      )}
    </>
  );
}
