import { TextField, Autocomplete } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';
import { CountryNames } from '../Countries';

const useStyles = makeStyles({
  option: {
    width: '100%',
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    width: '100%',
    margin: '10px 0',
  },
  inputRoot: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: '#767676',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
  },
  textField: {
    width: '100%',
    fontSize: FontSizes.cta,
  },
});

export default function CountrySelect({ value, name, setFieldValue, errorMessage, hasError }) {
  const classes = useStyles();

  return (
    <Autocomplete
      id="country-select"
      options={CountryNames.sort(function (a, b) {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      })}
      classes={{
        option: classes.option,
        root: classes.root,
      }}
      onChange={(event, newValue) => {
        if (newValue) {
          setFieldValue(name, newValue.code);
        } else {
          setFieldValue(name, '');
        }
      }}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderInput={(params) => (
        <TextField
          {...params}
          classes={{ root: classes.inputRoot }}
          name={name}
          label={localization.payment.form.country}
          variant="outlined"
          required="true"
          error={Boolean(hasError && errorMessage)}
          fullWidth
          className={classes.textField}
          helperText={hasError && errorMessage}
          value={value}
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
