import moment from 'moment';
import React from 'react';
import { bookTsTour } from '../../../api/booking';
import {
  fetchTsAvailabilities,
  fetchTsCalculatedPricings,
  fetchTsPricings,
  fetchTsSeason
} from '../../../api/tour';
import EffectfulBookingWidget from './EffectfulBookingWidget';

function TsBookingWidget({ tourId, languages, language, extraTrackingProperties }) {
  return (
    <EffectfulBookingWidget
      tourId={tourId}
      languages={languages}
      language={language}
      fetchAvailabilities={args =>
        fetchTsAvailabilities(args).then(dates =>
          dates.filter(date => date.vacancies > 0).map(date => new Date(date.date))
        )
      }
      fetchParticipants={fetchTsPricings}
      fetchSeason={fetchTsSeason}
      fetchCalculatedPricings={fetchTsCalculatedPricings}
      extraTrackingProperties={extraTrackingProperties}
      bookOption={({ selectedDate, option, ...bookingData }) =>
        bookTsTour({ ...bookingData, selectedDate: moment(selectedDate).format('YYYY-MM-DD') })
      }
    />
  );
}

export default TsBookingWidget;
