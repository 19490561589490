import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React, { useState } from 'react';
import localization from '../../../../../../../localization';
import { FontSizes } from '../../../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  option: {
    width: '100%',
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  root: {
    width: '100%',
    margin: '10px 0',
  },
  inputRoot: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: '#767676',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
  },
  textField: {
    width: '114.28%', // 16 / 14 * 100, https://thingsthemselves.com/no-input-zoom-in-safari-on-iphone-the-pixel-perfect-way/
    fontSize: FontSizes.p,
    transform: 'scale(0.875)', // 16 / 14
    transformOrigin: 'left center',
  },
  field: {
    height: '55px',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '114.28%', // 16 / 14 * 100
    fontSize: FontSizes.p,
    transform: 'scale(0.875)', // 16 / 14
    transformOrigin: 'left center',
    padding: '0 9px',
  },
  freeTextBlock: {
    margin: '20px 0 10px',
  },
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSizes.p,
    marginBottom: '5px',
    whiteSpace: 'pre-wrap',
  },
}));

export default function PickupPointSelect({ locations, name, setFieldValue }) {
  const classes = useStyles();

  let [chosenFreetext, setChosenFreetext] = useState(false);

  function handlePickupPointSelectChange(e) {
    const value = e.target.value;

    if (value === "I don't see my pickup location") {
      setChosenFreetext(true);
    } else {
      setChosenFreetext(false);
    }
    if (value) {
      setFieldValue(name, value);
    } else {
      setFieldValue(name, '');
    }
  }

  function handleFreeTextChange(event) {
    if (event) {
      setFieldValue(name, event.target.value);
    } else {
      setFieldValue(name, '');
    }
  }

  return (
    <>
      <div className={classes.title}>{localization.payment.form.selectPickupLocation}</div>
      {locations && (
        <Field
          component="select"
          id={'pickup-point-select'}
          name={name}
          required={true}
          multiple={false}
          className={classes.field}
          onChange={handlePickupPointSelectChange}
        >
          <option value="" disabled selected="selected">
            Please select...
          </option>
          {locations.map((ans, i) => (
            <option key={i} value={ans.reference}>
              {ans.name}
            </option>
          ))}
        </Field>
      )}
      {chosenFreetext ? (
        <div className={classes.freeTextBlock}>
          <TextField
            variant="outlined"
            required="true"
            name={name}
            fullWidth
            className={classes.textField}
            onChange={handleFreeTextChange}
            label={'Please enter your pickup location'}
          />
        </div>
      ) : null}
    </>
  );
}
