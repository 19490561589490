import { DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect } from 'react';
import localization from '../../../localization';
import { FontSizes, StyleBreakpoints } from '@constants';

const constructStyles = (theme) => ({
  container: {
    '& .MuiPaper-root': {
      maxWidth: '1400px',
      [theme.breakpoints.down(StyleBreakpoints.sm)]: {
        maxHeight: '100%',
        height: '100%',
        width: '100%',
        margin: '0',
        borderRadius: '0',
      },
    },
  },
  header: {
    minHeight: '60px',
  },
  body: (props) => ({
    padding: `${props.bodyPadding ? '16px 24px' : '0'}`,
    '&::-webkit-scrollbar': {
      width: '10px',
      cursor: 'pointer',
      backgroundColor: '#f6f3f3',
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#b8b7b7',
      width: '8px',
      borderRadius: '4px',
    },
  }),
  footer: {
    padding: '16px 24px',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      justifyContent: 'center',
      flexFlow: 'column-reverse',
    },
    '& > :not(:first-child)': {
      marginLeft: '12px',

      [theme.breakpoints.down(StyleBreakpoints.xs)]: {
        marginLeft: '0',
      },
    },
    '& button': {
      paddingLeft: '25px',
      paddingRight: '25px',

      [theme.breakpoints.down(StyleBreakpoints.xs)]: {
        width: '100%',
        height: '40px',
        marginTop: '10px',
      },
    },
  },
  title: (props) => ({
    color: '#4b4b4b',
    fontSize: '20px',
    fontWeight: 'bold',
    marginLeft: `${props.handleHeaderBackButton ? '35px' : '0'}`,
    marginRight: `${props.handleHeaderCloseButton ? '35px' : '0'}`,

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      marginTop: `${props.handleHeaderBackButton ? '40px' : '0'}`,
      marginLeft: 0,
    },
  }),
  desc: (props) => ({
    color: '#b8b8b8',
    fontWeight: '500',
    fontSize: FontSizes.cta,

    marginLeft: `${props.handleHeaderBackButton && !props.title ? '35px' : '0'}`,
    marginRight: `${props.handleHeaderCloseButton && !props.title ? '35px' : '0'}`,

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      marginTop: `${props.handleHeaderBackButton && !props.title ? '40px' : '0'}`,
      marginLeft: 0,
    },
  }),
  headerCloseButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    padding: '6px',
    fontSize: '28px',
    color: '#9e9e9e',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      right: 4,
      top: 4,
      fontSize: '32px',
    },
  },
  headerBackButton: {
    position: 'absolute',
    top: '10px',
    left: '9px',
    padding: '12px',
    borderRadius: '50%',
    background: 'transparent',
    minWidth: 'fit-content',
  },
  headerBackButtonArrow: {
    fontSize: '35px',
    lineHeight: '16px',
    color: '#9e9e9e',
    fontWeight: '500',
    width: '20px',
    height: '20px',
    display: 'block',
  },
  footerBackButton: {
    padding: '6px 8px!important',
    boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.5)',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      border: 'none',
      boxShadow: 'none',
    },
  },
  esc: {
    backgroundColor: '#e9e9e9',
    borderRadius: '4px',
    marginLeft: '10px',
    padding: '0 6px',
    color: '#787878',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      display: 'none',
    },
  },
});

/**
 * Returns common modal component. Let's use with component to create new modal windows.
 * @param {boolean} isOpen describe if the modal is open
 * @param {Function} onClose will work on modal closing

 * @param {boolean} withHeader set if the header will be shown
 * @param {boolean} withBody set if the body will be shown
 * @param {boolean} withFooter set if the footer will be shown
 * @param {boolean} withDividers set if the section dividers will be shown
 * @param {boolean} bodyPadding set if body will have paddings

 * @param {Function} handleHeaderBackButton handler for header back button; if absent - button won't be rendered
 * @param {Function} handleHeaderCloseButton handler for header close button; if absent - button won't be rendered

 * @param {{(string | JSX.Element)}} title modal title - can be either string or react element
 * @param {{(string | JSX.Element)}} desc modal description - can be either string or react element
 * @param {{(string | JSX.Element)}} body modal body - can be either string or react element

 * @param {Function} handleFooterBackButton handler for footer back button; if absent - button won't be rendered
 * @param {Function} handleFooterCloseButton handler for footer close button; if absent - button won't be rendered

 * @param {object} primaryActionButtonData object that describe primary action button
 * @param {array} customButtons array of react elements - custom buttons that will be added to the footer
 */
export default function CustomizableModal({
  isOpen,
  onClose,

  withHeader = true,
  withBody = true,
  withFooter = true,
  withDividers = false,
  bodyPadding = true,

  handleHeaderBackButton,
  handleHeaderCloseButton,

  title,
  desc,
  body,

  handleFooterBackButton,
  handleFooterCloseButton,

  primaryActionButtonData,
  customButtons = [],
}) {
  const classes = makeStyles(constructStyles)({
    handleHeaderBackButton,
    handleHeaderCloseButton,
    bodyPadding,
    title,
  });

  const shouldDisplayHeader =
    withHeader && (handleHeaderCloseButton || handleHeaderBackButton || title || desc);

  const shouldDisplayFooter =
    withFooter &&
    (handleFooterBackButton ||
      handleFooterCloseButton ||
      primaryActionButtonData ||
      customButtons.length);

  const escFunction = (event) => {
    if (isOpen && event.keyCode === 27) {
      const handler = handleFooterBackButton || handleHeaderBackButton || onClose;
      if (handler) handler();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <Dialog
      disableEscapeKeyDown={true}
      open={isOpen}
      onClose={onClose}
      classes={{
        container: classes.container,
      }}
    >
      {shouldDisplayHeader && (
        <DialogTitle
          classes={{
            root: classes.header,
          }}
        >
          {handleHeaderCloseButton && (
            <IconButton className={classes.headerCloseButton} onClick={onClose} size="large">
              <CloseIcon />
            </IconButton>
          )}
          {handleHeaderBackButton && (
            <Button onClick={handleHeaderBackButton} className={classes.headerBackButton}>
              <span className={classes.headerBackButtonArrow}>&#8249;</span>
            </Button>
          )}
          {title && <div className={classes.title}>{title}</div>}
          {desc && <div className={classes.desc}>{desc}</div>}
        </DialogTitle>
      )}

      {withBody && body && (
        <DialogContent
          classes={{
            root: classes.body,
          }}
          dividers={withDividers}
        >
          {body}
        </DialogContent>
      )}

      {shouldDisplayFooter && (
        <DialogActions
          classes={{
            root: classes.footer,
          }}
        >
          {handleFooterBackButton && (
            <Button className={classes.footerBackButton} onClick={handleFooterBackButton}>
              {localization.modal.back}
              <span className={classes.esc}>Esc</span>
            </Button>
          )}
          {handleFooterCloseButton && (
            <Button onClick={handleFooterCloseButton} color="primary">
              {localization.modal.cancel}
            </Button>
          )}
          {primaryActionButtonData && (
            <Button
              disabled={primaryActionButtonData.isDisabled}
              onClick={primaryActionButtonData.onClick}
              variant="contained"
              color="primary"
            >
              {primaryActionButtonData.content}
            </Button>
          )}
          {customButtons.length > 0 && customButtons}
        </DialogActions>
      )}
    </Dialog>
  );
}
