import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import { stripeTags } from '../../../utils/TextUtils';

export default class Description extends Component {
  constructor(props) {
    super(props);
    const { title, text } = props;
    this.state = {
      title,
      text: stripeTags(text)
    };

    this.scrollRef = React.createRef();
  }

  scrollToSelf() {
    setTimeout(() => {
      const offset = this.scrollRef.current.getBoundingClientRect().top;
      if (offset >= 0) return;
      window.scrollTo({
        top: window.scrollY + offset,
        behavior: 'smooth'
      });
    }, 0);
  }

  render() {
    return (
      <div ref={this.scrollRef}>
        <Row style={{ textAlign: 'left', lineHeight: '24px' }}>
          <Col md="2">
            <div className="third-heading">{this.state.title}</div>
          </Col>

          <Col md="10">{this.state.text}</Col>
        </Row>
      </div>
    );
  }
}
