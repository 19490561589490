import React from 'react';
import Header from '../../../components/Header';
import Footer from '../../../components/Footer';
import { Title, Paragraph, Paragraphs, Block } from '../../../components/Common/Policies';

import localization from '../../../localization';

class TermsAndConditions extends React.Component {

    render() {
        return (
            <div className="TermsAndConditions" style={{ fontFamily: 'DiodrumRegular' }}>
                <Header />
                <div style={{ textAlign: 'left', width: '80vw', margin: 'auto', marginTop: '5vh' }}>

                    <Title>{localization.policies.terms.title}</Title>
                    <Paragraph>{localization.policies.terms.intro}</Paragraph>
                    <Paragraph>{localization.policies.terms.breakdown.title}</Paragraph>
                    <Paragraphs content={localization.policies.terms.breakdown.content} />

                    <Title>{localization.policies.terms.generalTerms.title}</Title>
                    {
                        localization.policies.terms.generalTerms.content.map(
                            (content, key) => <Block key={key} content={content} />
                        )
                    }

                    <Title>{localization.policies.terms.intermediary.title}</Title>
                    <Paragraph>{localization.policies.terms.intermediary.intro}</Paragraph>
                    {
                        localization.policies.terms.intermediary.content.map(
                            (content, key) => <Block key={key} content={content} />
                        )
                    }

                    <Title>{localization.policies.terms.userTerms.title}</Title>
                    <Block content={localization.policies.terms.userTerms.introduction} />
                    <Block content={localization.policies.terms.userTerms.serviceOfTripAdmit} />
                    <Block content={localization.policies.terms.userTerms.payments} />
                    <Block content={localization.policies.terms.userTerms.availability} />
                    <Block content={localization.policies.terms.userTerms.liability} />
                    <Block content={localization.policies.terms.userTerms.dataProtection} />
                    <Block content={localization.policies.terms.userTerms.changesToTerms} />
                    <Block content={localization.policies.terms.userTerms.otherProvisions} />
                    <hr />
                    <Block content={localization.policies.terms.updates} />
                </div>
                <Footer />
            </div>

        );
    }
}

export default TermsAndConditions;
