import { TextField } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React from 'react';
import styled from 'styled-components';
import { FontSizes } from '../../utils/constants';

const useStyles = makeStyles({
  inputRoot: {
    width: '100%',
    '& .MuiFormLabel-root': {
      color: '#767676',
    },
    '& .MuiFormHelperText-root': {
      fontSize: '12px',
    },
  },
  textField: {
    width: '100%',
    fontSize: FontSizes.cta,
  },
});

const FormInput = styled.div`
  flex-basis: 100%;
  display: block;
  width: 100%;
  margin: 10px 0px;
`;

const CustomizedTextField = ({ field, form, placeholder, ...props }) => {
  const classes = useStyles();
  const [categoryOfField, name] = field.name.split('.');
  return (
    <>
      <TextField
        {...props}
        {...field}
        classes={{ root: classes.inputRoot }}
        className={classes.textField}
        variant="outlined"
        color="primary"
        error={Boolean(form.errors[categoryOfField]?.[name])}
        helperText={form.errors[categoryOfField]?.[name]}
        label={placeholder}
        // InputLabelProps={{
        //   shrink: true
        // }}
        onChange={(e) => {
          form.handleChange(e);
        }}
      />
    </>
  );
};

export const StyledField = ({ children, name, ...props }) => (
  <FormInput>
    <Field name={name} {...props} component={CustomizedTextField}>
      {children}
    </Field>
  </FormInput>
);
