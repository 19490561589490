import React from 'react';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Newsletter from '../../components/Newsletter'
import PoweredBy from '../../components/PoweredBy'
import TicketCard from '../../components/TicketCard'
import api from '../../api/index'
import queryString from 'query-string'
import theme from 'theme';

class Tour extends React.Component {

    constructor(props) {
        super(props);
        const values = queryString.parse(window.location.search)
        this.state = {
            values: values,
            modal: false,
            item: {
                keystoneTour: {
                    DisplayImage: {
                        CarouselTwo: {

                        }
                    },
                    Description: {

                    }
                }
            }
        }

        this.toggle = this.toggle.bind(this);
    }
    toggle() {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    componentDidMount() {
        window.scrollTo(0, 0);

        api.getTicketManifest(null, this.state.values.bookingReference, function (err, manifest) {
            
            api.getTourByTaId(manifest.taID, function (err, items) {
                this.setState({ item: items, manifest: manifest, loaded: true })
            }.bind(this))
        }.bind(this))

    }

    render() {
        var ticket = null
        var ticketEmail = null;
        if (this.state.loaded) {
            ticket = (
                <div style={{ maxWidth: '460px', margin: 'auto' }}>
                    <TicketCard item={this.state.item} manifest={this.state.manifest} />
                </div>
            )
        }

        return (
            <div className="Tour" style={{ fontFamily: theme.fonts.primary }}>

                {ticketEmail}

                <Header />
                <div style={{ width: '90vw', margin: 'auto', marginTop: '100px', textAlign: 'left' }}>
                    <span style={{ fontFamily: theme.fonts.primary, fontSize: "34px" }}>
                        Purchase{" "}
                    </span>

                    <span style={{ fontFamily: theme.fonts.primary, fontSize: "34px" }}>
                        Complete
                </span>
                    <br />
                    <div style={{ marginTop: "20px" }}></div>
                    <span style={{ fontFamily: theme.fonts.primary, fontSize: "24px", }}>
                        Your Ticket:
                </span>
                    {ticket}

                    <div style={{ marginTop: "200px" }}></div>
                </div>

                <PoweredBy />
                <Newsletter />
                <Footer />

            </div>
        );

    }

}



export default Tour;
