import React, { useEffect, useState } from 'react';
import './lib';

import { Provider } from 'react-redux';
import ReduxToastr from 'react-redux-toastr';
import { ThemeProvider } from 'styled-components';

import theme from 'theme';
import configureStore from './store';

import { Analytics, loadAllExperimentsFromCache } from '@analytics';
import { CssBaseline } from '@mui/material';
import { StyledEngineProvider, ThemeProvider as MaterialThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import ruLocale from 'date-fns/locale/ru';
import TagManager from 'react-gtm-module';
import { BrowserRouter, Route, Switch, useLocation } from 'react-router-dom';
import materialTheme from './defaultTheme/default.js';
import localization from './localization';
import { isGTMIdValid } from './utils/helpers';
import ContactUs from './views/ContactUs';
import Home from './views/Home';
import Location from './views/Location';
import NotFoundPage from './views/NotFoundPage';
import Payment from './views/Payment';
import CookiePolicy from './views/Policies/CookiePolicy';
import PrivacyPolicy from './views/Policies/PrivacyPolicy';
import TermsAndConditions from './views/Policies/TermsAndConditions';
import SearchResults from './views/Search';
import SimulatedOrderConfirmation from './views/SimulatedOrderConfirmation';
import Ticket from './views/Ticket';
import Tour from './views/Tour';
import Venue from './views/Venue';
import ViatorOrderConfirmation from './views/ViatorOrderConfirmation';
import ViatorPayment from './views/ViatorPayment';

const localeMap = {
  en: enLocale,
  fr: frLocale,
  ru: ruLocale,
};

function initializeAnalytics() {
  const GTM_TRACKING_ID = theme.config.GTMID;

  if (isGTMIdValid(GTM_TRACKING_ID)) {
    TagManager.initialize({ gtmId: GTM_TRACKING_ID });
  }

  Analytics.register({
    companyName: theme.name,
    currency: localStorage.getItem('curr'),
    language: localization.getLanguage(),
  });

  return isGTMIdValid(GTM_TRACKING_ID);
}

function usePageTracking() {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    loadAllExperimentsFromCache();
    setInitialized(initializeAnalytics());
  }, []);

  useEffect(() => {
    if (initialized) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'pageview',
        page: {
          url: location?.pathname + location?.search,
          title: location?.pathname.slice(1) + location?.search,
        },
      });
    }
  }, [initialized, location]);

  useEffect(() => {
    const splitpathname = location?.pathname.split('/');
    const pathname = splitpathname[1] !== '' ? splitpathname[1] : 'home';
    Analytics.track('page view', { page: pathname });
  }, [location]);
}

function AnalyticsWrapper(props) {
  usePageTracking();
  return <>{props.children}</>;
}

function App() {
  return (
    <Provider store={configureStore()}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theme}>
            <MaterialThemeProvider theme={materialTheme}>
              <CssBaseline />
              <BrowserRouter>
                <AnalyticsWrapper>
                  <div className="App" style={{ fontFamily: materialTheme.typography.fontFamily }}>
                    <Switch>
                      <Route exact path="/" component={Home} />
                      <Route exact path="/home" component={Home} />
                      <Route exact path="/checkout/:cartId" component={Payment} />
                      <Route exact path="/vcheckout/:cartId" component={ViatorPayment} />
                      <Route exact path="/location/:locationId" component={Location} />
                      <Route exact path="/venue" component={Venue} />
                      <Route exact path="/tour/:tour_id" component={Tour} />
                      <Route exact path="/tour/:tour_id/:tour_name" component={Tour} />
                      <Route exact path="/ticket" component={Ticket} />
                      <Route exact path="/search/:q" component={SearchResults} />
                      <Route exact path="/search" component={SearchResults} />
                      <Route exact path="/contact" component={ContactUs} />
                      <Route exact path="/policy/cookiepolicy" component={CookiePolicy} />
                      <Route exact path="/policy/privacypolicy" component={PrivacyPolicy} />
                      <Route
                        exact
                        path="/policy/termsandconditions"
                        component={TermsAndConditions}
                      />
                      <Route
                        exact
                        path="/order-confirmation/:cartId"
                        component={SimulatedOrderConfirmation}
                      />
                      <Route
                        exact
                        path="/vorder-confirmation/:bookingRef/:partnerBookingRef"
                        component={ViatorOrderConfirmation}
                      />
                      <Route component={NotFoundPage} />
                      <ReduxToastr
                        newestOnTop={false}
                        preventDuplicates
                        position="top-center"
                        transitionIn="fadeIn"
                        transitionOut="fadeOut"
                        closeOnToastrClick
                      />
                    </Switch>
                  </div>
                </AnalyticsWrapper>
              </BrowserRouter>
            </MaterialThemeProvider>
          </ThemeProvider>
        </StyledEngineProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
