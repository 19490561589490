import React from 'react';
import styled from 'styled-components';
import { StyledField } from './Input';
import { FontSizes } from '../../utils/constants';

const Title = styled.div`
  font-family: ${props => props.theme.fonts.primary};
  font-size: ${FontSizes.p};
  margin-bottom: 5px;
  white-space: pre-wrap;
`;

export default function CustomQuestions({ questions }) {
  return (
    <div>
      {questions.map((question, i) => (
        <div key={question.id}>
          <Title>{question.title}</Title>
          <StyledField name={`customQuestionsAnswers.${i}.answer`} />
        </div>
      ))}
    </div>
  );
}
