import { StyleBreakpoints } from '@constants';
import { MenuItem, Select } from '@mui/material';
import Loader from '@mui/material/CircularProgress';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import localization from '../../localization';

const useStyles = makeStyles((theme) => ({
  loaderWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  selectWrapper: {
    minWidth: '500px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '4px',

    [theme.breakpoints.down(StyleBreakpoints.sm)]: {
      minWidth: 0,
    },
  },
  select: {
    width: '100%',
  },
  error: {
    color: 'red',
  },
}));

const CancelReasons = ({
  cancelReasons,
  cancelReasonsError,
  selectedReason,
  handleSelectChange,
}) => {
  const classes = useStyles();

  if (!cancelReasons.length)
    return (
      <div className={classes.loaderWrapper}>
        {cancelReasonsError ? (
          <div className={classes.error}>{cancelReasonsError}</div>
        ) : (
          <Loader size={50} color="primary" />
        )}
      </div>
    );

  return (
    <div className={classes.selectWrapper}>
      <Select className={classes.select} value={selectedReason} onChange={handleSelectChange}>
        <MenuItem value="" disabled>
          {localization.cancelBooking.selectMock}
        </MenuItem>
        {cancelReasons.map((reason, i) => (
          <MenuItem key={i} value={reason.cancellationReasonCode}>
            {reason.cancellationReasonText}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default CancelReasons;
