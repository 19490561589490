import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import theme from 'theme';
import { isBrowser } from 'react-device-detect';
import 'react-multi-carousel/lib/styles.css';
import { Col, Row } from 'reactstrap';
import { FontSizes } from '../../utils/constants';
import { SvgColorHelper } from '../Common';

const useStyles = makeStyles((theme) => ({
  title: {
    margin: '0 25px 2px 18px',
    fontSize: FontSizes.cta,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.02px',
    color: '#000',
    textAlign: 'left',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    UserSelect: 'none',
    fontFamily: theme.typography.fontFamilyBold,
  },
  subText: {
    margin: '2px 25px 16px 18px',
    fontSize: '12px',
    fontWeight: '500',
    textAlign: 'left',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 'normal',
    letterSpacing: '-0.01px',
    color: '#000',
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    UserSelect: 'none',
  },
  icon: {
    WebkitTouchCallout: 'none',
    WebkitUserSelect: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    MsUserSelect: 'none',
    UserSelect: 'none',
  },
  iconContainer: {
    paddingRight: 'unset',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '50px',
  },
  textContainer: {
    paddingLeft: 'unset',
  },
  container: {
    alignItems: 'flex-start',
  },
}));

export default function TrustBuilder({ item }) {
  const classes = useStyles();
  const Icon = SvgColorHelper(item.svgId, item.img, theme.colors.primary);
  return (
    <div style={{ width: isBrowser ? '340px' : 'unset', minHeight: '100px', margin: 'auto' }}>
      <Row className={classes.container}>
        <Col className={classes.iconContainer} md={3} sm={3} xs={2} style={{}}>
          <Icon height="50px" />
        </Col>
        <Col className={classes.textContainer} md={9} sm={9} xs={10} style={{}}>
          <div className={classes.title}>{item.title}</div>
          <div className={classes.subText}>{item.text}</div>
        </Col>
      </Row>
    </div>
  );
}
