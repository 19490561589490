import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import Rating from '@mui/material/Rating';
import React, { useEffect, useRef } from 'react';
import EmptyStar from '../../assets/img/assets/EmptyStar.svg';
import Star from '../../assets/img/assets/Star.svg';
import theme from 'theme';
import './ActivityRating.scss';

const useStyles = makeStyles({
  root: {
    marginLeft: '0px',
    opacity: '0.99!important',
    '& > span': {
      paddingRight: '5px',
      '& > img:active': {
        background: '#000',
      },
    },
  },
  icon: {
    width: '12px',
  },
});

export function fixDecimals(reminder) {
  const decimals = reminder - Math.trunc(reminder);
  if (decimals < 0.06 && decimals !== 0) {
    return Math.trunc(reminder) + 0.06;
  }
  return reminder;
}

function ActivityRating({ tour }) {
  const overallRatingRef = useRef();
  const classes = useStyles();
  const overallRating = fixDecimals(tour.Rating?.toFixed(2));
  const remainder = (overallRating - Math.trunc(overallRating)) * 100 || 100;
  let useSecondaryColorForUIComponents = theme.config.useSecondaryColorForUIComponents;
  let secondaryColor = theme.colors.secondary;

  useEffect(() => {
    const filledStars = overallRatingRef.current?.querySelectorAll('.MuiRating-iconFilled');
    if (filledStars?.length) {
      const lastFilledStar = filledStars[filledStars.length - 1];
      lastFilledStar.style.clipPath = `polygon(0 1%, ${remainder}% 0, ${remainder}% 100%, 0% 100%)`;
    }
  }, [remainder, overallRating]);

  const getCustomRateIcon = (icon) => <img style={{ width: '100%' }} src={icon} alt="Rating" />;

  if (!tour.Rating || overallRating === 0) return null;

  return (
    <div className="ratingContainer">
      <Box ref={overallRatingRef} component="fieldset" borderColor="transparent">
        <Rating
          readOnly
          disabled
          value={overallRating}
          getLabelText={(value) => `${value} Heart${value !== 1 ? 's' : ''}`}
          precision={0.1}
          icon={getCustomRateIcon(Star)}
          emptyIcon={getCustomRateIcon(EmptyStar)}
          classes={{
            root: classes.root,
            icon: classes.icon,
          }}
        />
      </Box>
      <span
        className="overallRating"
        style={{ color: useSecondaryColorForUIComponents ? secondaryColor : '#ffc107' }}
      >
        {overallRating}
      </span>
    </div>
  );
}

export default ActivityRating;
