import { useFormikContext } from 'formik';
import { useEffect, useRef } from 'react';

const usePrevious = value => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export function ScrollToError() {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;
  const wasSubmitting = usePrevious(formik?.isSubmitting);

  useEffect(() => {
    const el = document.querySelector('.Mui-error, [data-error]');
    const target = el?.parentElement ?? el;
    if (target?.scrollIntoView && !submitting && wasSubmitting) {
      target.scrollIntoView();
      target.querySelector(`input`).focus();
    }
  }, [submitting, wasSubmitting]);
  return null;
}
