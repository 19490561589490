import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import { Col, Row } from 'reactstrap';
import localization from '../../../../../localization';
import { FontSizes } from '../../../../../utils/constants';
import { Divider } from '../../../../Common';

const useStyles = makeStyles({
  bodyText: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  thirdHeading: {
    fontWeight: '600',
    margin: '0',
    padding: '0 0 6px',
    fontSize: '18px',
  },
  menuCourse: {
    fontWeight: '600',
  },
  menuText: {
    marginBottom: '5px',
  },
});

export default function SampleMenu({ itinerary }) {
  const classes = useStyles();

  return (
    <>
      <Divider />
      <Row>
        <Col md="2">
          <div className={classes.thirdHeading}>{localization.itinerary.sampleMenu}</div>
        </Col>
        <Col md="10">
          <div>
            {itinerary.foodMenus.map((menuItem, i) => {
              return (
                <div key={i} className={classes.menuItem}>
                  <div className={classes.menuCourse}>
                    {menuItem.course[0].toUpperCase() + menuItem.course.substring(1).toLowerCase()}
                  </div>
                  <div className={classes.menuText}>{menuItem.dishName}</div>
                  <div className={classes.bodyText}>{menuItem.dishDescription}</div>
                </div>
              );
            })}
          </div>
        </Col>
      </Row>
    </>
  );
}
