import { StyleBreakpoints } from '@constants';
import { InputAdornment, Popover } from '@mui/material';
import TextField from '@mui/material/TextField';
import makeStyles from '@mui/styles/makeStyles';
import { CalendarPicker, DesktopDatePicker, PickersDay } from '@mui/x-date-pickers';
import React, { useEffect, useState } from 'react';
import { ReactComponent as Calendar } from '../../../../../assets/img/Calendar.svg';
import localization from '../../../../../localization';
import { FontSizes } from '../../../../../utils/constants';
import { formatDateToDMY, isiOS } from '../../../../../utils/helpers';
import CommonButton from '../../../../Common/Button';

const useStyles = makeStyles((theme) => ({
  calendarInput: {
    width: '100%',
  },
  selectBtnBlock: {
    marginTop: 'auto',
    padding: '0 20px',
    textAlign: 'center',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      width: '256px',
      margin: 'auto auto 0',
    },
  },
  paper: {
    textAlign: 'center',
    borderRadius: '7px',
    marginTop: '8px',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    '& .MuiPickersBasePicker-container': {
      alignItems: 'center',
    },
    '& .MuiPickersCalendarHeader-dayLabel': {
      color: theme.palette.text.primary,
    },
    '& .MuiPickersCalendarHeader-iconButton': {
      backgroundColor: 'transparent',
      color: theme.palette.text.primary,
      border: `1px solid ${theme.palette.text.grey}`,
      borderRadius: '5px',
      paddingTop: '5px',
      paddingBottom: '5px',
      margin: '0 10px',
    },
    '& .MuiPickersCalendarHeader-switchHeader': {
      '& p': {
        fontWeight: '700',
      },
      margin: '20px 0',
    },

    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      borderRadius: '0px',
      backgroundColor: theme.palette.background.dark,
      position: 'absolute',
      left: '0!important',
      top: 'auto!important',
      right: 0,
      bottom: 0,
      marginLeft: 'auto',
      marginRight: 'auto',
      maxWidth: '100vw',
      color: '#fff',
      '& .MuiPickersBasePicker-container': {
        backgroundColor: theme.palette.background.dark,
      },
      '& .MuiPickersCalendarHeader-root': {
        color: '#fff',
        '& .MuiPickersCalendarHeader-labelContainer > button': {
          color: '#ffff',
        },
        '& .MuiPickersArrowSwitcher-root > button': {
          color: '#fff',
        },
      },
      '& .MuiPickersCalendarHeader-iconButton': {
        backgroundColor: 'transparent',
        color: '#fff',
      },
      '& .MuiDayPicker-weekDayLabel': {
        color: '#fff',
      },
    },
  },
  inputRoot: {
    fontSize: isiOS() ? '16px' : '14px',
    '& label.Mui-focused': {
      color: theme.palette.text.grey,
    },

    '& label.MuiInputLabel-root': {
      color: theme.palette.text.grey,
    },

    '& label': {
      color: theme.palette.text.grey,
    },
  },
  calendarIcon: {
    color: theme.palette.primary.main,
  },
  calendarDay: {
    color: '#333333',
    width: '36px',
    height: '36px',
    margin: '0 2px',
    padding: '0',
    fontSize: FontSizes.cta,
    fontWeight: 'bold',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      color: '#ffffff',
      backgroundColor: 'transparent',
    },
  },
  calendarDaySelected: {
    color: 'white',
    width: '36px',
    height: '36px',
    margin: '0 2px',
    padding: '0',
    fontSize: FontSizes.cta,
    fontWeight: '500',
    background: `${theme.palette.primary.main}!important`,
  },
  calendarDayDisabled: {
    color: '#c1c1c1',
    width: '36px',
    height: '36px',
    margin: '0 2px',
    padding: '0',
    fontSize: FontSizes.cta,
    fontWeight: '300',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      color: '#606060!important',
      backgroundColor: 'transparent',
    },
  },
}));

export const CalendarIcon = () => {
  const classes = useStyles();

  return (
    <div className={classes.calendarIcon}>
      <Calendar style={{ width: '15px', height: '15px' }} />
    </div>
  );
};

const Datepicker = ({
  selectedDate,
  updateDate,
  anchorEl,
  nextAnchor,
  setAnchorEl,
  dateRef,
  isMobileView,
  availabilityCursor,
  onClose,
}) => {
  const classes = useStyles();
  const open = Boolean(anchorEl === dateRef.current);
  const id = open ? 'date-popover' : undefined;

  const dateHash = (date) => `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`;
  const [availabilityIndex, setAvailabilityIndex] = useState(new Set());
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);

  const { next: loadMoreAvailabilites, lastLoadedDate, availabilities } = availabilityCursor;

  useEffect(() => {
    if (availabilities.length) updateDate(availabilities[0]);

    setAvailabilityIndex(new Set(availabilities.map(dateHash)));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availabilities]);

  const handleClick = () => {
    setAnchorEl(dateRef.current);
  };

  const handleClose = () => {
    if (onClose) onClose();
    setAnchorEl(null);
  };

  const handleOnClose = () => {
    if (!isMobileView) {
      handleClose();
    }
  };

  const handleSelect = () => {
    if (isMobileView) {
      setAnchorEl(nextAnchor);
    } else {
      handleClose();
    }
  };

  const handleMonthChange = async (date) => {
    const lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    if (lastDayOfMonth >= lastLoadedDate) {
      await loadMoreAvailabilites();
    }
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 200);
    });
  };

  const shouldDisableDate = (date) => {
    return !availabilityIndex.has(dateHash(date));
  };

  const renderDay = (day, selectedDate, pickersDayProps) => {
    const iconsStyle = pickersDayProps.selected
      ? classes.calendarDaySelected
      : pickersDayProps.disabled
      ? classes.calendarDayDisabled
      : classes.calendarDay;

    return (
      <div
        style={{
          position: 'relative',
        }}
      >
        <PickersDay {...pickersDayProps} day={day} className={iconsStyle} />
      </div>
    );
  };

  const selectedDateTextRepresentation = (selectedDate) => {
    const result = formatDateToDMY(selectedDate);
    return selectedDate ? result : localization.tours.availabilitySearch.chooseDate;
  };

  return (
    <>
      {isMobileView ? (
        <>
          <TextField
            ref={dateRef}
            label={localization.tours.availabilitySearch.date}
            value={selectedDateTextRepresentation(selectedDate)}
            InputProps={{
              readOnly: true,
              endAdornment: (
                <InputAdornment position="end">
                  <CalendarIcon />
                </InputAdornment>
              ),
            }}
            onChange={updateDate}
            variant="outlined"
            color="primary"
            aria-describedby={id}
            onClick={handleClick}
            className={classes.calendarInput}
            classes={{
              root: classes.inputRoot,
            }}
          />
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            classes={{
              paper: classes.paper,
            }}
          >
            <CalendarPicker
              showToolbar={false}
              inputFormat="dd/MM/yyyy"
              id="date-picker-inline"
              label={localization.tours.availabilitySearch.date}
              onChange={updateDate}
              renderDay={renderDay}
              onClose={() => handleOnClose()}
              onMonthChange={handleMonthChange}
              shouldDisableDate={shouldDisableDate}
              style={{ margin: '0' }}
              renderInput={(params) => <TextField {...params} />}
              date={selectedDate}
            />
            <div className={classes.selectBtnBlock}>
              <CommonButton
                style={{
                  width: '100%',
                  margin: '25px 0',
                  fontSize: FontSizes.cta,
                  borderRadius: '8.5px',
                  height: '37px',
                }}
                onClick={handleSelect}
              >
                {localization.tours.availabilitySearch.select}
              </CommonButton>
            </div>
          </Popover>
        </>
      ) : (
        <DesktopDatePicker
          open={isDatePickerOpen}
          onOpen={() => setIsDatePickerOpen(true)}
          onClose={() => setIsDatePickerOpen(false)}
          label={localization.tours.availabilitySearch.date}
          value={selectedDate}
          inputFormat="dd/MM/yyyy"
          color="primary"
          aria-describedby={id}
          onChange={updateDate}
          className={classes.calendarInput}
          classes={{
            root: `${classes.inputRoot} ${classes.paper}`,
          }}
          shouldDisableDate={shouldDisableDate}
          renderInput={(params) => (
            <TextField
              {...params}
              classes={{
                root: classes.inputRoot,
              }}
              onClick={() => setIsDatePickerOpen(true)}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIcon />
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      )}
    </>
  );
};

export default Datepicker;
