import makeStyles from '@mui/styles/makeStyles';
import { isNil, prop } from 'lodash/fp';
import React from 'react';
import { GoChevronDown } from 'react-icons/go';
import { Col, Collapse, Row } from 'reactstrap';
import localization from '../../../localization';
import { Analytics } from '../../../utils/analytics';
import { FontSizes, StyleBreakpoints } from '../../../utils/constants';
import {
  isCardedOptions,
  isDarkOptions,
  isExpandableOptions,
  isLightOptions,
} from '../../../utils/experimentConstants';
import {
  getLangName,
  getParticipantsTextShort,
  getPriceWithCurrencySymbol,
} from '../../../utils/helpers';
import { Bold, Divider, Regular } from '../../Common';
import CommonButton from '../../Common/Button';
import { LoaderSmall as Loader } from '../../Loader';
import { getParticipantsAmount } from './common/helpers';

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: FontSizes.p,
    fontWeight: '700',
    textAlign: 'justify',
    marginTop: '9px',
    color: theme.palette.primary.main,
    fontFamily: theme.typography.fontFamilyBold,
  },
  availability: {
    marginRight: '21px',
    marginBottom: '5px',
    cursor: 'pointer',
    fontWeight: '700',
    fontSize: FontSizes.cta,
    borderRadius: '6px',
    padding: '6px 11px',
    minWidth: '85px',
    textAlign: 'center',
  },
  inactiveAvailability: {
    border: 'solid 1.5px rgba(140, 140, 140, 0.29)',
    color: '#979797',
  },
  activeAvailability: {
    border: 'solid 1.5px #ff3f15',
    background: '#ff3f15',
    color: '#FFFFFF',
  },
  availableTimeslots: {
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: FontSizes.p,
  },
  selectInstructions: {
    fontSize: FontSizes.cta,
  },
  description: {
    margin: '0 0 10px',
    padding: '0',
    textAlign: 'justify',
  },
  totalPrice: {
    fontFamily: theme.typography.fontFamilyBold,
  },
  priceTitle: {
    fontFamily: theme.typography.fontFamilyBold,
  },
  selectButton: {
    fontSize: '18px!important',
    borderRadius: '10px!important',
    marginBottom: '2px!important',
    height: '50px!important',
    width: '100%!important',
    fontWeight: 'bold!important',
    marginTop: 'auto!important',
  },
  dividerContainer: {
    marginLeft: '15px',
    width: '100%',
  },
  secondHeading: {
    margin: '0 0 10px',
    padding: '0',
  },
  option: isCardedOptions()
    ? {
        borderRadius: '6px',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 50%)',
        backgroundColor: isLightOptions() ? '#f9f9f9' : '#010e1d',
        color: isDarkOptions() ? '#fff' : '#202020',
        padding: '20px 5px',
        margin: '0 0 30px!important',
        [theme.breakpoints.up(StyleBreakpoints.sm)]: {
          padding: '26px 25px 26px', // 20px 0px 15px 20px
        },
      }
    : {},
  optionNumber: {
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'uppercase',
    margin: '13px 0',
  },
  optionDesc: isExpandableOptions()
    ? {
        cursor: 'pointer',
      }
    : {},
  expandButton: {
    [theme.breakpoints.up(StyleBreakpoints.sm)]: {
      width: 'auto!important',
    },
  },
  expandIcon: {
    fontSize: '30px',
  },
  collapseBlock: {
    '&.collapsing': {
      marginTop: '-10px',
    },
  },
  priceBlock: isCardedOptions()
    ? {
        height: '100%',
        display: 'flex',
        flexFlow: 'column',
        alignItems: 'flex-start',
        [theme.breakpoints.up(StyleBreakpoints.sm)]: {
          alignItems: 'flex-end',
          paddingTop: '20px',
        },
      }
    : {},
}));

function presentTimeslot({ hours, minutes }) {
  if (localization.getLanguage() === 'fr') {
    const hour = String(hours).length === 1 ? '0' + hours : hours;
    const minute = String(minutes).length === 1 ? '0' + minutes : minutes;
    return `${hour}h${minute}`;
  }
  const suffix = hours >= 12 ? 'PM' : 'AM';
  const hour = hours % 12 === 0 ? 12 : hours % 12;
  return `${hour}:${minutes.toString().padStart(2, '0')} ${suffix}`;
}

function RenderOneAvailability(operationalHours) {
  const classes = useStyles();
  return (
    <div className="opening-hours">
      <span className="title-small">
        {localization.payment.tourOptions.operationalHours}:&nbsp;
      </span>
      <span className={classes.text}>
        {`${presentTimeslot(operationalHours.start)} - ${presentTimeslot(operationalHours.end)}`}
      </span>
    </div>
  );
}

function LanguageList(languages) {
  const classes = useStyles();

  if (!languages.length) return null;

  const uniqueLanguages = languages.filter((val, index, self) => self.indexOf(val) === index);

  return (
    <div className="language">
      <span className="title-small">{localization.payment.tourOptions.language}:&nbsp;</span>
      <span className={classes.text}>
        {uniqueLanguages
          .map((lCode) => localization.language[lCode] || getLangName(lCode))
          .join(', ')}
      </span>
    </div>
  );
}

function PriceBreakdownPerParticipants({ breakdown }) {
  const classes = useStyles();
  return (
    <div className="participants-breakdown">
      <span className="title-small">
        {localization.tours.availabilitySearch.participants}:&nbsp;
      </span>
      <span className={classes.text}>
        {breakdown
          .filter((p) => p.type === 'participant')
          .map(getParticipantsTextShort)
          .join(', ')}
      </span>
    </div>
  );
}

function Price(
  { total, totalWithoutDiscount, breakdown },
  handleOptionSelect,
  isBookingInProgress,
  minimumParticipants,
  maximumParticipants,
  participants,
  isOpen,
  setIsOpen
) {
  const classes = useStyles();
  return (
    <div className={`${classes.priceBlock} total-price`}>
      <div className={`${classes.priceTitle} total-price-title`}>
        {localization.payment.tourOptions.totalPrice}
      </div>
      {Number(total) !== Number(totalWithoutDiscount) && (
        <div className="total-price-was-value">
          {getPriceWithCurrencySymbol(totalWithoutDiscount)}
        </div>
      )}
      <div className={`${classes.totalPrice} total-price-value`}>
        {getPriceWithCurrencySymbol(total)}
      </div>
      {isNil(minimumParticipants) === false &&
      getParticipantsAmount(participants) < minimumParticipants ? (
        <span className="amount-of-participants-message">
          <Regular>
            {localization.errors.aMinimumOf} <Bold>{minimumParticipants}</Bold>{' '}
            {localization.errors.participantsRequired}
          </Regular>
        </span>
      ) : isNil(maximumParticipants) === false &&
        getParticipantsAmount(participants) > maximumParticipants ? (
        <span className="amount-of-participants-message">
          <Regular>
            {localization.errors.aMaximumOf} <Bold>{maximumParticipants}</Bold>{' '}
            {localization.errors.participantsRequired}
          </Regular>
        </span>
      ) : (
        <>
          <CommonButton
            className={`select-button ${classes.selectButton} ${!isOpen && classes.expandButton}`}
            onClick={isOpen ? handleOptionSelect : () => setIsOpen(!isOpen)}
          >
            {isOpen ? (
              isBookingInProgress ? (
                <Loader small />
              ) : (
                localization.tours.availabilitySearch.select
              )
            ) : (
              <GoChevronDown className={classes.expandIcon} />
            )}
          </CommonButton>
        </>
      )}
    </div>
  );
}

function Timeslots({ timeslots, selectedTimeslot, onTimeslotSelect, extraTrackingProperties }) {
  const classes = useStyles();
  return (
    <div className="availabilities">
      {timeslots.map((timeslot) => (
        <div
          className={`${classes.availability} ${
            timeslot === selectedTimeslot
              ? classes.activeAvailability
              : classes.inactiveAvailability
          }`}
          key={`${timeslot.hours}${timeslot.minutes}`}
          onClick={() => {
            Analytics.track('timeslot chosen', extraTrackingProperties);
            onTimeslotSelect(timeslot);
          }}
        >
          {presentTimeslot(timeslot)}
        </div>
      ))}
    </div>
  );
}

function checkIfTimeslotsAbsent(timeslots) {
  return timeslots.length < 2 && !timeslots[0]?.hours && !timeslots[0]?.minutes;
}

export default function BookingOption({
  option: {
    title,
    languages,
    timeslots,
    operationalHours,
    price,
    minimumParticipants,
    maximumParticipants,
    errors,
    selectedTimeslot,
  },
  date,
  participants,
  onTimeslotSelect,
  isBookingInProgress,
  extraTrackingProperties,
  handleOptionSelect,
  productOptionDescription,
  description,
  number,
  isOpen,
  setIsOpen,
}) {
  const classes = useStyles();
  const priceBreakdown = selectedTimeslot
    ? timeslots.find((slot) => slot.time === selectedTimeslot).price
    : price;
  return (
    <>
      {isCardedOptions() && (
        <h2 className={classes.optionNumber}>
          {localization.tours.availabilitySearch.option} {number}
        </h2>
      )}
      <Row className={`${classes.option} option`}>
        <Col className="data" md={9}>
          <div className={classes.optionDesc} onClick={() => setIsOpen(!isOpen)}>
            <div className={`second-heading ${classes.secondHeading}`}>{title}</div>
            {description ? (
              <div className={classes.description}>
                {description.replace(/<\/?[^>]+(>|$)/g, ' ')}
              </div>
            ) : null}
          </div>
          <Collapse className={`${classes.collapseBlock}`} isOpen={isOpen}>
            {LanguageList(languages)}
            {priceBreakdown && PriceBreakdownPerParticipants(priceBreakdown)}
            {!checkIfTimeslotsAbsent(timeslots.map(prop('time'))) && (
              <>
                <div className={classes.availableTimeslots}>
                  {localization.payment.tourOptions.availableTimeslots}
                </div>
                {operationalHours
                  ? RenderOneAvailability(operationalHours)
                  : Timeslots({
                      timeslots: timeslots.map(prop('time')),
                      selectedTimeslot,
                      onTimeslotSelect,
                      extraTrackingProperties,
                    })}
                <div className={classes.selectTimeslot}>
                  *{localization.payment.tourOptions.selectTimeslot}
                </div>
              </>
            )}
          </Collapse>
        </Col>
        <Col className="price" md={3}>
          {!priceBreakdown.error &&
            Price(
              priceBreakdown,
              handleOptionSelect,
              isBookingInProgress,
              minimumParticipants,
              maximumParticipants,
              participants,
              isOpen,
              setIsOpen
            )}
          {priceBreakdown.error && (
            <div className="total-price">
              <div className="total-price-title">{priceBreakdown.error}</div>
            </div>
          )}
        </Col>
        {/*errors && <Error>{errors[0]}</Error>*/}
        {!isCardedOptions() && (
          <div className={classes.dividerContainer}>
            <Divider />
          </div>
        )}
      </Row>
    </>
  );
}
