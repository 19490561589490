export const FETCH_LOCATION_LIST = 'FETCH_LOCATION_LIST';
export const FETCH_LOCATION_LIST_INIT = 'FETCH_LOCATION_LIST_INIT';
export const FETCH_LOCATION_LIST_SUCCESS = 'FETCH_LOCATION_LIST_SUCCESS';

export const FETCH_LOCATIONS = 'FETCH_LOCATIONS';

export const FETCH_LOCATION = 'FETCH_LOCATION';
export const FETCH_LOCATION_INIT = 'FETCH_LOCATION_INIT';
export const FETCH_LOCATION_SUCCESS = 'FETCH_LOCATION_SUCCESS';

export const FETCH_NEARBY_LOCATIONS = 'FETCH_NEARBY_LOCATIONS';
export const FETCH_NEARBY_LOCATIONS_INIT = 'FETCH_NEARBY_LOCATIONS_INIT';
export const FETCH_NEARBY_LOCATIONS_SUCCESS = 'FETCH_NEARBY_LOCATIONS_SUCCESS';

export const FETCH_TOURS_BY_LOCATION = 'FETCH_TOURS_BY_LOCATION';
export const FETCH_TOURS_BY_LOCATION_INIT = 'FETCH_TOURS_BY_LOCATION_INIT';
export const FETCH_TOURS_BY_LOCATION_SUCCESS = 'FETCH_TOURS_BY_LOCATION_SUCCESS';

export const SELECT_LOCATION = 'SELECT_LOCATION';

export const FETCH_ATTRACTIONS_BY_DESTINATION = 'FETCH_ATTRACTIONS_BY_DESTINATION';
export const FETCH_ATTRACTIONS_BY_DESTINATION_INIT = 'FETCH_ATTRACTIONS_BY_DESTINATION_INIT';
export const FETCH_ATTRACTIONS_BY_DESTINATION_SUCCESS = 'FETCH_ATTRACTIONS_BY_DESTINATION_SUCCESS';