import { Box, Slider } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Analytics } from '../../../utils/analytics';
import { currencySign, getCurrency } from '../../../utils/helpers';

const isInr = getCurrency() === 'INR';
const PRICE_RANGE_MAX = isInr ? 25000 : 500;
const PRICE_RANGE_MIN = 0;
const PRICE_RANGE_MAX_VALUE = 9999999;

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  margin: {
    height: '100%',
    width: '92%',
    marginTop: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const CustomSlider = withStyles({
  root: {
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    backgroundColor: '#fff',
    border: '0px solid',
    margin: '6px 12px 26px 33px',
    marginTop: 0,
    marginLeft: 0,
    marginRight: -10,
    boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)',
    '&:focus, &:hover, &$active': {
      boxShadow: '0 2px 3px 0 rgba(0, 0, 0, 0.5)',
    },
  },
  valueLabel: {
    left: 'calc(-50% - 0px)',
    marginTop: '10px',
    fontSize: '11px',
    fontWeight: '700',
    backgroundColor: 'transparent',
    '& *': {
      background: 'transparent',
      color: '#000',
    },
  },
  track: {
    height: 5,
    borderRadius: 4,
  },
  rail: {
    height: 5,
    borderRadius: 4,
  },
})(Slider);

function pricingTitle(value) {
  return value === PRICE_RANGE_MAX
    ? currencySign(getCurrency()) + `${value}+`
    : currencySign(getCurrency()) + `${value}`;
}

export default function PriceRangeFilter({ filters, updateFilters }) {
  const classes = useStyles();
  const [priceRange, setPriceRange] = useState({
    min: filters?.price?.min || PRICE_RANGE_MIN,
    max: filters?.price?.max || PRICE_RANGE_MAX,
  });

  useEffect(() => {
    if (!filters.price) {
      setPriceRange({
        min: PRICE_RANGE_MIN,
        max: PRICE_RANGE_MAX,
      });
    }
  }, [filters]);

  const minDistance = isInr ? 5000 : 50;
  const handleChange = (event, newValue, activeThumb) => {
    if (!Array.isArray(newValue)) {
      return;
    }
    const min = newValue[0];
    const max = newValue[1];
    if (newValue[1] - newValue[0] < minDistance) {
      if (activeThumb === 0) {
        const clamped = Math.min(min, PRICE_RANGE_MAX - minDistance);
        setPriceRange({ min: clamped, max: clamped + minDistance });
      } else {
        const clamped = Math.max(max, minDistance);
        setPriceRange({ min: clamped - minDistance, max: clamped });
      }
    } else {
      setPriceRange({ min, max });
    }
  };

  const handlePriceSelection = (evt, value) => {
    updateFilters({
      price: {
        min: value[0],
        max: value[1] === PRICE_RANGE_MAX ? PRICE_RANGE_MAX_VALUE : value[1],
      },
    });

    Analytics.track('price range selected', {
      'display area': 'location filter sidebar',
      'min price': value[0],
      'max price': value[1],
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.margin}>
        <Box>
          <CustomSlider
            value={[priceRange.min, priceRange.max]}
            onChange={handleChange}
            onChangeCommitted={handlePriceSelection}
            valueLabelDisplay="on"
            color="primary"
            getAriaValueText={pricingTitle}
            valueLabelFormat={pricingTitle}
            min={PRICE_RANGE_MIN}
            max={PRICE_RANGE_MAX}
          />
        </Box>
      </div>
    </div>
  );
}
