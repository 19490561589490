import { sendEnquiryEmail } from '../../api/contact';
import { toastr } from 'react-redux-toastr';
import { call, takeLatest } from 'redux-saga/effects';
import { SEND_ENQUIRY } from './actionTypes'

function* sendEnquirySaga({ payload, history }) {
    try {
        yield call(sendEnquiryEmail, payload);
        history.push('/home')
        return toastr.success("Enquiry sent successfully", "Thank you for your enquiry, we will contact you soon", { timeOut: 5000 });
    } catch (error) {
        console.error(error);
        return toastr.error("Something went wrong", "Our development team will be dispatched to investigate this issue", { timeOut: 5000 });
    }
}

export function* watchContactSaga() {
    yield takeLatest(SEND_ENQUIRY, sendEnquirySaga)
}
