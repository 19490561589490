import queryString from 'query-string';
import React from 'react';
import { Col, Row } from 'reactstrap';
import api from '../../api/index.js';
import TourCard from '../TourCard';
import localization from '../../localization/index.js';

class TourCards extends React.Component {
  constructor(props) {
    super(props);

    const values = queryString.parse(window.location.search);
    this.state = {
      items: [],
      isLoaded: false,
      values: values,
      location_id: props.location_id,
      page: 0
    };
  }

  componentDidMount() {
    if (!this.state.location_id) {
      api.getFeaturedTours(
        function(err, items) {
          var newItems = [];
          items.data.forEach(tour => {
            if (tour.from_price !== '0.00') {
              newItems.push(tour);
            }
          });
          if (this.state.items.length < 1) {
            this.setState({
              isLoaded: true,
              items: newItems.splice(0, 6)
            });
          }
        }.bind(this)
      );
    } else {
      api.getLocationTours(
        this.state.location_id,
        0,
        function(err, items) {
          var newItems = [];
          items.data.forEach(tour => {
            if (tour.from_price !== '0.00') {
              newItems.push(tour);
            }
          });

          var more = newItems.length == 12;
          if (this.state.items.length < 1) {
            this.setState({
              isLoaded: true,
              items: newItems,
              more: more
            });
          }
        }.bind(this)
      );
    }
  }

  changePage() {
    api.getLocationTours(
      this.state.location_id,
      this.state.page + 1,
      function(err, items) {
        var newItems = [];
        items.data.forEach(tour => {
          if (tour.from_price !== '0.00') {
            newItems.push(tour);
          }
        });

        var more = newItems.length == 12;
        this.setState({
          items: this.state.items.concat(newItems),
          page: 1 + this.state.page,
          more: more
        });
      }.bind(this)
    );
  }

  render() {
    const itemList = this.state.items.map(item => (
      <Col xs={12} sm={12} md={6} large={4} xl={4} style={{ width: '100%' }}>
        <TourCard item={item} />
      </Col>
    ));

    if (!this.state.isLoaded) {
      return null;
    }

    return (
      <div>
        <Row>{itemList}</Row>
        {this.state.more ? (
          <div
            onClick={this.changePage.bind(this)}
            style={{
              backgroundColor: '#0d4c75',
              borderRadius: 18,
              color: 'white',
              fontWeight: 'bold',
              width: 180,
              padding: 8,
              margin: 'auto',
              marginTop: 18,
              fontSize: 18,
              cursor: 'pointer',
              marginBottom: '42px'
            }}
          >
            {localization.common.seeMore}
          </div>
        ) : null}
      </div>
    );
  }
}

export default TourCards;
