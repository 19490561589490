import React from 'react';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ContactForm from '../../components/ContactForm'
import styled from 'styled-components';



const MobileWide = styled.div`
    margin:10vh;
    @media(max-width: 375px){
        margin : unset;
        margin-top:10vh;
        margin-bottom : 10vh;
    }
  `

class ContactUs extends React.Component {

    render() {
        return (
            <div className="ContactUs" style={{ fontFamily: 'DiodrumRegular' }}>
                <Header />
                    <MobileWide>
                        <ContactForm/>
                    </MobileWide>
                <Footer />
            </div>

        );
    }
}

export default ContactUs;
