import React, { useEffect, useState } from 'react';
import { Col, Row } from 'reactstrap';
import theme from 'theme';
import { getMap } from '../../../api/viator';
import localization from '../../../localization';
import { TextPrimaryNormal } from '../../Common';
import MapboxMap from '../../Map';

const initialMapData = {
  lat: null,
  long: null,
  description: null,
  open: false,
};

const ViatorMap = ({ providerReference }) => {
  const [expanded, setExpanded] = useState(theme.config.showMapsByDefault);
  const [mapDetails, setMapDetails] = useState(initialMapData);

  useEffect(() => {
    if (!mapDetails.open) {
      getMap(providerReference).then((map) => {
        setMapDetails({
          lat: map.geometry.location.lat,
          long: map.geometry.location.lng,
          description: map.name,
          open: true,
        });
      });
    }
  });

  if (!mapDetails) return null;

  return (
    <Row style={{ textAlign: 'left', lineHeight: '24px' }}>
      <Col md="2">
        <div className="third-heading"> {localization.tours.prepareForActivity.meetingPoint} </div>
      </Col>
      <Col md="10">
        <div className="map-description">
          {mapDetails.description}
          <div>
            <TextPrimaryNormal onClick={() => setExpanded(!expanded)}>
              {expanded ? localization.common.hideMap : localization.common.showOnMap}
            </TextPrimaryNormal>
          </div>
        </div>
        {mapDetails.open && expanded && (
          <div className="map-wrapper">
            <MapboxMap
              description={mapDetails.description}
              lat={mapDetails.lat}
              long={mapDetails.long}
              width="100%"
            />
          </div>
        )}
      </Col>
    </Row>
  );
};

export default ViatorMap;
