import axios from 'axios';
import { apiRoot } from 'env-config';

const apiRootSendMail = `${apiRoot}/api/v1/send-mail`;

export const sendConfirmationEmail = ({
  to,
  bookingDetails,
  orderConfirmationTranslations = undefined
}) => {
  axios.post(`${apiRootSendMail}/confirmation`, {
    to,
    bookingDetails,
    orderConfirmationTranslations
  });
};

export const sendCancellationEmail = ({
  to,
  cancellationDetails,
  cancellationTranslations = undefined
}) => {
  axios.post(`${apiRootSendMail}/cancellation`, {
    to,
    cancellationDetails,
    cancellationTranslations
  });
};
