import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PoweredBy from '../../components/PoweredBy';
import localization from '../../localization';

import { StyleBreakpoints } from '@constants';
import NotFoundImg from '../../assets/img/404.svg';
import { FontSizes } from '../../utils/constants';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #f5f7fa;
`;

const Body = styled.div`
  display: flex;
  flex-grow: 1;
  min-height: 40%;
  align-items: center;
  justify-content: center;

    @media (max-width: ${StyleBreakpoints.sm}px) {
        flex-direction: column;
    }
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 50%;

    @media(max-width: ${StyleBreakpoints.sm}px) {
        width: 100%;
        justify-content: center;
    }
`;

const Image = styled.img`
  margin: 0 44px 0 0;
  width: 40%;

    @media(max-width: ${StyleBreakpoints.sm}px) {
        margin: 0 0 24px 0 !important;
        width: 60%;
    }
`;

const Info = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 50%;

  @media (max-width: ${StyleBreakpoints.sm}px) {
    width: 100%;
    align-items: center;
  }
`;

const DetailsHeader = styled.div`
  font-size: 32px;
  font-family: MontserratBold;
  color: #353535;

    @media(max-width: ${StyleBreakpoints.sm}px) {
        font-size: 24px;
    }
`;

const Details = styled.div`
  color: #989898;
  font-size: ${FontSizes.cta};

    @media(max-width: ${StyleBreakpoints.sm}px) {
        font-size: 12px;
    }
`;

const GoBackLink = styled.span`
  cursor: pointer;
  color: #1a73e8;
`;

function NotFoundPage() {
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <Container>
      <Header />
      <Body>
        <ImageContainer>
          <Image src={NotFoundImg} alt="" style={{ marginRight: 44 }} />
        </ImageContainer>
        <Info>
          <DetailsHeader>{localization.notFound.header}</DetailsHeader>
          <Details>
            <span>{localization.notFound.details}</span>
            <GoBackLink onClick={handleGoBack}>{localization.notFound.goBack}</GoBackLink>
          </Details>
        </Info>
      </Body>
      <PoweredBy />
      <Footer />
    </Container>
  );
}

export default NotFoundPage;
