import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import PaymentForm from '../../components/TourPaymentForm';
import Loader from '../../components/Loader';
import localization from '../../localization';
import { connect } from 'react-redux';
import { FETCH_CART } from '../../store/checkout/actionTypes';
import { fetchTsBookingDetails, fetchGygBooking } from '../../api/booking';
import { PaymentModule as TsPaymentModule } from '../../components/TourPaymentForm/Payment/TsPaymentModule';
import { PaymentModule as GygPaymentModule } from '../../components/TourPaymentForm/Payment/GygPaymentModule';
import { setCurrencyNoRerender } from '../../currency';

const WrappedPaymentForm = (booking) => (paymentModuleArgs) =>
  <PaymentForm booking={booking} {...paymentModuleArgs} />;

function Payment({ match, showModal }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [booking, setBooking] = useState(null);

  const bookingFetcher =
    match.params.cartId.length === 32 ? fetchGygBooking : fetchTsBookingDetails;
  const PaymentModule = match.params.cartId.length === 32 ? GygPaymentModule : TsPaymentModule;

  useEffect(() => {
    setIsLoaded(false);
    bookingFetcher({ id: match.params.cartId, language: localization.getLanguage() }).then(
      (booking) => {
        setBooking(booking);
        //setCurrencyNoRerender(booking.currency);
        setIsLoaded(true);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div className="Tour">
      <Header />
      <div style={{ maxWidth: '1220px', width: '100%', margin: '50px auto 0', textAlign: 'left' }}>
        <PaymentModule
          bookingId={match.params.cartId}
          showModal={showModal}
          FormContainer={WrappedPaymentForm(booking)}
        />
        <div style={{ marginTop: '50px' }}></div>
      </div>

      <Footer />
    </div>
  );
}
export default connect(
  (state) => ({
    isLoaded: state.cart.isLoaded,
    cart: state.cart.cart,
    shoppingCarthash: state.cart.shoppingCartHash,
  }),
  (dispatch) => ({
    fetchCart: (cartHash) => dispatch({ type: FETCH_CART, payload: cartHash }),
    showModal: (message) => toastr.error('Payment error', message),
  })
)(Payment);
