import moment from 'moment';
import React from 'react';
import { useDispatch } from 'react-redux';
import { bookOption } from '../../../api/booking';
import { fetchAvailabilities, fetchCalculatedPricings, fetchPricings } from '../../../api/tour';
import localization from '../../../localization';
import { FALLBACK_FETCH } from '../../../store/fallbackTours/types';
import EffectfulBookingWidget from './EffectfulBookingWidget';

function mapGygManifestItem({ name, min_age, max_age }) {
  return { id: name, displayName: name, minAge: min_age, maxAge: max_age };
}

export default function GygBookingWidget(args) {
  const { tourId, languages, language, tour, extraTrackingProperties } = args;
  const dispatch = useDispatch();

  const fetchFallback = () => {
    dispatch({
      type: FALLBACK_FETCH,
      payload: {
        locationIds: tour.Locations.map(l => l.location_id),
        language: localization.getLanguage()
      }
    });
  }

  return (
    <EffectfulBookingWidget
      language={language}
      languages={languages}
      tourId={tourId}
      fetchAvailabilities={args =>
        fetchAvailabilities(args).then(({ all }) => all.map(dateStr => new Date(dateStr)))
      }
      fetchParticipants={args =>
        fetchPricings(args)
          .then(pricings => pricings.manifest.map(mapGygManifestItem))
          .catch(fetchFallback)
      }
      fetchSeason={() => Promise.resolve([])}
      fetchFallback={fetchFallback}
      extraTrackingProperties={extraTrackingProperties}
      fetchCalculatedPricings={fetchCalculatedPricings}
      bookOption={({ selectedDate, option, ...bookingData }) =>
        bookOption({ ...bookingData, selectedDate: moment(selectedDate).format('YYYY-MM-DD') })
      }
    />
  );
}
