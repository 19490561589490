import {
    BOOK_OPTION,
    BOOK_OPTION_INIT,
    BOOK_OPTION_ERROR,
    BOOK_OPTION_SUCCESS,
    FETCH_AVAILABILITIES,
    FETCH_AVAILABILITIES_INIT,
    FETCH_AVAILABILITIES_SUCCESS,
} from './actionTypes';
import { toastr } from 'react-redux-toastr';
import { SET_CART_HASH } from '../checkout/actionTypes';
import { bookOption } from '../../api/booking';
import { call, put, takeLatest } from 'redux-saga/effects';
import { pricingCategories } from '../../lib/tour';
import { fetchAvailabilities } from '../../api/tour';

function* bookOptionSuccess(optionId, shoppingCartHash, history) {
    yield put({ type: BOOK_OPTION_SUCCESS, payload: { optionId } });
    yield put({ type: SET_CART_HASH, payload: shoppingCartHash });
    history.push(`/checkout/${shoppingCartHash}`)
}

function* fetchAvailabilitiesSaga({ payload }) {
    try {
        yield put({ type: FETCH_AVAILABILITIES_INIT });
        const availabilities = yield call(fetchAvailabilities, payload);
        yield put({ type: FETCH_AVAILABILITIES_SUCCESS, payload: availabilities });
    } catch (error) {
        console.warn(error);
        toastr.error(
            'Something went wrong',
            'Application encountered major error and stoped working. Please, reload the page',
            {
                timeOut: 4800
            }
        );
    }
}

function* bookOptionSaga({ payload }) {
    try {
        const {
            title,
            duration,
            duration_unit,
            cond_language,
            option_id,
            selectedAvailability,
            priceDetails,
            tourTitle,
            history,
            thumbnail,
            booking_parameter,
            image
        } = payload;
        yield put({ type: BOOK_OPTION_INIT, payload: { optionId: option_id } });
        const gygDetails = ({
            option_id,
            datetime: selectedAvailability.start_time,
            price: priceDetails.total,
            categories: pricingCategories(priceDetails)
        });
        const additionalData = {
            selectedAvailability,
            priceDetails,
            title,
            duration_unit,
            cond_language,
            duration,
            tourTitle,
            thumbnail,
            booking_parameter,
            image
        }
        const { shopping_cart_hash: shoppingCartHash } = yield call(
            bookOption,
            { option_id, additionalData, gygDetails }
        );
        yield call(bookOptionSuccess, option_id, shoppingCartHash, history);
    } catch (e) {
        yield put({
            type: BOOK_OPTION_ERROR,
            payload: {
                optionId: payload.option_id,
                error: e.response.data.error[0].errorMessage
            }
        })
    }
}

export function* watchTourOptions() {
    yield takeLatest(BOOK_OPTION, bookOptionSaga);
    yield takeLatest(FETCH_AVAILABILITIES, fetchAvailabilitiesSaga);
}