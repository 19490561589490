import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import localization from '../../../../../localization';
import { FontSizes } from '../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  itineraryItem: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',

    '&:not(:last-child)': {
      marginBottom: '15px',
    },
  },
  itineraryPoint: {
    marginLeft: '-29px',
    position: 'absolute',
    top: '8px',
    width: '7px',
    height: '7px',
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
  },
  unstructuredBodyText: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
    whiteSpace: 'pre-line',
  },
  bottomDetails: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: FontSizes.cta,
    color: '#63666a',
    marginBottom: '10px',
  },
  titleStop: {
    fontSize: FontSizes.p,
    marginBottom: '10px',
  },
  pointsOfInterestContainer: {
    marginBottom: '10px',
  },
  pointOfInterest: {
    fontSize: FontSizes.cta,
    '&:not(:last-child)': {
      marginBottom: '5px',
    },
  },
}));

export default function UnstructuredItinerary({ itinerary, itineraryLocations, formatDuration }) {
  const classes = useStyles();

  return (
    <div className={classes.itineraryItem}>
      <div className={classes.itineraryPoint} />
      <div className={classes.titleStop}>{localization.tours.experience.highlights}:</div>
      <div className={classes.pointsOfInterestContainer}>
        {itinerary.pointOfInterestLocations?.map((unstructuredLocation, i) => {
          return (
            <div key={i} className={classes.pointOfInterest}>
              {
                itineraryLocations.find(
                  (location) => location.reference === unstructuredLocation.location.ref
                )?.name
              }
            </div>
          );
        })}
      </div>
      <div className={classes.unstructuredBodyText}>
        {itinerary.unstructuredItinerary
          ? itinerary.unstructuredItinerary
          : itinerary.unstructuredDescription}
      </div>
      <div className={classes.bottomDetails}>
        {itinerary.duration.fixedDurationInMinutes &&
          formatDuration(itinerary.duration.fixedDurationInMinutes)}
      </div>
    </div>
  );
}
