import React from 'react';
import styled from 'styled-components';
import { ReactComponent as TickedIcon } from '../../assets/img/assets/GreenTick.svg';
import { ReactComponent as EmptyIcon } from '../../assets/img/assets/Oval.svg';
import { ReactComponent as ChevronIconSvg } from '../../assets/img/GreenArrow.svg';
import { hex2rgb, modifyHexColor, rgb2str } from '../../utils/styles';
import { StyleBreakpoints } from '@constants';

export const Bold = styled.span`
  font-family: ${props => props.theme.fonts.bold};
`;
export const Regular = styled.span`
  font-family: ${props => props.theme.fonts.regular};
`;
const modifyColor = x => props => modifyHexColor(props.theme.colors.primary, x);
export const Button = styled.button`
  background-color: ${props => props.theme.colors.primary};
  margin-bottom: 1rem;
  cursor: pointer;
  font-family: ${props => props.theme.fonts.primary};
  white-space: nowrap;
  color: white;
  border: 1px ${modifyColor(-20)};
  border-radius: 29.5px;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  :hover {
    background-color: ${modifyColor(-20)};
  }
  :focus {
    box-shadow: 0 0 0 0.2rem ${props => rgb2str({ ...hex2rgb(props.theme.colors.primary), a: 0.5 })};
  }
`;

export const ButtonLarge = styled(Button)`
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  height: 56px;
  max-width: 240px;
  border-radius : 5px;
  @media (max-width: ${StyleBreakpoints.xs}px) {
    max-width: unset;
    width: 100%;
  }
`;

export const ButtonLargeWithHover = styled(Button)`
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  line-height: 1.5;
  height: 56px;
  max-width: 240px;
   border-radius: 0.25rem;
   text-decoration: none;
   text-transform: none;
   font-weight: 400;

   color: #ffffff;
   background-color: #0f1928;
  border: unset;
  @media (max-width: ${StyleBreakpoints.xs}px) {
    max-width: unset;
    width: 100%;
  }
  :hover {
    background-color: ${modifyHexColor('#0F1928', -40)};
  }
  :focus {
    box-shadow: 0 0 0 0.2rem ${props => rgb2str({ ...hex2rgb('#0F1928'), a: 0.5 })};
  }
`;

export const ButtonMedium = styled(Button)`
  border-radius: 5px;
  font-weight: normal;
  width: 180px;
  height: 40px;
  padding: 8px;
  margin: 18px auto 42px;
  font-size: 18px;
`;

export const Text = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: -0.04px;
  color: #333333;
`;

export const ChevronIcon = styled(ChevronIconSvg)`
  g g g #Oval {
    fill: ${props => props.theme.colors.primary};
  }
`;

export const EmptyCheckboxIcon = styled(EmptyIcon)`
  cursor: pointer;
`;
export const TickedCheckboxIcon = styled(TickedIcon)`
  cursor: pointer;
  width: 26px;
  height: 26px;
  g g g {
    fill: ${props => props.theme.colors.secondary};
  }
`;

export const SubText = styled.div`
  font-family: ${props => props.theme.fonts.regular};
  font-size: 13px;
  text-shadow: 3px 3px 10px rgba(0, 0, 0, 1);
`;

export const CardTopImage = styled.div`
  min-height: 239px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${props => props.image});
  background-position: center;
  border-radius: 10px 10px 0 0; 
  @media (max-width: ${StyleBreakpoints.sm}px) {
    background-size: cover;
  }
`;

export const TextPrimaryNormal = styled.div`
  color: ${props => props.theme.colors.primary};
  cursor: pointer;
`;

export const PaymentMethodIcon = styled.div`
  width: 48px;
  height: 32px;
  margin-right: 4px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  background-image: url(${props => props.logo});
`;

export const PaymentMethodRow = styled.div`
  display: flex;
  align-items: flex-start;
`;
export const PaymentMethods = ({ methods }) => (
  <PaymentMethodRow>
    {methods.map((x, i) => (
      <PaymentMethodIcon logo={x} key={`payment-method-${i}`} />
    ))}
  </PaymentMethodRow>
);

export const SvgColorHelper = (id, svg, color) => styled(svg)`
  #${id} {
    fill: ${color};
  }
`;

export const Divider = styled.hr`
  border-color: #d7d7d7;
`;