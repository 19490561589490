import { StyleBreakpoints } from '@constants';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Fab from '@mui/material/Fab';
import withStyles from '@mui/styles/withStyles';
import { isArray, isNil } from 'lodash';
import queryString from 'query-string';
import React, { Fragment } from 'react';
import { isMobile } from 'react-device-detect';
import { connect } from 'react-redux';
import styled from 'styled-components';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import FilterSidebar from '../../components/Location/Filters/FilterSidebar';
import SortBy from '../../components/Location/Filters/SortBy';
import TopTags from '../../components/Location/Filters/TopTags';
import LocationNav from '../../components/Location/Nav';
import NoResultsFound from '../../components/Location/NoResultsFound';
import ResultsCount from '../../components/Location/ResultsCount';
import Tours from '../../components/Location/Tours';
import PoweredBy from '../../components/PoweredBy';
import ViewAllActivities from '../../components/ViewAllActivities';
import localization from '../../localization';
import {
  FETCH_LOCATION,
  FETCH_NEARBY_LOCATIONS,
  FETCH_TOURS_BY_LOCATION,
} from '../../store/locations/actionTypes';
import { Analytics } from '../../utils/analytics';

const Wrapper = styled.div`
  width: 95vw;
  margin: auto;
  overflow: hidden;
`;

export const FILTER_SIDEBAR_BREAKPOINT = 1000;
const GYG_FLAGS = ['mobile_voucher', 'pick_up', 'private', 'skip_line', 'wheelchair'];

function generateEmptyFilters() {
  return {
    rating: null,
    price: null,
    categories: [],
    selectedTags: [],
    attractions: [],
    duration: [],
    flags: [],
    sortOrder: 'plow',
  };
}

const styles = (theme) => ({
  toursContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    position: 'relative',
    marginTop: '20px',
  },
  filtersBlock: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    zIndex: '999',
    '& > div': {
      borderRadius: '0',
    },
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      position: 'static',
      flex: '0 1 25%',
      height: 'fit-content',
      '& > div': {
        borderRadius: '6px',
      },
    },
  },
  toursBlock: {
    flex: '0 1 100%',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      flex: '0 1 100%',
      marginLeft: '40px',
    },
  },
  mobileFilterButton: {
    display: 'block',
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      display: 'none',
    },
  },
  toursHeading: {
    marginTop: '-46px',
  },
});

function formDataForFilter(categories, attractions, flags = GYG_FLAGS, tags) {
  return {
    categories:
      categories?.map((c) => ({
        id: c.id,
        title: c.displayText,
      })) ?? [],
    attractions:
      attractions?.map((a) => ({
        id: a.seoId,
        title: a.title,
      })) ?? [],
    flags:
      flags?.map((f) => ({
        id: f,
        title: f.replace(/_/g, ' '),
      })) ?? [],
    tags: tags,
  };
}

class Location extends React.Component {
  constructor(props) {
    super(props);
    const { location, locations, tours, isLoaded } = props;
    tours.page = 1;
    const qsValues = queryString.parse(window.location.search);
    let filters = generateEmptyFilters();
    let activeFiltersBackup = generateEmptyFilters();
    if (qsValues.attractionId) {
      filters.attractions.push(parseInt(qsValues.attractionId));
      activeFiltersBackup.attractions.push(parseInt(qsValues.attractionId));
    }
    this.state = {
      location,
      locations,
      tours,
      isLoaded,
      qsValues,
      locationId: props.match.params.locationId,
      shouldDisplayFilters: false,
      filters: filters,
      activeFiltersBackup: activeFiltersBackup,
      attractions: [],
    };

    this.toggleFiltersBar = this.toggleFiltersBar.bind(this);
    this.handleCategorySelection = this.handleCategorySelection.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.updateFilters = this.updateFilters.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.handleFlagSelection = this.handleFlagSelection.bind(this);
    this.resetFiltersBackup = this.resetFiltersBackup.bind(this);
    this.createFiltersBackup = this.createFiltersBackup.bind(this);
    this.restoreFiltersFromBackup = this.restoreFiltersFromBackup.bind(this);
    this.handleAttractionSelection = this.handleAttractionSelection.bind(this);
    this.handleTagSelection = this.handleTagSelection.bind(this);
    this.handleDurationSelection = this.handleDurationSelection.bind(this);
    this.handleSortOrderSelection = this.handleSortOrderSelection.bind(this);
  }

  componentWillReceiveProps({
    location,
    locations,
    tours,
    isLoaded,
    match,
    categories,
    attractions,
  }) {
    this.setState({
      location,
      locations,
      tours,
      isLoaded,
      tags: location?.orderedTags ?? [],
      attractions,
      dataForFilter: formDataForFilter(
        categories,
        this.state.attractions || locations?.list,
        [],
        location?.orderedTags || []
      ),
    });

    const locationId = match.params.locationId;
    const seoId = match.params.seoId;
    if (locationId !== this.state.locationId) {
      this.setState({ locationId });
      this.fetchData(locationId);
      this.clearFilters();
    }
  }

  handleScroll(event) {
    this.setState({
      scrollToTopVisible: true,
    });
  }

  componentDidMount() {
    this.resize = this.resize.bind(this);
    window.addEventListener('resize', this.resize);
    this.fetchData(this.state.locationId);
    this.setState({ screenWidth: window.innerWidth });
    window.addEventListener('scroll', this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  fetchData(locationId) {
    this.props.fetchLocation({ locationId, filters: this.state.filters });
  }

  resize() {
    this.setState({ screenWidth: window.innerWidth });
  }

  applyFilters() {
    this.props.fetchLocationTours({
      id: this.state.location_id || this.state.location?.location_id,
      page: 0,
      filters: this.state.filters,
    });
    this.resetFiltersBackup();
  }

  toggleFiltersBar() {
    this.setState({ shouldDisplayFilters: !this.state.shouldDisplayFilters });
  }

  createFiltersBackup() {
    this.setState({ activeFiltersBackup: this.state.filters });
  }

  restoreFiltersFromBackup() {
    this.setState(
      {
        filters: this.state.activeFiltersBackup,
      },
      this.resetFiltersBackup
    );
  }

  resetFiltersBackup() {
    this.setState({
      activeFiltersBackup: generateEmptyFilters(),
    });
  }

  clearFilters() {
    const haveActiveFilters = Object.values(this.state.filters).some(
      (v) => (!isArray(v) && !isNil(v)) || v?.length
    );
    if (haveActiveFilters) {
      this.setState(
        {
          filters: generateEmptyFilters(),
          activeFiltersBackup: generateEmptyFilters(),
        },
        this.applyFilters
      );
    }
  }

  updateFilters(newFiltersData, shouldApply = false) {
    const prevFiltersData = this.state.filters;
    const isBigScreen = this.state.screenWidth > StyleBreakpoints.md;

    if (isBigScreen || shouldApply) {
      this.setState(
        {
          filters: {
            ...prevFiltersData,
            ...newFiltersData,
          },
        },
        this.applyFilters
      );
    } else {
      this.setState({
        filters: {
          ...prevFiltersData,
          ...newFiltersData,
        },
      });
    }
  }

  handleFlagSelection({ id, title }) {
    const { filters } = this.state;

    const newSelectedFlags = filters.flags.filter(
      (selectedId) => String(selectedId) !== String(id)
    );

    if (newSelectedFlags.length === filters.flags.length) {
      newSelectedFlags.push(id);

      Analytics.track('select flag', {
        'flag name': title,
      });
    } else {
      Analytics.track('deselect flag', {
        'flag name': title,
      });
    }

    this.updateFilters({ flags: newSelectedFlags });
  }

  handleCategorySelection({ id, title }, { isTopCategories } = { isTopCategories: false }) {
    const { filters } = this.state;
    const index = this.props.categories.findIndex((c) => String(c.id) === String(id));
    const displayArea = isTopCategories ? 'location category menu' : 'location filter sidebar';
    const shouldApply = isTopCategories;

    const newSelectedCategories = filters.categories.filter(
      (selectedId) => String(selectedId) !== String(id)
    );

    if (newSelectedCategories.length === filters.categories.length) {
      newSelectedCategories.push(id);

      Analytics.track('select category', {
        'category index': index,
        'display area': displayArea,
        'category name': title,
      });
    } else {
      Analytics.track('deselect category', {
        'category index': index,
        'display area': displayArea,
        'category name': title,
      });
    }

    this.updateFilters({ categories: newSelectedCategories }, shouldApply);
  }

  handleTagSelection({ tagId }) {
    const { filters } = this.state;
    let oldTags = filters.selectedTags || [];
    let newSelectedTags = oldTags;
    if (oldTags.indexOf(tagId) > -1) {
      //remove it instead
      newSelectedTags = oldTags.filter((x) => x != tagId);
    } else {
      newSelectedTags.push(tagId);
    }
    this.updateFilters({ selectedTags: newSelectedTags }, true);
  }

  handleDurationSelection({ duration }) {
    const { filters } = this.state;
    let oldDurations = filters.duration || [];
    let newSelectedDuration = oldDurations;
    if (oldDurations.indexOf(duration) > -1) {
      //remove it instead
      newSelectedDuration = oldDurations.filter((x) => x != duration);
    } else {
      newSelectedDuration.push(duration);
    }
    this.updateFilters({ duration: newSelectedDuration }, true);
  }

  handleSortOrderSelection(sortOrder) {
    this.updateFilters({ sortOrder: sortOrder }, true);
  }

  handleAttractionSelection({ seoId, title }) {
    const { filters } = this.state;

    const newSelectedAttractions = filters.attractions.filter(
      (selectedId) => String(selectedId) !== String(seoId)
    );

    if (newSelectedAttractions.length === filters.attractions.length) {
      newSelectedAttractions.push(seoId);

      Analytics.track('select attraction', {
        'attraction name': title,
      });
    } else {
      Analytics.track('deselect attraction', {
        'attraction name': title,
      });
    }

    this.updateFilters({ attractions: newSelectedAttractions });
  }

  render() {
    var { location, tours, isLoaded, screenWidth, shouldDisplayFilters, filters, dataForFilter } =
      this.state;
    const classes = this.props.classes;

    const isBigScreen = screenWidth > StyleBreakpoints.md;
    const shouldDisplayFiltersOnSmallScreen =
      screenWidth <= StyleBreakpoints.md && shouldDisplayFilters;

    const haveActiveFilters = Object.values(filters).some(
      (v) => (!isArray(v) && !isNil(v)) || v?.length
    );

    if (!isLoaded) {
      return <Loader />;
    }

    //TODO: Random hotfix for campsited. Remove.
    //const image = screenWidth <= 800 ? location.Image.replace('-93.jpg', '-156.jpg') : location.Image;

    const ToursList = () => {
      if (tours.list.length) {
        return (
          <Tours
            tours={tours.list}
            isLoaded={tours.isLoaded}
            canLoadMore={tours.canLoadMore}
            onLoadMore={() =>
              this.props.fetchLocationTours({
                id: location.location_id,
                page: tours.page + 1,
                filters,
              })
            }
            extraTrackingProperties={{
              'display area': 'top activities',
              page: 'location',
              'page destination name': this.state.location.name,
              'page destination id': this.state.location.location_id,
            }}
          />
        );
      }
      if (!tours.isLoaded) {
        return <Loader />;
      } else if (isLoaded) {
        return <NoResultsFound />;
      }
    };

    return (
      <Fragment>
        <Header />

        <Wrapper>
          <LocationNav name={location.name} />
          <Fab
            color="primary"
            style={{
              position: 'fixed',
              right: 15,
              bottom: 15,
              zIndex: 900,
              display: this.state.scrollToTopVisible ? 'block' : 'none',
            }}
            onClick={() => {
              document.body.scrollTop = 0; // For Safari
              document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
            }}
          >
            <ArrowUpwardIcon />
          </Fab>
          {Boolean(isMobile) && (
            <TopTags
              allTags={this.state.tags}
              selectedTags={filters.selectedTags}
              handleTagSelection={this.handleTagSelection}
              isBigScreen={isBigScreen}
              toggleFiltersBar={this.toggleFiltersBar}
              createFiltersBackup={this.createFiltersBackup}
            />
          )}

          <div className={classes.toursContainer}>
            {(isBigScreen || shouldDisplayFiltersOnSmallScreen) && (
              <div className={classes.filtersBlock}>
                <FilterSidebar
                  filters={filters}
                  dataForFilter={dataForFilter}
                  clearFilters={this.clearFilters}
                  updateFilters={this.updateFilters}
                  applyFilters={this.applyFilters}
                  handleClose={this.toggleFiltersBar}
                  restoreFiltersFromBackup={this.restoreFiltersFromBackup}
                  isBigScreen={isBigScreen}
                  selectedTags={filters.selectedTags}
                  selectedDurations={filters.duration}
                  handleCategorySelection={this.handleCategorySelection}
                  handleTagSelection={this.handleTagSelection}
                  handleDurationSelection={this.handleDurationSelection}
                  handleFlagSelection={this.handleFlagSelection}
                  handleAttractionSelection={this.handleAttractionSelection}
                  attractions={this.state.attractions}
                />
              </div>
            )}
            <div className={classes.toursBlock}>
              <ViewAllActivities withBoldText={false} term={this.state.location.english_name}>
                <SortBy handleSortOrderSelection={this.handleSortOrderSelection} />
              </ViewAllActivities>
              <ResultsCount
                isLoaded={tours.isLoaded}
                total={tours.total}
                clearFilters={this.clearFilters}
                haveActiveFilters={haveActiveFilters}
              />
              {ToursList()}
            </div>
          </div>
        </Wrapper>
        <PoweredBy />
        <Footer />
      </Fragment>
    );
  }
}

/*
 {(isBigScreen || shouldDisplayFiltersOnSmallScreen) && (
              <div className={classes.filtersBlock}>
                <FilterSidebar
                  filters={filters}
                  dataForFilter={dataForFilter}
                  clearFilters={this.clearFilters}
                  updateFilters={this.updateFilters}
                  applyFilters={this.applyFilters}
                  handleClose={this.toggleFiltersBar}
                  restoreFiltersFromBackup={this.restoreFiltersFromBackup}
                  isBigScreen={isBigScreen}
                  handleCategorySelection={this.handleCategorySelection}
                  handleFlagSelection={this.handleFlagSelection}
                  handleAttractionSelection={this.handleAttractionSelection}
                />
              </div>
            )}*/

const LocationWithStyles = withStyles(styles, { withTheme: true })(Location);

export default connect(
  (state) => ({
    locations: state.location.nearbyLocations,
    tours: state.location.tours,
    location: state.location.location,
    isLoaded: state.location.isLoaded,
    categories: state.location?.location?.orderedCategories,
    attractions: state.location.viatorAttractions.list,
  }),
  (dispatch) => ({
    fetchLocation: ({ locationId, filters }) => {
      dispatch({
        type: FETCH_LOCATION,
        payload: {
          locationId,
          language: localization.getLanguage(),
          filters,
        },
      });
    },
    fetchLocationTours: ({ id, page, filters }) => {
      dispatch({
        type: FETCH_TOURS_BY_LOCATION,
        payload: {
          page,
          locationId: id,
          language: localization.getLanguage(),
          perPage: 16,
          filters,
        },
      });
    },
    fetchNearbyLocations: ({ page, locationId }) =>
      dispatch({
        type: FETCH_NEARBY_LOCATIONS,
        payload: { page, locationId, language: localization.getLanguage(), perPage: 9 },
      }),
  })
)(LocationWithStyles);
