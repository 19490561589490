import React from 'react';

import { renderToStaticMarkup } from 'react-dom/server'
import { Row, Col, CardImgOverlay, Card, CardBody, CardImg, CardSubtitle, CardTitle, CardFooter, CardLink, CardText, Button, Badge } from "reactstrap";
import api from '../../api/index.js'
import icon from '../../assets/img/GreenArrow.svg'
import { NavLink } from 'react-router-dom'
import Map from '../../components/Map'
import './style.scss';
var QRCode = require('qrcode.react');

class TicketCard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            isLoaded: false,
            item: props.item,
            manifest: props.manifest
        }
    }

    render() {

        return (
            <Card className="activity-card">
                <CardImg top width="100%" height="289px" src={'FIXME'} alt="Card cap" />
                <CardBody className="content">
                    <div className='content-title'>
                        {
                            this.state.item.tour_name_long.split(' ').length > 8
                                ? this.state.item.tour_name_long.split(' ').slice(0, 8).join(' ') + '...'
                                : this.state.item.tour_name_long
                        }
                    </div>
                    <div className="content-option-title">
                        {this.state.item.Description.summary.substring(0, 120)}
                    </div>
                </CardBody>

            </Card>
        )
    }

}

export default TicketCard