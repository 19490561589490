import React from 'react'
import {ReactComponent as PinSVG} from '../../assets/img/Pin.svg'

const pinStyle = {
  fill: '#d00',
  stroke: 'none',
  cursor: 'pointer'
};


export const Pin = ({title}) => (
  <PinSVG style={pinStyle} title={title} />
);