import { call, takeLatest, put } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { fetchToursWithFilters as fetchTours, fetchFeaturedTours, fetchHighlightedTours, PER_PAGE } from '../../api/tours';
import {
    SET_FILTERS,
    FETCH_FILTER_OPTIONS,
} from '../filters/actionTypes';
import {
    FETCH_TOURS,
    FETCH_TOURS_RESTORED,
    FETCH_TOURS_INIT,
    FETCH_TOURS_SUCCESS,
    FETCH_FEATURED_TOURS,
    FETCH_HIGHLIGHTED_TOURS,
    SET_SCROLL,
    SEARCH_BY_QUERY,
    FETCH_FEATURED_TOURS_INIT,
    FETCH_FEATURED_TOURS_SUCCESS,
    FETCH_HIGHLIGHTED_TOURS_INIT,
    FETCH_HIGHLIGHTED_TOURS_SUCCESS
} from '../tours/actionTypes';

function* fetchToursSaga({ payload: { filters, resetTours = false } }) {
    try {
        yield put({
            type: FETCH_TOURS_INIT,
            payload: {
                resetTours
            }
        });
        const tours = yield call(fetchTours, filters);
        yield put({ type: FETCH_TOURS_SUCCESS, payload: { tours, perPage: filters.perPage } });
        yield put({ type: SET_FILTERS, payload: filters });
    } catch (error) {
        console.log(error);
        toastr.error(
            'Something went wrong',
            'Application encountered major error and stoped working. Please, reload the page',
            {
                timeOut: 4800
            }
        );
    }
}

function* fetchFeaturedToursSaga({ payload: { page, perPage, language } }) {
    try {

        yield put({
            type: FETCH_FEATURED_TOURS_INIT,
            payload: {
                shouldResetList: page === 0
            }
        });
        const { data, metadata } = yield call(fetchFeaturedTours, { page, perPage, language });
        yield put({
            type: FETCH_FEATURED_TOURS_SUCCESS,
            payload: {
                data,
                canLoadMore: metadata.total - ((page + 1) * perPage) > 0,
                page: page
            }
        });
    } catch (error) {
        console.warn(error);
        toastr.error(
            'Something went wrong',
            'Application encountered major error and stoped working. Please, reload the page',
            {
                timeOut: 4800
            }
        );
    }
}


function* fetchHighlightedToursSaga({ payload: { page, perPage, language, name } }) {
    try {

        yield put({
            type: FETCH_HIGHLIGHTED_TOURS_INIT,
            payload: {
                shouldResetList: page === 0
            }
        });
        const { data, metadata } = yield call(fetchHighlightedTours, { page, perPage, language });
        yield put({
            type: FETCH_HIGHLIGHTED_TOURS_SUCCESS,
            payload: {
                data,
                canLoadMore: metadata.total - ((page + 1) * perPage) > 0,
                page: page
            }
        });
    } catch (error) {
        console.warn(error);
        toastr.error(
            'Something went wrong',
            'Application encountered major error and stoped working. Please, reload the page',
            {
                timeOut: 4800
            }
        );
    }
}

function* fetchToursRestoredSaga({ payload }) {
  
    try {
        yield put({
            type: FETCH_TOURS_INIT,
            payload: {
                resetTours: true
            }
        });
        const { page = 0, perPage: perPagePrevious = PER_PAGE, primaryQuery } = payload.filters;
        const perPage = (page + 1) * perPagePrevious;
        yield put({ type: FETCH_FILTER_OPTIONS, payload: primaryQuery })
        const tours = yield call(fetchTours, { ...payload.filters, perPage, page: 0 });
        yield put({ type: FETCH_TOURS_SUCCESS, payload: { tours, perPage } });
        yield put({ type: SET_FILTERS, payload: payload.filters });
        yield put({ type: SET_SCROLL, payload: payload.scroll });
    } catch (error) {
        console.log(error);
        console.warn(error);
        toastr.error(
            'Something went wrong',
            'Application encountered major error and stoped working. Please, reload the page',
            {
                timeOut: 4800
            }
        );
    }
}

export function* watchTours() {
    yield takeLatest(FETCH_TOURS_RESTORED, fetchToursRestoredSaga);
    yield takeLatest(FETCH_TOURS, fetchToursSaga);
    yield takeLatest(FETCH_FEATURED_TOURS, fetchFeaturedToursSaga);
    yield takeLatest(FETCH_HIGHLIGHTED_TOURS, fetchHighlightedToursSaga);
}