import { StyleBreakpoints } from '@constants';
import { Tabs } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import theme from 'theme';
import { ReactComponent as MobileFiltersIcon } from '../../../assets/img/FilterWL_Mobile.svg';
import { SvgColorHelper } from '../../Common';
import categoryIconsById from './Icons';
import Tag from './Tag';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up(StyleBreakpoints.md)]: {
      margin: '40px 0 40px',
    },
  },
  scroller: {
    padding: '13px 0',
  },
  root: {
    position: 'relative',
  },
  scrollButtons: {
    position: 'absolute',
    height: '100%',
    zIndex: '1',
    width: '80px',
    opacity: '1',
    '&:first-child': {
      left: '0px',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        background: 'linear-gradient( to left, rgb(255 255 255 / 0%), rgb(255 255 255 / 90%) 63% )',
      },
    },
    '&:not(:first-child)': {
      right: '0px',
      '&:after': {
        content: '""',
        position: 'absolute',
        top: '0px',
        left: '0px',
        right: '0px',
        bottom: '0px',
        background:
          'linear-gradient( to right, rgb(255 255 255 / 0%), rgb(255 255 255 / 90%) 63% )',
      },
    },
    '& > svg': {
      fontSize: '38px',
      padding: '3px',
      borderRadius: '50%',
      background: `#fff`,
      boxShadow: '0 2px 4px 0 rgb(0 0 0 / 50%)',
      zIndex: '1',
    },
    '& > span': {
      display: 'none',
    },
  },
  filtersButton: {
    minWidth: '70px',
    height: '52px',
    padding: '8px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: '5px',
    marginRight: '15px',
    marginTop: '13px',
    float: 'left',
  },
}));

export default function TopCategories({
  allTags,
  selectedTags,
  handleTagSelection,
  isBigScreen,
  toggleFiltersBar,
  createFiltersBackup,
}) {
  const classes = useStyles();

  const MobileFiltersButton = SvgColorHelper(
    'MobileFiltersIcon',
    MobileFiltersIcon,
    theme.colors.primary
  );

  return (
    <div className={classes.wrapper}>
      {!isBigScreen && (
        <MobileFiltersButton
          className={classes.filtersButton}
          onClick={() => {
            createFiltersBackup();
            toggleFiltersBar();
          }}
        />
      )}
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        value={false}
        classes={{
          scroller: classes.scroller,
          scrollButtons: classes.scrollButtons,
          root: classes.root,
        }}
      >
        {allTags.map((tag) => (
          <Tag
            key={tag.tagId}
            name={tag.displayText}
            icon={categoryIconsById[String(tag.tagId)]}
            isSelected={selectedTags?.some((id) => id === tag.tagId)}
            handleTagSelection={() => handleTagSelection(tag)}
          />
        ))}
      </Tabs>
    </div>
  );
}
