import axios from 'axios';
import { apiRoot } from 'env-config';

import { getCurrency } from '../utils/helpers';

export const searchLocations = ({ query, language, currency = getCurrency() }) =>
  axios
    .get(`${apiRoot}/api/v1/location`, {
      params: {
        query,
        language,
        page: 0,
        perPage: 100
      }
    })
    .then(res => res.data.data);

export const fetchLocations = ({ query, page, perPage, type, global, language }) =>
  axios
    .get(`${apiRoot}/api/v1/location`, {
      params: { query, page, perPage, type, global, language }
    })
    .then(res => res.data.data);

function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

export const fetchLocation = ({ locationId, language }) =>
  axios
    .get(
      isNumeric(locationId)
        ? `${apiRoot}/api/v1/location/${locationId}`
        : `${apiRoot}/api/v1/location/${locationId}/byname`,
      {
        params: { language }
      }
    )
    .then(res => res.data.data);

export const fetchGlobalAttractions = ({ page, perPage, language }) =>
  axios
    .get(`${apiRoot}/api/v1/location/global/attractions`, {
      params: { page, perPage, language }
    })
    .then(res => res.data.data);

export const fetchGlobalCities = ({ page, perPage, language }) =>
  axios
    .get(`${apiRoot}/api/v1/location/global/cities`, {
      params: { page, perPage: 1000, language }
    })
    .then(res => res.data.data);

export const fetchNearbyLocations = ({ locationId, page, perPage, language }) =>
  axios
    .get(`${apiRoot}/api/v1/location/${locationId}/nearby`, {
      params: { page, perPage, language }
    })
    .then(res => res.data.data);
