// import LocalizedStrings from 'react-localization';
// import en from './en';
// import fr from './fr';

const currency = {
  eur: '€',
  inr: '₹',
  usd: '$',
  cad: '$',
  rsd: 'din',
};

const init = () => {};

export const setCurrency = (curr) => {
  localStorage.setItem('curr', curr);
  //Note this fixes an issue where a user had a specified a query param lang then interacts with the lang control
  window.history.pushState({}, document.title, window.location.pathname);
  window.location.reload();
};

export const setCurrencyNoRerender = (curr) => {
  localStorage.setItem('curr', curr);
};

//init();
export default currency;
