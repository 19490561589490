import { StyleBreakpoints } from '@constants';
import { Button as MUIButton, Card, CardContent } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { fetchViatorBookingDetails } from '../../api/booking';
import checkMarkGreenIcon from '../../assets/img/checkMarkGreen.svg';
import CloseIcon from '../../assets/img/close.svg';
import Button from '../../components/Common/Button';
import { CircularAnimation } from '../../components/Common/CircularAnimation';
import CustomizableModal from '../../components/Common/CustomizableModal';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import { LoaderVerySmall } from '../../components/Loader';
import MapContainer from '../../components/Map';
import {
  getMappedTourDetails,
  getParticipantsString,
} from '../../components/ViatorTourPaymentForm/Payment/paymentModuleHelper';
import localization from '../../localization';
import {
  CANCEL_BOOKING,
  FETCH_CANCEL_QUOTE,
  FETCH_CANCEL_REASONS,
} from '../../store/cancel_booking/actionTypes';
import { FontSizes } from '../../utils/constants';
import { formatPrice, getPriceWithCurrencySymbol } from '../../utils/helpers';
import { splitForBullets, stripeTags } from '../../utils/TextUtils';
import CancelReasons from './cancelReasons';
import RefundDetails from './refundDetails';
import './SimulatedOrderConfirmation.scss';
import TourDetails from './tourDetails';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    maxWidth: '1220px',
    width: '100%',
    margin: '50px auto 0',
    textAlign: 'left',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      margin: '10px auto 0',
    },
  },
  container: {
    padding: '24px',
    display: 'block', //Change to Grid
    columnGap: '4.5%',
    rowGap: '0',
    gridTemplateColumns: '56.33% 39.17%',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      gridTemplateColumns: '100%',
      columnGap: '0',
      rowGap: '0',
    },
  },
  rightBlock: {
    gridColumn: '2 / 3',
    gridRow: '1 / 3',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      gridColumn: '1 / 2',
      gridRow: '2 / 3',
    },
  },
  introBlock: {
    gridColumn: '1 / 2',
    gridRow: '1 / 2',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      gridRow: '1 / 2',
      marginBottom: '30px',
    },
  },
  mainInfoBlock: {
    gridColumn: '1 / 2',
    gridRow: '2 / 3',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      gridRow: '3 / 4',
    },
  },
  circularAnimation: {
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      display: 'flex',
      justifyContent: 'center',
    },
  },
  firstHeading: {
    fontSize: FontSizes.h1,
    color: '#424242',
    fontFamily: theme.typography.fontFamilyBold,
    margin: '20px 0 30px',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      fontSize: '28px',
    },
  },
  firstHeadingPart: {
    marginBottom: '-2px',
    display: 'block',
  },
  secondHeading: {
    fontSize: FontSizes.h2,
    fontFamily: theme.typography.fontFamilyBold,
    color: '#333333',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      fontSize: '18px',
    },
  },
  detailsHeading: {
    fontSize: '15px',
    fontFamily: theme.typography.fontFamilyBold,
    color: '#282828',
  },
  bookRef: {
    fontWeight: 'normal',
    fontFamily: theme.typography.fontFamily,
    overflowWrap: 'break-word',
    display: 'inline-block',
    marginTop: '5px',
    width: 'fit-content',
    maxWidth: '100%',
  },
  importantText: {
    fontSize: '18px',
    color: '#333333',
    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      fontSize: '15px',
    },
  },
  inclusionsList: {
    listStyle: 'none',
    padding: '0px',
    '& li': {
      lineHeight: '1.65',
    },
  },
  exclusionsList: {
    listStyle: 'none',
    padding: '0px',
    '& li': {
      lineHeight: '1.65',
    },
  },
  listIcon: {
    width: '20px',
    height: '20px',
    margin: '0 16px',
  },
  listItem: {
    listStyle: 'none',
    margin: '10px 0',
    fontSize: FontSizes.cta,
    color: '#282828',
    display: 'flex',
    alignItems: 'center',
  },
  importantDetailsBlock: {
    margin: '40px 0 30px',
  },
  closeButton: {
    width: '50px',
    height: '50px',
    color: '#aeaeae',
    fontSize: '25px',
    position: 'absolute',
    top: '11px',
    right: '11px',
  },
  viewTicketsText: {
    color: '#ffffff',
    textTransform: 'capitalize',
  },
  mapBlock: {
    width: '100%',
  },
  buttonWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
  },
  cancelButton: {
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  modal: {
    alignSelf: 'center',
    justifySelf: 'center',
    maxWidth: '500px',

    [theme.breakpoints.down(StyleBreakpoints.md)]: {
      maxWidth: '950px',
    },
  },
  cancelBookingText: {
    fontSize: '12px',
    color: 'gray',
    textAlign: 'center',
    marginTop: '8px',
  },
  tourDetailsInfo: {
    '& > div': {
      margin: '12px 0',
    },
  },
}));

const cancelQuoteStatus = {
  CANCELLABLE: 'CANCELLABLE',
  CANCELLED: 'CANCELLED',
  NOT_CANCELLABLE: 'NOT_CANCELLABLE',
};

const cancelStatusText = {
  CANCELLABLE: 'This booking is available to be cancelled',
  CANCELLED: 'This booking has already been cancelled',
  NOT_CANCELLABLE:
    "This booking cannot be cancelled (because the product's start time was in the past)",
};

function OrderConfirmation({
  match,
  getCancelReasons,
  cancelReasons,
  cancelReasonsError,
  cancelQuote,
  getCancelQuote,
  cancelBooking,
  isCancelLoading,
}) {
  const classes = useStyles();
  const fetchBookingDetails = fetchViatorBookingDetails;

  const [bookingDetails, setBookingDetails] = useState(null);
  const [booking, setBooking] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedReason, setSelectedReason] = useState('');
  const isBookingCancellable = cancelQuote?.status === cancelQuoteStatus.CANCELLABLE;
  const isBookingCancelled = cancelQuote?.status === cancelQuoteStatus.CANCELLED;
  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

  const handleSelectChange = (e) => {
    setSelectedReason(e.target.value);
  };

  useEffect(() => {
    fetchBookingDetails({
      bookingRef: match.params.bookingRef,
      partnerBookingRef: match.params.partnerBookingRef,
      language: localization.getLanguage(),
    }).then((bookingDetails) => {
      getCancelQuote(bookingDetails.confirmed.bookingRef);
      setBookingDetails(bookingDetails);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.cartId]);

  useEffect(() => {
    if (isBookingCancellable) {
      getCancelReasons();
    }
  }, [getCancelReasons, isBookingCancellable]);

  const resetSelectedData = () => {
    setSelectedReason('');
  };

  const toggleModal = () => {
    setIsOpen(!isOpen);
    resetSelectedData();
  };

  const handleCancelBooking = () => {
    const cancellationReasonText = cancelReasons.filter(
      (reason) => reason.cancellationReasonCode === selectedReason
    )[0].cancellationReasonText;
    const relevantTotal =
      bookingDetails.priceDetails.totalInUserCurrency || bookingDetails.priceDetails.total;
    const refundAmount = (relevantTotal / 100) * cancelQuote.refundDetails.refundPercentage;

    cancelBooking(
      bookingDetails.confirmed.bookingRef,
      selectedReason,
      bookingDetails.confirmed.orderDetails.communication.email,
      {
        cancellationPolicy: bookingDetails.confirmed.cancellationPolicy.description,
        cancellationReason: cancellationReasonText,
        refundAmount: refundAmount >= 1 ? refundAmount : 0,
        currency: bookingDetails.bookerCurrency,
        tourDetails: getMappedTourDetails(
          bookingDetails.tour._default_title,
          bookingDetails.priceDetails,
          bookingDetails.bookerCurrency,
          bookingDetails.languageGuides?.language || 'en',
          bookingDetails.confirmed.orderDetails
        ),
      },
      {
        ...localization.orderCancellation,
        tourDetails: localization.tourDetails,
      }
    );
    toggleModal();
  };

  if (!bookingDetails) {
    return null;
  }

  return (
    <div>
      <Header />
      <div className={classes.wrapper}>
        <div className={classes.container}>
          <div className={classes.introBlock}>
            <div className={classes.circularAnimation}>
              <CircularAnimation />
            </div>
            <h1 className={classes.firstHeading}>
              <span className={classes.firstHeadingPart}>
                {isBookingCancelled
                  ? 'Booking Cancelled'
                  : localization.orderConfirmation.orderConfirmed}
              </span>
              <span className={classes.firstHeadingPart}>
                {` ${localization.orderConfirmation.thankYou}`}!
              </span>
            </h1>
            <a
              href={bookingDetails.confirmed.voucherInfo?.url}
              target="_blank"
              style={{ display: isBookingCancelled ? 'none' : 'block' }}
              rel="noreferrer"
            >
              <Button>Download Your Voucher</Button>
            </a>
            <br />
            <br />
            <span>
              {bookingDetails.tour.ticketInfo.ticketTypeDescription}. Note:{' '}
              {bookingDetails.tour.ticketInfo.ticketsPerBookingDescription}
            </span>
            <br />
            <br />
            <h2 className={classes.secondHeading}>
              {localization.orderConfirmation.bookingRef}:{' '}
              <span className={classes.bookRef}>{bookingDetails.confirmed.bookingRef}</span>
            </h2>
            <span className={classes.importantText}>
              {localization.orderConfirmation.detailsToEmail}: <br />
              {bookingDetails.confirmed.orderDetails.communication.email}
            </span>{' '}
          </div>

          <TourDetails
            classes={classes}
            name={bookingDetails.tour._default_title}
            lang={languageNames.of(bookingDetails.languageGuides?.language || 'en')}
            date={bookingDetails.date}
            time={bookingDetails.startTime}
            participants={getParticipantsString(bookingDetails.confirmed.orderDetails.paxMix)}
            amount={getPriceWithCurrencySymbol(
              formatPrice(
                bookingDetails.priceDetails.totalInUserCurrency || bookingDetails.priceDetails.total
              ),
              bookingDetails.bookerCurrency
            )}
          />

          <div className={classes.mainInfoBlock}>
            {(bookingDetails.tour.translations.en.Inclusions?.length ||
              bookingDetails.tour.translations.en.Exclusions?.length) && (
              <div className={classes.importantDetailsBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.importantDetails}
                </h2>

                <Card elevation={2}>
                  <CardContent>
                    {bookingDetails.tour.translations.en.Inclusions?.length && (
                      <>
                        <span className={classes.detailsHeading}>
                          {localization.orderConfirmation.included}:
                        </span>
                        <ul className={classes.inclusionsList}>
                          {splitForBullets(bookingDetails.tour.translations.en.Inclusions)
                            .map(stripeTags)
                            .map((inclusion) => (
                              <li key={inclusion} className={classes.listItem}>
                                <img className={classes.listIcon} alt="" src={checkMarkGreenIcon} />
                                {inclusion}
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                    {bookingDetails.tour.translations.en.Exclusions?.length && (
                      <>
                        <span className={classes.detailsHeading}>
                          {localization.orderConfirmation.notIncluded}:
                        </span>
                        <ul className={classes.exclusionsList}>
                          {splitForBullets(bookingDetails.tour.translations.en.Exclusions)
                            .map(stripeTags)
                            .map((exclusion) => (
                              <li key={exclusion} className={classes.listItem}>
                                <img className={classes.listIcon} alt="" src={CloseIcon} />
                                {exclusion}
                              </li>
                            ))}
                        </ul>
                      </>
                    )}
                  </CardContent>
                </Card>
              </div>
            )}

            {bookingDetails.tour.cancellationPolicy.description && (
              <div className={classes.importantDetailsBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.cancellationPolicy}
                </h2>
                <Card elevation={2}>
                  <CardContent>
                    <span className={classes.listItem}>
                      {bookingDetails.tour.cancellationPolicy.description}
                    </span>
                  </CardContent>
                </Card>
              </div>
            )}

            {bookingDetails.tour.translations.en.AdditionalInformation && (
              <div className={classes.importantDetailsBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.importantInformation}
                </h2>
                <Card elevation={2}>
                  <CardContent>
                    <span className={classes.listItem}>
                      {bookingDetails.tour.translations.en.AdditionalInformation}
                    </span>
                  </CardContent>
                </Card>
              </div>
            )}

            {Boolean(booking?.meetingPoint?.lat && booking?.meetingPoint?.long) && (
              <div className={classes.mapBlock}>
                <h2 className={classes.secondHeading}>
                  {localization.orderConfirmation.meetingPoint}
                </h2>
                <Card elevation={2}>
                  <MapContainer
                    description={booking.meetingPoint.description}
                    lat={booking.meetingPoint.lat}
                    long={booking.meetingPoint.long}
                    width="100%"
                  />
                </Card>
              </div>
            )}
          </div>

          <div className={classes.buttonWrapper}>
            <MUIButton
              disabled={!isBookingCancellable}
              color="primary"
              variant="contained"
              onClick={toggleModal}
            >
              {isCancelLoading ? <LoaderVerySmall /> : localization.cancelBooking.cancelButtonText}
            </MUIButton>
            {cancelQuote && (
              <div className={classes.cancelBookingText}>
                {cancelStatusText[cancelQuote.status]}
              </div>
            )}
          </div>

          <CustomizableModal
            isOpen={isOpen}
            title={localization.cancelBooking.cancelModalTitle}
            desc={localization.cancelBooking.cancelModalDesc}
            onClose={toggleModal}
            body={
              <>
                <CancelReasons
                  cancelReasons={cancelReasons}
                  cancelReasonsError={cancelReasonsError}
                  selectedReason={selectedReason}
                  handleSelectChange={handleSelectChange}
                />
                <RefundDetails
                  refundDetails={cancelQuote?.refundDetails}
                  bookingDetails={bookingDetails}
                />
              </>
            }
            handleFooterBackButton={toggleModal}
            handleHeaderCloseButton={toggleModal}
            primaryActionButtonData={{
              content: localization.cancelBooking.cancelButtonText,
              isDisabled: !isBookingCancellable || !selectedReason,
              onClick: handleCancelBooking,
            }}
          />
          <div style={{ marginTop: '50px' }}></div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default connect(
  (store) => ({
    cancelReasons: store.cancelBooking.cancelReasons,
    cancelReasonsError: store.cancelBooking.cancelReasonsError,
    cancelQuote: store.cancelBooking.cancelQuote,
    isCancelLoading: store.cancelBooking.isCancelLoading,
  }),
  (dispatch) => ({
    getCancelReasons: () => dispatch({ type: FETCH_CANCEL_REASONS }),
    getCancelQuote: (bookingReference) =>
      dispatch({ type: FETCH_CANCEL_QUOTE, payload: { bookingReference } }),
    cancelBooking: (
      bookingReference,
      reasonCode,
      to,
      cancellationDetails,
      cancellationTranslations
    ) =>
      dispatch({
        type: CANCEL_BOOKING,
        payload: {
          bookingReference,
          reasonCode,
          to,
          cancellationDetails,
          cancellationTranslations,
        },
      }),
  })
)(OrderConfirmation);
