module.exports = `.order-confirmation {
    font-family: 'DiodrumRegular';
    max-width: 1170px;
    margin: auto;
    display: flex;
    flex-direction: row;
}
@media (max-width: 1150px) {
    .order-confirmation {
        flex-direction: column;
   }
}
.left-column {
    flex: 1;
}
.right-column {
    width: 100%;
    min-width: 464px;
}
.order-confirmation__all-modules, .left-column, .right-column {
    display: flex;
    flex-direction: column;
}
.order-confirmation__all-modules {
    width: 100%;
    align-items: center;
}
.order-confirmation__module {
    margin: 20px 0;
    display : none;
}
.order-confirmed {
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;
    margin-bottom: 42px;
}
.order-confirmed .icon {
    height: 100px;
}
@media (max-width: 1150px) {
    .order-confirmed {
        width: 100%;
   }
}
.order-confirmed__title-text {
    flex: 1;
}
.order-confirmed__title {
    font-size: 28px;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
}
.order-confirmed__title .strong {
    font-weight: 600;
}
.order-confirmed__text {
    font-size: 16px;
    margin-bottom: 12px;
}
.order-confirmed__text .highlight {
    color: red;
}
.tour-extras {
    display: flex;
    width: 100%;
    flex-direction: column;
}
.tour-extras__row {
    display: flex;
    width: 100%;
    flex-direction: row;
}
.tour-extras__item {
    margin-right: 5px;
}
.tour-extras__item-value {
    color: red;
}
`