import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React from 'react';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';
import theme from 'theme';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';
import { StyledField } from './Input';

const useStyles = makeStyles({
  section: {
    marginBottom: '30px',
  },
  formBody: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    marginBottom: '1rem',
  },
  titleSmall: {
    fontWeight: '700',
    fontSize: FontSizes.h2,
    margin: '20px 0',
  },
  promo: {
    color: '#1a73e8',
    cursor: 'pointer',
    margin: '5px 10px',
    flexBasis: '100%',
  },
  intlTelInput: {
    width: '725px',
    margin: '10px 0px',
    border: '0',
  },
});

const priorityPrefixCountries = theme.config.priorityPrefixCountries || ['us', 'gb'];

const FieldNames = {
  FIRST_NAME: 'cardholder.firstName',
  LAST_NAME: 'cardholder.lastName',
  EMAIL: 'cardholder.email',
  EMAIL_CONFIRMATION: 'cardholder.emailConfirmation',
  PHONE_NUMBER: 'cardholder.phoneNumber',
  PROMO: 'promo',
};

const CardholderForm = ({ displayPromo, setDisplayPromo }) => {
  const classes = useStyles();

  const toggleDisplayPromo = () => {
    setDisplayPromo(!displayPromo);
  };

  return (
    <div className={classes.section}>
      <h2 className={classes.titleSmall}>{localization.payment.form.cardholder}</h2>
      <div className={classes.formBody}>
        <StyledField
          required
          name={FieldNames.FIRST_NAME}
          placeholder={localization.payment.form.firstName}
        />
        <StyledField
          required
          name={FieldNames.LAST_NAME}
          placeholder={localization.payment.form.lastName}
        />
        <StyledField
          required
          name={FieldNames.EMAIL}
          placeholder={localization.payment.form.email}
        />
        <StyledField
          required
          name={FieldNames.EMAIL_CONFIRMATION}
          placeholder={localization.payment.form.confirmEmail}
        />
        <Field
          name={FieldNames.PHONE_NUMBER}
          required
          render={({ field, form }) => {
            return (
              <IntlTelInput
                preferredCountries={priorityPrefixCountries}
                fieldId={FieldNames.PHONE_NUMBER}
                defaultValue={field.value}
                fieldName={FieldNames.PHONE_NUMBER}
                containerClassName={`${classes.intlTelInput} intl-tel-input`}
                inputClassName="form-control"
                placeholder={localization.payment.form.mobile}
                onPhoneNumberChange={(_, value) => {
                  form.setFieldValue(FieldNames.PHONE_NUMBER, value);
                }}
              />
            );
          }}
        />
      </div>
      <div style={{ display: 'none' }} className={classes.promo}>
        <span onClick={toggleDisplayPromo}>{localization.payment.form.enterPromo}</span>
      </div>
      {displayPromo && (
        <StyledField placeholder={localization.payment.form.promo} name={FieldNames.PROMO} />
      )}
    </div>
  );
};

export default CardholderForm;
