export const FETCH_CANCEL_REASONS = 'FETCH_CANCEL_REASONS';
export const FETCH_CANCEL_REASONS_SUCCESS = 'FETCH_CANCEL_REASONS_SUCCESS';
export const FETCH_CANCEL_REASONS_ERROR = 'FETCH_CANCEL_REASONS_ERROR';

export const FETCH_CANCEL_QUOTE = 'FETCH_CANCEL_QUOTE';
export const FETCH_CANCEL_QUOTE_SUCCESS = 'FETCH_CANCEL_QUOTE_SUCCESS';

export const CANCEL_BOOKING_INIT = 'CANCEL_BOOKING_INIT';
export const CANCEL_BOOKING = 'CANCEL_BOOKING';
export const CANCEL_BOOKING_SUCCESS = 'CANCEL_BOOKING_SUCCESS';
