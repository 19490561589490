export const replaceTags = (text, replace) => (text || '').replace(/<\/?[^>]*>/g, replace);
export const stripeTags = text => replaceTags(text, '');

export const split = (text, ...by) => {
    if(!text){
        return ''
    }
    const unescaped = by.map(x => x.replace('\\', ''));
    return text.split(new RegExp(`(${by.join('|')})`, 'g'))
        .map(x => x.trim())
        .filter(x => x && !unescaped.includes(x))
}
export const splitForBullets = text => split(text, '\\n', '<br\\/>', '<br \\/>', '\\*', '•') || [];