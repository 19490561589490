import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import localization from '../../localization';
import { Analytics } from '../../utils/analytics';
import { SET_FILTER_DATE } from '../../store/filters/actionTypes';
import { FETCH_LOCATION_LIST } from '../../store/locations/actionTypes';
import SearchBar from '../Header/SearchBar.js';
import './TourSearch.css';

const MobileHidden = styled.div`
  position: relative;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #fff;
  @media screen and (min-width: 651px) {
    width: 100%;
    min-width: 350px;
  }
`;

class TourSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      date: props.date,
      Destination: '',
      locations: props.locations,
      focusedAlt: false,
      isLoaded: props.isLoaded,
      searchString: props.searchString || '',
      extraTrackingProperties: props.extraTrackingProperties,
      myRef: props.myRef
    };

    this.updateState.bind(this);
  }

  static getDerivedStateFromProps({ date, isLoaded, locations }) {
    return { date, isLoaded, locations };
  }
  executeScroll() {
    if (this.state.myRef) {
      this.state.myRef.current.scrollIntoView();
    }
  }

  toggle() {
    if (!this.state.isSearchFocused) {
      this.setState({ isSearchFocused: true });
    }
    Analytics.track('search button clicked', {
      ...this.state.extraTrackingProperties,
      'destination name': this.state.selectedLocationName,
      'destination id': this.state.selectedLocation,
      'tour name': this.state.selectedTourName,
      'tour id': this.state.selectedTour,
      'search term': this.state.searchString
    });

    if (this.state.selectedLocation) {
      this.props.history.push(`/location/${this.state.selectedLocation}`);
    } else if (this.state.selectedTour) {
      this.props.history.push(`/tour/${this.state.selectedTour}`);
    } else if (this.state.searchString && this.state.searchString.length == 0) {
      Analytics.track('search button clicked with empty text', {
        ...this.state.extraTrackingProperties,
        'destination name': this.state.selectedLocationName,
        'destination id': this.state.selectedLocation,
        'tour name': this.state.selectedTourName,
        'tour id': this.state.selectedTour,
        'search term': this.state.searchString
      });
      //this.props.history.push(`/search/${this.state.searchString}`);
      // return searchByQuery(this.props.history, query);
      this.executeScroll();
    }
  }

  updateState(query) {
    this.state.searchString = query;
  }

  setSelectedLocation(selectedLocation, selectedLocationName) {
    if (selectedLocation || selectedLocationName) {
      this.setState(
        {
          selectedLocation: selectedLocation,
          selectedLocationName
        },
        () => {
          this.toggle();
        }
      );
    }
  }

  setSelectedTour(selectedTour, selectedTourName) {
    if (selectedTour || selectedTourName) {
      this.setState(
        {
          selectedTour: selectedTour,
          selectedTourName
        },
        () => {
          this.toggle();
        }
      );
    }
  }

  render() {
    return (
      <MobileHidden>
        <SearchBar
          hideSearchIcon={true}
          placeholder={localization.header.searchbar.placeholder}
          notifyLocationChange={this.setSelectedLocation.bind(this)}
          notifyTourChange={this.setSelectedTour.bind(this)}
          notifySearchChange={value => this.updateState(value)}
          focus={this.state.isSearchFocused}
          setFocus={isSearchFocused => this.setState({ isSearchFocused })}
          extraTrackingProperties={this.state.extraTrackingProperties}
        />
      </MobileHidden>
    );
  }
}

export default connect(
  state => ({
    locations: state.location.locations
  }),
  dispatch => ({
    setDate: payload => dispatch({ type: SET_FILTER_DATE, payload }),
    searchLocations: payload => dispatch({ type: FETCH_LOCATION_LIST, payload })
  })
)(withRouter(TourSearch));
