import { StyleBreakpoints } from '@constants';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import localization from '../../../localization';
import { Analytics } from '../../../utils/analytics';
import { isCardedOptions } from '../../../utils/experimentConstants';
import { getCurrency } from '../../../utils/helpers';
import { Divider } from '../../Common';
import Loader from '../../Loader';
import BookingOptionList from './BookingOptionList';
import BookingWidget from './BookingWidget';
import AdditionalExtrasPicker from './BookingWidget/AdditionalExtrasPicker';
import { getParticipantsAmount } from './common/helpers';

function extendedBookingSlot(bookingSlot) {
  const result = { ...bookingSlot };
  if (result.tag === 'interval') {
    result.selectedTimeslot = bookingSlot.timeslots[0].time;
  }

  return result;
}

const useStyles = makeStyles((theme) => ({
  formDivider: {
    margin: '40px 0',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      margin: '25px 0 40px',
    },
  },
}));

function EffectfulBookingWidget({
  tourId,
  bookingRequirements,
  languages,
  language,
  fetchCalculatedPricings,
  fetchAvailabilities,
  fetchParticipants,
  fetchSeason,
  fetchFallback,
  extraTrackingProperties,
  bookOption,
  currency = getCurrency(),
}) {
  const history = useHistory();
  const classes = useStyles();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isBookingInProgress, setBookingInProgress] = useState(false);

  const [bookingOptions, setBookingOptions] = useState([]);
  const [isBookingOptionsLoaded, setIsBookingOptionsLoaded] = useState(true);
  const [selectedBookingOption, setSelectedBookingOption] = useState({});
  const [shouldRenderBookingOptionList, setShouldRenderBookingOptionList] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [participants, setParticipants] = useState({
    availableParticipants: [],
    selectedParticipants: {},
  });

  const [seasons, setSeasons] = useState([]);

  const [extras, setExtras] = useState([]);
  const [shouldRenderExtras, setShouldRenderExtras] = useState(false);

  const [searchError, setSearchError] = useState(null);

  const [availabilitiesCursorState, setAvailabilitiesCursor] = useState({
    isLoaded: false,
    availabilities: [],
    lastLoadedDate: new Date(),
  });

  const [windowWidth, setWindowWidth] = useState(0);
  const isSmallScreen = windowWidth < 800;
  const bookingOptionsListRef = useRef();

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWindowWidth]);

  function consAvailabilityIterator(prevState) {
    return async () => {
      setAvailabilitiesCursor({ ...prevState, isLoaded: false });
      const dates = await fetchAvailabilities({
        tourId,
        language,
        startDate: prevState.lastLoadedDate,
      });
      const nextDate = moment(prevState.lastLoadedDate).add(3, 'month').toDate();
      const newState = {
        isLoaded: true,
        lastLoadedDate: nextDate,
        availabilities: prevState.availabilities.concat(dates),
      };
      setAvailabilitiesCursor(newState);
    };
  }

  useEffect(function initGygBookingWidget() {
    Promise.all([
      fetchParticipants({ tourId, language }),
      fetchSeason({ tourId, language }),
      consAvailabilityIterator(availabilitiesCursorState)(),
    ]).then(([participants, seasons]) => {
      if (!participants.length) fetchFallback();

      setIsLoaded(true);
      setParticipants({ selectedParticipants: {}, availableParticipants: participants });
      setSeasons(seasons);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function extractExtrasForSelectedSeason(date) {
    const selectedSeason = seasons.find((season) => {
      const startDate = moment(season.start);
      const endDate = moment(season.end);
      return date?.isBetween(startDate, endDate);
    });
    setExtras(selectedSeason?.extras || []);
  }

  function handleOptionSelect(option, countdownValue) {
    setSelectedBookingOption(option);
    if (extras?.length) {
      const extrasWithCorrectCount = extras.map((e) => ({
        ...e,
        count: e.isMandatory ? getParticipantsAmount(participants) : 0,
      }));
      setExtras(extrasWithCorrectCount);
      setShouldRenderExtras(true);
      setShouldRenderBookingOptionList(false);
    } else {
      bookSelectedOption({ option, countdownValue });
    }
  }

  function handleExtraCountChange(id, amount) {
    const updatedExtras = extras.map((e) => {
      if (e._id === id) {
        e.count = Number(amount);
      }
      return e;
    });
    setExtras(updatedExtras);
  }

  async function bookSelectedOption({
    option = selectedBookingOption,
    isExtrasSkipped = false,
    countdownValue = null,
  }) {
    Analytics.track('book selected option', {
      isExtrasSkipped,
      ...extraTrackingProperties,
    });

    setBookingInProgress(true);
    try {
      const { cartId } = await bookOption({
        option,
        tourId,
        slotId: option.slotId,
        selectedDate,
        selectedLanguage,
        selectedParticipants: participants.selectedParticipants,
        selectedExtras: isExtrasSkipped ? [] : extras,
        selectedTimeslot: option.selectedTimeslot,
        currency,
        language,
        countdownValue,
      });
      setBookingInProgress(false);
      history.push(`/checkout/${cartId}`);
    } catch (e) {
      setBookingInProgress(false);
    }
  }

  async function searchAvailabilities(participants, date) {
    if (!participants || !Object.keys(participants).length || !date.isValid()) {
      setSearchError(localization.tours.availabilitySearch.fillInAllFields);
      return;
    }
    setSearchError(null);
    setIsBookingOptionsLoaded(false);
    setShouldRenderExtras(false);
    extractExtrasForSelectedSeason(date);

    const pricings = await fetchCalculatedPricings({
      tourId,
      language,
      participants,
      extras: [],
      currency: getCurrency().toLowerCase(),
      date: date?.toDate(),
    });

    setBookingOptions(pricings.map(extendedBookingSlot));
    setIsBookingOptionsLoaded(true);
    setShouldRenderBookingOptionList(true);
    if (isSmallScreen) scrollToSearchResults();
  }

  const scrollToSearchResults = () => {
    setTimeout(() => {
      const offset = bookingOptionsListRef.current.getBoundingClientRect().bottom;
      window.scrollTo({
        top: window.scrollY + offset - 65,
        behavior: 'smooth',
      });
    }, 0);
  };

  if (!isLoaded) {
    return <Loader />;
  }

  return (
    <div className="option-select">
      <BookingWidget
        extraTrackingProperties={extraTrackingProperties}
        date={{
          dateCursor: {
            ...availabilitiesCursorState,
            next: consAvailabilityIterator(availabilitiesCursorState),
          },
          selectedDate,
          setSelectedDate: (date) => {
            setShouldRenderBookingOptionList(false);
            setSelectedDate(date);
          },
          seasons,
        }}
        participants={{
          ...participants,
          setSelectedParticipants: (selectedParticipants) => {
            setParticipants({ ...participants, selectedParticipants });
            setShouldRenderBookingOptionList(false);
          },
        }}
        language={{
          availableLanguages: languages,
          selectedLanguage,
          setSelectedLanguage,
        }}
        searchAvailabilities={searchAvailabilities}
        errors={searchError}
        bookingRequirements={bookingRequirements}
      />
      <div ref={bookingOptionsListRef} />
      {
        //Loader
        !isBookingOptionsLoaded ? (
          <Loader />
        ) : (
          shouldRenderBookingOptionList && (
            <>
              {!isCardedOptions() && <Divider className={classes.formDivider} />}
              <BookingOptionList
                handleOptionSelect={handleOptionSelect}
                isBookingInProgress={isBookingInProgress}
                bookingOptions={bookingOptions}
                participants={participants}
                language={selectedLanguage}
                date={selectedDate}
                extraTrackingProperties={extraTrackingProperties}
                updateSelectedTimeslot={(slotId, timeslot) => {
                  setBookingOptions(
                    bookingOptions.map((option) =>
                      option.slotId === slotId ? { ...option, selectedTimeslot: timeslot } : option
                    )
                  );
                }}
              />
            </>
          )
        )
      }
      {shouldRenderExtras && (
        <>
          <Divider className={classes.formDivider} />
          <AdditionalExtrasPicker
            participantsAmount={getParticipantsAmount(participants)}
            currency={currency.toLowerCase()}
            extras={extras}
            handleCompletePurchase={bookSelectedOption}
            handleExtraCountChange={handleExtraCountChange}
          />
        </>
      )}
    </div>
  );
}

export default EffectfulBookingWidget;
