import { reducer as toastr } from 'react-redux-toastr';
import { combineReducers } from 'redux';
import cart from './checkout/reducers';
import fallback from './fallbackTours/reducers';
import filter from './filters/reducers';
import location from './locations/reducers';
import search from './search/reducers';
import tour from './tour/reducer';
import tourOptions from './tourOptions/reducers';
import tours from './tours/reducers';
import cancelBooking from './cancel_booking/reducer';

export default combineReducers({
  filter,
  tours,
  tour,
  tourOptions,
  cart,
  toastr,
  location,
  fallback,
  search,
  cancelBooking
});
