import { INIT_FALLBACK_FETCH, SUCCESS_FALLBACK_FETCH, HIDE_FALLBACK_MODAL } from "./types";
import defaultState from '../defaultState';

export default function fallbackReducer(state = defaultState.fallback, action){
    switch(action.type){
        case INIT_FALLBACK_FETCH:
            return {...state, isLoaded: false, isHidden: false};
        case SUCCESS_FALLBACK_FETCH: 
            return {...state, isLoaded: true, isHidden: false, tours: action.payload.tours};
        case HIDE_FALLBACK_MODAL:
            return {...state, isHidden: true};
        default: 
            return state;  
    }
}