import { apiRoot } from "env-config";
import axios from 'axios';
import moment from "moment";

import {getCurrency} from '../utils/helpers'

export const fetchTour = ({ tourId, language, currency = getCurrency()}) =>
   
    axios.get(
        `${apiRoot}/api/v1/tour/${tourId}/details`,
        {
            params: { language, currency }
        }
    )
        .then(res => res.data.data)
        .then(item => {
            if(!item.TourID){
                throw new Error('Tour not found');
            }
            if (!item.MeetingPoint && item.tour_options && item.tour_options[0]) {
                item.MeetingPoint = {
                    shortDescription: item.tour_options[0].meeting_point,
                    geo: item.tour_options[0].coordinates
                }
            }
            return item;
        });

export const fetchPricings = ({ tourId, language, currency = getCurrency(), optionIds = [] }) =>
    axios.get(`${apiRoot}/api/v1/tour/${tourId}/pricings`, {
        params: { optionIds, language, currency }
    })
        .then(res => res.data.data);

export const fetchAvailabilities = ({ tourId, language, currency = getCurrency(), startDate = moment(), optionIds = [] }) =>
    axios.get(`${apiRoot}/api/v1/tour/${tourId}/availabilities`, {
        params: { optionIds, language, currency, startDate: moment(startDate).toDate() }
    })
        .then(res => res.data.data);

export const fetchCalculatedPricings = ({tourId, language, date, participants, currency = getCurrency()}) => 
    axios.post(`${apiRoot}/api/v1/tour/${tourId}/calculate-pricings`, {
        language,
        participants,
        date: moment(date).format('YYYY-MM-DD'),
        currency : getCurrency()
    })
        .then(res => res.data.data);
        
export const fetchTsPricings = ({ tourId, language, currency = getCurrency()}) =>
    axios.get(`${apiRoot}/api/v1/tour/ts/${tourId}/participants`, {
        params: { language, currency }
    })
        .then(res => res.data.data);

export const fetchTsSeason = ({ tourId, language, currency = getCurrency()}) =>
    axios.get(`${apiRoot}/api/v1/tour/ts/${tourId}/seasons`, {
        params: { language, currency, currency }
    })
        .then(res => res.data.data);

export const fetchTsAvailabilities = ({ tourId, language, currency = getCurrency(), startDate = moment() }) =>
    axios.get(`${apiRoot}/api/v1/tour/ts/${tourId}/availabilities`, {
        params: { language, currency, startDate: moment(startDate).toDate() }
    })
        .then(res => res.data.data);

export const fetchTsCalculatedPricings = ({tourId, language, date, participants, extras, currency = getCurrency()}) => 
        axios.post(`${apiRoot}/api/v1/tour/ts/${tourId}/calculate-pricings`, {
            language,
            participants,
            extras,
            date: moment(date).format('YYYY-MM-DD'),
            currency
        })
            .then(res => res.data.data);