import { Checkbox, FormControlLabel } from '@mui/material';
import React, { Component } from 'react';
import styled from 'styled-components';
import { getLocationReferences } from '../../../../../../api/viator';
import { FontSizes } from '../../../../../../utils/constants';
import Loader from '../../../../../Loader';
import PickupPointSelect from './PickupPointSelect';

const FormInput = styled.div`
  flex-basis: 100%;
  display: block;
  width: 100%;
  margin: 10px 0px;
`;

const BillingCheckbox = styled.div`
  margin: 10px 0;
  width: 100%;
  display: flex;
  align-items: center;
`;

//Note add hint from bookingQuestions
class PickupPointField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      question: props.question,
      name: props.name,
      tour: props.tour,
      TRANSFER_ARRIVAL_MODE: props.TRANSFER_ARRIVAL_MODE,
      renderPickupLocations: true,
      pickupLocations: [],
      setFieldValue: props.setFieldValue,
      renderCheckboxes: false,
    };
  }

  async componentDidMount() {
    if (this.state.tour?.logistics?.travelerPickup?.allowCustomTravelerPickup) {
      var locationReferences = this.state.tour.logistics.travelerPickup.locations.map(
        (ans) => ans.location.ref
      );
      let resp = await getLocationReferences(locationReferences);

      this.state.tour.logistics.travelerPickup.locations.map((l) => {
        let i = resp.findIndex((o) => o.reference == l.location.ref);
        if (i > -1) {
          resp[i].pickupType = l.pickupType;
        }
      });
      this.setState({
        pickupLocations: resp,
      });
    }

    let tour = this.state.tour;
    let TRANSFER_ARRIVAL_MODE = this.state.TRANSFER_ARRIVAL_MODE;
    let chooseableLocations = [];
    let contactLaterLocation = this.state.pickupLocations.filter(
      (l) => l.reference == 'CONTACT_SUPPLIER_LATER'
    )[0];
    let allowCustomPickupLater = !!contactLaterLocation;
    //https://docs.viator.com/partner-api/technical/#section/Booking-concepts/Booking-questions         (Pickup point question)
    if (!tour.logistics.travelerPickup.allowCustomTravelerPickup) {
      allowCustomPickupLater = false;
    }
    this.setState({ renderCheckboxes: !!allowCustomPickupLater });

    if (this.state.pickupLocations.length > 0) {
      switch (TRANSFER_ARRIVAL_MODE) {
        case 'AIR':
          chooseableLocations = this.state.pickupLocations.filter((l) => l.pickupType == 'AIRPORT');
          break;
        case 'OTHER':
          chooseableLocations = this.state.pickupLocations.filter(
            (l) => l.pickupType == 'HOTEL' || l.pickupType == 'LOCATION'
          );
          break;
        case 'SEA':
          chooseableLocations = this.state.pickupLocations.filter((l) => l.pickupType == 'PORT');
          break;
        default:
          chooseableLocations = this.state.pickupLocations;
          break;
      }
      if (tour.logistics.travelerPickup.allowCustomTravelerPickup) {
        if (!chooseableLocations.some((l) => l.name === "I don't see my pickup location")) {
          chooseableLocations.push({
            name: "I don't see my pickup location",
          });
        }
      }
      const sortedLocations = chooseableLocations.sort(function (a, b) {
        if (a.name == "I don't see my pickup location") {
          return -1;
        }
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
      this.setState({
        locations: sortedLocations,
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState(nextProps);
  }

  handleContactLaterChange = (event, value) => {
    this.setState({ renderPickupLocations: !value });
    let contactLaterLocation = this.state.pickupLocations.filter(
      (l) => l.reference == 'CONTACT_SUPPLIER_LATER'
    )[0];
    this.state.setFieldValue(this.state.name, contactLaterLocation.reference);
  };

  render() {
    let question = this.state.question;
    var PickupLocationsSelector = <Loader />;
    if (this.state.renderPickupLocations) {
      PickupLocationsSelector = (
        <PickupPointSelect
          locations={this.state.locations}
          name={this.state.name}
          setFieldValue={this.state.setFieldValue}
        />
      );
    } else {
      PickupLocationsSelector = null;
    }
    if (!this.state.renderCheckboxes) {
      if (this.state.pickupLocations.length > 0) {
        return (
          <PickupPointSelect
            locations={this.state.locations}
            name={this.state.name}
            setFieldValue={this.state.setFieldValue}
          />
        );
      } else {
        return <Loader />;
      }
    }

    return (
      <div key={question.id} style={{}}>
        <FormInput>
          <BillingCheckbox>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={(e, value) => {
                    this.setState({ renderPickupLocations: value });
                  }}
                  size="medium"
                  color="primary"
                  checked={this.state.renderPickupLocations}
                  style={{
                    transform: 'scale(2)',
                    margin: '10px',
                    fontSize: FontSizes.cta,
                  }}
                  className="payment-form-checkbox"
                />
              }
              label={"Select where you'd like to be picked up from"}
            />
          </BillingCheckbox>
          <div>{PickupLocationsSelector}</div>
          <BillingCheckbox>
            <FormControlLabel
              control={
                <Checkbox
                  onChange={this.handleContactLaterChange.bind(this)}
                  size="medium"
                  color="primary"
                  checked={!this.state.renderPickupLocations}
                  style={{
                    transform: 'scale(2)',
                    margin: '10px',
                    fontSize: FontSizes.cta,
                  }}
                  className="payment-form-checkbox"
                />
              }
              label={'I will select my pickup location later'}
            />
          </BillingCheckbox>
        </FormInput>
      </div>
    );
  }
}
export default PickupPointField;
