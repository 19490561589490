import { ReactComponent as Activities } from '../../../../assets/img/tourCategoryIcons/Activities.svg';
import { ReactComponent as CityCards } from '../../../../assets/img/tourCategoryIcons/CityCards.svg';
import { ReactComponent as Collections } from '../../../../assets/img/tourCategoryIcons/Collections.svg';
import { ReactComponent as CultureHistory } from '../../../../assets/img/tourCategoryIcons/CultureHistory.svg';
import { ReactComponent as DealsDiscounts } from '../../../../assets/img/tourCategoryIcons/DealsDiscounts.svg';
import { ReactComponent as FamilyFriendly } from '../../../../assets/img/tourCategoryIcons/FamilyFriendly.svg';
import { ReactComponent as HopOnHopOff } from '../../../../assets/img/tourCategoryIcons/Hop-onHop-off.svg';
import { ReactComponent as NatureAdventure } from '../../../../assets/img/tourCategoryIcons/NatureAdventure.svg';
import { ReactComponent as Rentals } from '../../../../assets/img/tourCategoryIcons/Rentals.svg';
import { ReactComponent as ResellerExclusions } from '../../../../assets/img/tourCategoryIcons/ResellerExclusions.svg';
import { ReactComponent as SensitiveProducts } from '../../../../assets/img/tourCategoryIcons/SensitiveProducts.svg';
import { ReactComponent as SnowWinterSports } from '../../../../assets/img/tourCategoryIcons/SnowWinterSports.svg';
import { ReactComponent as Tickets } from '../../../../assets/img/tourCategoryIcons/Tickets.svg';
import { ReactComponent as Tours } from '../../../../assets/img/tourCategoryIcons/Tours.svg';
import { ReactComponent as Transportation } from '../../../../assets/img/tourCategoryIcons/Transportation.svg';
import { ReactComponent as UnknownCategory } from '../../../../assets/img/tourCategoryIcons/UnknownCategory.svg';
import { ReactComponent as WheelchairAccessible } from '../../../../assets/img/tourCategoryIcons/WheelchairAccessible.svg';

const categoryIconsById = {
  '54': Activities,
  '101': CityCards,
  '443': Collections,
  '27': CultureHistory,
  '203': DealsDiscounts,
  '1094': FamilyFriendly,
  '9': HopOnHopOff,
  '35': NatureAdventure,
  '143': Rentals,
  '390': ResellerExclusions,
  '447': SensitiveProducts,
  '113': SnowWinterSports,
  '123': Tickets,
  '1': Tours,
  '152': Transportation,
  '239': WheelchairAccessible,
  default: UnknownCategory
};

export default categoryIconsById;
