import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import Exclusion from '../../../assets/img/assets/Exclusion.svg';
import Inclusion from '../../../assets/img/assets/Inclusion.svg';
import localization from '../../../localization';
import { splitForBullets, stripeTags } from '../../../utils/TextUtils';

export default function Inclusions(props) {
  const [inclusions] = useState(splitForBullets(props.inclusions || '').map(stripeTags));
  const [exclusions] = useState(splitForBullets(props.exclusions || '').map(stripeTags));
  if (inclusions.length === 0 && exclusions.length === 0) {
    return null;
  }

  return (
    <>
      <Row style={{ textAlign: 'left' }}>
        <Col md="2">
          <div className="third-heading">
            {localization.tours.experience.includes}{' '}
          </div>
        </Col>

        <Col md="10">
          {renderInclusions(inclusions)}
          {renderExclusions(exclusions)}
        </Col>
      </Row>
    </>
  );
}

function renderInclusions(inclusions) {
  if (inclusions.length < 1) {
    return null;
  }
  return (
    <div className="inclusions-list-block">
      {inclusions.map(b =>
        b.length > 1 ? (
          <div>
            <img className="inclusions-list-icon" alt="Inclusion" src={Inclusion} />
            {b}
          </div>
        ) : null
      )}
    </div>
  );
}

function renderExclusions(exclusions) {
  if (exclusions.length < 1) {
    return null;
  }
  return (
    <div className="inclusions-list-block">
      {exclusions.map(b =>
        b.length > 1 ? (
          <div>
            <img className="inclusions-list-icon" alt="Exclusion" src={Exclusion} />
            {b}
          </div>
        ) : null
      )}
    </div>
  );
}
