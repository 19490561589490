import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import theme from 'theme';
import localization from '../../../localization';
import { TextPrimaryNormal } from '../../Common';
import MapboxMap from '../../Map';

export default class Map extends Component {
  constructor(props) {
    super(props);
    const {
      description,
      geo: { long, lat },
    } = props;
    this.state = {
      description,
      long,
      lat,
      expanded: theme.config.showMapsByDefault,
    };
  }

  //TODO: Maybe replace with a component with slots
  render() {
    const { description, lat, long, expanded } = this.state;

    if (description === 'LOADING' && lat === 0 && long === 0) return null;

    return (
      <Row style={{ textAlign: 'left', lineHeight: '24px' }}>
        <Col md="2">
          <div className="third-heading">
            {' '}
            {localization.tours.prepareForActivity.meetingPoint}{' '}
          </div>
        </Col>

        <Col md="10">
          <div className="map-description">
            {description}
            {Boolean(this.props.providerReference) && (
              <div>
                <TextPrimaryNormal
                  onClick={() => this.setState({ expanded: !this.state.expanded })}
                >
                  {theme.config.showMapsByDefault ? '' : localization.common.showOnMap}
                </TextPrimaryNormal>
              </div>
            )}
          </div>
          {expanded && Boolean(this.props.providerReference) && (
            <div className="map-wrapper">
              <MapboxMap description={description} lat={lat} long={long} width="100%" />
            </div>
          )}
        </Col>
      </Row>
    );
  }
}
