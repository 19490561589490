import { call, takeLatest } from 'redux-saga/effects';
import { stripeRefund } from '../../api/viator';
import { REFUND_STRIPE_PAYMENT } from './actionTypes';

function* refundPayment({ payload: { paymentIntentId, totalRefundAmountInCents } }) {
  try {
    yield call(stripeRefund(paymentIntentId, totalRefundAmountInCents));
  } catch (err) {
    console.error(err);
  }
}

export function* watchStripeActions() {
  yield takeLatest(REFUND_STRIPE_PAYMENT, refundPayment);
}
