import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import React, { useLayoutEffect, useRef, useState } from 'react';
import localization from '../../../../localization';
import { Analytics } from '../../../../utils/analytics';
import { StyleBreakpoints } from '../../../../utils/constants';
import {
  isCardedOptions,
  isDarkOptions,
  isLightOptions,
} from '../../../../utils/experimentConstants';
import CommonButton from '../../../Common/Button';
import DatepickerElement from './Datepicker';
import PeoplePicker from './PeoplePicker';
import ViatorLanguagePicker from './ViatorLanguagePicker';

const useStyles = makeStyles((theme) => ({
  filter: isCardedOptions()
    ? {
        borderRadius: '6px',
        boxShadow: '0 2px 4px 0 rgb(0 0 0 / 50%)',
        backgroundColor: isLightOptions() ? '#f9f9f9' : '#010e1d',
        padding: '20px',
        [theme.breakpoints.up(StyleBreakpoints.sm)]: {
          padding: '26px 40px 26px',
        },
        ...(isDarkOptions()
          ? {
              '& option': {
                color: '#000',
              },
              '& .MuiInputLabel-outlined': {
                color: '#fff!important',
              },
              '& .MuiOutlinedInput-input': {
                color: '#fff!important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'solid 1.6px rgba(255, 255, 255, 0.29)!important',
              },
              '& .MuiOutlinedInput-notchedOutline:hover': {
                border: 'solid 1.6px rgba(255, 255, 255, 0.29)!important',
              },
              '& .apply-btn > button:disabled': {
                border: 'solid 1.6px rgba(255, 255, 255, 0.29)',
                color: 'rgba(255, 255, 255, 0.29)',
              },
            }
          : {}),
      }
    : {},
}));

function BookingWidget({
  participants: { availableParticipants, selectedParticipants, setSelectedParticipants },
  date: { dateCursor, selectedDate, setSelectedDate, seasons },
  language: { availableLanguages, selectedLanguage, setSelectedLanguage },
  searchAvailabilities,
  extraTrackingProperties,
  bookingRequirements,
  errors,
}) {
  const classes = useStyles();

  const peopleRef = useRef();
  const dateRef = useRef();
  const langRef = useRef();

  const [anchorEl, setAnchorEl] = useState(null);

  const [windowWidth, setWindowWidth] = useState(0);
  const isMobileView = windowWidth < StyleBreakpoints.xs;
  const isAvailableLanguageSelected =
    (availableLanguages.length && selectedLanguage) || !availableLanguages.length;
  let searchEnabled = selectedParticipants && selectedDate && isAvailableLanguageSelected;

  useLayoutEffect(() => {
    function updateSize() {
      setWindowWidth(window.innerWidth);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, [setWindowWidth]);

  return (
    <>
      <div id="search-availability-widget" className="second-heading">
        {localization.tours.availabilitySearch.title}
      </div>
      <div className={`${classes.filter} filters`}>
        <div className="people-picker">
          <PeoplePicker
            availableOptions={availableParticipants}
            selectedOptions={selectedParticipants}
            updateSelectedOptions={setSelectedParticipants}
            onClose={() => {
              Analytics.track('participants selected', {
                ...extraTrackingProperties,
                participants: selectedParticipants,
              });
            }}
            peopleRef={peopleRef}
            anchorEl={anchorEl}
            nextAnchor={dateRef.current}
            setAnchorEl={setAnchorEl}
            isMobileView={isMobileView}
            bookingRequirements={bookingRequirements}
          />
        </div>
        <div className="date-picker-block">
          <DatepickerElement
            selectedDate={selectedDate}
            updateDate={setSelectedDate}
            onClose={() => {}}
            availabilityCursor={dateCursor}
            seasons={seasons}
            dateRef={dateRef}
            anchorEl={anchorEl}
            nextAnchor={null}
            setAnchorEl={setAnchorEl}
            isMobileView={isMobileView}
          />
        </div>
        {availableLanguages.length > 0 && (
          <div className="lang-picker">
            <ViatorLanguagePicker
              selectedLanguage={selectedLanguage}
              setSelectedLanguage={setSelectedLanguage}
              onClose={() => {
                Analytics.track('language selected', {
                  ...extraTrackingProperties,
                  language: selectedLanguage,
                });
              }}
              languages={availableLanguages}
              langRef={langRef}
              anchorEl={anchorEl}
              nextAnchor={null}
              setAnchorEl={setAnchorEl}
              isMobileView={isMobileView}
            />
          </div>
        )}
        <div className="apply-btn">
          <CommonButton
            disabled={!searchEnabled}
            style={{
              fontSize: '18px',
              borderRadius: '10px',
              marginBottom: '2px',
              height: '50px',
              width: '100%',
            }}
            onClick={() =>
              searchAvailabilities(selectedParticipants, moment(selectedDate), selectedLanguage)
            }
          >
            {localization.tours.availabilitySearch.search}
          </CommonButton>
        </div>
      </div>
      {errors && <div style={{ color: 'red' }}>{errors}</div>}
    </>
  );
}

export default BookingWidget;
