import React from 'react';
import Header from '../../components/Header'
import Footer from '../../components/Footer'
import Newsletter from '../../components/Newsletter'
import PoweredBy from '../../components/PoweredBy'
import TourCards from '../../components/TourCards'
import api from '../../api/index'
import queryString from 'query-string'
import { StyleBreakpoints } from '@constants';

class Venue extends React.Component {
    constructor(props){
        super(props);
        const values = queryString.parse(window.location.search)
        this.state = {
            values : values,
            item : {
                displayImage : {
                    url : ''
                }
            }
        }
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        api.getVenueById(this.state.values.venueId, function(err, items){
            
            this.setState({item:items[0]})
        }.bind(this))
    }

    render(){
        return (
        <div className="Venue" style={{fontFamily : 'DiodrumRegular'}}>
         
           
         <Header/>

            {window.innerWidth > StyleBreakpoints.sm ? (<div>
                    <img style={{height : '490px', width : '100%', objectFit : 'cover', marginTop : '-4px'}} src={this.state.item.displayImage.url}/>
                    <div style={  {textAlign:'center', 
                        color : 'white',
                        fontFamily : 'DiodrumSemibold',
                        fontSize : '74px',
                        top:'-250px',
                        position: 'relative',
                        textShadow: '-1px 1px 10px rgba(0, 0, 0, 0.75)'}}>
                        {this.state.item.displayTitle}
                    </div>
                </div>
            ) : (<div>
                    <img style={{height : '290px', width : '100%', objectFit : 'cover', marginTop : '-4px'}} src={this.state.item.displayImage.url}/>

                    <div style={  {textAlign:'center', 
                        color : 'white',
                        fontFamily : 'DiodrumSemibold',
                        fontSize : '44px',
                        top:'-150px',
                        lineHeight : '44px',
                        position: 'relative',
                        textShadow: '-1px 1px 10px rgba(0, 0, 0, 0.75)'}}>
                        {this.state.item.displayTitle}
                    </div>
                </div>
            )}

          <div style={{width : '80vw',  margin:'auto', marginTop : '5vw'}}>
            <TourCards/>
          </div>
    
          <PoweredBy/>
          <Newsletter/>
          <Footer/>
        </div>
      );

    }
  
}



export default Venue;
