import axios from 'axios';
import { apiRoot } from 'env-config';
import { getCurrency } from '../utils/helpers';

export const getSearchPrompts = ({ query, language, currency = getCurrency() }) =>
  axios
    .get(`${apiRoot}/api/v1/search/prompts`, {
      params: {
        q: query,
        language,
        page: 0,
        perPage: 100
      }
    })
    .then(res => res.data.data);
