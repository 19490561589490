import { createPaymentIntent, getBookingQuestions } from '../../../api/viator';
import localization from '../../../localization';

const freeTextQuestion = ['TRANSFER_ARRIVAL_DROP_OFF', 'TRANSFER_DEPARTURE_PICKUP', 'PICKUP_POINT'];

export const getQuestionAnswerUnit = (answer) => {
  const specialLocationCodes = ['LOC-', 'MEET_AT_DEPARTURE_POINT', 'CONTACT_SUPPLIER_LATER'];
  const isAnswerWithSpecialCode = specialLocationCodes.some((specialCode) =>
    answer.includes(specialCode)
  );

  if (isAnswerWithSpecialCode) return 'LOCATION_REFERENCE';

  return 'FREETEXT';
};

export const getBookingCustomQuestionAnswers = (customQuestionsAnswers) => {
  if (!customQuestionsAnswers?.length) return [];

  let travelerNum = 1;
  return customQuestionsAnswers.reduce((questionAnswers, customAnswer) => {
    if (!customAnswer) return questionAnswers;

    const allCustomAnswers = [];

    Object.keys(customAnswer).forEach((answerKey) => {
      if (answerKey.includes('unit')) return;

      const maybeUnitKeys = Object.keys(customAnswer).filter((answer) => answer.includes('unit'));
      const actualQuestionId = maybeUnitKeys.length && maybeUnitKeys[0].split('-')[0];
      const [questionId, , pb] = answerKey.split('-'); // What is pb? - ask to Andy

      const bookingQuestionAnswer = {
        question: questionId,
        answer: customAnswer[answerKey],
        ...(questionId === actualQuestionId && { unit: customAnswer[maybeUnitKeys[0]] }),
        ...(!pb && { travelerNum }),
        ...(freeTextQuestion.includes(questionId) && {
          unit: getQuestionAnswerUnit(customAnswer[answerKey]),
        }),
      };
      allCustomAnswers.push(bookingQuestionAnswer);
    });

    travelerNum++;
    return questionAnswers.concat(allCustomAnswers);
  }, []);
};

export const paxMixAsArrayOfNames = (paxMix) => {
  const paxMixAsArray = [];
  for (let pType = 0; pType < paxMix.length; pType++) {
    for (let pCount = paxMix[pType].numberOfTravelers; pCount > 0; pCount--) {
      paxMixAsArray.push(paxMix[pType].ageBand);
    }
  }
  return paxMixAsArray;
};

export const getPassengers = (paymentDetails, viatorBookingQuestions) => {
  const passengerCount = paymentDetails.booking.bookingHold.paxMix.reduce(
    (prev, passengerEntry) => {
      return prev + passengerEntry.numberOfTravelers;
    },
    0
  );

  const passengers = [];
  const perTravelerQuestions = viatorBookingQuestions.filter((e) => e.group === 'PER_TRAVELER');
  const eachPax = paxMixAsArrayOfNames(paymentDetails.booking.bookingHold.paxMix);

  if (perTravelerQuestions?.length) {
    for (let i = passengerCount, eachPaxCounter = 0; i >= 1; i--, eachPaxCounter++) {
      passengers.push({
        questions: perTravelerQuestions,
        ageBand: eachPax[eachPaxCounter],
      });
    }
  }

  return passengers;
};

export const mapBookingForHoldAndBook = (paymentDetails, bookingQuestionAnswers) => {
  const booking = paymentDetails.booking;

  return {
    productCode: booking.tourId,
    productOptionCode: booking.optionId,
    currency: booking.currency,
    partnerBookingRef: booking.bookingRef + 'TA',
    travelDate: booking.date,
    paxMix: booking.bookingHold.paxMix,
    bookingRef: booking.bookingRef,
    bookerInfo: {
      firstName: paymentDetails.traveler.firstName,
      lastName: paymentDetails.traveler.lastName,
    },
    bookingQuestionAnswers,
    communication: {
      email: paymentDetails.traveler.email,
      phone: paymentDetails.traveler.phoneNumber,
    },
    ...(booking.languageGuides && { languageGuide: booking.languageGuides }),
    ...(booking.startTime && { startTime: booking.startTime }),
  };
};

export const getAllBookingQuestionAnswers = async (paymentDetails) => {
  const bookingCustomQuestionAnswers = getBookingCustomQuestionAnswers(
    paymentDetails.customQuestionsAnswers
  );

  const viatorBookingQuestions = await getBookingQuestions(
    paymentDetails.booking.tour.bookingQuestions
  );
  const passengers = getPassengers(paymentDetails, viatorBookingQuestions);
  const firstNameQuestions = viatorBookingQuestions.filter(
    (e) => e.group === 'PER_TRAVELER' && (e.id === 'FULL_NAMES_FIRST' || e.id === 'FULL_NAMES_LAST')
  );

  if (!passengers.length && !firstNameQuestions.length) return bookingCustomQuestionAnswers;

  const bookingQuestionAnswers = [];

  passengers.length &&
    passengers.forEach((passenger, i) => {
      bookingQuestionAnswers.push({
        question: 'AGEBAND',
        answer: passenger.ageBand,
        travelerNum: i + 1,
      });
    });

  if (firstNameQuestions.length) {
    bookingQuestionAnswers.push({
      question: 'FULL_NAMES_FIRST',
      answer: paymentDetails.traveler.firstName,
      travelerNum: 1,
    });
    bookingQuestionAnswers.push({
      question: 'FULL_NAMES_LAST',
      answer: paymentDetails.traveler.lastName,
      travelerNum: 1,
    });
  }

  return [...bookingCustomQuestionAnswers, ...bookingQuestionAnswers];
};

export const getTotalPaymentAndCurrency = (paymentDetails) => {
  const localCurrencyTotal =
    paymentDetails.booking.priceDetails.totalInUserCurrency ||
    paymentDetails.booking.priceDetails.total;
  const currency = paymentDetails.booking.bookerCurrency || paymentDetails.booking.currency;
  const total = Math.floor(localCurrencyTotal * 100);

  return { currency, total };
};

export const getStripeResult = async (stripe, elements, CardNumberElement, paymentDetails) => {
  const { total, currency } = getTotalPaymentAndCurrency(paymentDetails);
  const clientSecret = await createPaymentIntent(total, currency);

  return await stripe.confirmCardPayment(clientSecret.data.client_secret, {
    payment_method: {
      card: elements.getElement(CardNumberElement),
      billing_details: {
        name: `${paymentDetails.traveler.firstName} ${paymentDetails.traveler.lastName}`,
        email: paymentDetails.traveler.email.replace(/\s/g, ''),
      },
    },
  });
};

export const getParticipantsString = (paxMix) => {
  return paxMix.reduce((prev, participant, i) => {
    return `${prev}${i > 0 ? ',' : ''} ${participant.numberOfTravelers} ${participant.ageBand}`;
  }, '');
};

export const getMappedTourDetails = (
  tourTitle,
  priceDetails,
  bookerCurrency,
  language,
  orderDetails
) => {
  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });

  return {
    name: tourTitle,
    lang: languageNames.of(language || 'en'),
    date: orderDetails.travelDate,
    time: orderDetails.startTime,
    participants: getParticipantsString(orderDetails.paxMix),
    amount: new Intl.NumberFormat(undefined, {
      style: 'currency',
      currency: bookerCurrency,
    }).format(priceDetails.totalInUserCurrency || priceDetails.total),
  };
};

export const mapConfirmationEmailData = (paymentDetails, confirmedBooking) => {
  return {
    to: paymentDetails.traveler.email,
    bookingDetails: {
      bookingRef: paymentDetails.booking.bookingRef,
      partnerBookingRef: paymentDetails.booking.partnerBookingRef,
      confirmed: {
        voucherInfo: confirmedBooking.voucherInfo,
        bookingRef: confirmedBooking.bookingRef,
        partnerBookingRef: confirmedBooking.partnerBookingRef,
        orderDetails: {
          communication: {
            email: paymentDetails.traveler.email,
          },
        },
      },
      tour: {
        translations: paymentDetails.booking.tour.translations,
        cancellationPolicy: {
          description: paymentDetails.booking.tour.cancellationPolicy.description,
        },
      },
      tourDetails: getMappedTourDetails(
        paymentDetails.booking.tour._default_title,
        paymentDetails.booking.priceDetails,
        paymentDetails.booking.bookerCurrency,
        paymentDetails.language,
        confirmedBooking.orderDetails
      ),
    },
    orderConfirmationTranslations: {
      ...localization.orderConfirmation,
      tourDetails: localization.tourDetails,
    },
  };
};
