import { Breadcrumbs, Link, Typography } from '@mui/material';
import React from 'react';
import localization from '../../../localization';
import './nav.scss';

const LocationNav = ({ name }) => {
  return (
    <Breadcrumbs aria-label="breadcrumb" style={{ marginTop: '26px' }}>
      <Link color="#000" href="/">
        {localization.common.home}
      </Link>
      <Link color="#000" href="#">
        {name}
      </Link>
      <Typography color="textPrimary">
        {name} {localization.common.tours}
      </Typography>
    </Breadcrumbs>
  );
};

export default LocationNav;
