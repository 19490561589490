import React from 'react';
import EmptyStar from '../../../assets/img/assets/EmptyStar.svg';
import FullStar from '../../../assets/img/assets/Star.svg';
import localization from '../../../localization';
import { Analytics } from '../../../utils/analytics';
import './RatingsFilter.scss';

function RenderStars({ value }) {
  let starIcons = [];
  for (let i = 0; i < value; i++) {
    starIcons.push(<img className="star" key={i} src={FullStar} alt="" />);
  }
  for (let j = value; j < 5; j++) {
    starIcons.push(<img className="star" key={j} src={EmptyStar} alt="" />);
  }
  return <>{starIcons}</>;
}

function RatingsFilter({ rating, updateFilters }) {
  function handleRatingSelection(evt) {
    Analytics.track('rating selected', {
      'rating value': evt.target.value,
      'display area': 'location filter sidebar'
    });

    updateFilters({ rating: Number(evt.target.value) });
  }

  return (
    <>
      <div className="ratings-filter-block" onChange={handleRatingSelection}>
        <div className="radio-button">
          <input
            className="star-rating"
            id="5-stars"
            value={5}
            type="radio"
            name="rating"
            checked={rating === 5}
          />
          <label className="ratings-label" htmlFor="5-stars">
            <RenderStars value={5} />
          </label>
        </div>
        <div className="radio-button">
          <input
            className="star-rating"
            id="4-stars"
            value={4}
            type="radio"
            name="rating"
            checked={rating === 4}
          />
          <label className="ratings-label" htmlFor="4-stars">
            <RenderStars value={4} />
            <span className="ratings-text">{localization.location.filter.andUp}</span>
          </label>
        </div>
        <div className="radio-button">
          <input
            className="star-rating"
            id="3-stars"
            value={3}
            type="radio"
            name="rating"
            checked={rating === 3}
          />
          <label className="ratings-label" htmlFor="3-stars">
            <RenderStars value={3} />
            <span className="ratings-text">{localization.location.filter.andUp}</span>
          </label>
        </div>
        <div className="radio-button">
          <input
            className="star-rating"
            id="2-stars"
            value={2}
            type="radio"
            name="rating"
            checked={rating === 2}
          />
          <label className="ratings-label" htmlFor="2-stars">
            <RenderStars value={2} />
            <span className="ratings-text">{localization.location.filter.andUp}</span>
          </label>
        </div>
        <div className="radio-button">
          <input
            className="star-rating"
            id="1-stars"
            value={1}
            type="radio"
            name="rating"
            checked={rating === 1}
          />
          <label className="ratings-label" htmlFor="1-stars">
            <RenderStars value={1} />
            <span className="ratings-text">{localization.location.filter.andUp}</span>
          </label>
        </div>
      </div>
    </>
  );
}
export default RatingsFilter;
