import axios from 'axios';
import { apiRoot } from 'env-config';

const apiV1Viator = `${apiRoot}/api/v1/tour/viator`;

export const getMap = (providerReference) =>
  axios
    .get(`${apiV1Viator}/map`, {
      params: {
        providerReference,
      },
    })
    .then((res) => res.data.data);

export const getTags = (locationId, language) =>
  axios.get(`${apiV1Viator}/tags/${locationId}?language=${language}`).then((res) => res.data);

export const getSchedule = (tourId) =>
  axios
    .get(`${apiV1Viator}/schedule`, {
      params: {
        tourId,
      },
    })
    .then((res) => res.data.data);

export const availabilityCheck = (tourId, travelDate, currency, paxMix) =>
  axios
    .post(`${apiV1Viator}/availabilitycheck`, {
      productCode: tourId,
      travelDate,
      currency,
      paxMix,
    })
    .then((res) => res.data);

export const bookingHold = (
  tourId,
  productOptionCode,
  travelDate,
  currency,
  startTime,
  paxMix,
  languageGuides
) =>
  axios
    .post(`${apiV1Viator}/bookinghold`, {
      productCode: tourId,
      productOptionCode,
      travelDate,
      currency,
      paxMix,
      startTime,
      languageGuides,
    })
    .then((res) => res.data);

export const getBooking = (cartId) =>
  axios.get(`${apiV1Viator}/booking/${cartId}`).then((res) => res.data);

export const getTour = (tourId) =>
  axios.get(`${apiV1Viator}/tour/${tourId}`).then((res) => res.data);

//paymentIntent
export const createPaymentIntent = (amount, currency) =>
  axios
    .post(`${apiV1Viator}/paymentIntent`, {
      amount,
      currency,
    })
    .then((res) => res.data);

export const getBookingQuestions = async function (questions) {
  const response = await axios.post(`${apiV1Viator}/bookingQuestions`, {
    questions,
  });
  return response.data.data;
};

export const getLocationReferences = async function (locationRefs) {
  const response = await axios.post(`${apiV1Viator}/locations`, {
    locationRefs,
  });

  return response.data.data;
};

export const bookholdbook = async function (body) {
  const response = await axios.post(`${apiV1Viator}/bookholdbook`, body);
  return response;
};

export const getCancelReasons = async () => {
  return axios.get(`${apiV1Viator}/cancel-reasons`).then((res) => res.data.data);
};

export const getCancelQuote = async (bookingReferenceCode) => {
  return axios
    .get(`${apiV1Viator}/cancel-quote`, {
      params: {
        bookingReferenceCode,
      },
    })
    .then((res) => res.data.data);
};

export const cancelBooking = async (bookingReference, reasonCode) => {
  return axios
    .post(`${apiV1Viator}/${bookingReference}/cancel`, {
      reasonCode,
    })
    .then((res) => res.data.data);
};

export const stripeRefund = async (paymentIntentId, totalRefundAmountInCents) => {
  return axios
    .post(`${apiV1Viator}/stripe-refund`, {
      paymentIntentId,
      totalRefundAmountInCents,
    })
    .then((res) => res.data.data);
};
