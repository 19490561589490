import React from 'react';
import { BrowserView, isMobile } from 'react-device-detect';
import withStyles from '@mui/styles/withStyles';
import theme from 'theme';
import SwiperCore, { FreeMode, Keyboard, Navigation, Scrollbar } from 'swiper';
import 'swiper/modules/navigation/navigation.min.css';
import 'swiper/modules/scrollbar/scrollbar.min.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import { getTopAttractions } from '../../api/attractions';
import { ReactComponent as LeftArrow } from '../../assets/img/icons/LeftIcon.svg';
import { ReactComponent as RightArrow } from '../../assets/img/icons/RightIcon.svg';
import AttractionCard from './AttractionCard';
import DestinationCard from './DestinationCard';
import { SvgColorHelper } from '../Common';

import { Grid } from '@mui/material';

SwiperCore.use([Keyboard, Scrollbar, Navigation, FreeMode]);

const styles = () => ({
  swiperContainer: {
    paddingBottom: '15px',

    '& .swiper-button-next:after': {
      content: 'none',
    },
    '& .swiper-button-prev:after': {
      content: 'none',
    },
  },
  rightArrow: {
    right: '0px',
    width: '40px',
    height: '40px',
    top: '42%',

    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
  leftArrow: {
    left: '0px',
    width: '40px',
    height: '40px',
    top: '42%',

    '& svg': {
      width: '40px',
      height: '40px',
    },
  },
});

class Places extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      attractions: [],
      destinations: props.destinations || [],
      isAttractions: props.isAttractions || false,
    };
  }
  async componentWillMount() {
    if (this.state.isAttractions) {
      let attractions = await getTopAttractions();
      this.setState({
        attractions,
      });
    }
  }

  async componentWillReceiveProps(props) {
    if (props.destinations) {
      this.setState({
        destinations: props.destinations,
      });
    }
  }

  render() {
    const { classes } = this.props;
    const LeftArrowIconColor = SvgColorHelper('LeftIcon #Oval', LeftArrow, theme.colors.primary);
    const RightArrowIconColor = SvgColorHelper('RightIcon #Oval', RightArrow, theme.colors.primary);
    const doNotUseSlider = theme.config.dontUseSliderForLocations || false;

    const attractionList = this.state.attractions.map((a, index) => {
      return (
        <SwiperSlide style={{ width: 'fit-content' }} key={index}>
          <AttractionCard attraction={a} mobile={isMobile} isClickable={this.state.isMoving} />
        </SwiperSlide>
      );
    });

    const destinationList = this.state.destinations.map((d, index) => {
      return (
        <SwiperSlide style={{ width: 'fit-content' }} key={index}>
          <DestinationCard destination={d} mobile={isMobile} isClickable={this.state.isMoving} />
        </SwiperSlide>
      );
    });

    const contentList = this.state.isAttractions ? attractionList : destinationList;

    const params = {
      slidesPerView: 'auto',
      loopedSlides: 100,
      grabCursor: true,
      freeMode: {
        enabled: true,
        momentumBounce: false,
      },
      keyboard: { enabled: true },
      loop: true,
      preventClicks: true,
      spaceBetween: 26,
      breakpoints: {
        1280: {
          spaceBetween: 35,
        },
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
    };

    if (doNotUseSlider) {
      const staticAttractionList = this.state.attractions.map((a, index) => {
        return (
          <Grid item>
            <div style={{ maxWidth: '268px' }}>
              <AttractionCard attraction={a} mobile={isMobile} isClickable={this.state.isMoving} />
            </div>
          </Grid>
        );
      });

      const staticDestinationList = this.state.destinations.map((d, index) => {
        return (
          <Grid item>
            <div style={{ maxWidth: '268px' }}>
              <DestinationCard
                destination={d}
                mobile={isMobile}
                isClickable={this.state.isMoving}
              />
            </div>
          </Grid>
        );
      });
      const staticContentList = this.state.isAttractions
        ? staticAttractionList
        : staticDestinationList;

      return (
        <Grid container spacing={3} justifyContent={'space-between'}>
          {staticContentList}
        </Grid>
      );
    }

    return (
      <Swiper {...params} className={classes.swiperContainer}>
        {contentList}
        <BrowserView>
          <div className={`${classes.rightArrow} swiper-button-next`}>
            <RightArrowIconColor />
          </div>
          <div className={`${classes.leftArrow} swiper-button-prev`}>
            <LeftArrowIconColor />
          </div>
        </BrowserView>
      </Swiper>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Places);
