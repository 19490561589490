import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Button, Paper } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import localization from '../../../localization';
import { Analytics } from '../../../utils/analytics';
import CommonButton from '../../Common/Button';
import DurationFilter from './DurationFilter';
import FeaturesFilter from './FeaturesFilter';
import PriceRangeFilter from './PriceRangeFilter';
import RatingsFilter from './RatingsFilter';
import TagFilters from './TagFilters';
import TopAttractions from './TopAttractions';

const useStyles = makeStyles((theme) => ({
  root: (props) => ({
    width: '100%',
    height: '100%',
    overflow: 'auto',
    overflowX: 'hidden',
    padding: `${props.isBigScreen ? '0' : '57px 0 95px'}`,
  }),
  header: (props) => ({
    width: '100%',
    padding: '4px 10px 4px 20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: 'solid 1px #d8d2d2',
    background: '#fff',
    zIndex: 2,
    position: `${props.isBigScreen ? 'static' : 'fixed'}`,
    top: `${props.isBigScreen ? 'unset' : '0'}`,
  }),
  footer: (props) => ({
    width: '100%',
    padding: '20px 5px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: '#fff',
    zIndex: 2,
    borderTop: `${props.isBigScreen ? 'none' : 'solid 1px #d8d2d2'}`,
    position: `${props.isBigScreen ? 'static' : 'fixed'}`,
    bottom: `${props.isBigScreen ? 'unset' : '0'}`,
  }),
  filterSection: {
    padding: '15px 25px 15px 25px',
    borderBottom: 'solid 1px #d8d2d2',
    textAlign: 'left',
    '&:nth-last-child(2)': {
      borderBottom: 'none',
    },
  },
  AccordianFilter: {
    borderBottom: 'solid 1px #d8d2d2',
    textAlign: 'left',
    '&:nth-last-child(2)': {
      borderBottom: 'none',
    },
  },
  heading: {
    fontFamily: theme.typography.fontFamilyBold,
    fontSize: '20px',
  },
  closeButton: {
    padding: '8px',
    margin: '4px',
    minWidth: 'fit-content',
    fontSize: '28px',
  },
  sectionTitle: {
    display: 'block',
    fontWeight: '700',
    margin: '5px 0 0 -2px',
    fontSize: '15px',
  },
}));

export default function FilterSidebar({
  filters,
  dataForFilter,
  clearFilters,
  updateFilters,
  applyFilters,
  handleClose,
  restoreFiltersFromBackup,
  isBigScreen,
  selectedTags,
  selectedDurations,
  handleCategorySelection,
  handleTagSelection,
  handleDurationSelection,
  handleFlagSelection,
  handleAttractionSelection,
  attractions,
}) {
  const classes = useStyles({ isBigScreen });

  const [expanded, setExpanded] = React.useState('panel1');
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Paper classes={{ root: classes.root }} elevation={3}>
      {!isBigScreen && (
        <div className={classes.header}>
          <span className={classes.heading}>{localization.location.filter.filter}</span>
          <Button
            className={classes.closeButton}
            onClick={() => {
              Analytics.track('filter closed');
              restoreFiltersFromBackup();
              handleClose();
            }}
          >
            <span style={{ lineHeight: '24px' }}>&#x2715;</span>
          </Button>
        </div>
      )}

      {Boolean(attractions?.length) && (
        <div className={classes.AccordianFilter}>
          <Accordion square expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className={classes.sectionTitle}>
                {localization.location.filter.topAttractions}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <TopAttractions
                attractions={attractions}
                handleChange={handleAttractionSelection}
                selectedItems={filters.attractions}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <div className={classes.AccordianFilter}>
        <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span className={classes.sectionTitle}>{localization.location.filter.categories}</span>
          </AccordionSummary>
          <AccordionDetails>
            <TagFilters
              tags={dataForFilter.tags}
              handleChange={handleTagSelection}
              selectedItems={selectedTags}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      <div className={classes.AccordianFilter}>
        <Accordion square expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span className={classes.sectionTitle}>{localization.location.filter.price}</span>
          </AccordionSummary>
          <AccordionDetails>
            <PriceRangeFilter filters={filters} updateFilters={updateFilters}></PriceRangeFilter>
          </AccordionDetails>
        </Accordion>
      </div>

      {Boolean(dataForFilter.categories?.length) && (
        <div className={classes.AccordianFilter}>
          <Accordion square expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className={classes.sectionTitle}>
                {localization.location.filter.categories}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <FeaturesFilter
                features={dataForFilter.categories}
                handleChange={handleCategorySelection}
                selectedItems={filters.categories}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <div className={classes.AccordianFilter}>
        <Accordion square expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span className={classes.sectionTitle}>{localization.location.filter.rating}</span>
          </AccordionSummary>
          <AccordionDetails>
            <RatingsFilter rating={filters.rating} updateFilters={updateFilters} />
          </AccordionDetails>
        </Accordion>
      </div>

      <div className={classes.AccordianFilter}>
        <Accordion square expanded={expanded === 'panel7'} onChange={handleChange('panel7')}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <span className={classes.sectionTitle}>{localization.location.filter.duration}</span>
          </AccordionSummary>
          <AccordionDetails>
            <DurationFilter
              selectedItems={selectedDurations}
              handleChange={handleDurationSelection}
            />
          </AccordionDetails>
        </Accordion>
      </div>

      {Boolean(dataForFilter.flags?.length) && (
        <div className={classes.AccordianFilter}>
          <Accordion square expanded={expanded === 'panel6'} onChange={handleChange('panel6')}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <span className={classes.sectionTitle}>
                {localization.location.filter.tourFeatures}
              </span>
            </AccordionSummary>
            <AccordionDetails>
              <FeaturesFilter
                features={dataForFilter.flags}
                handleChange={handleFlagSelection}
                selectedItems={filters.flags}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      )}

      <div className={classes.footer}>
        <CommonButton
          style={{
            flex: '0 1 48%',
            maxWidth: '200px',
            lineHeight: '23px',
            marginRight: '20px',
            whiteSpace: 'nowrap',
          }}
          background={false}
          onClick={() => {
            clearFilters();
            Analytics.track('clear location filters');
          }}
        >
          {localization.location.filter.clearAll}
        </CommonButton>

        <CommonButton
          style={{
            flex: '0 1 40%',
            maxWidth: '200px',
            lineHeight: '22px',
          }}
          onClick={() => {
            if (isBigScreen) {
              applyFilters();
            } else {
              applyFilters();
              handleClose();
            }
            Analytics.track('apply filter', {
              onMobile: !isBigScreen,
            });
          }}
        >
          {localization.location.filter.apply}
        </CommonButton>
      </div>
    </Paper>
  );
}
