import React, { Component } from 'react';

export default class PersistentComponent extends Component {
    constructor(props){
        super(props)
        this.persistensHandler = this.persistState.bind(this);
        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount(){
        window.addEventListener('resize', this.updateWindowDimensions);
     
        this.props.history.listen(this.persistensHandler);
        window.addEventListener('beforeunload', e => {
            this.persistensHandler();
        });
        return this.restoreState();
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.updateWindowDimensions);
        window.removeEventListener('beforeunload', this.persistensHandler);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
      }

}