import React from 'react';
import styled from 'styled-components';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';
import { TickedCheckboxIcon } from '../../Common';
import { BookingOptionVertical } from './BookingOption';
import { StyleBreakpoints } from '@constants';

const Data = styled.div`
  padding: 0 22px;
  padding-bottom: 22px;
  width: 100%;
`;
const WhyTripadmitElement = styled.div`
  margin-bottom: 5px;
  font-size: ${FontSizes.cta};
  font-weight: 600;
  font-family: ${props => props.theme.fonts.primary};
`;
const SecondTitle = styled.div`
  font-size: 17px;
  font-family: ${props => props.theme.fonts.bold};
  margin-bottom: 10px;
`;
const VerticalFormDivider = styled.hr`
  margin: 15px 0;
  background-color: #d7d7d7;
  border-color: #d7d7d7;
`;

const WhyWe = ({ list }) => (
  <>
    {list.map((text, i) => (
      <WhyTripadmitElement key={i}>
        <TickedCheckboxIcon style={{ width: '17px', marginRight: '10px' }} /> {text}
      </WhyTripadmitElement>
    ))}
  </>
);
const Sidebar = ({ bookings }) => {
  return (
    <div className="booking-summary-block">
      {bookings.map((booking, i) => (
        <BookingOptionVertical booking={booking} key={i} />
      ))}
      <Data>
        {/* <Title>{localization.payment.acceptedPayment}</Title> */}
        {/* <PaymentMethods methods={[mastercardLogo, visaLogo, jcbLogo, maestroLogo, discoverLogo]} /> */}
        {/* <Divider /> */}
        {window.innerWidth >= StyleBreakpoints.md ? (
          <>
            <VerticalFormDivider />
            <SecondTitle>{localization.payment.whyTripadimit.title}</SecondTitle>
            <WhyWe list={localization.payment.whyTripadimit.content} />
          </>
        ) : null}
      </Data>
    </div>
  );
};

export default Sidebar;
