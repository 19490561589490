import moment from 'moment';
import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Bold, ButtonLarge } from '../../components/Common';
import Filters from '../../components/Filters';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import Loader from '../../components/Loader';
import Locations from '../../components/Location/Attractions';
import PersistentComponent from '../../components/PersistentComponent';
import PoweredBy from '../../components/PoweredBy';
import TourCard from '../../components/TourCard';
import localization from '../../localization';
import { FETCH_LOCATIONS } from '../../store/locations/actionTypes';
import {
  FETCH_TOURS,
  FETCH_TOURS_RESTORED,
  SET_SCROLL
} from '../../store/tours/actionTypes';
import './Search.scss';

var filterActivities = require('../../assets/img/FilterActivities.svg');

const Block = styled.div`
  font-size: 34px; 
  margin-top: 42px;
  color: black; 
  letter-spacing: 0.07pt; 
  text-align: left;
  margin-top : 24px;
  margin-bottom : 24px;
  line-height : 38px;
`;


const ThemeColorText = styled.span`
color: ${props => (props.theme.colors.primary)};
`;
const PERSIST_THRESHOLD = 1000 * 60 * 60 * 1;//expire state in one hour;
const DEFAULT_STATE = {
  filters: {},
  page: 0
}
const isAfter = (timestampA, timestampB) => timestampA < timestampB;
const stateExpired = ({ timestamp }, now = new Date().getTime(), threshold = PERSIST_THRESHOLD) =>
  isAfter(timestamp + threshold, now);

class TourSearchResults extends PersistentComponent {
  constructor(props) {
    super();
    const {
      tours,
      filters,
      isToursLoaded,
      showSeeMore,
      isFiltersLoaded,
      attractions,
      cities,
      width,
      height,
      toggle
    } = props;
    this.state = {
      tours,
      filters,
      isToursLoaded,
      showSeeMore,
      isFiltersLoaded,
      attractions,
      cities,
      searchQuery: props.match.params.q || '',
      isInitialized: false,
      width: window.innerWidth,
      height,
      toggle: toggle,

    }
    this.scrollHandler = () => {
      this.windowScrollPosition = window.scrollY
    }

   
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.toggleFiltersMobile.bind(this);
    this.updateState.bind(this);
    this.toggleView.bind(this);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
   
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  applyFilters() {

    return filters =>
      this.props.applyFilters(filters);
  }

  toggleView() {
    if (window.localStorage.getItem('view') == '"single"') {
      window.localStorage.setItem('view', JSON.stringify('double'));
    } else {
      window.localStorage.setItem('view', JSON.stringify('single'));
    }

    this.setState({ view: window.localStorage.getItem('view') });
  }

  persistState() {
    const { filters } = this.state
    window.localStorage.setItem(
      this.state.searchQuery.toLowerCase(),
      JSON.stringify({ filters, scroll: this.windowScrollPosition, timestamp: new Date().getTime() })
    );
   

  }


  toggleFiltersMobile() {

    this.setState({ toggle: !this.state.toggle });

  }

  updateState = () => {
    this.state.toggle = false;
  }

  async restoreState() {
    const prevStateJson = window.localStorage.getItem(this.state.searchQuery.toLowerCase());
    if (prevStateJson) {
      const prevState = JSON.parse(prevStateJson);
      if (stateExpired(prevState)) return DEFAULT_STATE;
      const { filters, scroll } = prevState;
      return {
        filters: {
          ...filters,
          toDate: filters.toDate && moment(filters.toDate),
          fromDate: filters.fromDate && moment(filters.fromDate),
          language: localization.getLanguage()
        },
        scroll
      }
    }
    return DEFAULT_STATE;
  }

  async componentDidMount() {
    this.props.loadAttractions(0);
    this.props.loadCities(0);
    const { filters, scroll } = await super.componentDidMount();
    window.addEventListener('scroll', this.scrollHandler);
    this.props.initialLoad({
      filters: {
        ...filters,
        primaryQuery: this.state.searchQuery,
        perPage: 21
      },
      scroll
    });
   
   
  }

  async componentWillUnmount() {
    super.componentWillUnmount();
    window.removeEventListener('scroll', this.scrollHandler);
  }

  componentWillReceiveProps({
    tours,
    filters,
    isToursLoaded,
    showSeeMore,
    isFiltersLoaded,
    scroll,
    attractions,
    cities,
    view,
    match,
    toggle
  }) {
    this.setState({
      tours,
      filters,
      isToursLoaded,
      isFiltersLoaded,
      showSeeMore,
      attractions,
      cities,
      view,
      toggle: this.state.toggle
    });


    if (scroll) {
      requestAnimationFrame(() => {
        window.scrollTo({ behavior: "smooth", top: scroll });
        this.props.resetScroll();
      });
    }
    if (match.params.q && match.params.q !== this.state.searchQuery) {
      this.props.initialLoad({
        filters: {
          primaryQuery: match.params.q,
          perPage: 21,
          language: localization.getLanguage()
        }
      }, 0);

      this.setState({ searchQuery: match.params.q });
    }

  }

  render() {

    let totalTours = '';


    const { attractions, cities } = this.state;

    if (window.innerWidth < '620') {
      totalTours = totalToursMob;

    } else {
      totalTours = totalToursDesktop;

    }


    return (
      <div className="search">
        <Header toggleView={() => this.toggleView()} filters={this.state.toggle} />

        <div className={"search__contents" + (this.state.isFiltersModalOpen ? ' search__contents-modal--open' : '')}>

          <div className="search__contents-left">


            <div className={`search__contents-filters${!this.state.isFiltersLoaded ? '-loading' : ''}`}>
              {!this.state.toggle &&
                <div style={{ display: 'table' }}>
                  <span className="display_total">Displaying: {this.state.tours.length} <ThemeColorText>&nbsp;of&nbsp;{localStorage.getItem('count')}&nbsp;results&nbsp;</ThemeColorText></span>
                  {this.state.width <= '800' &&
                    <div style={{ position: 'relative', textAlign: 'center', color: 'white', marginTop: '50px', fontWeight: '800', fontSize: '15px', marginRight: '-15%' }}>
                      <button onClick={() => this.toggleFiltersMobile()} style={{ border: 'none', color: 'white', backgroundColor: 'white' }}><img src={filterActivities} style={{ width: '30vw' }}></img></button>
                      <div style={filterActivitiesText}>Filter Activities</div>
                    </div>

                  }
                </div>
              }

              {((this.state.width > '800') || (this.state.toggle && this.state.width <= '800')) &&

                <Filters
                  onApply={this.applyFilters()} toggle={this.state.toggle} func={() => this.updateState()}
                />


              }
            </div>
          </div>
          <div className="search__contents-main">
            {this.renderSearchResults()}
            {this.state.isToursLoaded && !this.state.toggle &&
              <div style={{ width: '93%' }}>
               <Block>
                 {localization.home.attractions.regular}
             <Bold> {localization.home.destinations.bold}</Bold>
               </Block>
               <Locations
                 attractions={cities.list}
                 isLoaded={cities.isLoaded}
                 canLoadMore={cities.canLoadMore}
                 onLoadMore={() => this.props.loadCities(cities.page + 1)}
               />
                <Block>
                {localization.home.attractions.regular}
              <Bold> {localization.home.attractions.bold}</Bold>
                </Block>
                <Locations
                  attractions={attractions.list}
                  isLoaded={attractions.isLoaded}
                  canLoadMore={attractions.canLoadMore}
                  onLoadMore={() => this.props.loadAttractions(attractions.page + 1)}
                />
              
             </div>
            }

          </div>
        </div>

        {!this.state.isFiltersModalOpen ? (<span></span>) : (<template><PoweredBy /> <Footer />)</template>)}

        <PoweredBy />
        <Footer />
      </div>
    )
  }

  renderSearchResults() {
    const { tours, isToursLoaded, filters, showSeeMore } = this.state;
    return (

      <Fragment>
        {this.renderTourList(tours, isToursLoaded)}
        {this.renderLoadMore(showSeeMore, isToursLoaded, filters)}
      </Fragment>
    );
  }

  renderTourList(tours, isToursLoaded) {

    if (isToursLoaded && tours.length === 0) {
      return null;
    }
    let classname = 'search__contents-card';
    let wrapperclass = '';
    if (this.state.width < 800) {
      if (window.localStorage.getItem('view') !== '"single"') {
        classname = 'search__contents-card-double';
      } else {
        classname = 'search__contents-card-single';
      }
    }


    return (
      <Fragment>

        {!this.state.toggle &&
          this.state.tours.map((item, i) =>
            <div key={i} className={classname}>
              <TourCard item={item} />
            </div>
          )
        }

      </Fragment>
    );
  }

  renderLoadMore(showSeeMore, isToursLoaded, filters) {
    if (!isToursLoaded) {
      return <Loader />
    }
    if (!showSeeMore) {
      return null;
    }
    return (
      <div className="search__search-button-container">
        {((!this.state.toggle && this.state.width < 800) || (this.state.width > 800)) &&
          <ButtonLarge
            className="btn btn-lg btn-primary search__search-button"
            onClick={this.props.loadNextPage(filters)}
          >
            {localization.common.seeMore}
        </ButtonLarge>
        }
      </div>
    );
  }

}

export default connect(

  state => ({
    isToursLoaded: state.tours.isLoaded,
    filters: state.filter.filters,
    tours: state.tours.all,
    showSeeMore: state.tours.canLoadMore,
    isFiltersLoaded: state.filter.isLoaded,
    scroll: state.tours.scroll,
    cities: state.location.cities,
    attractions: state.location.attractions,
    view: state
  }),
  dispatch => ({
    resetScroll: () => dispatch({ type: SET_SCROLL, payload: null }),
    initialLoad: payload => dispatch({ type: FETCH_TOURS_RESTORED, payload }),

    applyFilters: filters => dispatch({
      type: FETCH_TOURS,
      payload: {
        filters,
        language: localization.getLanguage(),
        resetTours: true
      }
    }),
    loadCities: page => dispatch({ type: FETCH_LOCATIONS, payload: { type: 'CITY', page, global: 'cities' } }),
    loadAttractions: page => dispatch({ type: FETCH_LOCATIONS, payload: { type: 'POI', page, global: 'attractions' } }),
    loadNextPage: filters => () => dispatch({
      type: FETCH_TOURS,
      payload: {
        filters: {
          ...filters,
          language: localization.getLanguage(),
          page: filters.page + 1
        }
      }

    }),
  })
)(TourSearchResults);

const totalToursDesktop = {
  display: 'flex',
  marginLeft: '40px',
  fontSize: '20px',
  color: 'black',
  float: 'left',
  fontWeight: 'bold',
  marginTop: '10%',
  marginBottom: '10%'
}

const totalToursMob = {
  position: 'relative',

  fontSize: '5vw',

  color: 'black',
  marginLeft: 'auto',
  fontWeight: 'bold',
  marginTop: '20px',
  paddingTop: '20px',
  marginBottom: '10%'

}

const filterActivitiesText = {
  position: 'absolute',
  top: '65%',
  left: '70%',
  transform: 'translate(-65%, -75%)',
  fontSize: '2vw'
}
