import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import theme from 'theme';
import localization from '../../../../../localization';
import { FontSizes, StyleBreakpoints } from '../../../../../utils/constants';
import { wrapedSynthetichandler } from '../../../../../utils/helpers';
import { getAgeRepresentation } from './helper';
import { Age, Data, Icon, Input, Option, Select } from './styles';

const useStyles = makeStyles((theme) => ({
  inputCount: {
    paddingTop: '3px',
    fontSize: '15px',
    fontWeight: '600',
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      color: 'grey',
      backgroundColor: theme.palette.background.dark,
    },
  },
  minTravelers: {
    textAlign: 'left',
    fontSize: FontSizes.cta,
    color: '#63666A',
  },
  pickerContainer: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  pickerData: {
    [theme.breakpoints.down(StyleBreakpoints.xs)]: {
      color: '#ffffff',
      backgroundColor: theme.palette.background.dark,
    },
  },
}));

const ParticipantOption = ({
  option,
  optionCount,
  updateOption,
  incrementTotal,
  decrementTotal,
  canIncrementTotal,
}) => {
  const { minAge, maxAge, displayName, id } = option;
  const classes = useStyles();

  if (option.displayName === 'ADULT' || option.displayName === 'TRAVELER') {
    if (!optionCount && option.minTravelersPerBooking) {
      incrementTotal(option.minTravelersPerBooking);
      updateOption(option.displayName, option.minTravelersPerBooking);
    } else if (!optionCount) {
      incrementTotal();
      updateOption(option.displayName, 1);
    }
  }

  return (
    <Option>
      <div className={classes.pickerContainer}>
        <Data>
          {displayName}
          <Age>{getAgeRepresentation(minAge, maxAge)}</Age>
        </Data>
        <Select>
          <Icon
            style={{ ...theme.styles.pickerControlButton, opacity: optionCount === 0 ? 0.2 : 1 }}
            disabled={optionCount === 0 || optionCount >= option.minTravelersPerBooking}
            onClick={function () {
              if (optionCount && optionCount > option.minTravelersPerBooking) {
                decrementTotal();
                updateOption(id, optionCount - 1);
              }
            }}
          >
            -
          </Icon>
          <Input
            className={optionCount ? classes.pickerData : classes.inputCount}
            style={{ ...theme.styles.pickerCounter }}
            type="tel"
            pattern="[0-9]*"
            disabled
            tabIndex="-1"
            onChange={wrapedSynthetichandler((value) => updateOption(id, Number(value)))}
            value={optionCount}
          />
          <Icon
            style={{ ...theme.styles.pickerControlButton }}
            onClick={function () {
              if (optionCount === 0 || optionCount < option.maxTravelersPerBooking) {
                if (canIncrementTotal()) {
                  incrementTotal();
                  updateOption(id, optionCount + 1);
                }
              }
            }}
            disabled={optionCount <= option.maxTravelersPerBooking}
          >
            +
          </Icon>
        </Select>
      </div>
      {option.minTravelersPerBooking > 0 && (
        <div className={classes.minTravelers}>
          {localization.errors.aMinimumOf} {option.minTravelersPerBooking} {option.displayName}{' '}
          {localization.errors.travelersRequired}.
        </div>
      )}
    </Option>
  );
};
ParticipantOption.propTypes = {
  option: PropTypes.object,
  optionCount: PropTypes.number,
  updateOption: PropTypes.func,
  incrementTotal: PropTypes.func,
  decrementTotal: PropTypes.func,
  canIncrementTotal: PropTypes.bool,
};
export default ParticipantOption;
