import React from 'react';
import styled from 'styled-components';
import localization from '../../../localization';
import { FontSizes } from '../../../utils/constants';
import {
  formatIfInr,
  formatPrice,
  getFormattedDate,
  getLangName,
  getParticipantsTextLong,
  getPriceWithCurrencySymbol,
} from '../../../utils/helpers';

const CardImageVertical = styled.div`
  display: block;
  width: 100%;
  img {
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  }
`;
const VerticalCardContent = styled.div`
  padding: 22px;
  width: 100%;
  padding-bottom: 0px;
`;
const Title = styled.div`
  font-size: ${FontSizes.h2};
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: 10px;
`;
const SecondTitle = styled.div`
  font-size: 17px;
  font-family: ${(props) => props.theme.fonts.bold};
  margin-bottom: 10px;
`;
const SubTitle = styled.div`
  font-size: ${FontSizes.p};
  font-weight: 600;
  font-family: ${(props) => props.theme.fonts.primary};
  margin-bottom: 5px;
`;
const Date = styled.div`
  font-family: ${(props) => props.theme.fonts.bold};
  color: ${(props) => props.theme.colors.primary};
  font-size: 13px;
  margin-bottom: 7px;
`;
const FieldWrapper = styled.span`
  font-family: ${(props) => props.theme.fonts.bold};
  font-size: 13px;
  margin-right: 7px;
`;
const Highlight = styled.span`
  font-family: inherit;
  color: ${(props) => props.theme.colors.primary};
`;

const WasPrice = styled.span`
  color: ${(props) => props.theme.colors.inactive};
  text-decoration: line-through;
`;
const TotalPrice = styled.div`
  font-size: 17px;
  font-family: ${(props) => props.theme.fonts.bold};
  margin: 10px 0 -10px;
`;
const VerticalFormDivider = styled.hr`
  margin: 15px 0;
  background-color: #d7d7d7;
  border-color: #d7d7d7;
`;

const BreakdownRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 5px;
  font-size: ${FontSizes.cta};
  font-weight: 600;
`;

const LeftSide = styled.div`
  text-align: left;
  flex: 0 1 70%;
`;
const RightSide = styled.div`
  text-align: right;
  flex: 0 1 70%;
`;

const formatLanguages = (langs) =>
  langs.map((lCode) => localization.language[lCode] || getLangName(lCode)).join(', ');

const Total = ({ total, totalWithoutDiscount, bookerCurrency }) => (
  <TotalPrice>
    {Number(total) !== Number(totalWithoutDiscount) && (
      <BreakdownRow style={{ marginBottom: '0' }}>
        <LeftSide>
          <SecondTitle>
            <WasPrice>{localization.payment.tourOptions.normalPrice}</WasPrice>
          </SecondTitle>
        </LeftSide>
        <RightSide>
          <SecondTitle>
            <WasPrice>
              {getPriceWithCurrencySymbol(
                formatIfInr(formatPrice(totalWithoutDiscount)),
                bookerCurrency
              )}
            </WasPrice>
          </SecondTitle>
        </RightSide>
      </BreakdownRow>
    )}
    <BreakdownRow>
      <LeftSide>
        <SecondTitle>{localization.payment.tourOptions.totalPrice}</SecondTitle>
      </LeftSide>
      <RightSide>
        <SecondTitle>
          {getPriceWithCurrencySymbol(formatIfInr(formatPrice(total)), bookerCurrency)}
        </SecondTitle>
      </RightSide>
    </BreakdownRow>
  </TotalPrice>
);

const Breakdown = ({ breakdown, bookerCurrency }) => {
  return (
    <div>
      {breakdown.map((participant, i) => {
        if (participant.priceType === 'flat') {
          return (
            <BreakdownRow key={`price-breakdown-${i}`}>
              <LeftSide>{getParticipantsTextLong(participant)}</LeftSide>
            </BreakdownRow>
          );
        } else {
          switch (participant.type) {
            case 'participant':
              return (
                <BreakdownRow key={`price-breakdown-${i}`}>
                  <LeftSide>
                    {participant.count} {participant.title}
                  </LeftSide>
                  <RightSide>
                    {getPriceWithCurrencySymbol(
                      formatPrice(participant.elementPrice),
                      bookerCurrency
                    )}
                  </RightSide>
                  {/* {getParticipantsTextLong(participant)} */}
                </BreakdownRow>
              );
            case 'additional_extra':
              return (
                <BreakdownRow key={`price-breakdown-${i}`}>
                  <LeftSide>
                    {participant.count} {participant.title}
                  </LeftSide>
                  <RightSide>
                    {getPriceWithCurrencySymbol(
                      formatPrice(participant.elementPrice),
                      bookerCurrency
                    )}
                  </RightSide>
                  {/* {getParticipantsTextLong(participant)} */}
                </BreakdownRow>
              );
            case 'fee':
              return (
                <BreakdownRow key={`price-breakdown-${i}`}>
                  <LeftSide>{localization.payment.fees.transactionFee}</LeftSide>
                  <RightSide>
                    {getPriceWithCurrencySymbol(formatPrice(participant.price), bookerCurrency)}
                  </RightSide>
                  {/* {localization.payment.fees.transactionFee} - {participant.price} */}
                </BreakdownRow>
              );
            default:
              return null;
          }
        }
      })}
    </div>
  );
};

export const BookingOptionVertical = ({ booking }) => {
  const {
    title,
    secondaryTitle,
    duration,
    thumbnail,
    languages,
    date,
    operationalHours,
    price,
    tour,
    bookerCurrency,
  } = booking;
  let durationCount = null;
  let durationUnit = null;
  if (duration) {
    durationCount = duration.split(' ')[0];
    durationUnit = duration.split(' ')[1];
  }

  return (
    <section>
      <CardImageVertical>
        <img alt="Tour preview" src={tour.DisplayImageRemote.CarouselOne} />
      </CardImageVertical>

      <VerticalCardContent>
        <Title>{tour._default_title}</Title>
        {
          //don't show subtitle if it's the same as title(true for tours with one option);
          title !== secondaryTitle && <SubTitle>{secondaryTitle}</SubTitle>
        }
        <div>
          <Date>{getFormattedDate(date)}</Date>

          {!operationalHours && booking.startTime && (
            <FieldWrapper style={{ display: 'inline-block' }}>
              {localization.payment.tourOptions.startTime}
              <Highlight>:&nbsp;{booking.startTime}</Highlight>
            </FieldWrapper>
          )}

          {duration ? (
            <FieldWrapper style={{ display: 'inline-block' }}>
              {localization.payment.tourOptions.duration}:
              <Highlight>
                &nbsp;
                {durationCount}
                &nbsp;
                {localization.tours.duration[durationUnit] || durationUnit}
              </Highlight>
            </FieldWrapper>
          ) : null}

          <VerticalFormDivider />
          <SecondTitle>{localization.orderCancellation.cancellationPolicy}</SecondTitle>
          <div>{booking.tour.translations.en.CancelationPolicySupplierText}</div>

          <Total
            total={booking.priceDetails.totalInUserCurrency || booking.priceDetails.total}
            totalWithoutDiscount={
              booking.priceDetails.totalInUserCurrency || booking.priceDetails.total
            }
            bookerCurrency={bookerCurrency}
          />
        </div>
      </VerticalCardContent>
    </section>
  );
};
