import makeStyles from '@mui/styles/makeStyles';
import { Field } from 'formik';
import React from 'react';
import { FontSizes } from '../../../../../../utils/constants';

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: theme.typography.fontFamily,
    fontSize: FontSizes.p,
    marginBottom: '5px',
    whiteSpace: 'pre-wrap',
  },
  formInput: {
    flexBasis: '100%',
    display: 'block',
    width: '100%',
    margin: '10px 0px',
  },
  field: {
    height: '55px',
    borderRadius: '4px',
    backgroundColor: 'white',
    width: '114.28%', // 16 / 14 * 100, https://thingsthemselves.com/no-input-zoom-in-safari-on-iphone-the-pixel-perfect-way/
    fontSize: FontSizes.p,
    transform: 'scale(0.875)', // 16 / 14
    transformOrigin: 'left center',
    padding: '0 9px',
  },
}));

const QuestionWithAnswers = ({
  question,
  fieldName,
  renderSelectOptionForMode,
  label = null,
  shouldRender = true,
  onChange = null,
}) => {
  const classes = useStyles();
  const isRequiredField = question.required !== 'OPTIONAL';

  if (!shouldRender) return null;

  return (
    <div key={question.id}>
      <div className={classes.title}>{`${label || question.label} ${
        isRequiredField ? '*' : ''
      }`}</div>
      <div className={classes.formInput}>
        <Field
          component="select"
          autoComplete="new-password"
          id={question.id}
          name={fieldName}
          required={question.required !== 'OPTIONAL'}
          multiple={false}
          className={classes.field}
          onChange={onChange}
        >
          <option value="" disabled selected="selected">
            Please select...
          </option>
          {question.allowedAnswers.map(renderSelectOptionForMode)}
        </Field>
      </div>
    </div>
  );
};

export default QuestionWithAnswers;
