import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import localization from '../../localization';
import { Analytics } from '../../utils/analytics';
import { ButtonLarge } from '../Common';

const SeeAll = styled(ButtonLarge)`
  min-width: 200px;
`;

const Card = styled.div`
  background-image: url(${(props) => props.img});
  background-position: center;
  background-size: cover;
  background-color: ${(props) => props.theme.colors.primary};
  min-height: 150;
  border-radius: 3;
  font-size: 44px;
  text-shadow: '-1px 1px 10px rgba(0, 0, 0, 0.85)';
  margin-top: 12;
`;

const Text = styled.div`
  color: white;
  position: relative;
  top: 0px;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 2px 4px;
  padding: 20px;
  font-family: ${(props) => props.theme.fonts.primary};
`;

const LocationBanner = ({ location, extraTrackingProperties }) => {
  if (!location) {
    return null;
  }
  const { location_id, english_name, Image } = location;
  return (
    <NavLink
      to={{ pathname: `/location/${location_id}` }}
      onClick={() => {
        Analytics.track('location selected', {
          ...extraTrackingProperties,
          'location name': location.name || english_name,
          'location id': location.location_id,
        });
      }}
    >
      <Card>
        <Text>
          {location.name || english_name}
          <br />
          <SeeAll>{localization.common.seeAll}</SeeAll>
        </Text>
      </Card>
    </NavLink>
  );
};

export default LocationBanner;
